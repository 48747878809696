import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import LoadingOverlay from "../../../../Common/LoadingOverlay/LoadingOverlay";
import {getAccessTokenFromSession} from "../../../../../helpers/sessionHelpers";
import {getOrganizations} from "../../../../../helpers/organizationAPIServices";

const OrganizationsPage = (props) => {
    const [organizations, setOrganizations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function loadOrganizations() {
            setIsLoading(true);
            const getOrganizationsResults = await getOrganizations(getAccessTokenFromSession());
            console.log(getOrganizationsResults);
            if(getOrganizationsResults.error) {
                console.log('There is an error fetching organizations.');
                setIsLoading(false);
                return;
            }
            setOrganizations(getOrganizationsResults);
            setIsLoading(false);
        }

        loadOrganizations();
    }, [])

    return (
        <div className="main-content">
            {
                isLoading && <LoadingOverlay />
            }
            <div className="container p-3 p-md-5">
                <div className="row mb-4 p-0 mx-0">
                    <div className="col-6 px-0 mx-0">
                        <h2 className="h1 txt-color-dark_purple font-weight-bold">Manage Organizations</h2>
                    </div>
                    <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                        <Link to='/subscribers/organizations/create' className="btn btn-solid btn-solid-orange px-3">
                            <i className="fas fa-plus mr-0 mr-sm-2"></i>
                            <span className="d-none d-sm-block">
                                            Create Organization
                                        </span>
                        </Link>
                    </div>
                </div>

                <div className="col-12 mt-2">
                    <div className="w-100">

                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="top">
                                <div className="row mb-4">
                                    <div className="col-12 col-md-6 show-entries">
                                        <div className="dataTables_length" id="DataTables_Table_0_length">
                                            {/*<label>Show*/}
                                            {/*    <select name="DataTables_Table_0_length"*/}
                                            {/*            aria-controls="DataTables_Table_0"*/}
                                            {/*            className="custom-select custom-select-sm form-control form-control-sm">*/}
                                            {/*        <option value="10">10</option>*/}
                                            {/*        <option value="25">25</option>*/}
                                            {/*        <option value="50">50</option>*/}
                                            {/*        <option value="50">59</option>*/}
                                            {/*        <option value="100">100</option>*/}
                                            {/*    </select>*/}
                                            {/*    entries*/}
                                            {/*</label>*/}
                                        </div>
                                    </div>

                                    {/*<div className="col-12 col-md-6 search-input">*/}
                                    {/*    <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                                    {/*        <label>Search:*/}
                                    {/*            <input type="search" className="form-control form-control-sm"*/}
                                    {/*                   placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                                    {/*        </label>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                            <div className="bottom"></div>
                            <div className="clear"></div>
                        </div>

                        <table className="table table-custom display dt-responsive nowrap w-100">
                            <thead className="thead-light rounded-10">
                            <tr>
                                <th></th>
                                <th>Code</th>
                                <th>Organization Name</th>
                                <th>Contact Person</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>

                            {
                                organizations.map((o) => {
                                    const contact_name = `${o.first_name ? o.first_name : ''} ${o.last_name ? o.last_name : ''}`;
                                    return (
                                        <tr>
                                            <td></td>
                                            <td>{o.code}</td>
                                            <td>{o.name}</td>
                                            <td>{contact_name}</td>
                                            <td>{o.email ? o.email : 'Not available'}</td>
                                            <td>{o.address ? o.address : 'Not available'}</td>
                                            <td>{o.phone ? o.phone : 'Not available'}</td>
                                            <td><Link to={`/subscribers/organizations/edit/${o.code}`} className="text-decoration-none"
                                                   data-toggle="modal" data-target="#view_subscriber_details">Edit</Link></td>
                                        </tr>
                                    )
                                })
                            }





                            </tbody>
                        </table>
                    </div>

                    {/*<nav aria-label="Page navigation example">*/}
                    {/*    <ul className="pagination justify-content-end">*/}
                    {/*        <li className="page-item disabled">*/}
                    {/*            <a className="page-link" href="#" tabIndex="-1"><i*/}
                    {/*                className="fas fa-angle-double-left"></i></a>*/}
                    {/*        </li>*/}
                    {/*        <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                    {/*        <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                    {/*        <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                    {/*        <li className="page-item">*/}
                    {/*            <a className="page-link txt-color-purple" href="#"><i*/}
                    {/*                className="fas fa-angle-double-right"></i></a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</nav>*/}


                </div>


            </div>
        </div>
    )
}

export default OrganizationsPage;