import React from 'react';
import PleaseWaitButton from "../../../../Buttons/PleaseWaitButton/PleaseWaitButton";

class LocationUpdatesViewFooter extends React.Component {
    render() {
        return(
            <footer>
                <div className="modal fade" id="approved_update" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content overflow-hidden border-0">
                            {
                                !this.props.approved ? <div>
                                        <p style={{
                                            textAlign: "center",
                                            padding: '30px',
                                        }}><PleaseWaitButton />Submitting approval...</p>
                                </div> :
                                    <div className="modal-body p-0">
                                        <img src="/img/success_approve.svg" alt="" className="w-100 img-fluid" />

                                        <div className="w-100 p-5">
                                            <h4 className="txt-color-dark_purple text-center">Approved Location Request</h4>

                                            <a href='/requests/'>
                                                <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table">Back to
                                                    List
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="declined_update" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content overflow-hidden border-0">
                            {
                                !this.props.rejected ? <div>
                                        <p style={{
                                            textAlign: "center",
                                            padding: '30px',
                                        }}><PleaseWaitButton/>Submitting...</p>
                                    </div> :
                                    <div className="modal-body p-0">
                                        <img src="/img/declined_location.svg" alt="" className="w-100 img-fluid"/>

                                        <div className="w-100 p-5">
                                            <h4 className="txt-color-dark_purple text-center">Declined Location
                                                Request</h4>

                                            <a href='/requests/'>
                                                <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table">Back to
                                                    List
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TimelineLite.min.js"
                        integrity="sha512-UznfQR3LKkKPL0k7HISMqOsmUCjVhjWK8HlR0Pxo5JpjN8eZ8XRwKBRX6OTOQIO0FYOl8Oue+4dG4uHtx1SgKQ=="
                        crossOrigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                        integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                        crossOrigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                        integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                        crossOrigin="anonymous"></script>
                <script src="animation.js"></script>

            </footer>
        )
    }
}

export default LocationUpdatesViewFooter;