import parse from 'html-react-parser';
import React, {
  useEffect,
  useState
} from "react";
import { useParams } from "react-router";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import { getTierDetails, getTierFeatures } from "../../../helpers/tierAPIServices";
import HeaderSection from "../../Common/HeaderSection/HeaderSection";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";

function TierDetails(props) {
  const { tier_code } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [tierDetails, setTierDetails] = useState(null);
  const [tierFeatures, setTierFeatures] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    async function loadTierDetails() {
      try {
        const response = await getTierDetails(tier_code, getAccessTokenFromSession());
        setTierDetails(response?.result);
      }
      catch (e) {
        console.log(e);
        return;
      }
    }

    async function loadTierFeatures() {
      try {
        const response = await getTierFeatures(tier_code, getAccessTokenFromSession());
        setIsLoading(false);
        setTierFeatures(response?.result);
      }
      catch (e) {
        console.log(e);
        return;
      }
    }

    loadTierDetails();
    loadTierFeatures();
  }, [tier_code]);

  const getPaymentMode = (item) => {
    if (item?.code === "BASIC") return "Free";

    let paymentMode = null;

    switch (item?.payment_mode) {
      case "monthly":
        paymentMode = "month";
        break;
      case "annually":
        paymentMode = "year";
        break;
      case "daily":
        paymentMode = "day";
        break;
      // no default
    }

    return (paymentMode) ? `$${item?.price} / ${paymentMode}` : "";
  }

  const getSubscribersCount = (item) => {
    return item?.subscribers?.length ?? 0;
  }

  const renderTierFeatures = () => {
    let layout = [];

    for (let i in tierFeatures) {
      let item = tierFeatures[i];
      let description = item?.description;

      layout.push(
        <li className="txt-color-secondary">
          {parse(description)}
        </li>
      );
    }

    return layout;
  }

  return (
    <>
      <HeaderSection />
      {
        isLoading ? <LoadingOverlay /> : (
          <main>
            <div className="main-content">

              <div className="container p-3 p-md-5">
                <div className="row mb-4 p-0 mx-0">
                  <div className="col-12 px-0 mx-0">
                    <h2 className="h1 txt-color-dark_purple font-weight-bold">View Tier Details</h2>
                    <a href="/tiers" className="mt-4 d-table txt-color-purple text-decoration-none ">
                      <i className="fas fa-arrow-left mr-2"></i>
                      Back to Tiers
                    </a>
                  </div>
                </div>

                <div className="row mt-5 mx-0">
                  <div className="col-12 col-lg-8  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                    <div className="d-table w-100 mb-4">
                      <div className="row border-bottom mx-0 px-0 pb-4 mb-4">
                        <div className="col-10 px-0">
                          <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">{`${tierDetails?.name}`}</h3>
                          <div className="w-100">
                            <span className="form-label">{getPaymentMode(tierDetails)}</span>
                          </div>
                          <div className="w-100">
                            <span className="form-label">{`${getSubscribersCount(tierDetails)} Subscriber(s)`}</span>
                          </div>
                        </div>

                        <div className="col-2 justify-content-end">
                          <a href={`/tiers/${tier_code}/edit`} className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none">
                            <i className="fas fa-pen mr-1"></i>
                            <span className="d-none d-sm-inline">Edit</span>
                          </a>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <ul>
                            {renderTierFeatures()}
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
        )
      }
    </>
  )
}


export default TierDetails;
