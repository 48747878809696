import React from 'react';

class LocationCard extends React.Component {

    handleSelectClicked = (e) => {
        e.preventDefault();
        this.props.onSelectClicked(this.props.practice);
    }

    render() {
        return (
            <div
                className="row border-light_blue p-1 p-sm-3 mx-0 mb-4 rounded-10 campaign-location-btn"
                data-address="1915 S. Coulter St. Amarillo TX 79106"
                data-name="Amarillo Urgent Care" onClick={this.handleSelectClicked} data-dismiss="modal" aria-label="Close">
                <div className="col-12 col-md-8">
                    <div className="w-100 mb-3 d-flex">
                        <span className="d-table  font-weight-bold">{this.props.practice.name}</span>
                    </div>
                    <div className="w-100 mb-3 d-flex">
                                        <span className="d-table txt-color-purple mr-3"><i
                                            className="fas fa-map-marker-alt"></i></span>
                        <span className="d-table font-weight-normal">
                        {`${this.props.practice.address}, ${this.props.practice.city}, ${this.props.practice.state} ${this.props.practice.zip}`}
                                        </span>
                    </div>
                </div>

                <div className="col-2 d-none d-sm-inline col-md-4 justify-content-end" onClick={this.handleSelectClicked} data-dismiss="modal" aria-label="Close">
                    <a href="void(0);" className="btn-select-changes btn-select-changes-single float-right" onClick={this.handleSelectClicked} data-dismiss="modal" aria-label="Close">
                                        <span className="d-none d-sm-block">
                                            Select
                                        </span>
                    </a>
                </div>
            </div>
        )
    }
};

export default LocationCard;