import React from 'react';
import HeaderSection from "../../Common/HeaderSection/HeaderSection";
import {getMyLocations} from "../../../helpers/accountAPIServices";
import {getAccessTokenFromSession, getUserProfileFromSession} from "../../../helpers/sessionHelpers";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import {DAYS, getTimeDecimalEquivalent, isStillOpenV2} from "../../../helpers/timeHelpers";
import MyLocationsPageFooter from "../MyLocationsPageFooter/MyLocationsPageFooter";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";


class MyLocationsPage extends React.Component {
    state = {
        myLocations: {
            approved: [],
            rejected: [],
            pending_edit: [],
            pending_new: []
        },
        isLoading: false,
    }

    async componentDidMount() {
        // get locations
        this.setLoading(true)
        const getLocationsResults = await getMyLocations(getAccessTokenFromSession());

        if(getLocationsResults.error) {
            console.log("EEERRRRRRPRRR")
            this.setErrors(['There is a problem getting your locations. Please, send a message to contact@urgentcare.com']);
            this.setLoading(false);

            return;
        }

        console.log(getLocationsResults);
        this.setState({
            myLocations: {
                ...getLocationsResults,
            },
            isLoading: false,
        }, () => console.log(this.state.myLocations));
        // this.setLoading(false);
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    setErrors = (errorList) => {
        this.setState({
            message: "",
            errors: errorList,
        });
    }

    isActiveAccount = () => {
        console.log("ACTIVE OR NOT?");
        console.log(this.state.myLocations.approved.length > 0);
        return this.state.myLocations.approved.length > 0;
    }

    render() {
        const allLocations = [...this.state.myLocations.approved,
            ...this.state.myLocations.pending_new,
            ...this.state.myLocations.pending_edit,
            ...this.state.myLocations.rejected];
        return (
            <>
                {
                    (getUserProfileFromSession() == null) && <Redirect to='/' />
                }
                <HeaderSection />
                <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">My Locations</h2>
                        </div>
                        <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                            <Link to="/listings/new" className="btn btn-solid btn-solid-orange px-3">
                                <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                <span className="d-none d-sm-block">
                                New Location
                            </span>
                            </Link>
                            <a href="https://www.urgentcare.com/?claim=True" target="_blank" rel="noreferrer" className="btn btn-solid btn-solid-orange px-3 ml-3">
                                <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                <span className="d-none d-sm-block">
                                Claim Location
                            </span>
                            </a>
                        </div>
                        {/*<div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">*/}
                        {/*    <a href="my_location_add_new.html" className="btn btn-solid btn-solid-orange px-3">*/}
                        {/*        <i className="fas fa-plus mr-0 mr-sm-2"></i>*/}
                        {/*        <span className="d-none d-sm-block">*/}
                        {/*        New Location*/}
                        {/*    </span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        {
                            !this.isActiveAccount() && !this.state.isLoading &&
                            <div className="col-12 px-0 mx-0 mt-4">
                                <p className="txt-color-dark_purple py-3 px-4 rounded-40 bg-color-yellow d-table">
                                    <i className="fas fa-exclamation-circle mr-2"></i><strong>Your account is inactive.</strong> You will
                                    not be
                                    able to update your listing until an <strong>UrgentCare.com</strong> associate has
                                    approved
                                    your account.
                                </p>
                            </div>
                        }
                    </div>

                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-md-10 col-lg-7 border-light_blue rounded-4 p-3 p-xl-5 mx-0">
                            <div className="d-table w-100 ">
            
                            </div>

                            {
                                this.state.isLoading ? <LoadingOverlay /> :
                                <div className="col-12 mx-0 px-0">

                                    {
                                        this.state.myLocations.approved.length < 1 &&
                                            this.state.myLocations.pending_edit.length < 1 &&
                                            this.state.myLocations.pending_new.length < 1 &&
                                            this.state.myLocations.rejected < 1 && <p className="information">You don't have any approved claims.</p>
                                    }

                                    {
                                        this.state.myLocations.pending_edit.length > 0 &&
                                        <div className="locations">
                                            <div className="d-table w-100 ">
                                                <h5 className="claims txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0" style={{
                                                    color: 'gray'
                                                }}>Pending Claims
                                                </h5>
                                            </div>

                                            {
                                                this.state.myLocations.pending_edit.map((location) => {
                                                    const dateToday = new Date();
                                                    const currentDay = DAYS[dateToday.getDay()];
                                                    const schedule = location.schedules.find((schedule) => schedule.occurrence.name === currentDay);
                                                    const timeStatus = schedule ? isStillOpenV2(getTimeDecimalEquivalent(schedule.opening), getTimeDecimalEquivalent(schedule.closing)) : "";

                                                    return (
                                                        <a href="#location_details"
                                                           className="w-100 rounded-10 border-light_blue p-3 mb-3 location-item"
                                                           data-toggle="modal"
                                                            // data-target="#location_details">
                                                           data-target={`#modal-location-${location.practice_id}-${location.approval_status}`}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-5 mb-3 mb-sm-0">
                                                                    <img src="/img/sample_loc_profile.png" alt=""
                                                                         className="border-light_blue rounded-10 d-table mx-auto img-fluid w-100" />
                                                                </div>

                                                                <div className="col-12 col-sm-7 mx-0">
                                                                    <div className="w-100">
                                                                        <p className="txt-color-dark_purple">
                                                                            <strong>
                                                                                {location.name}
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                            <span className="txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                                        <p className="txt-color-dark_purple">{`${location.address}, ${location.city}, ${location.state} ${location.zip}`}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <span className="txt-color-purple mr-3"><i className="fas fa-clock"></i></span>
                                                                        <p className="txt-color-dark_purple pb-0 mb-0">
                                                                            {timeStatus !== "" ?`${timeStatus.status}${timeStatus.message !== "" ? " - " + timeStatus.message : ""}` : "No schedule information."}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }


                                        </div>
                                    }

                                    {
                                        this.state.myLocations.pending_new.length > 0 &&
                                        <div className="locations">
                                            <div className="d-table w-100 ">
                                                <h5 className="claims txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0" style={{
                                                    color: 'gray'
                                                }}>Pending New Claims
                                                </h5>
                                            </div>

                                            {
                                                this.state.myLocations.pending_new.map((location) => {
                                                    const dateToday = new Date();
                                                    const currentDay = DAYS[dateToday.getDay()];
                                                    const schedule = location.schedules.find((schedule) => schedule.occurrence.name === currentDay);
                                                    const timeStatus = schedule ? isStillOpenV2(getTimeDecimalEquivalent(schedule.opening), getTimeDecimalEquivalent(schedule.closing)) : "";

                                                    return (
                                                        <a href="#location_details"
                                                           className="w-100 rounded-10 border-light_blue p-3 mb-3 location-item"
                                                           data-toggle="modal"
                                                            // data-target="#location_details">
                                                           data-target={`#modal-location-${location.practice_id}-${location.approval_status}`}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-5 mb-3 mb-sm-0">
                                                                    <img src="/img/sample_loc_profile.png" alt=""
                                                                         className="border-light_blue rounded-10 d-table mx-auto img-fluid w-100" />
                                                                </div>

                                                                <div className="col-12 col-sm-7 mx-0">
                                                                    <div className="w-100">
                                                                        <p className="txt-color-dark_purple">
                                                                            <strong>
                                                                                {location.name}
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                            <span className="txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                                        <p className="txt-color-dark_purple">{`${location.address}, ${location.city}, ${location.state} ${location.zip}`}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <span className="txt-color-purple mr-3"><i className="fas fa-clock"></i></span>
                                                                        <p className="txt-color-dark_purple pb-0 mb-0">
                                                                            {timeStatus !== "" ?`${timeStatus.status}${timeStatus.message !== "" ? " - " + timeStatus.message : ""}` : "No schedule information."}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }


                                        </div>
                                    }

                                    {
                                        this.state.myLocations.approved.length > 0 &&
                                        <div className="locations">
                                            <div className="d-table w-100 ">
                                                <h5 className="claims txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0" style={{
                                                    color: 'green'
                                                }}>Approved Claims
                                                </h5>
                                            </div>

                                            {
                                                this.state.myLocations.approved.map((location) => {
                                                    const dateToday = new Date();
                                                    const currentDay = DAYS[dateToday.getDay()];
                                                    const schedule = location.schedules.find((schedule) => schedule.occurrence.name === currentDay);
                                                    const timeStatus = schedule ? isStillOpenV2(getTimeDecimalEquivalent(schedule.opening), getTimeDecimalEquivalent(schedule.closing)) : "";

                                                    return (
                                                        <a href="#location_details"
                                                           className="w-100 rounded-10 border-light_blue p-3 mb-3 location-item"
                                                           data-toggle="modal"
                                                            // data-target="#location_details">
                                                           data-target={`#modal-location-${location.practice_id}-${location.approval_status}`}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-5 mb-3 mb-sm-0">
                                                                    <img src="/img/sample_loc_profile.png" alt=""
                                                                         className="border-light_blue rounded-10 d-table mx-auto img-fluid w-100" />
                                                                </div>

                                                                <div className="col-12 col-sm-7 mx-0">
                                                                    <div className="w-100">
                                                                        <p className="txt-color-dark_purple">
                                                                            <strong>
                                                                                {location.name}
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                            <span className="txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                                        <p className="txt-color-dark_purple">{`${location.address}, ${location.city}, ${location.state} ${location.zip}`}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <span className="txt-color-purple mr-3"><i className="fas fa-clock"></i></span>
                                                                        <p className="txt-color-dark_purple pb-0 mb-0">
                                                                            {timeStatus !== "" ?`${timeStatus.status}${timeStatus.message !== "" ? " - " + timeStatus.message : ""}` : "No schedule information."}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }


                                        </div>
                                    }

                                    {
                                        this.state.myLocations.rejected.length > 0 &&
                                        <div className="locations">
                                            <div className="d-table w-100 ">
                                                <h5 className="claims txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0" style={{
                                                    color: 'red'
                                                }}>Rejected Claims
                                                </h5>
                                            </div>

                                            {
                                                this.state.myLocations.rejected.map((location) => {
                                                    const dateToday = new Date();
                                                    const currentDay = DAYS[dateToday.getDay()];
                                                    const schedule = location.schedules.find((schedule) => schedule.occurrence.name === currentDay);
                                                    const timeStatus = schedule ? isStillOpenV2(getTimeDecimalEquivalent(schedule.opening), getTimeDecimalEquivalent(schedule.closing)) : "";

                                                    return (
                                                        <a href="#location_details"
                                                           className="w-100 rounded-10 border-light_blue p-3 mb-3 location-item"
                                                           data-toggle="modal"
                                                            // data-target="#location_details">
                                                           data-target={`#modal-location-${location.practice_id}-${location.approval_status}`}>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-5 mb-3 mb-sm-0">
                                                                    <img src="/img/sample_loc_profile.png" alt=""
                                                                         className="border-light_blue rounded-10 d-table mx-auto img-fluid w-100" />
                                                                </div>

                                                                <div className="col-12 col-sm-7 mx-0">
                                                                    <div className="w-100">
                                                                        <p className="txt-color-dark_purple">
                                                                            <strong>
                                                                                {location.name}
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                            <span className="txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                                        <p className="txt-color-dark_purple">{`${location.address}, ${location.city}, ${location.state} ${location.zip}`}</p>
                                                                    </div>
                                                                    <div className="w-100 d-flex">
                                                                        <span className="txt-color-purple mr-3"><i className="fas fa-clock"></i></span>
                                                                        <p className="txt-color-dark_purple pb-0 mb-0">
                                                                            {timeStatus !== "" ?`${timeStatus.status}${timeStatus.message !== "" ? " - " + timeStatus.message : ""}` : "No schedule information."}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                })
                                            }


                                        </div>
                                    }


                                </div>
                            }



                        </div>


                    </div>
                </div>
            </div>
                <MyLocationsPageFooter myLocations={allLocations} token={getAccessTokenFromSession()}/>
            </>
        )
    }
}

export default MyLocationsPage;