import {catchGenericError, checkStatus, parseJSON} from "./responseHandlers";

const SUPERADMIN_API_ROOT_URL = process.env.REACT_APP_SUPERADMIN_API_ROOT_URL;


const login = (credentials) => {
    const URL = SUPERADMIN_API_ROOT_URL + "token/";
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials)
    }

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getUserDetails = (token, email) => {
    const URL = SUPERADMIN_API_ROOT_URL + "account/?email=" + email;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        }
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    login,
    getUserDetails,
    SUPERADMIN_API_ROOT_URL
}