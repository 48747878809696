import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { getUSAStatesForOptionsComponent } from "../../../../helpers/addressHelpers";
import {
    editOrganizationCampaign,
    getCampaignAsAdmin, newGeolocationCampaign
} from "../../../../helpers/campaignAPIServices";
import { searchClinicsForInvite } from "../../../../helpers/sendInviteAPIServices";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";

const NewGeolocationPlanPage = (props) => {
    const [campaign, setCampaign] = useState({
        location_name: '',
        campaign_name: '',
        campaign_url: '',
        locations: [],
    });

    const {code} = useParams();

    const [error, setError] = useState(null);

    const [fieldErrors, setFieldErrors] = useState({
        locationNameError: false,
        organizationAddressError: false,
        locationsError: false,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isNewCampaignLoading, setIsNewCampaignLoading] = useState(false);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const [fetchedLocations, setFetchedLocations] = useState([]);
    const [filteredFetchedLocations, setFilteredFetchedLocations] = useState([]);

    const [fetchedCampaign, setFetchedCampaign] = useState(null);

    const [redirectTo404, setRedirectTo404] = useState(false);

    const [searchDetails, setSearchDetails] = useState({
        query: "",
        zip: "",
        city: "",
        state: "default",
    });

    const [previewLink, setPreviewLink] = useState('');

    const [filters, setFilters] = useState({
        covid_testing: false,
        antibody_testing: false,
        xray: false,
    });

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const validations = {
            locationNameError: campaign.location_name === "",
            organizationAddressError: campaign.address === "",
            locationsError: selectedLocations.length < 1,
        }

        setFieldErrors(validations);
    }, [campaign, selectedLocations])

    const prepareCampaignFields = () => {
        let campaignFields = {};
        campaignFields.location_name = campaign.location_name;
        campaignFields.address = campaign.address;

        if (campaign.campaign_name !== "") campaignFields.campaign_name = campaign.campaign_name;
        if (campaign.campaign_url !== "") campaignFields.campaign_url = campaign.campaign_url;

        campaignFields.locations = selectedLocations.map(location => location.practice_id);

        if(props.edit) {
            campaignFields = {};
            if(campaign.organization_name !== fetchedCampaign.organization_name) {
                campaignFields.organization_name = campaign.organization_name;
            }

            if(campaign.address !== fetchedCampaign.organization_address) {
                campaignFields.address = campaign.address;
            }

            if(campaign.campaign_url !== fetchedCampaign.campaign_url) {
                campaignFields.campaign_url = campaign.campaign_url;
            }

            if(campaign.campaign_name !== fetchedCampaign.campaign_name) {
                campaignFields.campaign_name = campaign.campaign_name;
            }

            let isNewLocationSet = false;
            if(fetchedCampaign.locations.length !== selectedLocations.length) isNewLocationSet = true;
            for(let location of fetchedCampaign.locations) {
                if(!selectedLocations.find((s) => location.practice_id === s.practice_id)) isNewLocationSet = true;
            }
            if(isNewLocationSet) campaignFields.locations = selectedLocations.map(location => location.practice_id);
        }

        // console.log(campaignFields);

        return campaignFields;
    }

    const handlePreviewCampaign = () => {
        const practices = selectedLocations.map((l) => l.practice_id).join(',');
        const locationName = encodeURI(campaign.location_name);
        const link = `https://www.urgentcare.com/campaigns/preview/?locations=${practices}&type=GEOLOCATION_PLAN&location_name=${locationName}`;
        setPreviewLink(link);
    }

    const handleSubmitNewGeolocationCampaignClick = async () => {
        const campaignFields = prepareCampaignFields();
        console.log(campaignFields);
        if(Object.keys(campaignFields).length === 0) {
            setError('No changes to the fields are submitted. Aborting edit.');
            return;
        }
        setError(null);
        setSuccess(null);

        setIsNewCampaignLoading(true);
        const newCampaignResults = !props.edit ? await newGeolocationCampaign(campaignFields, getAccessTokenFromSession()) :
            await editOrganizationCampaign(code, campaignFields, getAccessTokenFromSession());

        if(newCampaignResults.error) {
            setError(newCampaignResults.error);
            setIsNewCampaignLoading(false);
            return;
        }

        setIsNewCampaignLoading(false);
        setSuccess(!props.edit ? "The new geolocation campaign is successfully added." :
            "The geolocation campaign has been successfully edited.");
    }

    const prepareSearchDetails = (fields) => {
        return {
            ...fields,
            state: fields.state === "default" ? "" : fields.state
        }
    }

    const handleClickPracticeToCampaign = (practice) => {
        // check if it's already selected
        if (selectedLocations.find((selectedLocation) => selectedLocation.practice_id === practice.practice_id)) {
            const newSelectedLocations = selectedLocations.filter((location) => location.practice_id !== practice.practice_id);
            setSelectedLocations(newSelectedLocations);
        } else {
            const newSelectedLocations = [...selectedLocations, practice];
            setSelectedLocations(newSelectedLocations);
        }
    }

    const handleRemoveLocationClick = (practice_id) => {
        const newSelectedLocations = selectedLocations.filter((location) => location.practice_id !== practice_id);
        setSelectedLocations(newSelectedLocations);
    }

    useEffect(() => {
        console.log(selectedLocations)
    }, [selectedLocations]);

    const handleSearchLocationClick = async () => {
        const preparedSearchDetails = prepareSearchDetails(searchDetails);
        setIsLoading(true);
        const searchResults = await searchClinicsForInvite(preparedSearchDetails);

        if (searchResults.error) {
            console.log('There is an error searching for clinics.');
            setIsLoading(false);

            return;
        }

        console.log(searchResults);
        setFetchedLocations(searchResults);
        setIsLoading(false);
    }

    const filterClinics = (clinics, filter) => {
        // COVID-19 tests
        let filtered_clinics = [...clinics];
        if (filter.covid_testing) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.covid19TestServices.length > 0);
        }

        if (filter.antibody_testing) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.antibodyTestServices.length > 0);
        }

        if (filter.xray) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.xray);
        }

        return filtered_clinics;
    }

    useEffect(() => {
        setFilteredFetchedLocations(filterClinics(fetchedLocations, filters));
    }, [fetchedLocations, filters])


    const isValidFields = () => {
        const fields = searchDetails;
        return !([
            fields.query === "",
            fields.zip === "",
            fields.city === "",
            fields.state === "default" || fields.state === "",
        ].every((test) => test));
    }


    const handleFilterChanges = (event) => {
        const fieldName = event.target.name;
        const checked = event.target.checked;

        const newFilters = {
            ...filters,
        };

        newFilters[fieldName] = checked;
        setFilters(newFilters);
    }

    const handleSearchDetailsChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const newSearchDetails = {
            ...searchDetails,
        };

        newSearchDetails[fieldName] = fieldValue;
        setSearchDetails(newSearchDetails);
    }

    const handleCampaignDetailsChange = (event) => {
        const fieldName = event.target.name;
        let fieldValue = event.target.value;
        if(fieldName === 'campaign_url') {
            fieldValue = fieldValue.replaceAll(/[^a-zA-Z0-9-]+/g, '');
        }
        const newCampaignDetails = {
            ...campaign,
        };

        newCampaignDetails[fieldName] = fieldValue;
        setCampaign(newCampaignDetails);
    }

    useEffect(() => {
        async function loadOrganizationPlan() {
            setIsLoading(true);
            const getOrganizationPlanResults = await getCampaignAsAdmin(code, getAccessTokenFromSession());
            console.log(getOrganizationPlanResults);

            if(getOrganizationPlanResults.error) {
                console.log('Error in fetching organization campaign.');
                console.log(getOrganizationPlanResults.error);
                setRedirectTo404(true);
                setIsLoading(false);
                return;
            }

            setFetchedCampaign(getOrganizationPlanResults);
            const newCampaignDetails = {
                campaign_name: getOrganizationPlanResults.campaign_name,
                organization_name: getOrganizationPlanResults.organization_name,
                campaign_url: getOrganizationPlanResults.campaign_url,
                address: getOrganizationPlanResults.organization_address,
            }

            setCampaign(newCampaignDetails);

            setSelectedLocations([...getOrganizationPlanResults.locations]);
            setIsLoading(false);
        }

        if(props.edit) {
            loadOrganizationPlan();
        }
    }, [code, props.edit])


    return (
        <>
            {/*TODO: comment when geolocation campaign is done.*/}
            {/*<Redirect to='/campaigns' />*/}
            {
                redirectTo404 && <Redirect to='/404' />
            }
            {
                isNewCampaignLoading ? <LoadingOverlay /> :
                    <div className="main-content">

                        <div className="container p-3 p-md-5">
                            {
                                success && <div className="alert alert-success mb-5" role="alert">
                                    <i className="fa fa-check-circle mr-2"></i>{success}
                                </div>
                            }
                            {
                                error && <div className="alert alert-success error-message mb-5" role="alert">
                                    <i className="fa fa-exclamation-circle mr-2"></i>{error}
                                </div>
                            }
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-6 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold">{props.edit ? "Edit Geolocation Campaign" :"New Geolocation Campaign"}</h2>
                                    <Link to='/campaigns'
                                          className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                        className="fas fa-arrow-left mr-2"></i> Back to Marketing Campaigns</Link>
                                </div>
                            </div>
                            <div className="row mt-5 mx-0">
                                <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                    <div className="d-table w-100 ">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Campaign
                                            Information</h3>
                                    </div>

                                    <div className="col-12 mx-0 px-0">
                                        <div className="form-row mb-4 mx-0 px-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label htmlFor="" className="form-label">Geographical Name</label>
                                                <input type="text" value={campaign.organization_name} className="form-control"
                                                       name='location_name' onChange={handleCampaignDetailsChange}/>
                                            </div>
                                            {
                                                fieldErrors.locationNameError && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                </small>
                                            }
                                        </div>
                                    </div>

                                    {/*<div className="col-12 mx-0 px-0">*/}
                                    {/*    <div className="form-row mb-4 mx-0 px-0">*/}
                                    {/*        <div className="col-12 mx-0 px-0">*/}
                                    {/*            <label htmlFor="" className="form-label">Organization Address</label>*/}
                                    {/*            <input type="text" className="form-control" value={campaign.address} name='address'*/}
                                    {/*                   onChange={handleCampaignDetailsChange}/>*/}
                                    {/*        </div>*/}
                                    {/*        {*/}
                                    {/*            fieldErrors.organizationAddressError && <small className="py-2 d-table txt-color-secondary">*/}
                                    {/*                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required*/}
                                    {/*            </small>*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}


                                    <div className="col-12 mx-0 px-0">
                                        <div className="form-row mb-4 mx-0 px-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label htmlFor="" className="form-label">Campaign Name</label>
                                                <input type="text" className="form-control" value={campaign.campaign_name}
                                                       name='campaign_name' onChange={handleCampaignDetailsChange}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 mx-0 px-0">
                                        <div className="form-row mb-4 mx-0 px-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label htmlFor="" className="form-label">URL Endpoint</label>
                                                <input type="text" className="form-control"
                                                       value={campaign.campaign_url} name='campaign_url'
                                                       onChange={handleCampaignDetailsChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="col-12 mx-0 px-0">*/}
                                    {/*    <div className="form-row mb-4 mx-0 px-0">*/}
                                    {/*        <div className="col-12 mx-0 px-0">*/}
                                    {/*            <label htmlFor="" className="form-label">ZIP Code</label>*/}
                                    {/*            <input type="text" className="form-control"*/}
                                    {/*                   value={campaign.campaign_url} name='campaign_url'*/}
                                    {/*                   onChange={handleCampaignDetailsChange}/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="col-12 mx-0 px-0">*/}
                                    {/*    <div className="form-row mb-4 mx-0 px-0">*/}
                                    {/*        <div className="col-12 mx-0 px-0">*/}
                                    {/*            <label htmlFor="" className="form-label">Latitude</label>*/}
                                    {/*            <input type="text" className="form-control"*/}
                                    {/*                   value={campaign.campaign_url} name='campaign_url'*/}
                                    {/*                   onChange={handleCampaignDetailsChange}/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="col-12 mx-0 px-0">*/}
                                    {/*    <div className="form-row mb-4 mx-0 px-0">*/}
                                    {/*        <div className="col-12 mx-0 px-0">*/}
                                    {/*            <label htmlFor="" className="form-label">Longitude</label>*/}
                                    {/*            <input type="text" className="form-control"*/}
                                    {/*                   value={campaign.campaign_url} name='campaign_url'*/}
                                    {/*                   onChange={handleCampaignDetailsChange}/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-12 mx-0 px-0 mb-4">
                                        <div className="form-row mb-4 mx-0 px-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label htmlFor="" className="form-label">Locations</label>
                                                {
                                                    fieldErrors.locationsError && <small className="py-2 d-table txt-color-secondary">
                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                    </small>
                                                }
                                                <button className="btn btn-outline btn-outline-purple " data-toggle="modal"
                                                        data-target="#select_location">
                                                    <i className="fas fa-plus mr-2"></i>
                                                    Select Locations
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        selectedLocations.map(location => {
                                            return (
                                                <div className="w-100 border-light_blue rounded-4 p-3 p-md-3 row p-0 m-0 mb-3" key={location.practice_id}>
                                                    <div className="w-100 mb-3 d-flex">
                                                        <span className="d-table  font-weight-bold">{location.name}</span>
                                                    </div>
                                                    <div className="col-10 col-md-10 pl-0">
                                                        <div className="w-100 mb-3 d-flex ">
                                    <span className="d-table txt-color-purple mr-3"><i
                                        className="fas fa-map-marker-alt"></i></span>
                                                            <span className="d-table font-weight-normal">
                                        {`${location.address}, ${location.city}, ${location.state} ${location.zip}`}
                                    </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 col-md-2  text-right">
                                                        <a href='void(0);' className="text-right" onClick={(e) => {
                                                            e.preventDefault()
                                                            handleRemoveLocationClick(location.practice_id)
                                                        }}><i className="fa fa-times"></i></a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                    <div className="col-12 mx-0 px-0 pt-4 ">
                                        {
                                            error && <p style={{color: 'red'}}>{error}</p>
                                        }
                                        <div className="form-row mx-0 px-0">
                                            {
                                                fieldErrors.locationsError || fieldErrors.organizationAddressError || fieldErrors.locationNameError ?

                                                    <button
                                                        className="btn btn-solid btn-solid-orange d-inline-block w-100 " style={{backgroundColor: 'gray'}}>
                                                        Save
                                                    </button>
                                                    :
                                                    <button onClick={handleSubmitNewGeolocationCampaignClick}
                                                            className="btn btn-solid btn-solid-orange d-inline-block w-100 ">
                                                        Save
                                                    </button>
                                            }


                                        </div>

                                        <div className="form-row mx-0 px-0" style={{
                                            margin: "10px 0px",
                                        }}>
                                            {
                                                fieldErrors.locationsError || fieldErrors.organizationAddressError || fieldErrors.locationNameError ?

                                                    <button
                                                        className="btn btn-solid btn-solid-orange d-inline-block w-100 " style={{backgroundColor: 'gray'}}>
                                                        Preview Campaign
                                                    </button>
                                                    :
                                                    <a href={previewLink} target='_blank' rel='noreferrer' onClick={handlePreviewCampaign}
                                                       className="btn btn-solid btn-solid-orange d-inline-block w-100 ">
                                                        Preview Campaign
                                                    </a>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="success" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content overflow-hidden border-0">
                                    <div className="modal-body p-0">
                                        <img src="img/success_approve.svg" alt="" className="w-100 img-fluid"/>

                                        <div className="w-100 p-5">
                                            <h4 className="txt-color-dark_purple text-center">Success!</h4>
                                            <p className="txt-color-dark_purple  text-center my-4">
                                                You have successfully edited a campaign.
                                            </p>

                                            <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table">Back to List
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="modal fade p-5" id="select_landing" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="exampleModalLabel">Select Site Map</h3>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times-circle fa-xs"></i>
                                        </button>
                                    </div>
                                    <div className="modal-body p-4">

                                        <div className="row py-3 mx-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label className="form-label font-weight-bold" htmlFor="">Select Site Map:</label>
                                                <div className="form-row mx-0 px-4">
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" id="covid_testing" name="customRadioInline1"
                                                               className="form-control-input-checkbox"/>
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="covid_testing">Results Listings</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" id="x_ray" name="customRadioInline1"
                                                               className="form-control-input-checkbox"/>
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="x_ray">Detailed Listing</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" id="antibody_testing" name="customRadioInline1"
                                                               className="form-control-input-checkbox"/>
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="antibody_testing">Landing A</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" id="24_hrs" name="customRadioInline1"
                                                               className="form-control-input-checkbox"/>
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="24_hrs">Landing B</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" id="24_hrs" name="customRadioInline1"
                                                               className="form-control-input-checkbox"/>
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="24_hrs">Landing C</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="modal-footer">

                                        <a href="void(0);" onClick={(e) => e.preventDefault()} className="btn btn-solid btn-solid-orange w-100 ">
                                            Continue
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="modal fade" id="success" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content overflow-hidden border-0">
                                    <div className="modal-body p-0">
                                        <img src="img/success_approve.svg" alt="" className="w-100 img-fluid"/>

                                        <div className="w-100 p-5">
                                            <h4 className="txt-color-dark_purple text-center">Success!</h4>
                                            <p className="txt-color-dark_purple  text-center my-4">
                                                You have successfully added a new campaign.
                                            </p>

                                            <a href="marketing_campaign.html"
                                               className="btn btn-block btn-solid btn-solid-orange w-100 mt-4 d-table pt-2">Back
                                                to List</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="modal fade p-5" id="select_location" tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title" id="exampleModalLabel">Select Locations</h3>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <i className="fas fa-times-circle fa-xs"></i>
                                        </button>
                                    </div>
                                    {
                                        isLoading && <LoadingOverlay />
                                    }
                                    <div className="modal-body p-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-input-search-label"></label>
                                                <input type="text" className="form-control form-control-input-search"
                                                       value={searchDetails.query} name='query'
                                                       onChange={handleSearchDetailsChange}/>
                                            </div>
                                        </div>
                                        <div className="row py-3 mx-0">
                                            <div className="col-12 mx-0 px-0">
                                                <label className="form-label font-weight-bold" htmlFor="">Filter:</label>
                                                <div className="form-row mx-0 px-4">
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-checkbox">
                                                        <input type="checkbox" id="covid_testing" name="covid_testing"
                                                               className="" onChange={handleFilterChanges}
                                                               checked={filters.covid_testing}/>
                                                        <label className="" htmlFor="covid_testing" style={{
                                                            margin: '0px 10px',
                                                        }}>COVID-19 Testing</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-checkbox">
                                                        <input type="checkbox" id="antibody_testing" name="antibody_testing"
                                                               className="" onChange={handleFilterChanges}
                                                               checked={filters.antibody_testing}/>
                                                        <label className="" htmlFor="antibody_testing" style={{
                                                            margin: '0px 10px',
                                                        }}>Antibody Testing</label>
                                                    </div>
                                                    <div
                                                        className="col-6 mx-0 py-2 custom-checkbox">
                                                        <input type="checkbox" id="xray" name="xray"
                                                               className="" onChange={handleFilterChanges} checked={filters.xray}/>
                                                        <label className="" htmlFor="xray" style={{
                                                            margin: '0px 10px',
                                                        }}>X-Ray</label>
                                                    </div>
                                                    {/*<div*/}
                                                    {/*    className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                                    {/*    <input type="checkbox" id="24_hrs" name="customRadioInline1"*/}
                                                    {/*           className="form-control-input-checkbox" />*/}
                                                    {/*        <label className="form-control-input-checkbox-label form-label"*/}
                                                    {/*               htmlFor="24_hrs">24 Hours</label>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="row py-4 d-flex align-items-center">*/}
                                        {/*    <div className="col-4">*/}
                                        {/*        <label htmlFor="" className="form-control-label">*/}
                                        {/*            <span className="font-weight-bold">Radius:</span>*/}
                                        {/*            <span className="font-weight-normal" id="rad_value">50</span>*/}
                                        {/*            <span className="font-weight-normal">mi</span>*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-6">*/}
                                        {/*        <input type="range" min="1" max="100" value="50" data-orientation="horizontal" />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="row mb-4">
                                            <div className="col-4">
                                                <label htmlFor="" className="form-label">ZIP Code</label>
                                                <input type="text" className="form-control" name='zip' value={searchDetails.zip}
                                                       onChange={handleSearchDetailsChange}/>
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor="" className="form-label">City</label>
                                                <input type="text" className="form-control" name='city' value={searchDetails.city}
                                                       onChange={handleSearchDetailsChange}/>
                                            </div>
                                            <div className="col-4">
                                                <label htmlFor="" className="form-label">State</label>
                                                <select name='state' className="form-control"
                                                        value={searchDetails.state} onChange={handleSearchDetailsChange}>
                                                    <option value='default' key='default'></option>
                                                    {
                                                        getUSAStatesForOptionsComponent().map((state) => {
                                                            return <option value={state.value} key={state.key}>{state.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        {
                                            isValidFields() ?
                                                <div className="modal-footer">
                                                    <button className="btn btn-solid btn-solid-orange w-100 "
                                                            onClick={handleSearchLocationClick}>
                                                        Search
                                                    </button>
                                                </div> :
                                                <div className="modal-footer">
                                                    <button className="btn btn-solid btn-solid-orange w-100 "
                                                            style={{backgroundColor: 'gray'}}>
                                                        Search
                                                    </button>
                                                </div>
                                        }

                                        <div className="row rounded-10 border-light_blue  p-3 mx-0 overflow-auto campaign-location">
                                            {
                                                filteredFetchedLocations.map(location => {
                                                    const selected = selectedLocations.find((selectedLocation) => location.practice_id === selectedLocation.practice_id);
                                                    return (
                                                        <div className={`row border-light_blue p-3 mx-0 mb-4 rounded-10 campaign-location-btn ` + (selected ? 'active' : '')} onClick={() => handleClickPracticeToCampaign(location)}>
                                                            <div className="col-10 col-md-8">
                                                                <div className="w-100 mb-3 d-flex">
                                                                    <span className="d-table  font-weight-bold">{location.name}</span>
                                                                </div>
                                                                <div className="w-100 mb-3 d-flex">
                                            <span className="d-table txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                                    <span className="d-table font-weight-normal">
                                                            {`${location.address}, ${location.city}, ${location.state} ${location.zip}`}
                                        </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-4 justify-content-end">
                                                                <a href="void(0);"
                                                                    className={`btn-select-changes float-right ` + (selected ? 'selected' : '')} onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleClickPracticeToCampaign(location)
                                                                    }}>Select</a>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                    {/*<div className="modal-footer">*/}
                                    {/*    <p className="text-center d-block txt-color-purple mx-auto pb-3"> 5 Selected </p>*/}
                                    {/*    <button className="btn btn-solid btn-solid-orange w-100 ">*/}
                                    {/*        Add Locations*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

export default NewGeolocationPlanPage;