import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { parseResponse } from "./passwordManagementServices";
import { catchGenericError, parseJSON } from "./responseHandlers";

const register = (details) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'account/register/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    register,
};
