import React, { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getAllUploadReports } from "../../../../helpers/csv_utils/csv_utils";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import CustomDataTable from "../../../_includes/CustomDataTable";





const TABLE_HEADERS = [
    "Upload Date/Time",
    "Filename",
    "Action",
];
const ITEM_PARAMS = [
    "uploaded_at",
    "csv_filename",
    "_action_view",
];

function CSVReportsList() {
    var searchDelay = null;

    const [isLoading, setIsLoading] = useState(true);
    const [reportsList, setReportsList] = useState([]);
    const [totalCount, setTotalCount] = useState([]);

    const [page, setPage] = useState(1);
    const [numOfPages, setNumOfPages] = useState(1);
    const [entriesLimit, setEntriesLimit] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [limitUpdatedOver, setLimitUpdatedOver] = useState(false);

    const getAllUploadReportsAsync = useCallback((page, limit) => {
        /* Update page number and page limit */
        setPage(Number(page));
        setEntriesLimit(Number(limit));

        getAllUploadReports(searchText, page, limit, getAccessTokenFromSession())
            .then((response) => {

                const { result, pagination } = response;
                const { data, original_count } = result;

                setReportsList(data ?? []);
                setNumOfPages(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchText?.trim() === "") {
                    setTotalCount(Number(original_count));
                }

                setIsLoading(false);
            })
            .catch((error) => {
                console.log(`getAllUploadReports(): ${error}`);
                setIsLoading(false);
            });
    }, [searchText]);
    
    useEffect(() => {
        setIsLoading(true);
        getAllUploadReportsAsync(page, entriesLimit);
    }, [entriesLimit, page, getAllUploadReportsAsync]);

    useEffect(() => {
        getAllUploadReportsAsync(page, entriesLimit);
    }, [entriesLimit, getAllUploadReportsAsync, page]);

    useEffect(() => {
        getAllUploadReportsAsync(1, entriesLimit);
    }, [entriesLimit, getAllUploadReportsAsync, searchText]);

    const onChangeNumEntries = (val) => {
        setEntriesLimit(val);

        if (val > 10) {
            setLimitUpdatedOver(true);
        } else {
            setLimitUpdatedOver(false);
        }
    };

    const onChangeSearch = (val) => {
        clearTimeout(searchDelay);
        searchDelay = setTimeout(() => {
            setSearchText(val);
        }, 200);
    };

    const paginateToPage = (page = 1) => {
        setPage(Number(page));
    };

    if (isLoading) return <LoadingOverlay />;
    return (
        <div className="main-content">
            <div className="container p-3 p-md-5">
                <div className="col-6 px-0 mx-0">
                    <Link to="/" className="mb-4 d-table txt-color-purple text-decoration-none">
                        <i className="fa fa-arrow-left mr-2" /> Back to Dashboard
                    </Link>
                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Reports</h2>
                </div>
                {/* eslint-disable-next-line */}
                <h5 className="mt-4"></h5>

                <p>{`Total number of reports: `}<strong>{totalCount}</strong></p>
                <CustomDataTable
                    key={`CustomDataTable-[Reports]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    data={reportsList}
                    dataTotalCount={Number(totalCount)}
                    currentPage={Number(page)}
                    numOfPages={Number(numOfPages)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val)}
                    onChangePage={(val) => paginateToPage(val)}
                    onChangeSearch={(val) => onChangeSearch(val)}
                    viewItemLink={`/reports-view`}
                    page_limit={Number(entriesLimit)}
                    limitUpdatedOver={limitUpdatedOver}
                />
            </div>
        </div>
    );
}


export default CSVReportsList;
