import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { getUSAStatesForOptionsComponent } from "../../../../helpers/addressHelpers";
import {
    editIndividualCampaign,
    getCampaignAsAdmin,
    newIndividualCampaign
} from "../../../../helpers/campaignAPIServices";
import { searchClinicsForInvite } from "../../../../helpers/sendInviteAPIServices";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import LocationCard from "../LocationCard/LocationCard";


const NewIndividualPlanPage = (props) => {
    const [practices, setPractices] = useState([]);
    const [searchPracticeDetails, setSearchPracticeDetails] = useState({
        zip: '',
        city: '',
        state: 'default',
        query: '',
    });
    const [areFieldsValid, setAreFieldsValid] = useState(false);

    const [campaignDetails, setCampaignDetails] = useState({
        campaign_name: '',
        campaign_url: '',
    });

    let {code} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const [selectedPractice, setSelectedPractice] = useState(null);

    const [error, setError] = useState(null);

    const [message, setMessage] = useState(null);

    const [redirectTo404, setRedirectTo404] = useState(false);

    const [campaignToEdit, setCampaignToEdit] = useState(null);

    const [previewLink, setPreviewLink] = useState('');

    const checkFields = (fields) => {
        return !([
            fields.query === "",
            fields.zip === "",
            fields.city === "",
            fields.state === "default" || fields.state === "",
        ].every((test) => test));
    }

    useEffect(() => {
        async function loadIndividualPlan() {
            setIsLoading(true);
            const getIndividualPlanResults = await getCampaignAsAdmin(code, getAccessTokenFromSession());
            if(getIndividualPlanResults.error) {
                console.log('Error in fetching marketing campaign.');
                console.log(getIndividualPlanResults.error);
                setIsLoading(false);

                if(getIndividualPlanResults.error_code === "INVALID_CAMPAIGN_URL") {
                    setError(getIndividualPlanResults.error);
                    return;
                }

                setRedirectTo404(true);
                return;
            }
            setIsLoading(false);
            console.log(getIndividualPlanResults);
            setCampaignToEdit(getIndividualPlanResults);

            setSelectedPractice(getIndividualPlanResults.locations[0]);

            setCampaignDetails({
                campaign_name: getIndividualPlanResults.campaign_name,
                campaign_url: getIndividualPlanResults.campaign_url,
            })
        }

        if(props.edit) {
            loadIndividualPlan();
        }
    }, [code, props.edit]);

    useEffect(() => {
        setAreFieldsValid(checkFields(searchPracticeDetails));
    }, [searchPracticeDetails, searchPracticeDetails.query, searchPracticeDetails.city, searchPracticeDetails.state, searchPracticeDetails.zip]);

    const handleFormFieldChange = (event) => {
        const fieldName = event.target.name;
        let fieldValue = event.target.value;
        if(fieldName === "campaign_url") {
           fieldValue = fieldValue.replaceAll(/[^a-zA-Z0-9-]+/g, '');
        }
        setCampaignDetails((previousState) => {
            const newDetails = {
                ...previousState,
            };

            newDetails[fieldName] = fieldValue;
            return newDetails;
        });
    }

    const handleSearchLocationClick = () => {
        const searchDetails = {
            ...searchPracticeDetails
        };

        if(searchDetails['state'] === "default") {
            searchDetails['state'] = '';
        }

        async function loadQueriedClinics() {
            setIsLoading(true);
            const searchClinicResults = await searchClinicsForInvite(searchDetails);
            if(searchClinicResults.error) {
                console.log('There is an error fetching clinics: ' + searchClinicResults.error_message);
                return;
            }

            setIsLoading(false);
            setPractices(searchClinicResults);
        }

        loadQueriedClinics();
    }



    const handleSearchDetailsChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        setSearchPracticeDetails((previousState) => {
            const newDetails = {
                ...previousState,
            };

            newDetails[fieldName] = fieldValue;
            return newDetails;
        });
    }

    const handleChangeSelectedPractice = (practice) => {
        setSelectedPractice(practice);
        setPractices([]);
    }


    const handleSaveCampaign = () => {
        const fields = prepareFields(campaignDetails);
        if(Object.keys(fields).length === 0) {
            setError('No changes to the fields are submitted. Aborting edit.');
            return;
        }
        console.log(fields);
        async function sendNewCampaign() {
            setIsLoading(true);
            setError(null);
            setMessage(null);
            const newCampaignResults = !props.edit ? await newIndividualCampaign(fields, getAccessTokenFromSession()) :
                                                    await editIndividualCampaign(code, fields, getAccessTokenFromSession());
            if(newCampaignResults.error) {
                setError(newCampaignResults.error);
                setIsLoading(false);
                return;
            }
            setMessage(!props.edit ? 'The individual plan campaign has been successfully added.' :
            'The individual plan has been successfully edited.');
            setIsLoading(false);
        }

        sendNewCampaign();
    }

    const handlePreviewCampaign = () => {
        const link = `https://www.urgentcare.com/campaigns/${campaignDetails.campaign_url}`;
        setPreviewLink(link);
    }

    const prepareFields = (fields) => {
        let preparedCampaignDetails = {
            ...fields,
        };

        preparedCampaignDetails['location'] = selectedPractice.practice_id;
        if(preparedCampaignDetails.campaign_url === "") delete preparedCampaignDetails.campaign_url;
        if(preparedCampaignDetails.campaign_name === "") delete preparedCampaignDetails.campaign_name;

        if(props.edit) {
            preparedCampaignDetails = {};
            if(campaignDetails.campaign_url !== campaignToEdit.campaign_url) {
                preparedCampaignDetails.campaign_url = campaignDetails.campaign_url;
            }

            if(campaignDetails.campaign_name !== campaignToEdit.campaign_name) {
                preparedCampaignDetails.campaign_name = campaignDetails.campaign_name;
            }

            if(selectedPractice.practice_id !== campaignToEdit.locations[0].practice_id) {
                preparedCampaignDetails.location = selectedPractice.practice_id;
            }
        }

        return preparedCampaignDetails;
    }

    const queryPracticesCards = practices.map((practice) => {
        return (
            <LocationCard practice={practice} onSelectClicked={handleChangeSelectedPractice} key={practice.practice_id}/>
        )
    });

    return (
        <div className="main-content">
            {
                redirectTo404 && <Redirect to='/404' />
            }
                    <div className="container p-3 p-md-5">
                        {
                            message &&
                            <div className="alert alert-success mb-5" role="alert">
                                <i className="fa fa-check-circle mr-2"></i>{message}
                            </div>
                        }
                        <div className="row mb-4 p-0 mx-0">
                            <div className="col-6 px-0 mx-0">
                                <h2 className="h1 txt-color-dark_purple font-weight-bold">{props.edit ? 'Edit Individual Plan' : 'New Individual Plan'}</h2>
                                <Link to="/campaigns"
                                      className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                    className="fas fa-arrow-left mr-2"></i> Back to Marketing Campaigns</Link>
                            </div>

                        </div>

                        <div className="row mt-5 mx-0">
                            {
                                isLoading ? <LoadingOverlay />:
                                    <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                        <div className="d-table w-100 ">
                                            <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Selected
                                                location</h3>
                                        </div>

                                        <div className="col-12 mx-0 px-0 mb-4">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    <a href="void(0);"
                                                       data-toggle="modal" data-target="#select-location"
                                                       className="btn btn-outline btn-outline-purple w-50"
                                                       onClick={(e) => e.preventDefault()}>
                                                        <i className="fas fa-plus mr-2"></i>
                                                        Select Location
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            selectedPractice &&
                                            <div className="w-100 border-light_blue rounded-4 p-3 p-md-3 row p-0 m-0 mb-3">
                                                <div className="col-10 col-md-10 pl-0">
                                                    <div className="w-100 mb-3 d-flex">

                                                <span
                                                    className="d-table  font-weight-bold">{selectedPractice.name}</span>
                                                    </div>
                                                    <div className="w-100 mb-3 d-flex">
                                    <span className="d-table txt-color-purple mr-3"><i
                                        className="fas fa-map-marker-alt"></i></span>
                                                        <span className="d-table font-weight-normal">
                                                {`${selectedPractice.address}, ${selectedPractice.city}, ${selectedPractice.state} ${selectedPractice.zip}`}
                                            </span>
                                                    </div>
                                                </div>
                                                {/*<div className="col-2 col-md-2  text-right">*/}
                                                {/*    <a href="" className="text-right"><i className="fa fa-times"*/}
                                                {/*                                         onClick={handleRemoveSelectedPracticeClick}></i></a>*/}
                                                {/*</div>*/}
                                            </div>
                                        }

                                        <div className="col-12 mx-0 px-0">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-9 mx-0 px-0">
                                                    <label htmlFor="" className="form-label">Campaign Name</label>
                                                    <input type="text" className="form-control"
                                                           value={campaignDetails.campaign_name} name='campaign_name'
                                                           onChange={handleFormFieldChange}/>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="col-12 mx-0 px-0">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-9 mx-0 px-0">
                                                    <label htmlFor="" className="form-label">Campaign URL</label>
                                                    <p>(Note: only the URL endpoint)</p>
                                                    <input type="text" className="form-control"
                                                           value={campaignDetails.campaign_url} name='campaign_url'
                                                           onChange={handleFormFieldChange}/>
                                                </div>
                                                {/*<div className="col-2">*/}
                                                {/*    <label htmlFor="" className="form-label"> &nbsp;  </label>*/}
                                                {/*    <a href="#" className="btn btn-outline-secondary      "><i*/}
                                                {/*        className="fa fa-copy"></i></a>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>


                                        <div className="col-12 mx-0 px-0 pt-4 ">
                                            {
                                                error && <p style={{color: 'red'}}>{error}</p>
                                            }
                                            <div className="form-row mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    {
                                                        selectedPractice ?
                                                            <button className="btn btn-solid btn-solid-orange w-100 "
                                                                    onClick={handleSaveCampaign}>
                                                                Save
                                                            </button> :
                                                            <button className="btn btn-solid btn-solid-orange w-100 "
                                                                    style={{backgroundColor: 'gray'}}>
                                                                Save
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mx-0 px-0 pt-4 ">
                                            <div className="form-row mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    {
                                                        selectedPractice ?
                                                            <a className="btn btn-solid btn-solid-orange w-100 "
                                                                    href={previewLink} target='_blank' rel="noreferrer" onClick={handlePreviewCampaign} >
                                                                Preview Campaign
                                                            </a> :
                                                            <button className="btn btn-solid btn-solid-orange w-100 "
                                                                    style={{backgroundColor: 'gray'}}>
                                                                Preview Campaign
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            <div className="modal fade p-5" id="select-location" tabIndex="-1" role="dialog"
                                 aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title" id="exampleModalLabel">Select Location</h3>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                <i className="fas fa-times-circle fa-xs"></i>
                                            </button>
                                        </div>
                                        <div className="modal-body p-4">
                                            <div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="form-control-input-search-label"></label>
                                                        <input type="text"
                                                               className="form-control form-control-input-search"
                                                               name='query'
                                                               placeholder="Search..." value={campaignDetails.query}
                                                               onChange={handleSearchDetailsChange}/>
                                                    </div>
                                                </div>

                                                {/*<div class="row py-3 mx-0">*/}
                                                {/*    <div class="col-12 mx-0 px-0">*/}
                                                {/*        <label class="form-label font-weight-bold" for="">Filter:</label>*/}
                                                {/*        <div class="form-row mx-0 px-4">*/}
                                                {/*            <div class="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                                {/*                <input type="checkbox" id="covid_testing" name="customRadioInline1" class="form-control-input-checkbox">*/}
                                                {/*                    <label class="form-control-input-checkbox-label form-label" for="covid_testing">Covid Testing</label>*/}
                                                {/*            </div>*/}
                                                {/*            <div class="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                                {/*                <input type="checkbox" id="x_ray" name="customRadioInline1" class="form-control-input-checkbox">*/}
                                                {/*                    <label class="form-control-input-checkbox-label form-label" for="x_ray">X-Ray</label>*/}
                                                {/*            </div>*/}
                                                {/*            <div class="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                                {/*                <input type="checkbox" id="antibody_testing" name="customRadioInline1" class="form-control-input-checkbox">*/}
                                                {/*                    <label class="form-control-input-checkbox-label form-label" for="antibody_testing">Antibody Testing</label>*/}
                                                {/*            </div>*/}
                                                {/*            <div class="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                                {/*                <input type="checkbox" id="24_hrs" name="customRadioInline1" class="form-control-input-checkbox">*/}
                                                {/*                    <label class="form-control-input-checkbox-label form-label" for="24_hrs">24 Hours</label>*/}

                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                <div className="row" style={{
                                                    padding: '10px',
                                                }}>
                                                    <div className="col-12 col-sm-4 mb-3">
                                                        <label htmlFor="" className="form-label">ZIP Code</label>&nbsp;
                                                        <input type="text" className="form-control" name='zip'
                                                               value={searchPracticeDetails.zip}
                                                               onChange={handleSearchDetailsChange}/>
                                                    </div>
                                                    <div className="col-12 col-sm-4 mb-3">
                                                        <label htmlFor="" className="form-label">City</label>&nbsp;
                                                        <input type="text" className="form-control" name='city'
                                                               value={searchPracticeDetails.city}
                                                               onChange={handleSearchDetailsChange}/>
                                                    </div>
                                                    <div className="col-12 col-sm-4 mb-3">
                                                        <label htmlFor="" className="form-label">State</label>&nbsp;
                                                        {/*<input type="text" className="form-control" />*/}
                                                        <select name='state' className="form-control"
                                                                value={searchPracticeDetails.state}
                                                                onChange={handleSearchDetailsChange}>
                                                            <option value='default' key='default'></option>
                                                            {
                                                                getUSAStatesForOptionsComponent().map((state) => {
                                                                    return <option value={state.value}
                                                                                   key={state.key}>{state.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                {/*<div className="modal-body p-4">*/}
                                                {
                                                    isLoading && <LoadingOverlay/>
                                                }
                                                {
                                                    areFieldsValid && !isLoading ? <button
                                                            className="btn btn-solid btn-solid-orange w-100 search-button-send-invite"
                                                            id="set_loc_invite" onClick={handleSearchLocationClick}>
                                                            Search
                                                        </button> :
                                                        <button
                                                            className="btn btn-solid btn-solid-orange w-100 search-button-send-invite disabled-search-send-invite"
                                                            id="set_loc_invite">
                                                            Search
                                                        </button>
                                                }
                                                <div
                                                    className="row rounded-10 border-light_blue  p-3 mx-0 overflow-auto campaign-location">

                                                    {/*TODO: put location cards here.*/}

                                                    {/*{*/}
                                                    {/*    this.state.queryPractices.length < 1 ? <p>No results found.</p> :*/}
                                                    {/*        {queryPracticesCards}*/}
                                                    {/*}*/}

                                                    {queryPracticesCards}

                                                    {/*<LocationCard practice={{*/}
                                                    {/*    name: "Catli General Hospital",*/}
                                                    {/*    address: "591 San Juan",*/}
                                                    {/*    city: "San Luis, Pampanga",*/}
                                                    {/*    zip: "2014"*/}
                                                    {/*}} />*/}

                                                </div>
                                                {/*</div>*/}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
        </div>
    )
}

export default NewIndividualPlanPage;