import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { checkStatus, parseJSON } from "./responseHandlers";

// eslint-disable-next-line
const EMAIL_REGEX_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const updateProfile = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'account/?change=profile';
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,

        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

const updatePassword = (newPasswords, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'account/?change=password';
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,

        },
        body: JSON.stringify(newPasswords),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

const getMyLocations = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "account/locations";
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,

        },
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}

const registerAndClaim = (profile, practice_id) => {
    const URL = SUPERADMIN_API_ROOT_URL + "account/register/?claim=" + practice_id;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}

const claimPracticeUsingToken = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "account/claim/?practice_id=" + practice_id;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}

const getAllRequestsForSuperadmin = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "request/approvals";
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}


export {
    updateProfile,
    updatePassword,
    getMyLocations,
    EMAIL_REGEX_PATTERN,
    registerAndClaim,
    claimPracticeUsingToken,
    getAllRequestsForSuperadmin,
};
