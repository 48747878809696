import React from 'react';
import {getFullAddress} from "../../../../helpers/addressHelpers";
import {searchClinicsForInvite} from "../../../../helpers/sendInviteAPIServices";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";

import { getAllRequestsForSuperadmin } from '../../../../helpers/accountAPIServices';
import { getAccessTokenFromSession } from '../../../../helpers/sessionHelpers';

class ListingsViewPage extends React.Component {
    state = {
        query: "",
        practices: [],
        isLoading: false,
    }

    async componentDidMount() {
        await this.handleSearchClick();

        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleSearchClick = async (event) => {
        if(this.state.query === "") {
            return;
        }

        this.setLoading(true);
        const searchResults = await searchClinicsForInvite({
            query: this.state.query,
            zip: "",
            state: "",
            city: ""
        });

        if(searchResults.error) {
            console.log(searchResults.error);
            this.setLoading(false);
            return;
        }

        this.setState({
            practices: searchResults
        }, () => this.setLoading(false));
    }

    handleQueryChange = (event) => {
        this.setState({
            query: event.target.value
        });
    }

    handleEnterSearch = e => {
        if (e.key === "Enter") {
            this.handleSearchClick();
        }
    }


    render() {
        const rows = this.state.practices.map((practice) => {
            return (
                <tr>
                    <td></td>
                    <td>{practice.practice_id}</td>
                    <td>{practice.name}</td>
                    {/*TODO: internal email*/}
                    {/*<td>internal@amarillourgentcare.com</td>*/}
                    <td>{getFullAddress(practice)}</td>
                    {/*TODO: Claimer*/}
                    {/*<td>Ralph Edwards</td>*/}
                    <td><a href={`/listings/${practice.practice_id}`} className="text-decoration-none">View</a></td>
                </tr>
            )
        })
        return (
            <div className="main-content">
                        <div className="container p-3 p-md-5">
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-6 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Location Listings</h2>
                                </div>
                                <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                                    <a href="listings/new" className="btn btn-solid btn-solid-orange px-3">
                                        <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                        <span className="d-none d-sm-block">
                                            New Location
                        </span>
                                    </a>
                                </div>
                            </div>

                            {
                                this.state.isLoading ? <LoadingOverlay /> :
                                    <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                        <div className="col-12">
                                            <div className="d-table w-100 ">
                                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Location
                                                    Listings</h3>
                                            </div>
                                        </div>

                                        <div className="input-group border-light_blue rounded-5 overflow-hidden">
                                            <input type="text" className="form-control shadow-none border-0" name='query'
                                        placeholder="ID or NUCR Practice Name"
                                        onChange={this.handleQueryChange}
                                        onKeyDown={this.handleEnterSearch}
                                    />
                                            <div className="input-group-append">
                                                <button className="btn btn-solid-light_blue rounded-0 custom-search-button" onClick={this.handleSearchClick}
                                                >Search
                                                </button>
                                            </div>
                                        </div>



                                        <div className="col-12 mt-2">
                                            <div className="w-100">
                                                {/*TODO: table*/}
                                                {/*<table className="table table-custom display dt-responsive nowrap w-100">*/}
                                                {rows.length > 0 ?
                                                    <table className="table-custom display dt-responsive nowrap w-100">
                                                        <thead className="thead-light rounded-10">
                                                        <tr>
                                                            <th></th>
                                                            <th>ID</th>
                                                            <th>NUCR Practice Name</th>
                                                            {/*<th>Internal Email</th>*/}
                                                            <th>Address</th>
                                                            {/*<th>Claimed By</th>*/}
                                                            <th></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {rows}
                                                        </tbody>
                                                    </table> :

                                                    <p>No results found.</p>
                                                }
                                            </div>

                                            {/*TODO: Navigation*/}
                                            {/*<nav aria-label="Page navigation example">*/}
                                            {/*    <ul className="pagination justify-content-end">*/}
                                            {/*        <li className="page-item disabled">*/}
                                            {/*            <a className="page-link" href="#" tabindex="-1"><i className="fas fa-angle-double-left"></i></a>*/}
                                            {/*        </li>*/}
                                            {/*        <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                                            {/*        <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                                            {/*        <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                                            {/*        <li className="page-item">*/}
                                            {/*            <a className="page-link txt-color-purple" href="#"><i className="fas fa-angle-double-right"></i></a>*/}
                                            {/*        </li>*/}
                                            {/*    </ul>*/}
                                            {/*</nav>*/}


                                        </div>
                                    </div>
                            }
                        </div>


                <footer>

                    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TimelineLite.min.js"
                        integrity="sha512-UznfQR3LKkKPL0k7HISMqOsmUCjVhjWK8HlR0Pxo5JpjN8eZ8XRwKBRX6OTOQIO0FYOl8Oue+4dG4uHtx1SgKQ=="
                        crossOrigin="anonymous"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                        integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                        crossOrigin="anonymous"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                        integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                        crossOrigin="anonymous"></script>
                    <script src="/animation.js"></script>

                </footer>



            </div>
        )
    }
}
export default ListingsViewPage;