import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { DAYS, MONTHS } from "../../../../helpers/timeHelpers";


const NotificationsCard = (props) => {
    const notification = props.notification;
    const filter = props.filter;

    const [isCancel, setIsCancel] = useState(false);
    const [cancelLink, setCancelLink] = useState('');
    const [cancelInfo, setCancelInfo] = useState('');
    const [isChange, setIsChange] = useState(false);
    const [changeLink, setChangeLink] = useState('');
    const [changeInfo, setChangeInfo] = useState('');

    useEffect(() => {
        if (notification.type !== 'location_admin_subscription_cancel' || 'location_admin_subscription_change') {
            setChangeInfo('');
            setChangeLink('');
            setIsChange(false);
            setCancelInfo('');
            setCancelLink('');
            setIsCancel(false);
        }

        if (notification.type === 'location_admin_subscription_cancel') {
            const splitCancelInformation = notification.information.split('Link: ');
            if (splitCancelInformation.length > 1) {
                setCancelInfo(splitCancelInformation[0]);
                setCancelLink(splitCancelInformation[1]);
                setIsCancel(true);
                setChangeInfo('');
                setChangeLink('');
                setIsChange(false);
            }
        }

        if (notification.type === 'location_admin_subscription_change') {
            const splitChangeInformation = notification.information.split('Link: ');
            if (splitChangeInformation.length > 1) {
                setChangeInfo(splitChangeInformation[0]);
                setChangeLink(splitChangeInformation[1]);
                setIsChange(true);
                setCancelInfo('');
                setCancelLink('');
                setIsCancel(false);
            }
        }

    }, [notification.type, notification.information, filter]);


    const typesTitle = {
        "edit_location_external": "Update Location",
        "new_location_external": "New Location",
        "claim_location": "Claim Location",
        "superadministrator": "Administrator",
        "location_admin_subscription_cancel": "Cancel Plan Request",
        "location_admin_subscription_change": "Change Plan Request",
        "delete_location_listing": "Deleted Location Listing",
    }

    const typesIndicator = {
        "edit_location_external": {
            padding: "5px 20px",
            backgroundColor: 'orange',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "new_location_external": {
            padding: "5px 20px",
            backgroundColor: 'green',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "claim_location": {
            padding: "5px 20px",
            backgroundColor: 'orangered',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "superadministrator": {
            padding: "5px 20px",
            backgroundColor: 'violet',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "location_admin_subscription_cancel": {
            padding: "5px 20px",
            backgroundColor: '#ff4a30',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "location_admin_subscription_change": {
            padding: "5px 20px",
            backgroundColor: '#6466a8',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        },

        "delete_location_listing": {
            padding: "5px 20px",
            backgroundColor: '#ff4a30',
            borderRadius: '15px',
            textTransform: 'uppercase',
            fontSize: '0.65rem',
            border: "0px solid black",
            color: "white",
        }

    }

    const handleDateFormat = () => {
        const date = new Date(notification.date_created);
    
        var dt = {
            "day": DAYS[date.getDay()],
            "month": MONTHS[date.getMonth()],
            "dayOfMonth": date.getDate(),
            "year": date.getFullYear(),
            "hours": String(date.getHours()).padStart(2, '0'),
            "minutes": String(date.getMinutes()).padStart(2, '0'),
            "seconds": String(date.getSeconds()).padStart(2, '0'),
        }
    
        const hours = Number(dt.hours);
        let formattedHour = `${hours}`;
    
        if (hours === 0) {
            formattedHour = "12";
        }
        else if (hours > 12) {
            formattedHour = `${hours - 12}`;
        }
    
        const ampm = (hours < 12) ? "AM" : "PM";
        const dtTime = `${formattedHour}:${dt.minutes} ${ampm}`;
        const dateString = `${dt.day}, ${dt.month} ${dt.dayOfMonth}, ${dt.year} at ${dtTime}`;

        return dateString;
    }
    
    return (
        <div className="w-100 border-light_blue rounded-4 p-4 row p-0 m-0 mb-3">
            <div className="col-12 notif-crd">


                {!isCancel && !isChange &&
                    <p className="mb-2">
                        {/* <!--Notification Title--> */}
                        <span className="font-weight-bold " style={typesIndicator[notification.type]}>{typesTitle[notification.type]}</span> {notification.information}
                    </p>
                }
                {isCancel &&
                    <p className="mb-2">
                        {/* <!--Notification Title--> */}
                        <Link to='/membership/updates' className="font-weight-bold " style={typesIndicator[notification.type]}>{typesTitle[notification.type]}</Link> <span>{cancelInfo}</span>
                    </p>
                }
                {isChange &&
                    <p className="mb-2">
                        {/* <!--Notification Title--> */}
                        <Link to='/membership/updates' className="font-weight-bold " style={typesIndicator[notification.type]}>{typesTitle[notification.type]}</Link> <span>{changeInfo}</span>
                    </p>
                }

                {cancelLink &&
                    <>
                        <p className="m-0">
                            <a href={cancelLink} target='_blank' rel='noreferrer'>Go to Listing</a>
                        </p>
                    </>
                }

                {changeLink &&
                    <>
                        <p className="m-0">
                            <a href={changeLink} target='_blank' rel='noreferrer'>Go to Listing</a>
                        </p>
                    </>
                }
                <p className="m-0">

                    {/* <!--DATE / Time RECIEVE--> */}
                    <span className="txt-color-purple">
                        {handleDateFormat()}
                    </span>
                </p>

                {/* </button> */}
            </div>
        </div>
    );
}

export default NotificationsCard;