import React, {
  useCallback, useEffect, useRef, useState
} from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import {
  addTier,
  bulkUpdateOrCreateTierFeatures,
  deleteTier,
  deleteTierFeature,
  editTier,
  getSuggestedTierCode,
  getTierDetails,
  getTierFeatures
} from "../../../helpers/tierAPIServices";
import HeaderSection from "../../Common/HeaderSection/HeaderSection";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";

function AddEditTier(props) {
  const history = useHistory();

  const { tier_code } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [accessToken,] = useState(getAccessTokenFromSession());

  /* Hooks for data from the API */
  const [tierDetailsFromAPI, setTierDetailsFromAPI] = useState({});
  const [tierFeaturesFromAPI, setTierFeaturesFromAPI] = useState([]);

  /* Hooks for the input fields */
  const [tierName, setTierName] = useState("");
  const [tierCode, setTierCode] = useState("");
  const [tierPrice, setTierPrice] = useState("");
  const [tierPaymentMode, setTierPaymentMode] = useState("monthly");
  const [tierHeaderColor, setTierHeaderColor] = useState("");

  /* Temporary API data holder */
  var tempTierFeatures = useRef([]);

  const getTierFeaturesAsync = useCallback(async () => {
    try {
      if (tier_code) {
        const response = await getTierFeatures(tier_code, accessToken);

        /* Stop loading here */
        setIsLoading(false);

        /* Store API result to state */
        setTierFeaturesFromAPI(response?.result);
      }
      else {
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e);
      return;
    }
  }, [tier_code, accessToken])

  const getTierDetailsAsync = useCallback( async () => {
    try {
      if (tier_code) {
        const response = await getTierDetails(tier_code, accessToken);

        /* Store API result to state */
        setTierDetailsFromAPI(response?.result);

        /* 2nd API call */
        getTierFeaturesAsync();
      }
      else {
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e);
      return;
    }
  }, [tier_code, accessToken, getTierFeaturesAsync])

  const getSuggestedTierCodeAsync = useCallback( async () => {
    if (tier_code) {
      setTierCode(tier_code);
    }
    else {
      try {
        const response = await getSuggestedTierCode(accessToken);
        let result = response?.result;
        let suggestedTierCode = result?.["tier_code"];
        setTierCode(suggestedTierCode);
      }
      catch (e) {
        console.log(e);
      }
    }
  }, [tier_code, accessToken])

  useEffect(() => {
    setIsLoading(true);

    /* 1st API call */
    getTierDetailsAsync();

    getSuggestedTierCodeAsync();
  }, [getTierDetailsAsync, getSuggestedTierCodeAsync]);

  useEffect(() => {
    /* Attempt to store data from API, if available. */
    setTierName(tierDetailsFromAPI?.name ?? tierName);
    setTierPrice(tierDetailsFromAPI?.price ?? tierPrice);
    setTierPaymentMode(tierDetailsFromAPI?.payment_mode ?? tierPaymentMode);

    if (tierHeaderColor) {
      setTierHeaderColor(tierHeaderColor);
    } else if (tierDetailsFromAPI) {
      setTierHeaderColor(tierDetailsFromAPI?.header_color);
    } else if (tierHeaderColor && tierDetailsFromAPI?.header_color) {
      setTierHeaderColor(tierHeaderColor);
    }
    // setTierHeaderColor(tierHeaderColor ?? tierDetailsFromAPI?.header_color);
    // setTierHeaderColor(tierDetailsFromAPI?.header_color ?? tierHeaderColor);
  }, [tierDetailsFromAPI, tierHeaderColor, tierName, tierPrice, tierPaymentMode])

  useEffect(() => {
    /* Store latest API data to a temporary variable */
    // eslint-disable-next-line
    tempTierFeatures = JSON.parse(JSON.stringify(tierFeaturesFromAPI));
  }, [
    tierName,
    tierCode,
    tierPrice,
    tierPaymentMode,
    tierHeaderColor,
    tierFeaturesFromAPI,
  ])



  const addTierAsync = async () => {
    try {
      let payload = {
        name: tierName,
        code: tierCode,
        price: tierPrice,
        payment_mode: tierPaymentMode,
        header_color: tierHeaderColor,
        external_link: null,
      }

      const response = await addTier(payload, accessToken);
      const statusCode = response?.status_code;

      if (statusCode === 200 || statusCode === 201) {
        /* Store API result to state */
        setTierDetailsFromAPI(response?.result);

        /* Bulk update or create TierFeature(s) */
        bulkUpdateOrCreateTierFeaturesAsync(tierCode);
      }

    } catch (e) {
      console.log(e);
      return;
    }
  }

  const editTierAsync = async () => {
    try {
      let payload = {
        name: tierName,
        code: tierCode,
        price: tierPrice,
        payment_mode: tierPaymentMode,
        header_color: tierHeaderColor,
        external_link: null,
      }

      const response = await editTier(tier_code, payload, accessToken);
      const statusCode = response?.status_code;

      /* Bulk update or create TierFeature(s) */
      if (statusCode === 200 || statusCode === 201) {
        /* Store API result to state */
        setTierDetailsFromAPI(response?.result);

        /* Bulk update or create TierFeature(s) */
        bulkUpdateOrCreateTierFeaturesAsync(tierCode);
      }

    } catch (e) {
      console.log(e);
      return;
    }
  }

  const deleteTierAsync = async () => {
    if (tier_code) {
      try {
        const response = await deleteTier(tier_code, accessToken);
        const statusCode = response?.status_code;

        console.log(statusCode)

      } catch (e) {
        console.log(e);
        return;
      }
    }
  }

  const bulkUpdateOrCreateTierFeaturesAsync = async (tierCode) => {
    let payload = {
      tier_code: tierCode,
      features_list: tempTierFeatures,
    };

    try {
      const response = await bulkUpdateOrCreateTierFeatures(tierCode, payload, accessToken);
      const statusCode = response?.status_code;

      console.log("bulkUpdateOrCreateTierFeaturesAsync()", statusCode);

    } catch (e) {
      console.log(e);
      return;
    }
  }

  const onChangeTierFeature = (_uuid_, value) => {
    /* Makes a copy of the TierFeatures from the API and modify it instead. */
    let tempTierFeaturesCopy = tempTierFeatures;
    for (let i in tempTierFeaturesCopy) {
      let item = tempTierFeaturesCopy[i];

      if (item?.uuid === _uuid_) {
        item.description = value;
      }
    }
    /* Updates the original temporary data holder */
    tempTierFeatures = tempTierFeaturesCopy;
  }

  const onEndEditingTierFeature = (_uuid_) => {
    for (let i in tempTierFeatures) {
      let item = tempTierFeatures[i];

      if (item?.uuid === _uuid_) {
        if (item?.description === "") {
          tempTierFeatures.splice(i, 1);

          setTierFeaturesFromAPI(tempTierFeatures);
        } else {
          setTierFeaturesFromAPI(tempTierFeatures);
        }
      }
    }
    setTierFeaturesFromAPI(tempTierFeatures);
  }

  const addFeatureButtonAction = () => {
    /* Use temporary API data holder to update the state before adding new data */
    // setTierFeaturesFromAPI(tempTierFeatures);
    let nextListRanking = 0;
    let newTierFeatures = [];
    for (let i in tempTierFeatures) {
      let element = tempTierFeatures[i];

      if (Number(i) < tempTierFeatures.length) {
        let listRanking = Number(element?.list_ranking);
        if (listRanking >= nextListRanking) {
          nextListRanking = listRanking + 1;
        }
      }

      newTierFeatures.push(element);
    }

    newTierFeatures.push({
      uuid: `${uuidv4()}`,
      description: "",
      list_ranking: nextListRanking,
    });

    /* Update state */
    setTierFeaturesFromAPI(newTierFeatures);
  }

  const deleteTierFeatureButtonAction = (_uuid_) => {
    /* Makes a copy of the TierFeatures from the API and modify it instead. */
    let tempTierFeaturesCopy = tempTierFeatures;
    for (let i in tempTierFeaturesCopy) {
      let item = tempTierFeaturesCopy[i];

      if (item?.uuid === _uuid_) {
        tempTierFeaturesCopy.splice(i, 1);
        /* Updates the original temporary data holder */
        tempTierFeatures = tempTierFeaturesCopy;

        /* Asynchronous API call */
        deleteTierFeatureAsync(_uuid_);
        break;
      }
    }

    /* Update state */
    setTierFeaturesFromAPI(tempTierFeatures);
  }

  const deleteTierFeatureAsync = async (uuid) => {
    let payload = { uuid };

    try {
      const response = await deleteTierFeature(payload, accessToken);
      const statusCode = response?.status_code;

      console.log("deleteTierFeatureAsync()", statusCode)

    } catch (e) {
      console.log(e);
      return;
    }
  }

  const changeColor = (value) => {

    setTierHeaderColor(value);

  }

  const saveButtonAction = () => {
    if (tier_code) {
      editTierAsync();
    }
    else {
      addTierAsync();
    }
  }

  const navigateBackToTiers = () => {
    /* Navigate back to Tiers list */
    history.push("/tiers");
  }

  const renderTierFeatures = () => {
    let layout = [];
    let features = tierFeaturesFromAPI ?? [];
    if (features.length > 0) {
      for (let i in features) {
        let item = features[i];

        /* Used UUID4 to track text changes between multiple textarea fields */
        let uniqueKey = `${uuidv4()}`;
        let _uuid_ = item?.uuid ?? uniqueKey;
        let description = item?.description;

        layout.push(
          <div
            key={uniqueKey}
            // data-feature_id="1x"
            className="col-12 mx-0 px-0 mt-3">
            <div className="d-flex justify-content-between">
              <label htmlFor="" className="form-label">Feature</label>
              <button onClick={() => deleteTierFeatureButtonAction(_uuid_)} className="txt-color-purple delFeat">
                <i className="far fa-trash-alt mr-2"></i>Delete
              </button>
            </div>
            <textarea
              className="form-control"
              id={_uuid_}
              name=""
              cols="30"
              rows="6"
              placeholder={"Enter Tier feature description"}
              onChange={(e) => onChangeTierFeature(_uuid_, e.target.value)}
              onBlur={() => onEndEditingTierFeature(_uuid_)}
              defaultValue={description} />
          </div>
        );
      }
    }

    return layout;
  }

  return (
    <>
      <HeaderSection />
      {
        isLoading ? <LoadingOverlay /> :
          <main>
            <div className="main-content">

              <div className="container p-3 p-md-5">
                <div className="row mb-4 p-0 mx-0">
                  <div className="col-6 px-0 mx-0">
                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Edit Tier</h2>
                    <a href="/tiers" className="mt-4 d-table txt-color-purple text-decoration-none">
                      <i className="fas fa-arrow-left mr-2"></i>
                      Back to Tiers
                    </a>
                  </div>
                </div>

                <div className="row mt-5 mx-0">
                  <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">

                    {/* Tier name */}
                    <div className="col-12 mx-0 px-0">
                      <div className="form-row mb-4 mx-0 px-0">
                        <div className="col-12 mx-0 px-0">
                          <label htmlFor="" className="form-label">Tier Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter tier name"
                            onChange={(e) => setTierName(e.target.value)}
                            value={tierName ?? ""}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Tier code */}
                    <div className="col-12 mx-0 px-0">
                      <div className="form-row mb-4 mx-0 px-0">
                        <div className="col-12 mx-0 px-0">
                          <label htmlFor="" className="form-label">Tier Code</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter tier code"
                            onChange={(e) => setTierCode(e.target.value)}
                            value={tierCode ?? ""}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Price / Payment mode */}
                    <div className="col-12 mx-0 px-0">
                      <div className="form-row mb-4 mx-0 px-0">
                        <div className="col-12 col-md-6 ml-0 pl-0">
                          <label htmlFor="" className="form-label">Price</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="0.00"
                            onChange={(e) => setTierPrice(e.target.value)}
                            value={tierPrice ?? ""} />
                        </div>

                        <div className="col-12 col-md-6 ml-0 pl-0">
                          <label htmlFor="" className="form-label">Monthly/Annually</label>
                          <select
                            className="form-control"
                            onChange={(e) => setTierPaymentMode(e.target.value)}
                            value={tierPaymentMode}>
                            <option value="monthly">Monthly</option>
                            <option value="annually">Annually</option>
                            <option value="daily">Daily</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Tier header color */}
                    <div className="col-12 mx-0 px-0">
                      <div className="form-row mb-4 mx-0 px-0">
                        <div className="col-12 mx-0 px-0">
                          <label htmlFor="" className="form-label">Color</label>
                          <input
                            type="color"
                            className="form-control"
                            onChange={e => changeColor(e.target.value)}
                            // defaultValue="#6610f2"
                            value={tierHeaderColor ?? "#6610f2"}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Tier Features */}
                    <div className="col-12 mx-0 px-0">
                      <div
                        className="form-row mb-4 mx-0 px-0"
                        id="add_feature-container">
                        {renderTierFeatures()}
                      </div>
                    </div>

                    {/* Add Tier Feature button */}
                    <div className="col-12 mx-0 px-0 mb-4">
                      <div className="form-row mb-4 mx-0 px-0">
                        <div className="col-12 mx-0 px-0">
                          <button
                            id="add_feature-btn"
                            className="btn btn-outline btn-outline-purple "
                            onClick={addFeatureButtonAction}>
                            <i className="fas fa-plus mr-2" />
                            Add Feature
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Save / Delete button */}
                    <div className="col-12 mx-0 px-0 pt-4 ">
                      <div className="form-row mx-0 px-0">
                        <div className="col-12 mx-0 px-0">
                          <button
                            className="btn btn-solid btn-solid-orange w-100 mb-2"
                            data-toggle="modal"
                            data-target="#success_tier"
                            onClick={saveButtonAction}>
                            Save
                          </button>
                          {
                            tier_code && (
                              <button
                                className="btn btn-outline btn-outline-purple w-100"
                                data-toggle="modal"
                                data-target="#delete_tier">
                                Delete
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </main>
      }

      {/* Success modal */}
      <div
        className={`modal fade`}
        id="success_tier"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{
          "display": "none",
        }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content overflow-hidden border-0">
            <div className="modal-body p-0">
              <img src="/img/success_approve.svg" alt="" className="w-100 img-fluid" />

              <div className="w-100 p-5">
                <h4 className="txt-color-dark_purple text-center">Success</h4>
                <p className="txt-color-dark_purple  text-center my-4">
                  You have successfully created a new Tier.
                </p>
                <button
                  className="btn btn-solid btn-solid-orange w-100 mt-4 d-table"
                  data-toggle="modal"
                  data-target="#success_tier"
                  onClick={() => {
                    setTimeout(() => {
                      navigateBackToTiers();
                    }, 300);
                  }}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm delete modal */}
      <div
        className="modal fade"
        id="delete_tier"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content overflow-hidden border-0">
            <div className="modal-body p-0">
              <img src="/img/declined_location.svg" alt="" className="w-100 img-fluid" />

              <div className="w-100 p-5">
                <h4 className="txt-color-dark_purple text-center">Delete Tier</h4>
                <p className="txt-color-dark_purple  text-center my-4">
                  Are you sure you want to delete this Tier?
                </p>
                <div className="d-flex">
                  <button
                    className="btn btn-solid btn-outline-orange w-50 mt-4 d-table mx-2"
                    data-target="#delete-success"
                    data-toggle="modal"
                    data-dismiss="modal"
                    onClick={() => {
                      setTimeout(() => {
                        deleteTierAsync();  /* Proceed on delete */
                      }, 300);
                    }}>
                    Yes
                  </button>
                  <button
                    className="btn btn-solid btn-outline-purple w-50 mt-4 d-table mx-2"
                    data-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* DELETE SUCCESS  */}
			<div className="modal fade p-5" id="delete-success" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header border-bottom-0">

							<button type="button" className="close" onClick={() => navigateBackToTiers()} data-dismiss="modal" aria-label="Close">
                <i className="fas fa-times-circle fa-xs"></i>
							</button>
						</div>
            <div className="modal-body p-4">
              <>
                  <h3 className="h2 font-weight-bold text-center">
                      Success!
                  </h3>

                  <p className="mt-4 text-center">
                      You have successfully deleted this tier
                  </p>

                  <div className="form-row mt-5 px-5">
                      <div className="col-md-12 mb-3">
                        <button className="btn btn-solid-orange w-100" data-dismiss="modal" onClick={() => navigateBackToTiers()}>
                          OK
                        </button>
                      </div>
                  </div>
              </>
						</div>
						{/* <!-- <div className="modal-footer">
										<a href="#" data-toggle="modal" data-target="#search-location2" className="btn btn-solid btn-solid-orange w-100 " data-dismiss="modal">
											Search
										</a>
									</div> --> */}
					</div>
				</div>
			</div>
    </>
  );

}

export default AddEditTier;
