import React from 'react';
import { Link, useLocation } from "react-router-dom";

const SuperadminNavigationBar = (props) => {
    const currentPathName = useLocation().pathname;
    const displayName = (props.user.user.first_name && props.user.user.last_name) ? `${props.user.user.first_name} ${props.user.user.last_name}` : props.user.user.email;
    const linkRequestsTest = /.*requests.*/;
    const linkOnListingsTest = /\/listings.*/;
    const linkProductsTest = /.*products.*/;
    const linkMembershipTest = /.*membership.*/;
    const linkMarketingCampaignsTest = /.*campaigns.*/;
    const linkSubscribersTest = /.*subscribers.*/;
    const linkTiersTest = /.*tiers.*/;

    return (
        <div className="sidebar-container">
            <div className="sidebar-profile-container">
                <div className="sidebar-profile-content">
                    {/*TODO: Profile Picture*/}
                    {/*<div className="sidebar-profile-content-img">*/}
                    {/*    <img src="img/logo.svg" alt="" />*/}
                    {/*</div>*/}
                    <div className="sidebar-profile-content-details">
                        <p className="sidebar-profile-content-details-name">
                            {displayName}
                        </p>
                        <Link to='/settings' className="sidebar-profile-content-details-view">View Profile</Link>
                    </div>
                </div>
            </div>

            <div className="sidebar-nav-container">
                <Link to='/' className={`sidebar-nav-item ${currentPathName === '/' ? "active" : ""}`}>Dashboard</Link>
                <Link to='/listings' className={`sidebar-nav-item ${linkOnListingsTest.test(currentPathName) ? "active" : ""}`}>Locations Listings</Link>
                <Link to='/requests' className={`sidebar-nav-item ${linkRequestsTest.test(currentPathName) ? "active" : ""}`}>Location Updates/Requests</Link>
                <Link to='/subscribers' className={`sidebar-nav-item ${linkSubscribersTest.test(currentPathName) ? "active" : ""}`}>Subscribers</Link>
                <Link to='/tiers' className={`sidebar-nav-item ${linkTiersTest.test(currentPathName) ? "active" : ""}`}>Tiers</Link>
                <Link to='/invite' className={`sidebar-nav-item ${currentPathName === '/invite' ? "active" : ""}`}>Send Invite</Link>
                <Link to='/notifications' className={`sidebar-nav-item ${currentPathName === '/notifications' ? "active" : ""}`}>Notifications</Link>
                <Link to='/products' className={`sidebar-nav-item ${linkProductsTest.test(currentPathName) ? "active" : ""}`}>Products</Link>
                <Link to='/membership/updates' className={`sidebar-nav-item ${linkMembershipTest.test(currentPathName) ? "active" : ""}`}>Membership Updates</Link>
                <Link to='/campaigns' className={`sidebar-nav-item ${linkMarketingCampaignsTest.test(currentPathName) ? "active" : ""}`}>Marketing Campaigns</Link>
                <Link to='/settings' className={`sidebar-nav-item ${currentPathName === '/settings' ? "active" : ""}`}>Settings</Link>
            </div>

            <div className="sidebar-logout-container">
                <button className="btn btn-solid btn-solid-dark_purple w-100 text-center rounded-100" onClick={props.onLogout}><i
                    className="fas fa-power-off mr-2" ></i> Logout
                </button>
            </div>

        </div>
    )
}

export default SuperadminNavigationBar;