import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import { catchGenericError, checkStatus, parseJSON } from "./responseHandlers";
import { parseResponse } from "./passwordManagementServices";



const getPracticeInformation = (practice_id) => {
    const URL = SUPERADMIN_API_ROOT_URL + "practice/?practice_id=" + practice_id;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}


const deleteLocationListing = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `practice/${practice_id}/delete_matching_practices/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const checkLocationListing = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `practice/${practice_id}/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}



export {
    getPracticeInformation,
    deleteLocationListing,
    checkLocationListing,
}