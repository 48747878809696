import React from 'react';

class LoginHeaderSection extends React.Component {
    render() {
        return (
            <header
                className="p-4 border-bottom-light_blue d-flex align-items-center justify-content-center fixed-top position-fixed position-xs-relative">
                <img src="/img/logo.svg" alt="" />
            </header>
        );
    }
}

export default LoginHeaderSection;