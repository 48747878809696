import React from 'react';
import {getProducts} from "../../../../helpers/productsAPIServices";
import {getMyLocations} from "../../../../helpers/accountAPIServices";
import {getAccessTokenFromSession} from "../../../../helpers/sessionHelpers";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";

class OrderProductsPage extends React.Component {
    state = {
        products: [],
        userLocations: {
            approved: [],
            pending_edit: [],
        },
    }

    async componentDidMount () {
        this.setLoading(true);
        const getProductsResults = await getProducts(getAccessTokenFromSession());
        const getUserLocations = await getMyLocations(getAccessTokenFromSession());

        if(getProductsResults.error || getUserLocations.error) {
            console.log('There is an error fetching products.');
            this.setState({
                redirectTo404: true,
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            products: getProductsResults,
            userLocations: getUserLocations,
        });
        this.setLoading(false);
    }

    setLoading = (state) => {
        this.setState({
            isLoading: state,
        });
    }

    render() {
        const allLocations = [...this.state.userLocations.approved, ...this.state.userLocations.pending_edit].map((location) => location.practice_id).join(',');
        console.log(allLocations);
        return(

                this.state.isLoading ? <LoadingOverlay /> :
                    <div className="main-content">
                        <div className="container p-3 p-md-5">
                            <div className="row mb-4 mx-0">
                                <div className="col-6 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Order Products</h2>
                                </div>
                                <div className="col-6 d-flex justify-content-end">

                                </div>
                            </div>


                            <div className="row mt-5 mx-0">
                                {
                                    this.state.products.map((product) => {
                                        const productLink = `${product.redirect_url}?account_id=${this.props.user.id}${allLocations.length > 0 ? ',' + allLocations : ""}`
                                        return (
                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                                                <div className="card border-0 shadow">
                                                    <div className="card-body border-0">
                                                        <a href={productLink} className="txt-color-purple font-weight-bold">
                                                            {product.name}
                                                        </a>
                                                        <p className="small txt-color-secondary">
                                                            {product.description !== "" ? product.description : "No description available. " +
                                                                "Please, visit link for more information."}
                                                        </p>
                                                    </div>

                                                    <div className="card-footer border-0 bg-white">

                                                        <a href={productLink}>
                                                            <button className="btn btn-outline btn-outline-purple px-4"
                                                                    data-target="#product_info" data-toggle="modal">
                                                                Order Now
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/*TODO: Past order*/}
                            {/*<div className="row mt-5 p-3 mx-0">*/}
                            {/*    <div className="w-100 border-light_blue rounded-4 p-4">*/}
                            {/*        <div className="row">*/}
                            {/*            <div className="col-12">*/}
                            {/*                <div className="d-table w-100 ">*/}
                            {/*                    <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">*/}
                            {/*                        Past Order*/}
                            {/*                    </h3>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}

                            {/*            <div className="col-12 mt-2">*/}
                            {/*                <div className="w-100">*/}
                            {/*                    <table className="table table-custom display w-100 dt-responsive nowrap">*/}
                            {/*                        <thead className="thead-light rounded-10">*/}
                            {/*                        <tr>*/}
                            {/*                            <th>Product</th>*/}
                            {/*                            <th>Paid By</th>*/}
                            {/*                            <th>Amount</th>*/}
                            {/*                            <th>Payment Date</th>*/}
                            {/*                            <th>Status</th>*/}

                            {/*                        </tr>*/}
                            {/*                        </thead>*/}
                            {/*                        <tbody>*/}
                            {/*                        <tr>*/}
                            {/*                            <td>Offer 2</td>*/}
                            {/*                            <td>Jessica Miller</td>*/}
                            {/*                            <td>$50.00</td>*/}
                            {/*                            <td>01/15/2021</td>*/}
                            {/*                            <td>Done</td>*/}
                            {/*                        </tr>*/}

                            {/*                        </tbody>*/}
                            {/*                    </table>*/}
                            {/*                </div>*/}

                            {/*                <nav aria-label="Page navigation example">*/}
                            {/*                    <ul className="pagination justify-content-end">*/}
                            {/*                        <li className="page-item disabled">*/}
                            {/*                            <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                                className="fas fa-angle-double-left"></i></a>*/}
                            {/*                        </li>*/}
                            {/*                        <li className="page-item"><a className="page-link txt-color-purple"*/}
                            {/*                                                     href="#">1</a></li>*/}
                            {/*                        <li className="page-item"><a className="page-link text-secondary"*/}
                            {/*                                                     href="#">2</a></li>*/}
                            {/*                        <li className="page-item"><a className="page-link text-secondary"*/}
                            {/*                                                     href="#">3</a></li>*/}
                            {/*                        <li className="page-item">*/}
                            {/*                            <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                                className="fas fa-angle-double-right"></i></a>*/}
                            {/*                        </li>*/}
                            {/*                    </ul>*/}
                            {/*                </nav>*/}


                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

        )
    }
}

export default OrderProductsPage;