import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import { getSubscriptions } from "../../../../helpers/tierAPIServices";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import { getAllRequestsForSuperadmin } from '../../../../helpers/accountAPIServices';

const TierPage = (props) => {
    const [tiers, setTiers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectTo404,] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        async function loadTiers() {
            setIsLoading(true);
            const subscriptions = await getSubscriptions(getAccessTokenFromSession());
            console.log(subscriptions);
            if (subscriptions.error) {
                console.log('There is an error fetching subscriptions');
                setIsLoading(false);
                return;
            }
            setSubscriptions(subscriptions);
            setIsLoading(false);

        }

        loadTiers();
    }, [])

    useEffect(() => {
        async function getRequest() {
            const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
            if (getRequestResults?.error) {
                console.log("API ERROR: " + getRequestResults?.error.response.statusText);

                if (getRequestResults?.error.response.status === 401) {
                    localStorage.clear();
                    window.location.reload(true);
                }
                this.setState({
                    error: "There is an error fetching all the requests.",
                });
                this.setLoading(false);
                return;
            }
        }

        getRequest();
    });

    useEffect(() => {
        const tiers = {};
        for (let subscription of subscriptions) {
            if (!tiers[subscription.tier.name]) {
                tiers[subscription.tier.name] = [{
                    practice: subscription.practice,
                    approval_status: subscription.approval_status,
                    acted_on: subscription.acted_on,
                }]
            } else {
                tiers[subscription.tier.name].push({
                    practice: subscription.practice,
                    approval_status: subscription.approval_status,
                    acted_on: subscription.acted_on,
                })
            }
        };

        setTiers(tiers);
    }, [subscriptions])

    const handleSearchChange = (e) => {
        for (let key in tiers) {
            if (e.target.id === key) {
                setSearchTerm(e.target.value);
                console.log(e.target.id);
            }
            return;
        }
    };

    const renderTables = () => {
        const tables = [];

        for (let key in tiers) {
            const totalSubscribersString = tiers[key].length > 0 ? `${tiers[key].length} subscriber${tiers[key].length > 1 ? "s" : ""}` :
                "No subscribers";
            tables.push(
                <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                    <div className="col-12">
                        <div className="d-table w-100 ">
                            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">{`${key} `}
                                Subscribers</h3>
                            <div className="w-100 border-bottom mb-4 pb-4">
                                <span className="form-label">{totalSubscribersString}</span>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 mt-2">
                        <div className="w-100" style={tiers[key].length >= 6 ? { overflowY: "scroll", maxHeight: "500px" } : {}}>

                            <div id="DataTables_Table_0_wrapper"
                                className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="top">
                                    <div className="row mb-4">
                                        <div className="col-12 col-md-6 show-entries">
                                            {/*<div className="dataTables_length" id="DataTables_Table_0_length">*/}
                                            {/*    <label>Show*/}
                                            {/*        <select name="DataTables_Table_0_length"*/}
                                            {/*                aria-controls="DataTables_Table_0"*/}
                                            {/*                className="custom-select custom-select-sm form-control form-control-sm">*/}
                                            {/*            <option value="10">10</option>*/}
                                            {/*            <option value="25">25</option>*/}
                                            {/*            <option value="50">50</option>*/}
                                            {/*            <option value="50">59</option>*/}
                                            {/*            <option value="100">100</option>*/}
                                            {/*        </select>*/}
                                            {/*        entries*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className="col-12 col-md-6 search-input">
                                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                <label>Search:
                                                    <input type="search" id={`${key}`}
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                        aria-controls="DataTables_Table_0" value={searchTerm} onChange={handleSearchChange} />
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="bottom"></div>
                                <div className="clear"></div>
                            </div>

                            <table
                                className="table table-custom display dt-responsive nowrap w-100">
                                <thead className="thead-light rounded-10">
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>Practice Name</th>
                                        <th>Contact Info</th>
                                        <th>State</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        tiers[key].length < 1 ?
                                            <tr>
                                                <td>

                                                </td>
                                                <td>No data available.</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>

                                                </td>
                                            </tr> :

                                            !searchTerm ?

                                                tiers[key].map((s) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <span className="mr-0">
                                                                    {/*<input type="checkbox" className="form-control-input-checkbox" name=""*/}
                                                                    {/*       id="check_1" checked={s.acted_on}/>*/}
                                                                    {/*<label htmlFor="check_1"*/}
                                                                    {/*       className="form-control-input-checkbox-label m-0 mr-0"></label>*/}
                                                                </span>
                                                            </td>
                                                            <td>{s.practice.practice_id}</td>
                                                            <td>{s.practice.name}</td>
                                                            <td>{`${s.practice.claimed_by.first_name} ${s.practice.claimed_by.last_name}`}</td>
                                                            <td>{s.practice.state}</td>
                                                            <td>{s.practice.claimed_by.email}</td>
                                                            <td>{s.approval_status.toUpperCase()}</td>
                                                            <td>
                                                                <a href={`/listings/${s.practice.practice_id}`}
                                                                    className="text-decoration-none"
                                                                    rel="noreferrer"
                                                                    target='_blank'>View</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                                :
                                                tiers[key].filter((s) => {
                                                    const information = `${s.practice.practice_id} ${s.practice.name} ${s.practice.claimed_by.first_name} ${s.practice.claimed_by.last_name} ${s.practice.state} ${s.practice.claimed_by.email}`
                                                    if (searchTerm === '') {
                                                        return s
                                                    }
                                                    else if (information.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                        return s
                                                    }
                                                    return !s;
                                                }).map((s) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <span className="mr-0">
                                                                    {/*<input type="checkbox" className="form-control-input-checkbox" name=""*/}
                                                                    {/*       id="check_1" checked={s.acted_on}/>*/}
                                                                    {/*<label htmlFor="check_1"*/}
                                                                    {/*       className="form-control-input-checkbox-label m-0 mr-0"></label>*/}
                                                                </span>
                                                            </td>
                                                            <td>{s.practice.practice_id}</td>
                                                            <td>{s.practice.name}</td>
                                                            <td>{`${s.practice.claimed_by.first_name} ${s.practice.claimed_by.last_name}`}</td>
                                                            <td>{s.practice.state}</td>
                                                            <td>{s.practice.claimed_by.email}</td>
                                                            <td>{s.approval_status.toUpperCase()}</td>
                                                            <td>
                                                                <a href={`/listings/${s.practice.practice_id}`}
                                                                    className="text-decoration-none"
                                                                    rel="noreferrer"
                                                                    target='_blank'>View</a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                    }

                                </tbody>
                            </table>
                        </div>

                        {/*<nav aria-label="Page navigation example">*/}
                        {/*    <ul className="pagination justify-content-end">*/}
                        {/*        <li className="page-item disabled">*/}
                        {/*            <a className="page-link" href="#" tabIndex="-1"><i*/}
                        {/*                className="fas fa-angle-double-left"></i></a>*/}
                        {/*        </li>*/}
                        {/*        <li className="page-item"><a className="page-link txt-color-purple"*/}
                        {/*                                     href="#">1</a></li>*/}
                        {/*        <li className="page-item"><a className="page-link text-secondary" href="#">2</a>*/}
                        {/*        </li>*/}
                        {/*        <li className="page-item"><a className="page-link text-secondary" href="#">3</a>*/}
                        {/*        </li>*/}
                        {/*        <li className="page-item">*/}
                        {/*            <a className="page-link txt-color-purple" href="#"><i*/}
                        {/*                className="fas fa-angle-double-right"></i></a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</nav>*/}


                    </div>
                </div>
            );

        }

        return tables;
    }

    return (
        <>
            {
                redirectTo404 && <Redirect to='/404' />
            }

            {
                isLoading ? <LoadingOverlay /> :
                    <div className="main-content">
                        <div className="container p-3 p-md-5">
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-6 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold">All Subscribers</h2>
                                </div>
                                <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                                    <Link to='/subscribers/organizations'
                                        className="btn btn-solid btn-solid-orange px-3">
                                        <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                        <span className="d-none d-sm-block">
                                            Manage Organizations
                                        </span>
                                    </Link>
                                </div>

                                {
                                    subscriptions.length < 1 && <p>No subscribers yet.</p>
                                }

                                {renderTables()}



                            </div>

                            {/*<p>Subscriber feature, soon to be deployed.</p>*/}

                            {/*<div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="d-table w-100 ">*/}
                            {/*            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">Basic Subscribers</h3>*/}
                            {/*            <div className="w-100 border-bottom mb-4 pb-4">*/}
                            {/*                <span className="form-label"> 100 total Custom Organizations</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}

                            {/*    <div className="col-12 mt-2">*/}
                            {/*        <div className="w-100">*/}

                            {/*            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">*/}
                            {/*                <div className="top">*/}
                            {/*                    <div className="row mb-4">*/}
                            {/*                        <div className="col-12 col-md-6 show-entries">*/}
                            {/*                            <div className="dataTables_length" id="DataTables_Table_0_length">*/}
                            {/*                                <label>Show*/}
                            {/*                                    <select name="DataTables_Table_0_length"*/}
                            {/*                                            aria-controls="DataTables_Table_0"*/}
                            {/*                                            className="custom-select custom-select-sm form-control form-control-sm">*/}
                            {/*                                        <option value="10">10</option>*/}
                            {/*                                        <option value="25">25</option>*/}
                            {/*                                        <option value="50">50</option>*/}
                            {/*                                        <option value="50">59</option>*/}
                            {/*                                        <option value="100">100</option>*/}
                            {/*                                    </select>*/}
                            {/*                                    entries*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-12 col-md-6 search-input">*/}
                            {/*                            <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                            {/*                                <label>Search:*/}
                            {/*                                    <input type="search" className="form-control form-control-sm"*/}
                            {/*                                           placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="bottom"></div>*/}
                            {/*                <div className="clear"></div>*/}
                            {/*            </div>*/}

                            {/*            <table className="table table-custom display dt-responsive nowrap w-100">*/}
                            {/*                <thead className="thead-light rounded-10">*/}
                            {/*                <tr>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th>ID</th>*/}
                            {/*                    <th>Organization Name</th>*/}
                            {/*                    <th>Contact Info</th>*/}
                            {/*                    <th>State</th>*/}
                            {/*                    <th>Phone Number</th>*/}
                            {/*                    <th>Email</th>*/}
                            {/*                    <th>Status</th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*                </thead>*/}
                            {/*                <tbody>*/}

                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Organization 1</td>*/}
                            {/*                    <td>Trish Woods</td>*/}
                            {/*                    <td>CA</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Jason Aldean</td>*/}
                            {/*                    <td>FL</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Declined</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Garth Brooks</td>*/}
                            {/*                    <td>TX</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Cancelled</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Marry Perry</td>*/}
                            {/*                    <td>IO</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Amarillo John</td>*/}
                            {/*                    <td>WA</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                </tbody>*/}
                            {/*            </table>*/}
                            {/*        </div>*/}

                            {/*        <nav aria-label="Page navigation example">*/}
                            {/*            <ul className="pagination justify-content-end">*/}
                            {/*                <li className="page-item disabled">*/}
                            {/*                    <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                        className="fas fa-angle-double-left"></i></a>*/}
                            {/*                </li>*/}
                            {/*                <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                            {/*                <li className="page-item">*/}
                            {/*                    <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                        className="fas fa-angle-double-right"></i></a>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </nav>*/}


                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="d-table w-100 ">*/}
                            {/*            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">Tier 1 Subscribers</h3>*/}
                            {/*            <div className="w-100 border-bottom mb-4 pb-4">*/}
                            {/*                <span className="form-label"> 400 total Subscribers</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}

                            {/*    <div className="col-12 mt-2">*/}
                            {/*        <div className="w-100">*/}

                            {/*            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">*/}
                            {/*                <div className="top">*/}
                            {/*                    <div className="row mb-4">*/}
                            {/*                        <div className="col-12 col-md-6 show-entries">*/}
                            {/*                            <div className="dataTables_length" id="DataTables_Table_0_length">*/}
                            {/*                                <label>Show*/}
                            {/*                                    <select name="DataTables_Table_0_length"*/}
                            {/*                                            aria-controls="DataTables_Table_0"*/}
                            {/*                                            className="custom-select custom-select-sm form-control form-control-sm">*/}
                            {/*                                        <option value="10">10</option>*/}
                            {/*                                        <option value="25">25</option>*/}
                            {/*                                        <option value="50">50</option>*/}
                            {/*                                        <option value="50">59</option>*/}
                            {/*                                        <option value="100">100</option>*/}
                            {/*                                    </select>*/}
                            {/*                                    entries*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-12 col-md-6 search-input">*/}
                            {/*                            <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                            {/*                                <label>Search:*/}
                            {/*                                    <input type="search" className="form-control form-control-sm"*/}
                            {/*                                           placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="bottom"></div>*/}
                            {/*                <div className="clear"></div>*/}
                            {/*            </div>*/}

                            {/*            <table className="table table-custom display dt-responsive nowrap w-100">*/}
                            {/*                <thead className="thead-light rounded-10">*/}
                            {/*                <tr>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th>ID</th>*/}
                            {/*                    <th>Organization Name</th>*/}
                            {/*                    <th>Contact Info</th>*/}
                            {/*                    <th>State</th>*/}
                            {/*                    <th>Phone Number</th>*/}
                            {/*                    <th>Email</th>*/}
                            {/*                    <th>Status</th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*                </thead>*/}
                            {/*                <tbody>*/}

                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Organization 1</td>*/}
                            {/*                    <td>Trish Woods</td>*/}
                            {/*                    <td>CA</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Jason Aldean</td>*/}
                            {/*                    <td>FL</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Declined</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Garth Brooks</td>*/}
                            {/*                    <td>TX</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Cancelled</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Marry Perry</td>*/}
                            {/*                    <td>IO</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Amarillo John</td>*/}
                            {/*                    <td>WA</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                </tbody>*/}
                            {/*            </table>*/}
                            {/*        </div>*/}

                            {/*        <nav aria-label="Page navigation example">*/}
                            {/*            <ul className="pagination justify-content-end">*/}
                            {/*                <li className="page-item disabled">*/}
                            {/*                    <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                        className="fas fa-angle-double-left"></i></a>*/}
                            {/*                </li>*/}
                            {/*                <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                            {/*                <li className="page-item">*/}
                            {/*                    <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                        className="fas fa-angle-double-right"></i></a>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </nav>*/}


                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="d-table w-100 ">*/}
                            {/*            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">Tier 2 Subscribers</h3>*/}
                            {/*            <div className="w-100 border-bottom mb-4 pb-4">*/}
                            {/*                <span className="form-label"> 400 total Subscribers</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}

                            {/*    <div className="col-12 mt-2">*/}
                            {/*        <div className="w-100">*/}

                            {/*            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">*/}
                            {/*                <div className="top">*/}
                            {/*                    <div className="row mb-4">*/}
                            {/*                        <div className="col-12 col-md-6 show-entries">*/}
                            {/*                            <div className="dataTables_length" id="DataTables_Table_0_length">*/}
                            {/*                                <label>Show*/}
                            {/*                                    <select name="DataTables_Table_0_length"*/}
                            {/*                                            aria-controls="DataTables_Table_0"*/}
                            {/*                                            className="custom-select custom-select-sm form-control form-control-sm">*/}
                            {/*                                        <option value="10">10</option>*/}
                            {/*                                        <option value="25">25</option>*/}
                            {/*                                        <option value="50">50</option>*/}
                            {/*                                        <option value="50">59</option>*/}
                            {/*                                        <option value="100">100</option>*/}
                            {/*                                    </select>*/}
                            {/*                                    entries*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-12 col-md-6 search-input">*/}
                            {/*                            <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                            {/*                                <label>Search:*/}
                            {/*                                    <input type="search" className="form-control form-control-sm"*/}
                            {/*                                           placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="bottom"></div>*/}
                            {/*                <div className="clear"></div>*/}
                            {/*            </div>*/}

                            {/*            <table className="table table-custom display dt-responsive nowrap w-100">*/}
                            {/*                <thead className="thead-light rounded-10">*/}
                            {/*                <tr>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th>ID</th>*/}
                            {/*                    <th>Organization Name</th>*/}
                            {/*                    <th>Contact Info</th>*/}
                            {/*                    <th>State</th>*/}
                            {/*                    <th>Phone Number</th>*/}
                            {/*                    <th>Email</th>*/}
                            {/*                    <th>Status</th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*                </thead>*/}
                            {/*                <tbody>*/}

                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Organization 1</td>*/}
                            {/*                    <td>Trish Woods</td>*/}
                            {/*                    <td>CA</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Jason Aldean</td>*/}
                            {/*                    <td>FL</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Declined</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Garth Brooks</td>*/}
                            {/*                    <td>TX</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Cancelled</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Marry Perry</td>*/}
                            {/*                    <td>IO</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Amarillo John</td>*/}
                            {/*                    <td>WA</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                </tbody>*/}
                            {/*            </table>*/}
                            {/*        </div>*/}

                            {/*        <nav aria-label="Page navigation example">*/}
                            {/*            <ul className="pagination justify-content-end">*/}
                            {/*                <li className="page-item disabled">*/}
                            {/*                    <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                        className="fas fa-angle-double-left"></i></a>*/}
                            {/*                </li>*/}
                            {/*                <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                            {/*                <li className="page-item">*/}
                            {/*                    <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                        className="fas fa-angle-double-right"></i></a>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </nav>*/}


                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="d-table w-100 ">*/}
                            {/*            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">Tier 3 Subscribers</h3>*/}
                            {/*            <div className="w-100 border-bottom mb-4 pb-4">*/}
                            {/*                <span className="form-label"> 400 total Subscribers</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}

                            {/*    <div className="col-12 mt-2">*/}
                            {/*        <div className="w-100">*/}

                            {/*            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">*/}
                            {/*                <div className="top">*/}
                            {/*                    <div className="row mb-4">*/}
                            {/*                        <div className="col-12 col-md-6 show-entries">*/}
                            {/*                            <div className="dataTables_length" id="DataTables_Table_0_length">*/}
                            {/*                                <label>Show*/}
                            {/*                                    <select name="DataTables_Table_0_length"*/}
                            {/*                                            aria-controls="DataTables_Table_0"*/}
                            {/*                                            className="custom-select custom-select-sm form-control form-control-sm">*/}
                            {/*                                        <option value="10">10</option>*/}
                            {/*                                        <option value="25">25</option>*/}
                            {/*                                        <option value="50">50</option>*/}
                            {/*                                        <option value="50">59</option>*/}
                            {/*                                        <option value="100">100</option>*/}
                            {/*                                    </select>*/}
                            {/*                                    entries*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-12 col-md-6 search-input">*/}
                            {/*                            <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                            {/*                                <label>Search:*/}
                            {/*                                    <input type="search" className="form-control form-control-sm"*/}
                            {/*                                           placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="bottom"></div>*/}
                            {/*                <div className="clear"></div>*/}
                            {/*            </div>*/}

                            {/*            <table className="table table-custom display dt-responsive nowrap w-100">*/}
                            {/*                <thead className="thead-light rounded-10">*/}
                            {/*                <tr>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th>ID</th>*/}
                            {/*                    <th>Organization Name</th>*/}
                            {/*                    <th>Contact Info</th>*/}
                            {/*                    <th>State</th>*/}
                            {/*                    <th>Phone Number</th>*/}
                            {/*                    <th>Email</th>*/}
                            {/*                    <th>Status</th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*                </thead>*/}
                            {/*                <tbody>*/}

                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Organization 1</td>*/}
                            {/*                    <td>Trish Woods</td>*/}
                            {/*                    <td>CA</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Jason Aldean</td>*/}
                            {/*                    <td>FL</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Declined</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Garth Brooks</td>*/}
                            {/*                    <td>TX</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Cancelled</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Marry Perry</td>*/}
                            {/*                    <td>IO</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Amarillo Urgent Care</td>*/}
                            {/*                    <td>Amarillo John</td>*/}
                            {/*                    <td>WA</td>*/}
                            {/*                    <td>407989656</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}
                            {/*                </tbody>*/}
                            {/*            </table>*/}
                            {/*        </div>*/}

                            {/*        <nav aria-label="Page navigation example">*/}
                            {/*            <ul className="pagination justify-content-end">*/}
                            {/*                <li className="page-item disabled">*/}
                            {/*                    <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                        className="fas fa-angle-double-left"></i></a>*/}
                            {/*                </li>*/}
                            {/*                <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                            {/*                <li className="page-item">*/}
                            {/*                    <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                        className="fas fa-angle-double-right"></i></a>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </nav>*/}


                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">*/}
                            {/*    <div className="col-12">*/}
                            {/*        <div className="d-table w-100 ">*/}
                            {/*            <h3 className="h4 txt-color-dark_purple font-weight-bold mx-0">Custom</h3>*/}
                            {/*            <div className="w-100 border-bottom mb-4 pb-4">*/}
                            {/*                <span className="form-label"> 400 total Subscribers</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}

                            {/*    <div className="col-12 mt-2">*/}
                            {/*        <div className="w-100">*/}

                            {/*            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">*/}
                            {/*                <div className="top">*/}
                            {/*                    <div className="row mb-4">*/}
                            {/*                        <div className="col-12 col-md-6 show-entries">*/}
                            {/*                            <div className="dataTables_length" id="DataTables_Table_0_length">*/}
                            {/*                                <label>Show*/}
                            {/*                                    <select name="DataTables_Table_0_length"*/}
                            {/*                                            aria-controls="DataTables_Table_0"*/}
                            {/*                                            className="custom-select custom-select-sm form-control form-control-sm">*/}
                            {/*                                        <option value="10">10</option>*/}
                            {/*                                        <option value="25">25</option>*/}
                            {/*                                        <option value="50">50</option>*/}
                            {/*                                        <option value="50">59</option>*/}
                            {/*                                        <option value="100">100</option>*/}
                            {/*                                    </select>*/}
                            {/*                                    entries*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                        <div className="col-12 col-md-6 search-input">*/}
                            {/*                            <div id="DataTables_Table_0_filter" className="dataTables_filter">*/}
                            {/*                                <label>Search:*/}
                            {/*                                    <input type="search" className="form-control form-control-sm"*/}
                            {/*                                           placeholder="Search" aria-controls="DataTables_Table_0" />*/}
                            {/*                                </label>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}

                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className="bottom"></div>*/}
                            {/*                <div className="clear"></div>*/}
                            {/*            </div>*/}

                            {/*            <table className="table table-custom display dt-responsive nowrap w-100">*/}
                            {/*                <thead className="thead-light rounded-10">*/}
                            {/*                <tr>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th>ID</th>*/}
                            {/*                    <th>Organization Name</th>*/}
                            {/*                    <th>Contact Info</th>*/}
                            {/*                    <th>State</th>*/}
                            {/*                    <th>Phone Number</th>*/}
                            {/*                    <th>Email</th>*/}
                            {/*                    <th>Status</th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*                </thead>*/}
                            {/*                <tbody>*/}

                            {/*                <tr>*/}
                            {/*                    <td></td>*/}
                            {/*                    <td>U15</td>*/}
                            {/*                    <td>Organization 1</td>*/}
                            {/*                    <td>Trish Woods</td>*/}
                            {/*                    <td>CA</td>*/}
                            {/*                    <td>8632075971</td>*/}
                            {/*                    <td>Kevin@urgentcare.com</td>*/}
                            {/*                    <td>Active</td>*/}
                            {/*                    <td><a href="#view_subscriber_details" className="text-decoration-none"*/}
                            {/*                           data-toggle="modal" data-target="#view_subscriber_details">View</a></td>*/}
                            {/*                </tr>*/}

                            {/*                </tbody>*/}
                            {/*            </table>*/}
                            {/*        </div>*/}

                            {/*        <nav aria-label="Page navigation example">*/}
                            {/*            <ul className="pagination justify-content-end">*/}
                            {/*                <li className="page-item disabled">*/}
                            {/*                    <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                        className="fas fa-angle-double-left"></i></a>*/}
                            {/*                </li>*/}
                            {/*                <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*/}
                            {/*                <li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*/}
                            {/*                <li className="page-item">*/}
                            {/*                    <a className="page-link txt-color-purple" href="#"><i*/}
                            {/*                        className="fas fa-angle-double-right"></i></a>*/}
                            {/*                </li>*/}
                            {/*            </ul>*/}
                            {/*        </nav>*/}


                            {/*    </div>*/}
                            {/*</div>*/}


                        </div>
                    </div>
            }
        </>
    )
}

export default TierPage;