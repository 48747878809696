import React, {
  useEffect,
  useState
} from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  getAccessTokenFromSession
} from "../../../helpers/sessionHelpers";
import {
  getTiersList
} from "../../../helpers/tierAPIServices";
import FooterSection from "../../Common/FooterSection/FooterSection";
import HeaderSection from "../../Common/HeaderSection/HeaderSection";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import { getAllRequestsForSuperadmin } from "../../../helpers/accountAPIServices";




function TiersMain(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [tiersData, setTiersData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    async function loadTiers() {
      try {
        const tiers = await getTiersList(getAccessTokenFromSession());
        setIsLoading(false);
        setTiersData(tiers?.result);
      }
      catch (e) {
        console.log(e);
        return;
      }
    }

    loadTiers();
  }, []);

  useEffect(() => {
        async function getRequest() {
            const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
            if (getRequestResults?.error) {
                console.log("API ERROR: " + getRequestResults?.error.response.statusText);

                if (getRequestResults?.error.response.status === 401) {
                    localStorage.clear();
                    window.location.reload(true);
                }
                this.setState({
                    error: "There is an error fetching all the requests.",
                });
                this.setLoading(false);
                return;
            }
        }

        getRequest();
  });

  const getPaymentMode = (item) => {
    if (item?.code === "BASIC") return "Free";

    let paymentMode = null;

    switch (item?.payment_mode) {
      case "monthly":
        paymentMode = "month";
        break;
      case "annually":
        paymentMode = "year";
        break;
      case "daily":
        paymentMode = "day";
        break;
      // no default
    }

    return (paymentMode) ? `$${item?.price} / ${paymentMode}` : "";
  }

  const renderTiersList = () => {
    let layout = [];

    for (let i in tiersData) {
      let item = tiersData[i];
      let uniqueKey = uuidv4();
      let tierName = item?.name;

      layout.push(
        <div key={uniqueKey} className="w-100 border-light_blue rounded-4 p-3 row p-0 m-0 mb-3">
          <div className="col-10 col-md-9 px-0">
            <div className="w-100 mb-2">
              <span className="d-table font-weight-bold txt-color-dark_purple">{`${tierName}`}</span>
            </div>

            <div className="w-100 mb-3">
              <span className="d-table font-weight-normal txt-color-dark_purple">
                {getPaymentMode(item)}
              </span>
            </div>

            <div className="w-100 mb-0 d-flex mt-3">
              <a href={`/tiers/${item?.code}`} className="btn btn-outline btn-outline-purple px-4 mr-2">
                View Details <i className="fas fa-arrow-right ml-3"></i>
              </a>
              {
                /* 
              <a href="#" className="btn btn-outline btn-outline-purple px-4">
                View Subscribers <i className="fas fa-arrow-right ml-3"></i>
              </a>
                */
              }
            </div>
          </div>

          <div className="col-2 col-md-3 px-0">
            <a href={`/tiers/${item?.code}/edit`} className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none">
              <i className="fas fa-pen mr-1"></i>
              <span className="d-none d-sm-inline">Edit</span>
            </a>
          </div>
        </div>
      );
    }

    return layout;
  }


  return (
    <>
      <HeaderSection />
      {
        isLoading ? <LoadingOverlay /> :
          <main>
            <div className="main-content">
              <div className="container container-lg p-3 py-md-5 px-md-0">

                <div className="row mb-4 p-0 mx-0">
                  <div className="col-6 px-0 mx-0">
                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Tiers</h2>
                  </div>
                  <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                    <a href="/tiers/add" className="btn btn-solid btn-solid-orange px-3">
                      <i className="fas fa-plus mr-0 mr-sm-2"></i>
                      <span className="d-none d-sm-block">
                        Add New Tier
                      </span>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <a className="btn btn-outline btn-outline-purple px-4 mb-4" href={"/subscribers"}>
                      View All Subscribers
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-7 mb-4">
                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-xl-5">
                      <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Tier Types</h3>

                      {renderTiersList()}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </main>
      }

      <FooterSection />
    </>
  )

}

export default TiersMain;
