import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {checkStatus, parseJSON} from "./responseHandlers";

const approveRequest = (id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "request/approvals/";
    const body = {
        approved: [id],
        rejected: [],
    }
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}

const rejectRequest = (id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "request/approvals/";
    const body = {
        approved: [],
        rejected: [id],
    }
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(e => {
            return {
                error: e
            }
        });
}

export {
    approveRequest,
    rejectRequest
}