import React from 'react'
/* eslint-disable */
const EditListingsReportViewPage = (props) => {
  return (
    <>
      {/* <!--MAIN CONTENT START--> */}
            <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Edit Location</h2>
                            <a href="report-view.html" className="mt-4 d-table txt-color-purple text-decoration-none "><i className="fas fa-arrow-left mr-2"></i> Back to Location Listings</a>
                        </div>

                    </div>

                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-lg-10 col-xl-8  border-light_blue rounded-4 p-3 p-md-5 mx-0">

                            {/* <!--BASIC INFO--> */}
                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Basic Info</h4>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Record ID</label> 
                                        <input type="text" className="form-control" placeholder="" value="U18"/>
                                    </div>
                                </div>

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">NUCR Practice Name</label> 
                                        <input type="text" className="form-control" placeholder="" value="Amarillo Urgent Care"/>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Classification</label> 
                                        <input type="text" className="form-control" placeholder="" value="Traditional UC"/>
                                    </div>
                                </div>

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Email Address</label> 
                                        <input type="email" className="form-control" placeholder="" value="admin@amarillourgentcare.com"/>
                                    </div>
                                </div>

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Internal Email Address</label> 
                                        <input type="email" className="form-control" placeholder="" value="internal@amarillourgentcare.com"/>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Phone</label> 
                                        <input type="email" className="form-control" placeholder="" value=" +1 (505) 555-0125"/>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Website</label> 
                                        <input type="email" className="form-control" placeholder="" value="www.amarillourgentcare.com"/>
                                    </div>
                                </div>

                            </div>
                            {/* <!--BASIC INFO--> */}

                            {/* <!--Location Address--> */}
                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Location Address</h4>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Street Address</label> 
                                        <input type="text" className="form-control" placeholder="" value="1915 S Coulter St"/>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">City</label> 
                                        <input type="text" className="form-control" placeholder="" value="Amarillo"/>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="" className="form-label">State</label> 
                                        <input type="email" className="form-control" placeholder="" value="Texas"/>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <label htmlFor="" className="form-label">ZIP Code</label> 
                                        <input type="email" className="form-control" placeholder="" value="79106"/>
                                    </div>
                                </div>
                            </div>
                            {/* <!--Location Address--> */}

                            {/* <!--Business Info--> */}
                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Business Info</h4>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Services</label> 
                                        <textarea name="" id="" cols="30" rows="5" className="form-control">- Injuries & Illnesses
                                            - Pediatric Care
                                            - Physical Exams
                                            - Vaccinations
                                            - STD Testing</textarea>
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">About</label>
                                        <textarea name="" id="" cols="30" rows="5" className="form-control">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus faucibus sodales at vel scelerisque volutpat sed scelerisque sapien. Egestas consequat, diam velit egestas. Egestas vestibulum morbi
                                            viverra vestibulum facilisi arcu. Amet, quis ultrices viverra et</textarea>
                                    </div>
                                </div>
                                <div className="form-row mb-5">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Insurance</label>
                                        <textarea name="" id="" cols="30" rows="5" className="form-control">- Aetna
                                            - Blue Cross Blue Shield
                                            - Cigna
                                            - Humana Gold
                                            - Medicare</textarea>
                                    </div>
                                </div>
                                {/* <!-- Covid Test --> */}
                                <label htmlFor="" className="form-label">Offers COVID-19 Tests?</label> 
                                <div className="w-100 input-group mb-3">
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="yes" checked/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="yes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="no"/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="no">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-4">
                                        <label htmlFor="" className="form-label mb-0">Finger prick?</label> 
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="fingerprick" id="fp-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="fp-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="fingerprick" id="fp-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="fp-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-4">
                                        <label htmlFor="" className="form-label mb-0">Blood Draw?</label> 
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="blooddraw" id="bd-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="bd-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="blooddraw" id="bd-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="bd-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-4">
                                        <label htmlFor="" className="form-label mb-0">Nasal Swab?</label>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="nasalswab" id="ns-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="ns-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="nasalswab" id="ns-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="ns-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-5">
                                    <div className="col-md-4">
                                        <label htmlFor="" className="form-label mb-0">Spit test?</label>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="spittest" id="st-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="st-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="spittest" id="st-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="st-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--  / Covid Test --> */}
                                {/* <!-- Antibody Test --> */}
                                <label htmlFor="" className="form-label">Offers Antibody Tests?</label> 
                                <div className="w-100 input-group mb-3">
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="yes" checked/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="yes">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="no"/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="no">
                                            No
                                        </label>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-5">
                                        <label htmlFor="" className="form-label mb-0">Roche venipuncture</label> 
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="Roche" id="rv-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="rv-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="Roche" id="rv-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="rv-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-5">
                                        <label htmlFor="" className="form-label mb-0">Finger Prick?</label> 
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="at-fp" id="at-fp-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="at-fp-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="at-fp" id="at-fp-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="at-fp-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-5">
                                    <div className="col-md-5">
                                        <label htmlFor="" className="form-label mb-0">Blood Test?</label>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline mb-2">
                                            <div className="mr-4">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="at-bt" id="at-bt-1" value="yes" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="at-bt-1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="">
                                                <input className="form-check-input form-control-input-radio position-absolute" type="radio" name="at-bt" id="at-bt-2" value="no"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="at-bt-2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--  / Antibody Test--> */}

                                {/* <!-- X-Ray --> */}
                                <label htmlFor="" className="form-label">Offers X-ray?</label> 
                                <div className="w-100 input-group mb-3">
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="xray" id="xray-1" checked/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="xray-1">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="xray" id="xray-2"/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="xray-2">
                                            No
                                        </label>
                                    </div>
                                </div>

                                {/* <!-- Partner --> */}
                                <label htmlFor="" className="form-label">Featured Partner?</label> 
                                <div className="w-100 input-group mb-3">
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="fpartner" id="fpartner-1" checked/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="fpartner-1">
                                            Yes
                                        </label>
                                    </div>
                                    <div className="float-none float-sm-left mr-4">
                                        <input className="form-check-input form-control-input-radio" type="radio" name="fpartner" id="fpartner-2"/>
                                        <label className="form-check-label form-control-input-radio-label form-label" htmlFor="fpartner-2">
                                            No
                                        </label>
                                    </div>
                                </div>


                            </div>

                            {/* <!--Business Info--> */}

                            {/* <!--Add Organization--> */}
                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Organization</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 mx-0 ">
                                        Organization if any

                                        <div className="dropdown d-inline-block float-right ">
                                            <a className="btn dropdown-toggle btn btn-outline btn-outline-purple px-3" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                                <span className="d-none d-sm-block">
                                                    New Campaign
                                                </span>
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <span className="p-2 p-md-3"><small>Select Campaign Type</small></span>
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#select-location">Individual Plan </a>
                                                <a className="dropdown-item" href="#">Organization Plan</a>
                                                <a className="dropdown-item" href="#">Geolocate Plan</a>
                                                <a className="dropdown-item" href="#">Custom</a>
                                            </div>

                                        </div>
                                        <select name="" id="" className="form-control px-3 mt-5">
                                            <option value="">Team Orlando - Geo Locaition </option>
                                            <option value="">Team Orlando - Geo Locaition </option>
                                            <option value="">Team Orlando - Geo Locaition </option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            {/* <!--TIERS--> */}
                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Tiers</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 mx-0 px-0">
                                        <div className="row mx-0 px-0">
                                            <div className="col-12 mr-4 mb-3 pl-1">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-1" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-1">
                                                    Basic ($Free) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple"></i></span>
                                                </label>

                                            </div>
                                            <div className="col-12 mr-4 mb-3 pl-1">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-2"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-2">
                                                    Tier 1 ($1,000.00) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple"></i></span>
                                                </label>
                                            </div>
                                            <div className="col-12 mr-4 mb-3 pl-1">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-3"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-3">
                                                    Tier 2 ($500.00) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple"></i></span>
                                                </label>
                                            </div>
                                            <div className="col-12 mr-4 mb-3 pl-1">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-4"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-4">
                                                    Custom <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple"></i></span>
                                                </label>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- Stripe Billing --> */}

                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Stripe Billing</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Add Stripe Billing URL</label> 
                                        <input type="text" className="form-control" placeholder="" value="www.stripe.com/"/>
                                    </div>
                                </div>

                            </div>



                            {/* <!--Days of Operation--> */}
                            <div className="w-100 mb-4 pb-4 ">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Hours of Operation</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-md-2 pb-3">
                                    <div className="col-12 col-md-8">
                                        <label htmlFor="" className="form-label">Operates 24 hours?</label> 
                                        <div className="w-100 input-group">
                                            <div className="float-none float-sm-left mr-4">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4c" id="yes_4c"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="yes_4c">
                                                    Yes
                                                </label>
                                            </div>

                                            <div className="float-none float-sm-left mr-4">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4c" id="no_4c" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="no_4c">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 mt-3">
                                        <label htmlFor="" className="form-label">Operates Overnight</label> 
                                        <div className="w-100 input-group">
                                            <div className="float-none float-sm-left mr-4">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4z" id="yes_4z"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="yes_4z">
                                                    Yes
                                                </label>
                                            </div>

                                            <div className="float-none float-sm-left mr-4">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4z" id="no_4z" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="no_4z">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Days Operation</h4>
                                    </div>
                                </div>
                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Timezone</label>
                                    </div>
                                    <div className="col-12 col-sm-11">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                            <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                            <option value="-10:00">(GMT -10:00) Hawaii</option>
                                            <option value="-09:50">(GMT -9:30) Taiohae</option>
                                            <option value="-09:00">(GMT -9:00) Alaska</option>
                                            <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                            <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                            <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                            <option value="-05:00" selected="selected">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                            <option value="-04:50">(GMT -4:30) Caracas</option>
                                            <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                            <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                            <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                            <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                            <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                            <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                            <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                            <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                            <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                            <option value="+03:50">(GMT +3:30) Tehran</option>
                                            <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                            <option value="+04:50">(GMT +4:30) Kabul</option>
                                            <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                            <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                            <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                            <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                            <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                            <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                            <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                            <option value="+08:75">(GMT +8:45) Eucla</option>
                                            <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                            <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                            <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                            <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                            <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                            <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                            <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                            <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                            <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                            <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Monday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_1" name="customRadioInline1" className="form-control-input-checkbox"/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_1">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Tuesday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_2" name="customRadioInline1" className="form-control-input-checkbox"/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_2">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Wednesday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_3" name="customRadioInline1" className="form-control-input-checkbox"/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_3">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Thursday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_4" name="customRadioInline1" className="form-control-input-checkbox"/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_4">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Friday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_5" name="customRadioInline1" className="form-control-input-checkbox"/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_5">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Saturday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM" selected>5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_6" name="customRadioInline1" className="form-control-input-checkbox" checked/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_6">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="" className="form-label m-0">Saturday</label>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM" selected>9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM">9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mb-2">
                                        <select name="" id="" className="form-control px-3">
                                            <option value="1:00 AM">1:00 AM</option>
                                            <option value="2:00 AM">2:00 AM</option>
                                            <option value="3:00 AM">3:00 AM</option>
                                            <option value="4:00 AM">4:00 AM</option>
                                            <option value="5:00 AM">5:00 AM</option>
                                            <option value="8:00 AM">6:00 AM</option>
                                            <option value="7:00 AM">7:00 AM</option>
                                            <option value="8:00 AM">8:00 AM</option>
                                            <option value="9:00 AM">9:00 AM</option>
                                            <option value="10:00 AM">10:00 AM</option>
                                            <option value="11:00 AM">11:00 AM</option>
                                            <option value="12:00 AM">12:00 AM</option>
                                            <option value="1:00 PM">1:00 PM</option>
                                            <option value="2:00 PM">2:00 PM</option>
                                            <option value="3:00 PM">3:00 PM</option>
                                            <option value="4:00 PM">4:00 PM</option>
                                            <option value="5:00 PM">5:00 PM</option>
                                            <option value="8:00 PM">6:00 PM</option>
                                            <option value="7:00 PM">7:00 PM</option>
                                            <option value="8:00 PM">8:00 PM</option>
                                            <option value="9:00 PM" selected>9:00 PM</option>
                                            <option value="10:00 PM">10:00 PM</option>
                                            <option value="11:00 PM">11:00 PM</option>
                                            <option value="12:00 PM">12:00 PM</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center">
                                        <input type="checkbox" id="closed_7" name="customRadioInline1" className="form-control-input-checkbox" checked/>
                                        <label className="form-control-input-checkbox-label form-label m-0" htmlFor="closed_7">Closed</label>
                                    </div>

                                </div>

                                <div className="form-row mb-3 mb-md-2 mt-4">
                                    <div className="col-12 col-md-8 mt-3">
                                        <label htmlFor="" className="form-label">Visible In:</label> 
                                        <div className="w-100 input-group">
                                            <div className="w-100 mr-4 mb-2">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4c" id="urgentcare"/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="urgentcare">
                                                    urgentcare.com
                                                </label>
                                            </div>

                                            <div className="w-100 mr-4">
                                                <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault4c" id="antibodytesting" checked/>
                                                <label className="form-check-label form-control-input-radio-label form-label" htmlFor="antibodytesting">
                                                    antibodytesting.com
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>




                            {/* <!--Subscription--> */}
                            <div className="w-100 mb-3">
                                <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-3">
                                    Approve location update?
                                </h3>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <a href="javascript:void(0)" className="w-100 btn btn-solid btn-solid-orange mb-2 mb-md-0" data-toggle="modal" data-target="#submit-change">Submit Changes</a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href="report-view.html" className="w-100 btn btn-outline btn-outline-orange" data-toggle="modal" data-target="#approve-deny">Decline</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* <!--MAIN CONTENT END--> */}

        
        {/* <!-- MODAL --> */}
        <div className="modal fade p-5" id="approve-deny" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fas fa-times-circle fa-xs"></i>
                        </button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row">
                            <div className="col-12 text-center mb-4">
                                <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">Are you sure you want to <span className="txt-color-orange font-weight-bold">Decline</span> update?</h3>
                            </div>
                            <div className="col-12 mb-4">
                                <div className="row justify-content-center">
                                    <div className="col-md-4">
                                        <a href="report-view.html" className="w-100 btn btn-solid btn-solid-orange mb-2 mb-md-0">Yes</a>
                                    </div>
                                    <div className="col-md-4">
                                        <button className="w-100 btn btn-outline btn-outline-orange" data-dismiss="modal">No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </>
  )
}

export default EditListingsReportViewPage
