import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import { EMAIL_REGEX_PATTERN } from "../../../../../helpers/accountAPIServices";
import {
    createOrganizations,
    deleteOrganization,
    editOrganization,
    getOrganization
} from "../../../../../helpers/organizationAPIServices";
import { getAccessTokenFromSession } from "../../../../../helpers/sessionHelpers";
import LoadingOverlay from "../../../../Common/LoadingOverlay/LoadingOverlay";
import SelectLocationWidget from "../../SelectLocationWidget/SelectLocationWidget";


const CreateOrganizationPage = (props) => {
    const [organization, setOrganization] = useState({
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        phone: '',
        locations: [],
    });

    const [validationError, setValidationError] = useState({
        name: true,
        first_name: true,
        last_name: true,
        email: true,
        address: true,
        phone: true,
        locations: true,
    });

    const [redirectToOrganizationList, setRedirectToOrganizationList] = useState(false);

    useEffect(() => {
        async function loadOrganization() {
            setIsLoading(true);
            const getOrganizationResult = await getOrganization(props.code, getAccessTokenFromSession());
            if(getOrganizationResult.error) {
                console.log('There is an error editing the organization.');
                console.log(getOrganizationResult.error);

                setIsLoading(false);
                return;
            }
            setOrganization({
                name: getOrganizationResult.name,
                first_name: getOrganizationResult.first_name,
                last_name: getOrganizationResult.last_name,
                email: getOrganizationResult.email,
                phone: getOrganizationResult.phone,
                locations: getOrganizationResult.locations,
            });
            setIsLoading(false);
        }
        if(props.edit && props.code) {
            loadOrganization();
        }
    }, [props.code, props.edit])

    const [isLoading, setIsLoading] = useState(false);
    const [successfulCreation, setSuccessfulCreation] = useState(false);

    const validations = {
        name: (v) => v !== "",
        first_name: (v) => v !== "",
        last_name: (v) => v !== "",
        email: (v) => {
            if(v !== "") {
                return EMAIL_REGEX_PATTERN.test(v);
            }
            return true;
        },
        address: (v) => true,
        phone: (v) => true,
        locations: (v) => v.length !== 0,
    }

    const validate = (fieldName, fieldValue) => {
        return validations[fieldName](fieldValue);
    }

    const handleOrganizationDetailsChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        const newDetails = {
            ...organization
        };

        const newValidation = {
            ...validationError
        }

        newDetails[fieldName] = fieldValue;
        setOrganization(newDetails);

        newValidation[fieldName] = validate(fieldName, fieldValue);
        setValidationError(newValidation);
    }

    const handleRemoveLocationClick = (practice_id) => {
        const newSelectedLocations = organization.locations.filter((location) => location.practice_id !== practice_id);
        setOrganization({
            ...organization,
            locations: newSelectedLocations,
        });
    }

    const handleChangeSelectedLocations = (locations) => {
        let newLocations = [
            ...organization.locations
        ];

        for(let location of locations) {
            if(!newLocations.find((l) => l.practice_id === location.practice_id)) {
                newLocations = [...newLocations, location]
            }
        }

        setOrganization({
            ...organization,
            locations: newLocations
        });
    }

    const areFieldsValid = () => {
        const validations = {
            name: organization.name !== "",
            first_name: organization.first_name !== "",
            last_name: organization.last_name !== "",
            email: organization.email !== "" ? EMAIL_REGEX_PATTERN.test(organization.email) : true,
            locations: organization.locations.length > 0,
        }
        setValidationError(validations)
        return [
            validations.name,
            validations.first_name,
            validations.last_name,
            validations.email,
            validations.locations,
        ].every(t => t);
    }

    const prepareFields = () => {
        const fields = {
            ...organization,
        };

        fields.locations = organization.locations.map(l => l.practice_id);
        return fields;
    }

    const handleSaveClick = async () => {
        if(!areFieldsValid()) {
            return;
        }
        const fields = prepareFields();

        setIsLoading(true);
        console.log(fields);
        const createOrganizationResults = (props.edit && props.code) ? await editOrganization(props.code, fields, getAccessTokenFromSession()) :
            await createOrganizations(fields, getAccessTokenFromSession());
        if(createOrganizationResults.error) {
            console.log(`There is a problem ${props.edit ? 'editing' : 'creating'} organization.`);
            console.log(createOrganizationResults.error);
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
        setSuccessfulCreation(true);
        console.log(createOrganizationResults);
        setTimeout(() => {
            setRedirectToOrganizationList(true);
        }, 3000);
    }

    const handleOnDeleteClick = async () => {
        setIsLoading(true);
        const deleteResults = await deleteOrganization(props.code, getAccessTokenFromSession());
        if(deleteResults.error) {
            console.log('There is a problem on deleting the organization.');
        }

        setIsLoading(true);
        setRedirectToOrganizationList(true);

    }

    return (
        <div className="main-content">
            {
                redirectToOrganizationList && <Redirect to='/subscribers/organizations' />
            }
            {
                isLoading ? <LoadingOverlay /> :
                    <div className="container p-3 p-md-5">
                        {
                            successfulCreation &&
                            <div className="alert alert-success mb-5" role="alert">
                                <i className="fa fa-check-circle mr-2"></i> {props.edit ? 'Organization successfully edited.' :
                            'New organization successfully created.'}
                            </div>
                        }
                        <div className="row mb-4 p-0 mx-0">


                            <div className="col-6 px-0 mx-0">

                                <h2 className="h1 txt-color-dark_purple font-weight-bold">Create New Organization</h2>
                                <Link to='/subscribers/organizations'
                                      className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                    className="fas fa-arrow-left mr-2"></i> Back to All Organizations</Link>
                            </div>

                        </div>

                        <div className="row mt-5 mx-0 mb-5">
                            <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">

                                <div className="col-12 mx-0 px-0">
                                    <div className="form-row mb-4 mx-0 px-0">
                                        <div className="col-12 mx-0 px-0">
                                            <label htmlFor="" className="form-label">Organization Name</label>
                                            <input type="text" className="form-control" value={organization.name} name='name'
                                                   onChange={handleOrganizationDetailsChange}/>
                                            {
                                                !validationError.name && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                </small>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 mx-0 px-0">
                                    <div className="form-row mb-4 mx-0 px-0">
                                        <div className="col-12 col-md-6 ml-0 pl-0">
                                            <label htmlFor="" className="form-label">First Name</label>
                                            <input type="text" className="form-control" placeholder="" value={organization.first_name} name='first_name'
                                                   onChange={handleOrganizationDetailsChange}/>
                                            {
                                                !validationError.first_name && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                </small>
                                            }
                                        </div>

                                        <div className="col-12 col-md-6 ml-0 pl-0">
                                            <label htmlFor="" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" placeholder="" value={organization.last_name} name='last_name'
                                                   onChange={handleOrganizationDetailsChange}/>
                                            {
                                                !validationError.last_name && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                </small>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mx-0 px-0">
                                    <div className="form-row mb-4 mx-0 px-0">
                                        <div className="col-12 col-md-6 ml-0 pl-0">
                                            <label htmlFor="" className="form-label">Email</label>
                                            <input type="text" className="form-control" placeholder="" value={organization.email} name='email'
                                                   onChange={handleOrganizationDetailsChange}/>
                                            {
                                                !validationError.email && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Invalid Email Address
                                                </small>
                                            }
                                        </div>

                                        <div className="col-12 col-md-6 ml-0 pl-0">
                                            <label htmlFor="" className="form-label">Phone Number</label>
                                            <input type="text" className="form-control" placeholder="" value={organization.phone} name='phone'
                                                   onChange={handleOrganizationDetailsChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mx-0 px-0">
                                    <div className="form-row mb-4 mx-0 px-0">
                                        {/*<div className="col-12 ml-0 pl-0">*/}
                                        {/*    <label htmlFor="" className="form-label">Search Location</label>*/}
                                        {/*    <input type="text" className="form-control" placeholder="" />*/}
                                        {/*</div>*/}

                                        <button className="btn btn-outline btn-outline-purple " data-toggle="modal"
                                                data-target="#select_location">
                                            <i className="fas fa-plus mr-2"></i>
                                            Locations
                                        </button>

                                        {
                                            organization.locations.length < 1 && <small className="py-2 d-table txt-color-secondary">
                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                            </small>
                                        }




                                        {
                                            organization.locations.map(location => {
                                                return (
                                                    <div className="w-100 border-light_blue rounded-4 p-3 p-md-3 row p-0 m-0 mb-3" key={location.practice_id}>
                                                        <div className="w-100 mb-3 d-flex">
                                                            <span className="d-table  font-weight-bold">{location.name}</span>
                                                        </div>
                                                        <div className="col-10 col-md-10 pl-0">
                                                            <div className="w-100 mb-3 d-flex ">
                                    <span className="d-table txt-color-purple mr-3"><i
                                        className="fas fa-map-marker-alt"></i></span>
                                                                <span className="d-table font-weight-normal">
                                        {`${location.address}, ${location.city}, ${location.state} ${location.zip}`}
                                    </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 col-md-2  text-right">
                                                            <a href='void(0);' className="text-right"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleRemoveLocationClick(location.practice_id)
                                                                }}
                                                            ><i className="fa fa-times"></i></a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>


                                <div className="col-12 mx-0 px-0 pt-4 ">
                                    <div className="form-row mx-0 px-0">
                                        <div className="col-12 mx-0 px-0">
                                            <button className="btn btn-solid btn-solid-orange w-100 mb-2" onClick={handleSaveClick}>
                                                Save
                                            </button>
                                            <Link to='/subscribers/organizations' className="btn btn-outline btn-outline-orange w-100 ">
                                                Cancel
                                            </Link>
                                            {
                                                props.edit &&
                                                <button data-toggle="modal" onClick={handleOnDeleteClick} data-target="#delete_organization" className="btn btn-solid btn-solid-orange w-100 mb-2" style={{
                                                    margin: '10px 0px'
                                                }}>
                                                    Delete Organization
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <SelectLocationWidget onChangeSelectedLocations={handleChangeSelectedLocations} currentSelectedLocations={organization.locations}/>
        </div>
    )
}

export default CreateOrganizationPage;