import { createBrowserHistory } from "history";
import React from 'react';
import { Link } from "react-router-dom";
import { getUserDetails, login } from "../../helpers/authServices";
import PleaseWaitButton from "../Buttons/PleaseWaitButton/PleaseWaitButton";
import FooterSection from "../Common/FooterSection/FooterSection";
import LoginHeaderSection from "./LoginHeaderSection/LoginHeaderSection";

class LoginPage extends React.Component {
    state = {
        username: '',
        password: '',
        rememberMe: false,
        errors: [],
        isLoading: false,
    }

    handleFieldChange = (event) => {
        const field = event.target.name;

        const newState = {
            ...this.state,
        };

        newState[field] = event.target.value;

        this.setState({
            ...newState
        });
    }

    handleRememberMeChange = (event) => {
        this.setState({
            rememberMe: event.target.checked
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = this.validateFields();

        if (isFormValid) {
            // login here
            this.setLoading(true);
            const credentials = {
                username: this.state.username.toLowerCase(),
                password: this.state.password,
            };
            
            const loginResults = await login(credentials);

            if (loginResults.error) {
                this.setState({
                    errors: ["The username or password is incorrect."]
                });
                this.setLoading(false);
            } else {
                // set token state first
                this.props.onTokenChange(loginResults);

                // get user profile
                this.setLoading(true);
                const userProfileResults = await getUserDetails(loginResults.access, this.state.username.toLowerCase());

                if (userProfileResults.error) {
                    // error getting user profile
                    this.setLoading(false);
                } else {
                    // set user information to state
                    this.props.onUserChange(userProfileResults);
                }
            }

            return;
        }
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    validateFields = () => {
        const validations = [
            this.createValidation(this.state.username !== "", "Username cannot be blank."),
            this.createValidation(this.state.password !== "", "Password cannot be blank."),
        ];

        const errors = validations.filter((test) => !test.test).map((test) => test.error);

        this.setState({
            errors: [...errors]
        });

        return errors.length < 1;
    }

    createValidation = (test, errorMessage) => {
        return {
            test: test,
            error: errorMessage
        }
    }

    handleEnterSubmit = (e) => {
        if (e.key === "Enter") {
            this.handleSubmit()
        }
    }


    passwordVisibility = () =>{
        const {isPasswordShown} = this.state;
        this.setState({isPasswordShown : !isPasswordShown})
        };

    render() {
        const { isPasswordShown } = this.state;
        const history = createBrowserHistory();
        const CLAIM_PATTERN = /.*claim-success/;
        return (
            <>
                <LoginHeaderSection />

                <main className="p-0 container-fluid">
                    <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                        <div className="form-login-container p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                            <h4 className="border-bottom pb-4 txt-color-dark_purple">Login</h4>
                            {
                                CLAIM_PATTERN.test(history.location.pathname) && <p style={{
                                    color: 'darkviolet',
                                    textAlign: 'center',
                                }}>Login to claim the listing.</p>
                            }
                            <div className="form-errors">
                                {
                                    this.state.errors.map((error) => {
                                        return <p className="form-error">{error}</p>
                                    })
                                }
                            </div>

                            <div className="form-control-wrap mt-5" onKeyDown={this.handleEnterSubmit}>

                                <div className="form-row mb-4">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Email Address</label>
                                        <input type="text" className="form-control" name='username' value={this.state.username} onChange={this.handleFieldChange}/>
                                    </div>
                                </div>

                                <div className="form-row mb-4">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Password</label>
                                        <input type={(isPasswordShown) ? "text" : "password"}
                                            className="form-control"
                                            name='password'
                                            value={this.state.password}
                                            onChange={this.handleFieldChange}
                                            
                                        />
                                        <label htmlFor="show_pass" className="show_pass-label">
                                            <i className={isPasswordShown ?
                                                "fa fa-eye-slash password-icon"
                                            :
                                                "fa fa-eye password-icon"}
                                                onClick={this.passwordVisibility}>
                                            </i>
                                        </label>
                                    </div>
                                </div>
                                {/*TODO: remember me*/}
                                {/*<div className="form-row mb-4">*/}
                                {/*    <div className="col-12 align-items-center d-flex my-3">*/}
                                {/*        <input type="checkbox" className="form-checkbox mr-2" name="remember_me_ckbx" onChange={this.handleRememberMeChange}/>*/}
                                {/*        <label htmlFor="remember_me_ckbx" className="form-label m-0">Remember Me</label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="form-row mb-4">
                                    {/*TODO: forgot password feature*/}
                                    <div className="col-12" disabled>
                                        <a href="void(0);" className="btn btn-solid btn-solid-orange w-100" onClick={this.handleSubmit}>
                                            {
                                                this.state.isLoading ? <PleaseWaitButton loadingMessage="" /> : "Login"
                                            }
                                        </a>
                                        <Link to="/password/forgot"
                                            className="btn btn-outline btn-outline-purple w-100 mt-2">Forgot Password</Link>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="SignUpLink">
                        <div className="mt-5 pt-4">
                            <p className="d-block text-center mx-auto txt-color-dark_purple  ">Don't have an account? <a href="/register" className="txt-color-orange">Sign up here</a></p>
                        </div>
                    </div>
                    </div>
                    
                </main>
                <FooterSection />
            </>
        )
    }
}

export default LoginPage;