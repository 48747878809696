import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {checkStatus, parseJSON} from "./responseHandlers";


const newClinic = (clinic, token) => {
    const URL = SUPERADMIN_API_ROOT_URL +"request/";
    return fetch(URL, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(clinic),
    }).then(checkStatus)
        .then(parseJSON)
        .catch((response) => {
            return {
                error: "Error adding new record: " + response,
            }
        })
}

const editClinic = (clinic, token) => {
    const URL = SUPERADMIN_API_ROOT_URL +"request/";
    return fetch(URL, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(clinic),
    }).then(checkStatus)
        .then(parseJSON)
        .catch((response) => {
            return {
                error: "Error adding new record: " + response,
            }
        })
}

export {
    newClinic,
    editClinic
}