import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { parseResponse } from "./passwordManagementServices";
import { catchGenericError, checkStatus, parseJSON } from "./responseHandlers";

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;


const getProducts = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "products";
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getProduct = (code) => {
    const URL = SUPERADMIN_API_ROOT_URL + "products/" + code;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch(catchGenericError);
}

const addNewProduct = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + "products/admin/";
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const editProduct = (code, details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `products/admin/?code=${code}`;
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    getProducts,
    getProduct,
    addNewProduct,
    editProduct,
    URL_REGEX,
};
