import React from 'react';
import LoginHeaderSection from "../LoginPage/LoginHeaderSection/LoginHeaderSection";
import {Link, Redirect} from "react-router-dom";

const ChangePasswordSuccessPage = (props) => {
    return (
        <>
            <LoginHeaderSection />
            {
                !props.location.redirectedFromSuccess && <Redirect to='/' />
            }
            <main>
                <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                    <div
                        className="form-login-container p-0 border-light_blue border-xs-0 rounded-5 overflow-hidden mt-5">
                        <img src="/img/success.svg" alt="" className="w-100 img-fluid" />
                            <div className="p-3">
                                <div className="form-row">
                                    <div className="col-12">
                                        <h4 className="pb-2 txt-color-dark_purple text-center ">Password Changed</h4>
                                        <label htmlFor="" className="form-label text-center w-100">You have successfully
                                            change your
                                            password.</label>
                                    </div>
                                </div>

                                <div className="form-row mb-4">
                                    <div className="col-12 px-5">
                                        <Link to='/' className="btn btn-solid btn-solid-orange w-100">Login to
                                            your Account</Link>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ChangePasswordSuccessPage;