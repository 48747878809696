import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { parseResponse } from "./passwordManagementServices";
import { parseJSON } from "./responseHandlers";


const sendInquiry = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'emails/inquire/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

export {
    sendInquiry,
};
