import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { catchGenericError, parseJSON } from "./responseHandlers";

const MINIMUM_8_CHARACTERS_REGEX = /^.{10,}$/;
const ONE_UPPERCASE_REGEX = /[A-Z]/;
const ONE_LOWERCASE_REGEX = /[a-z]/;
const ONE_SPECIAL_CHARACTER_REGEX = /[$&+,:;=?@#|'<>.^*()%!-]/;
const ONE_NUMBER_REGEX = /[0-9]/;

const send_forgot_password_request = (email) => {
    const URL = SUPERADMIN_API_ROOT_URL + `account/password/reset/?email=${email}`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const change_password_from_request = (details, code) => {
    const URL = SUPERADMIN_API_ROOT_URL + `account/password/change/?code=${code}`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const parseResponse = (response) => {
    return response;
}

export {
    send_forgot_password_request,
    change_password_from_request,
    parseResponse,
    MINIMUM_8_CHARACTERS_REGEX,
    ONE_LOWERCASE_REGEX,
    ONE_NUMBER_REGEX,
    ONE_SPECIAL_CHARACTER_REGEX,
    ONE_UPPERCASE_REGEX,
};
