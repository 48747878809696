import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {parseResponse} from "./passwordManagementServices";
import {catchGenericError, parseJSON} from "./responseHandlers";

const getOrganizations = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/admin/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getOrganization = (code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/admin/` + code;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const createOrganizations = (fields, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/admin/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const editOrganization = (code, fields, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/admin/` + code + '/';
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const deleteOrganization = (code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/admin/` + code + '/';
    const options = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getListingOrganization = (token, practice_id) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/get/${practice_id}/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const addListingOrganization = (fields, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `organizations/add/practice/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    getOrganizations,
    getOrganization,
    editOrganization,
    createOrganizations,
    deleteOrganization,
    getListingOrganization,
    addListingOrganization,
}