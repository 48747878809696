import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {parseResponse} from "./passwordManagementServices";
import {catchGenericError, parseJSON} from "./responseHandlers";


const deleteLocation = (code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `account/locations/delete/` + code;
    console.log(URL);
    const options = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    deleteLocation,
}