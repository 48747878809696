import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {catchGenericError, parseJSON} from "./responseHandlers";
import {parseResponse} from "./passwordManagementServices";

const getAllCampaigns = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const newIndividualCampaign = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?type=INDIVIDUAL_PLAN`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const editIndividualCampaign = (code, details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?type=INDIVIDUAL_PLAN&code=${code}`;
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const newOrganizationCampaign = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?type=ORGANIZATION_PLAN`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const editOrganizationCampaign = (code, details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?type=ORGANIZATION_PLAN&code=${code}`;
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const newGeolocationCampaign = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?type=GEOLOCATION_PLAN`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getCampaignAsAdmin = (code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `campaigns/admin/?code=${code}`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

export {
    getAllCampaigns,
    newIndividualCampaign,
    newOrganizationCampaign,
    getCampaignAsAdmin,
    editIndividualCampaign,
    editOrganizationCampaign,
    newGeolocationCampaign,
}