/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import LoginHeaderSection from "../../../LoginPage/LoginHeaderSection/LoginHeaderSection";
import FooterSection from "../../../Common/FooterSection/FooterSection";
import {Link} from "react-router-dom";

const VerifiedEmailSentPage = ({onClear, email}) => {
    return (
        <>
            
            <LoginHeaderSection />
            <main>
                <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                    <div className="form-login-container p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                        <h4 className="border-bottom pb-4 txt-color-dark_purple">Verify to login</h4>

                        <div className="form-control-wrap mt-5">

                            <div className="form-row mb-3">
                                <div className="col-12">
                                    
                                    <p className="txt-color-secondary">Please check your email <strong><a
                                        href="email-verify-account.html"
                                        className="txt-link">{email}</a></strong>. <br /> We've sent a link to
                                        verify your login.</p>
                                </div>


                            </div>

                            <div className="form-row mb-4">
                                <div className="col-12">

                                    <Link to='/' className="btn btn-outline btn-outline-purple w-100 mt-2" onClick={onClear}><i
                                        className="fas fa-arrow-left mr-2" ></i>Back to Login</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <FooterSection />
        </>
    );
};

export default VerifiedEmailSentPage;