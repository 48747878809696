import dateFormat from 'dateformat';
import React from 'react';
import { Link } from "react-router-dom";
import { getAllRequestsForSuperadmin } from "../../../helpers/accountAPIServices";
// eslint-disable-next-line
import { getAllCampaigns, getCampaignAsAdmin } from "../../../helpers/campaignAPIServices";
import { getLatestUploadReport, handleCSVUpload } from '../../../helpers/csv_utils/csv_utils';
import { getAccessTokenFromSession, getUserProfileFromSession } from "../../../helpers/sessionHelpers";
import FooterSection from "../../Common/FooterSection/FooterSection";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";




// const CSV_UPLOAD_FILTERS = [
//     { label: "Record ID", value: "record_id", isSelected: true },
//     { label: "Practice Name", value: "practice_name", isSelected: true },
//     { label: "Address", value: "address", isSelected: true },
//     { label: "City", value: "city", isSelected: true },
//     { label: "State", value: "state", isSelected: true },
//     { label: "Zip", value: "zip", isSelected: true },
//     { label: "Latitude", value: "latitude", isSelected: true },
//     { label: "Longitude", value: "longitude", isSelected: true },
//     { label: "Phone", value: "phone", isSelected: true },
//     { label: "Classification", value: "classification", isSelected: true },
//     { label: "website", value: "website", isSelected: true },
//     { label: "Xray", value: "xray", isSelected: true },
//     { label: "Hrs of operation: All day", value: "hrs_of_operation_all_day", isSelected: true },
//     { label: "Hrs of operation: Days", value: "hrs_of_operation_days", isSelected: true },
//     { label: "Hrs of operation: Duration time", value: "hrs_of_operation_duration_time", isSelected: true },
//     { label: "Hrs of operation: Time open", value: "hrs_of_operation_time_open", isSelected: true },
//     { label: "Hrs of operation: Time closed", value: "hrs_of_operation_time_closed", isSelected: true },
//     { label: "Hrs of operation: Hrs Annually", value: "hrs_of_operation_hrs_annually", isSelected: true },
// ]

class Dashboard extends React.Component {
    fileInput = null;

    state = {
        requests: {
            // pending_edit: [],
            pending_new: [],
        },
        isLoading: false,
        error: "",
        campaigns: [],
        searchTerm: '',
        currentPage: 1,
        postPerPage: 10,
        isCsvUploaded: false,
        csvUploadError: false,
        csvFile: null,
        latestCSVReport: {},
        // csvUploadFiltersModalVisible: false,
        // selectedCsvUploadFilters: CSV_UPLOAD_FILTERS,
    }


    async componentDidMount() {
        this.setLoading(true);

        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });

        this.setLoading(true);

        const getCampaignsResults = await getAllCampaigns(getAccessTokenFromSession());
        if (getCampaignsResults?.error) {
            console.log('Error in fetching results: ' + getCampaignsResults?.error);
            this.setLoading(false);
            return;
        }
        this.setCampaigns(getCampaignsResults);
        this.setLoading(false);

        /* Get latest CSV Report */
        getLatestUploadReport(getAccessTokenFromSession())
            .then((response) => {
                const result = response?.result ?? {};
                result["uploaded_at"] = (result?.["uploaded_at"])
                    ? dateFormat(result?.["uploaded_at"], "mmm dd, yyyy - h:MM TT")
                    : null;
                this.setState({
                    latestCSVReport: result,
                })
            })
            .catch((error) => {
                console.log(`getLatestUploadReport(): ${error}`)
            })
    }

    setCurrentPage = (e) => {
        this.setState({
            currentPage: e.target.value,
        });
    };

    setPostPerPage = (e) => {
        this.setState({
            postPerPage: e.target.value,
        });
    };

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }
    setCampaigns = (campaigns) => {
        this.setState({
            campaigns: [...campaigns],
        })
    }

    handleSearchChange = (e) => {
        this.setState({
            searchTerm: e.target.value,
        })
    };

    onFileUploadChange = (e) => {
        var file = e.target.files[0];
        this.setState({ csvFile: file });
    };

    handleUploadSubmit = () => {
        const csvFile = this.state.csvFile;
        const userProfile = getUserProfileFromSession();

        let user = {
            userID: userProfile?.id,
            username: userProfile?.user?.username,
            email: userProfile?.user?.email,
        }

        this.setState({ isLoading: true });

        handleCSVUpload(csvFile, user, getAccessTokenFromSession())
            .then((response) => {
                this.setState({ isLoading: false });

                this.setState({
                    isCsvUploaded: true,
                    csvUploadError: false
                });

                if (this.fileInput) {
                    this.setState({ csvFile: null });
                    this.fileInput.value = null;
                }

                // Timer to dismiss alert message.
                setTimeout((e) => {
                    this.setState({
                        isCsvUploaded: false,
                        csvUploadError: false
                    })
                }, 60000);
            })
            .catch((error) => {
                this.setState({ isLoading: false });

                this.setState({
                    isCsvUploaded: false,
                    csvUploadError: true
                });

                // Timer to dismiss alert message.
                setTimeout((e) => {
                    this.setState({
                        isCsvUploaded: false,
                        csvUploadError: false
                    })
                }, 60000);
            })

    };

    handleEnterSubmit = (e) => {
        if (e.key === "Enter" && this.stat.csvFile) {
            this.handleUploadSubmit()
        }
    }

    // toggleAllCsvUploadFilter = () => {
    //     let filters = JSON.parse(JSON.stringify(this.state.selectedCsvUploadFilters)) ?? [];
    //     filters?.forEach((item) => {
    //         item.isSelected = !item.isSelected;
    //     });
    //     this.setState({ selectedCsvUploadFilters: filters });
    // }

    render() {
        // const allLocations = [...this.state.requests.pending_edit,
        //     ...this.state.requests.pending_new];

        const allLocations = [...this.state.requests.pending_new];

        const cards = allLocations.map((location) => {
            const claimedBy = location.claimed_by;
            const date = dateFormat(location.date_modified, "m/d/yy");
            return (
                <div className="w-100 border-light_blue rounded-4 p-4 row p-0 m-0 mb-3">
                    <div className="col-10 col-md-9">
                        <div className="w-100 mb-3 d-flex">
                            <span className="d-table  txt-color-purple mr-3"><i
                                className="fas fa-user"></i></span>
                            <span className="d-table  font-weight-bold">{`${claimedBy.first_name} ${claimedBy.last_name}`}</span>
                        </div>
                        <div className="w-100 mb-3 d-flex">
                            <span className="d-table  txt-color-purple mr-3"><i
                                className="fa fa-heartbeat" aria-hidden="true"></i></span>
                            <span className="d-table  font-weight-bold">{location.name}</span>
                        </div>
                        <div className="w-100 mb-3 d-flex">
                            <span className="d-table txt-color-purple mr-3"><i
                                className="fas fa-map-marker-alt"></i></span>
                            <span className="d-table font-weight-normal">
                                {`${location.address}, ${location.city}. ${location.state} ${location.zip}`}
                            </span>
                        </div>
                        <div className="w-100 mb-3 d-flex mt-3">
                            <Link to={{
                                pathname: `/requests/${location.id}`,
                                practice: {
                                    practice: location
                                }
                            }}>
                                <button className="btn btn-outline btn-outline-purple px-4">View Details <i
                                    className="fas fa-arrow-right ml-3"></i></button></Link>
                        </div>
                    </div>

                    <div className="col-2 col-md-3">
                        <p
                            className="d-none d-md-table btn-location-changes" disabled>{location.approval_status === "pending_edit" ? "Claim/Edit" : "New"}</p>
                        <p href=""
                            className="d-flex d-md-none btn-location-changes-icon"><i
                                className="far fa-compass"></i></p>
                    </div>

                    <div className="col-10 col-md-9">
                    </div>
                    <div className="col-2 col-md-3">
                        <span className="w-100 d-flex">{`${date}`}</span>
                    </div>
                </div>
            )
        });

        if (this.state.isLoading) return <LoadingOverlay />
        else {
            return (
                <>
                    <div className="main-content">
                        <div className="container p-3 p-md-5">
                            {
                                this.state.isCsvUploaded &&
                                <div className="alert alert-success rounded-40 mb-md-5 py-3 px-4 align-middle  d-flex" role="alert">
                                    <div className="cancel-alert">
                                        <div><i className="fa fa-check-circle mr-2"></i></div>
                                        <div>
                                            Successfully uploaded CSV file. You can check the status <a href="/notifications"> here</a>.
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.csvUploadError &&
                                <div className="alert alert-danger rounded-40 mb-md-5 py-3 px-4 align-middle  d-flex" role="alert">
                                    <div className="cancel-alert">
                                        <div><i className="fa fa-check-circle mr-2"></i></div>
                                        <div>
                                            There was an error processing your request.
                                        </div>
                                    </div>
                                </div>
                            }


                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-12 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold">Dashboard</h2>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-12 col-lg-7" style={ cards.length >= 2 ? {overflowY:"scroll", maxHeight:"650px"} : {}}>
                                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Pending
                                            Location Changes and Claims</h3>
                                        {cards.length > 0 ? cards : <p>There are no pending requests for now.</p>}
                                    </div>
                                </div>

                                {/* === Upload CSV === */}
                                <div className="col-12 col-lg-5 mt-5 mt-lg-0">
                                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Upload CSV File</h3>
                                        <p> Select the CSV file to upload. </p>
                                        <div className="form-group files" onKeyDown={this.handleEnterSubmit}>
                                            <label>
                                            </label>
                                            <input
                                                ref={(ref) => this.fileInput = ref}
                                                type="file"
                                                onChange={this.onFileUploadChange}
                                                name="upload_csv"
                                                id="upload_csv"
                                                accept=".csv"
                                                className="form-control" />
                                        </div>

                                        <div className="w-100 mb-3 d-flex mt-3">
                                            <button
                                                className="btn btn-outline btn-outline-purple px-4"
                                                onClick={this.handleUploadSubmit}
                                                disabled={!this.state.csvFile}>
                                                <i className="fas fa-file-upload mr-3" /> Upload
                                            </button>

                                            {/* <button
                                                // onClick={() => this.setState({ csvUploadFiltersModalVisible: true })}
                                                className="btn btn-outline btn-outline-orange px-4 w-100"
                                                data-toggle="modal"
                                                // data-target="#csv_upload_filter_modal"
                                                data-target="#add-filter"
                                            > Add Filters
                                            </button> */}

                                            {/* <button className="btn btn-outline btn-outline-orange px-4 w-100" data-toggle="modal" data-target="#add-filter">Add Filter</button> */}
                                        </div>
                                    </div>

                                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5 mt-4">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Reports</h3>
                                        {/* <p style={{ fontStyle: "italic" }}>Latest upload</p> */}
                                        <div className="w-100 d-block mb-4  p-3 mt-3 border-light_blue rounded-10">
                                            <small style={{ fontStyle: "italic" }}>Latest upload</small>
                                            <p className="mb-1 ">
                                                <i className="fas fa-file mr-1 txt-color-purple" />
                                                {` ${this.state.latestCSVReport?.csv_filename}`}
                                            </p>
                                            <small>
                                                <p>{`Uploaded: ${this.state.latestCSVReport?.uploaded_at}`}</p>
                                                <Link to={`/reports-view/${this.state.latestCSVReport?.id}`}> View Report</Link>
                                            </small>
                                            {/* <small><Link to={`/reports-view`}> View Report</Link></small> */}
                                        </div>
                                        <a href="/reports-list" className="btn btn-solid btn-outline-orange px-3">
                                            <i className="fas fa-eye mr-0 mr-sm-2"></i>
                                            <span className="d-none d-sm-block">
                                                View All
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/*TODO: Marketing campaign table*/}
                            {/* <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                        <div className="col-12">
                            <div className="d-table w-100 ">
                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Current
                                    Marketing Campaigns</h3>
                            </div>
                        </div>
    
                        <div className="col-12 mt-2">
                            <div className="w-100">
                                <table className="table table-custom display w-100 dt-responsive nowrap">
                                    <thead className="thead-light rounded-10">
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">NUCR Practice Name</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">City</th>
                                        <th scope="col">Zip</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">U15</td>
                                        <td>Amarillo Urgent Care</td>
                                        <td>1915 S Coulter St</td>
                                        <td>Amarillo</td>
                                        <td>79106</td>
                                        <td><a href="javascript:void(0)" className="text-decoration-none">Edit</a></td>
                                    </tr>
    
                                    </tbody>
                                </table>
                            </div>
    
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    <li className="page-item disabled">
                                        <a className="page-link" href="#" tabIndex="-1"><i
                                                className="fas fa-angle-double-left"></i></a>
                                        </li>
                                        <li className="page-item"><a className="page-link txt-color-purple" href="#">1</a>
                                        </li>
                                        <li className="page-item"><a className="page-link text-secondary" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link text-secondary" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link txt-color-purple" href="#"><i
                                                className="fas fa-angle-double-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>
    
    
                            </div>
                        </div> */}

                            {/* START OF MARKETING CAMPAIGNS TABLE */}
                            <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0 overflow-auto" style={this.state.campaigns.length >= 12 ? { maxHeight: "1450px" } : {}}>
                                <div className="col-12">
                                    <div className="d-table w-100 ">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Current
                                            Marketing Campaigns</h3>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="w-100">

                                        <div id="DataTables_Table_0_wrapper"
                                            className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="top">
                                                <div className="row mb-4">
                                                    <div className="col-12 col-md-6 show-entries">
                                                        {/*<div className="dataTables_length" id="DataTables_Table_0_length">*/}
                                                        {/*    <label>Show <select name="DataTables_Table_0_length"*/}
                                                        {/*                        aria-controls="DataTables_Table_0"*/}
                                                        {/*                        className="custom-select custom-select-sm form-control form-control-sm">*/}
                                                        {/*        <option value="10">10</option>*/}
                                                        {/*        <option value="25">25</option>*/}
                                                        {/*        <option value="50">50</option>*/}
                                                        {/*        <option value="50">59</option>*/}
                                                        {/*        <option value="100">100</option>*/}
                                                        {/*    </select> entries</label></div>*/}
                                                    </div>

                                                    <div className="col-12 col-md-6 search-input">
                                                        <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                            <label>
                                                                Search:
                                                                <input
                                                                    type="search"
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Search"
                                                                    aria-controls="DataTables_Table_0"
                                                                    onChange={this.handleSearchChange}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="bottom"></div>
                                            <div className="clear"></div>
                                        </div>

                                        <table className="table table-custom display dt-responsive nowrap w-100">
                                            <thead className="thead-light rounded-10">
                                                <tr>
                                                    <th></th>
                                                    <th>ID</th>
                                                    <th>Campaign Name</th>
                                                    <th>Campaign Type</th>
                                                    <th>URL</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.campaigns.filter((campaign) => {
                                                        const url = `https://www.urgentcare.com/campaigns/${campaign.campaign_url}`;
                                                        const ids = campaign.locations.map(location => {
                                                            return location.practice_id;
                                                        }).join(', ');
                                                        const locations = campaign.locations.map(location => {
                                                            return location.city;
                                                        }).join(', ');
                                                        if (this.state.searchTerm === "") {
                                                            return campaign
                                                        } else if (campaign.campaign_name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                                                            return campaign
                                                        } else if (url.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                                                            return campaign
                                                        } else if (ids.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                                                            return campaign
                                                        } else if (locations.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
                                                            return campaign
                                                        }
                                                        else {return campaign}

                                                    }).map((campaign) => {
                                                        const url = `https://www.urgentcare.com/campaigns/${campaign.campaign_url}`;
                                                        const ids = campaign.locations.map(location => {
                                                            return location.practice_id;
                                                        }).join(', ');
                                                        // eslint-disable-next-line no-unused-vars
                                                        const locations = campaign.locations.map(location => {
                                                            return location.city;
                                                        }).join(', ');
                                                        let campaignType = '';
                                                        switch (campaign.campaign.code) {
                                                            case 'INDIVIDUAL_PLAN':
                                                                campaignType = "Individual";
                                                                break;
                                                            case 'GEOLOCATION_PLAN':
                                                                campaignType = "Geolocation";
                                                                break;
                                                            case 'ORGANIZATION_PLAN':
                                                                campaignType = "Organization";
                                                                break;
                                                            default:
                                                        };
                                                        return (
                                                            <tr>
                                                                <td></td>
                                                                <td>{ids}</td>
                                                                <td>{campaign.campaign_name}</td>
                                                                <td>{campaignType}</td>
                                                                <td><a href={url} target='_blank'
                                                                    className="text-decoration-none d-table" rel='noreferrer'>{url}</a>
                                                                </td>

                                                                {
                                                                    campaign.campaign.code === "GEOLOCATION_PLAN" &&
                                                                    <td><Link to={`/campaigns/edit/geolocation/${campaign.code}`}
                                                                        className="text-decoration-none">Edit</Link></td>
                                                                }

                                                                {
                                                                    campaign.campaign.code === "INDIVIDUAL_PLAN" &&
                                                                    <td><Link to={`/campaigns/edit/individual/${campaign.code}`}
                                                                        className="text-decoration-none">Edit</Link></td>
                                                                }
                                                                {
                                                                    campaign.campaign.code === "ORGANIZATION_PLAN" &&
                                                                    <td><Link to={`/campaigns/edit/organization/${campaign.code}`}
                                                                        className="text-decoration-none">Edit</Link></td>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/*<nav aria-label="Page navigation example">*/}
                                    {/*    <ul className="pagination justify-content-end">*/}
                                    {/*        <li className="page-item disabled">*/}
                                    {/*            <a className="page-link" href="#" tabIndex="-1"><i*/}
                                    {/*                className="fas fa-angle-double-left"></i></a>*/}
                                    {/*        </li>*/}
                                    {/*        /!*<li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*!/*/}
                                    {/*        /!*<li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*!/*/}
                                    {/*        /!*<li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*!/*/}
                                    {/*        /!*<li className="page-item">*!/*/}
                                    {/*        /!*    <a className="page-link txt-color-purple" href="#"><i*!/*/}
                                    {/*        /!*        className="fas fa-angle-double-right"></i></a>*!/*/}
                                    {/*        /!*</li>*!/*/}
                                    {/*    </ul>*/}
                                    {/*</nav>*/}
                                </div>
                            </div>

                            {/* END OF MARKETING CAMPAIGNS TABLE */}


                        </div>
                    </div>

                    {/* Success modal */}
                    <div
                        className={`modal fade`}
                        id="csv_upload_filter_modal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        style={{"display": "none"}}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content overflow-hidden border-0">
                                <div className="modal-body p-0">
                                    {
                                        true &&
                                        <>
                                            <div className="w-100 p-5">
                                                <h4 className="txt-color-dark_purple text-center">Lorem ipsum</h4>
                                                <p className="txt-color-dark_purple  text-center my-4">
                                                    Lorem ipsum dolor amet
                                                </p>
                                                <button
                                                    className="btn btn-solid btn-solid-orange w-100 mt-4 d-table"
                                                    data-toggle="modal"
                                                    data-target="#csv_upload_filter_modal"
                                                    onClick={() => console.log("okay")}>
                                                    Okay
                                                </button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Modal Location 2 --> */}
            {/* <div className="modal fade p-1 p-sm-5" id="add-filter" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Filter Columns for CSV Upload</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4" >

                            <label className="form-label font-weight-bold" for="">Filter:</label>
                            <div className="row py-3 mx-0">
                                <div className="col-12 mx-0 px-0">
                                    <div className="form-row mx-0 px-4">
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-01" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-01">All</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mx-0 px-0">
                                    <div className="form-row mx-0 px-4">
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-02" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-02">Record ID</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-03" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-03">Practice Name</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-04" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-04">Address</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-05" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-05">City</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-06" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-06">State</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-07" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-07">Zip</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-08" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-08">Latitude</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-09" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-09">Longtitude</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-10" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-10">Phone</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-11" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-11">Classification</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-12" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-12">Website</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-13" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-13">Insurance</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-14" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-14">Email</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 mx-0 px-0">
                                    <div className="form-row mx-0 px-4">
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-15" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-15">X-ray</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-16" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-16">Hrs of Operation: All Day</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-17" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-17">Hrs of Operation: Days</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-18" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-18">Hrs of Operation: Duration Time</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-19" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-19">Hrs of Operation: Overnight</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-20" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-20">Hrs of Operation: Time Open</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-21" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-21">Hrs of Operation: Time Closed</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                            <div>
                                                <input type="checkbox" id="cbx-22" name="customRadioInline1" className="form-control-input-checkbox"/>
                                                <label className="form-control-input-checkbox-label form-label" for="cbx-22">Hrs of Operation: Hrs Annually</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="void(0)" onClick={(e) => e.preventDefault()} className="btn btn-solid btn-solid-orange w-100 ">
                                Apply Filter
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}

                    <FooterSection />
                </>
            )
        }

    }
}

export default Dashboard;