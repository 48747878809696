import React from 'react';
import { useEffect, useState, useCallback } from "react";
import {
    adjustRangeOffset,
    range
} from "../../utils/Utils";



/**
 * You can reuse this custom datatable by copying
 * all these components to any React component.
 */

function CustomDataTable({
    tableHeaders = [],
    itemParams = [],
    data,
    dataTotalCount = 0,
    currentPage = 1,
    numOfPages = 7,
    onChangeNumEntries = () => null,
    onChangePage = () => null,
    onChangeSearch = () => null,
    viewItemLink = "#",
    page_limit,
    limitUpdatedOver,
}) {
    return (
        <div className="row mt-3 border-light_blue rounded-4 p-3 p-md-5 mx-0">
            <div className="col-12 mt-2">
                <div className="w-100">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="top">
                            <div className="row mb-4">
                                <div className="col-12 col-md-6 show-entries">
                                    {/* <!-- SHOW NUMBER OF ENTRIE IN LIST --> */}
                                    <div
                                        className="dataTables_length"
                                        id="DataTables_Table_0_length">
                                        <label>
                                            Show <select
                                                name="DataTables_Table_0_length"
                                                aria-controls="DataTables_Table_0"
                                                className="custom-select custom-select-sm form-control form-control-sm"
                                                onChange={(e) => onChangeNumEntries(e?.target?.value)}
                                                value={page_limit}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select> entries
                                        </label>
                                    </div>
                                </div>

                                {/* <!-- SEARCH FROM LIST --> */}
                                <div className="col-12 col-md-6 search-input">
                                    <div
                                        id="DataTables_Table_0_filter"
                                        className="dataTables_filter">
                                        <label>Search:
                                            <input
                                                type="search"
                                                className="form-control form-control-sm"
                                                placeholder="Search"
                                                aria-controls="DataTables_Table_0"
                                                onChange={(e) => onChangeSearch(e?.target?.value)}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bottom" />
                        <div className="clear" />
                    </div>

                    {/* Table data starts here! */}
                    {
                        (dataTotalCount > 0) &&
                        <div style={{ ...limitUpdatedOver ? { overflowY: "scroll", height: "500px" } : {} }}>
                            <table className="table table-custom display dt-responsive nowrap w-100">
                                <thead className="thead-light rounded-10">
                                    <tr>
                                        {tableHeaders.map((e) => <th>{`${e}`}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableData
                                        data={data}
                                        itemParams={itemParams}
                                        viewItemLink={viewItemLink}
                                    />
                                </tbody>
                            </table>
                        </div>
                    }
                </div>

                {/* <!-- Pagination control --> */}
                <Paginator
                    currentPage={Number(currentPage)}
                    pageMax={Number(numOfPages)}
                    paginateToPageAction={(val) => onChangePage(val)}
                />

            </div>
        </div>
    );
}


function TableData({
    data = [],
    itemParams = [],
    viewItemLink = "#",
}) {
    /**
     * Each item in data should have an 'id' attribute.
     */
    var layout = [];

    for (let i in data) {
        let item = data[i];

        let tableData = [];
        for (var k in itemParams) {
            let _key = itemParams[k];
            if (_key === "_action_view") {
                tableData.push(
                    <td key={`(${k})-TableData-${_key}`}>
                        <a
                            href={`${viewItemLink}/${item?.id}`}
                            // target="_blank"
                            className="text-decoration-none d-flex txt-color-orange align-items-center"
                        >
                            <i className="fas fa-eye mr-0 mr-sm-2"></i>
                            <span className="d-none d-sm-block small">
                                View
                            </span>
                        </a>
                    </td>
                );
            }
            else {
                tableData.push(<td key={`(${k})-TableData-${_key}`}>{item?.[_key]}</td>);
            }
        }

        layout.push(
            <tr key={`TableRow-${i}`}>
                {tableData}
            </tr>
        );
    }

    return layout;
}


function Paginator({
    currentPage = 1,
    pageMax = 0,
    limit = 7,
    paginateToPageAction = function () { },
}) {
    /**
     * Parameters:
     * number: pageMax = the total / max number of pages. (Default: 0)
     * number: limit = the number of pages per set. (Default: 7)
     * function: paginateToPageAction = function to move to another page.
     */

    const initPageArray = useCallback(() => {
        // Returns an array of numbers.
        let r;
        if (Number(pageMax) < Number(limit)) {
            // If (N = total number of pages) is less than (L = limit per set),
            // create range and use (N) as the last page for the first set.
            r = range(pageMax, 1);
        }
        else {
            // Else, create range and use L as the last page for the first set.
            r = range(limit, 1);
        }
        return r;
    }, [pageMax, limit])

    const [currentPageArray, setCurrentPageArray] = useState(initPageArray());
    const [, setPrevMin] = useState(1);
    const [prevMax, setPrevMax] = useState(limit);

    /* TODO: Figure out where to use these */
    // eslint-disable-next-line
    const [numOfPages, setNumOfPages] = useState(pageMax);
    // eslint-disable-next-line
    const [limitPerSet, setLimitPerSet] = useState(limit);

    /**
     * If last known minimum page changes,
     * retain the last page array even if no items available for render.
     */
    // useEffect(() => {
    //     if (prevMin > 1) {
    //         let r = range(limit, prevMin);
    //         setCurrentPageArray(r);
    //     }
    //     else {
    //         setCurrentPageArray(initPageArray());
    //         setPrevMin(1);
    //     }
    // }, [pageMax])

    /**
     * Resets the page array every time the total number of pages changes.
     */
    useEffect(() => {
        setCurrentPageArray(initPageArray());
        setPrevMin(1);
    }, [pageMax, initPageArray]);

    const paginateLeftAction = () => {
        let { newRange, min, max } = adjustRangeOffset(currentPageArray, -(Number(limit)));
        setCurrentPageArray(newRange);
        setPrevMin(min);
        setPrevMax(max);
    };

    const paginateRightAction = () => {
        let { newRange, min, max } = adjustRangeOffset(currentPageArray, limit);

        if (Number(pageMax) < Number(max)) {
            if (Number(prevMax) >= Number(pageMax)) return;

            let finalRange = [...newRange];
            let finalMax = Number(finalRange.pop());
            let finalMin = Number(prevMax) + 1;

            setCurrentPageArray(newRange);
            setPrevMin(finalMin);
            setPrevMax(finalMax);
        }
        else {
            setCurrentPageArray(newRange);
            setPrevMin(min);
            setPrevMax(max);
        }
    };

    const PaginatorItems = () => {
        let items = [];
        for (let i in currentPageArray) {
            let num = Number(currentPageArray[i]);
            let showPage = (Number(pageMax) >= num);
            let _currentPage_ = Number(currentPage);
            let isActive = (_currentPage_ === num);

            items.push(
                <li key={`PaginatorItems-${i}`} className={`page-item text-center ${(isActive) ? 'active' : ''}`} style={{ width: "50px" }}>
                    {
                        showPage &&
                        <button
                            className="page-link txt-color-purple"
                            onClick={() => (!isActive) ? paginateToPageAction(num) : false}>
                            {num}
                        </button>
                    }
                </li>
            );
        }

        return items;
    };

    const PaginatorContainer = () => {
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className="page-item text-center">
                        <button className="page-link txt-color-purple" onClick={() => paginateLeftAction()}><i className="fa fa-angle-double-left" /></button>
                    </li>
                    <PaginatorItems />
                    <li className="page-item text-center">
                        <button className="page-link txt-color-purple" onClick={() => paginateRightAction()}><i className="fa fa-angle-double-right" /></button>
                    </li>
                </ul>
            </nav>
        );
    };

    return <PaginatorContainer />;
}


export default CustomDataTable;
