const DAYS_OF_THE_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
]

const convert24HourFormatStringTo12HourFormatString = (time24HourFormatString) => {
    const [hour, minutes,] = time24HourFormatString.split(':');
    const ante = hour < 12 ? "AM" : "PM";
    const new_hour = hour % 12;

    return `${new_hour === 0 ? "12" : new_hour}:${minutes} ${ante}`;
}

const generateTimeObjects = (interval_minutes) => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
        hours.push(i);
    }

    const minutes = [];
    for (let i = 0; i < 60; i += interval_minutes) {
        minutes.push(i);
    }

    const time24hours = [];
    for (let hour of hours) {
        for (let minute of minutes) {
            time24hours.push(`${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}:00`);
        }
    }

    const option_objects = time24hours.map((time) => {
        return {
            value: time,
            display: convert24HourFormatStringTo12HourFormatString(time)
        };
    });

    option_objects.push({
        value: "23:59:00",
        display: convert24HourFormatStringTo12HourFormatString("23:59:00")
    });

    return option_objects;
}

const convertTimeStringToDecimalValue = (time24HourFormatString) => {
    const [hour, minutes, seconds] = time24HourFormatString.split(':');
    return parseFloat(hour) + parseFloat(minutes / 60) + parseFloat(seconds / 3600);
}

const compareSchedules = (original_schedules, schedules) => {
    
    // function sort_days(days) {
    //     var list = ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    //     var sorted_list = list.slice().concat(list.slice(0,));
    //     return days.sort(function(a,b) { return sorted_list.indexOf(a) > sorted_list.indexOf(b); });
    // }

    // for (let i in original_schedules) {
    //     let original = original_schedules[i];
    //     console.log("schedule of index " + i)
    //     console.log(original);
    // }

    if (original_schedules !== undefined && schedules !== undefined) {

        if (original_schedules?.length !== schedules?.length) {
            return false;
        }
        else if (original_schedules?.length === schedules?.length) {
            for (let i in schedules) {
                let opening = schedules[i].opening;
                let closing = schedules[i].closing;
                let day = schedules[i].occurrence.name;
                let original_opening = original_schedules[i].opening;
                let original_closing = original_schedules[i].closing;
                let original_day = original_schedules[i].occurrence.name;

                if (opening !== original_opening) {
                    // console.log('opening did not match');
                    return false;
                }
                else if (closing !== original_closing) {
                    // console.log('Closing did not match');
                    return false;
                }
                else if (day !== original_day) {
                    // console.log('day did not match');
                    return false;
                }
            }
        }
        return true;
    }

    //         let days = [];
    //         let openings = [];
    //         let closings = [];
    //         for (let i = 0; i < original_schedules.length; i++) {
    //             let opening = original_schedules[i].opening;
    //             let closing = original_schedules[i].closing;
    //             let day = original_schedules[i].occurrence.name;
    //             days.push(day);
    //             openings.push(opening);
    //             closings.push(closing);
    //         }
    //         let newDays = [];
    //         let newOpenings = [];
    //         let newClosings = [];
    //         for (let i = (schedules.length - 1); i >= 0; i--) {
    //             let opening = schedules[i].opening;
    //             let closing = schedules[i].closing;
    //             let day = schedules[i].occurrence.name;
    //             newDays.push(day);
    //             newOpenings.push(opening);
    //             newClosings.push(closing);
    //         }

    //     console.log(sort_days(days));
    //     console.log(sort_days(newDays));

    //     let sorted_days = sort_days(days);
    //     let sorted_newDays = sort_days(newDays);

    //         let isFalse = [];
    //         let isTrue = [];

    //         for (let index = 0; index < days.length; index++) {
    //             if (sorted_days[index] !== sorted_newDays[index] || openings[index] !== newOpenings[index] || closings !== newClosings[index]) {
    //                 isFalse.push(false);
    //             }
    //             else {
    //                 isTrue.push(true)
    //             }
    //     }
    //     console.log(isFalse.length);
    //     console.log(isTrue);
    //     if (isFalse.length !== 0) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }


        //  for (let i in schedules) {
        //     let opening = schedules[i].opening;
        //     let closing = schedules[i].closing;
        //     let day = schedules[i].occurrence.name;
        //     let original_opening = original_schedules[i].opening;
        //     let original_closing = original_schedules[i].closing;
        //     let original_day = original_schedules[i].occurrence.name;

        //     if(opening !== original_opening){
        //         return false;
        //     }
        //     else if (closing !== original_closing) {
        //         return false;
        //     }
        //     else if (day !== original_day) {
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }
    // }

    // else {
    //     return false;
    // }

    // for (let i in schedules) {
    //     let opening = schedules[i].opening;
    //     let closing = schedules[i].closing;
    //     let day = schedules[i].occurrence.name;
    //     // let original_length = original_schedules?.length;
    //     let length = original_schedules?.length - i;
    //     let original_opening = original_schedules[i].opening;
    //     let original_closing = original_schedules[i].closing;
    //     let original_day = original_schedules[i].occurrence.name;

    //     if(opening !== original_opening){
    //         return false;
    //     }
    //     else if (closing !== original_closing) {
    //         return false;
    //     }
    //     else if (day !== original_day) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // }

}
export {
    generateTimeObjects,
    DAYS_OF_THE_WEEK,
    convertTimeStringToDecimalValue,
    convert24HourFormatStringTo12HourFormatString,
    compareSchedules,

};

