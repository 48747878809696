import React, { Component } from 'react';
import {getPracticeInformation} from "../../../../helpers/practiceHelpers";
import {getAccessTokenFromSession, getRefreshTokenFromSession} from "../../../../helpers/sessionHelpers";
import {Redirect} from "react-router";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import {convert24HourFormatStringTo12HourFormatString, DAYS_OF_THE_WEEK} from "../../../../helpers/scheduleUtilties";
import {Link} from "react-router-dom";
import { getTierSubscription, subscribe, editStripeUrl } from "../../../../helpers/tierAPIServices";
import {getListingOrganization} from "../../../../helpers/organizationAPIServices"

export default class LocationViewPage extends Component {
    state = {
        practice_id: this.props.match.params.practice_id,
        redirectTo404: false,
        isLoading: true,
        practice: {
            services: "",
            insurance: "",
            partners: [],
            covid19TestServices: [],
            antibodyTestServices: [],
            schedules: [],
        },
        status: {
            token: null,
        },
        tiers: [],
        tier: 'BASIC',
        oldTier: 'BASIC',
        subscription: [],
        stripe_url: '',
        organization: 'No Organization',
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleCancelClick = async () => {
        this.setLoading(true);
            const updateTier = await subscribe(this.state.practice.practice_id, 'BASIC', getAccessTokenFromSession());
            console.log(updateTier);
            const updateUrl = await editStripeUrl(this.state.practice.practice_id, 'BASIC', this.state.stripe_url, getAccessTokenFromSession())
            console.log(updateUrl);
        this.setLoading(false)
    }

    async componentDidMount() {
        this.setLoading(true);
        const getPracticeResults = await getPracticeInformation(this.state.practice_id);

        console.log(getPracticeResults);

        if(getPracticeResults.error) {
            this.setState({
                redirectTo404: true
            });
            console.log('ERRORRRRRRRRRRRRRRRRRR');
            this.setLoading(false);
            return;
        }

        this.setState({
            practice: getPracticeResults,
        }, () => this.setLoading(false));

        // let tierOptions = await getTiers(getAccessTokenFromSession());
        // if(tierOptions.error) {
        //     console.log('Error fetching tiers');
        //     tierOptions = [];
        // }
        // console.log(tierOptions);
        this.setLoading(true);

        let listingOrganization = await getListingOrganization(getAccessTokenFromSession(), this.state.practice_id);

        if (listingOrganization.error) {
            console.log(listingOrganization.error);
            this.setLoading(false);
            return;
        }

        console.log(listingOrganization)

        this.setState({
            organization: listingOrganization.name,
        })


        let tierSubscription = await getTierSubscription(this.state.practice_id, getAccessTokenFromSession());
        if(tierSubscription.error) {
            console.log('Error fetching subscription');
            tierSubscription = [];
            this.setLoading(false);
            return;
        }
        if (tierSubscription.stripe_billing_url == null) {
            console.log("Stripe URL not set");
        }
        console.log(tierSubscription);
        console.log(tierSubscription.stripe_billing_url);
        if (tierSubscription.length !== 0) {
            this.setState({
                status: {
                    ...this.state.status,
                    token: getRefreshTokenFromSession(),
                },
                // tiers: tierOptions,
                tier: tierSubscription.tier ? tierSubscription.tier.code : this.state.tier,
                oldTier: tierSubscription.tier ? tierSubscription.tier.code : this.state.tier,
                subscription: tierSubscription.tier,
                stripe_url: tierSubscription.stripe_billing_url,
            }, () => console.log(this.state.status.token));
        }

        this.setLoading(false);

       

        // console.log(this.state.subscription);
    }

    render() {
        const location = this.state.practice;
        let services = location.services !== "" ? location.services.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];
        let insurance = location.insurance !== "" ? location.insurance.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];

        // const subscription = this.state.tiers.map((tier) => {

        //     return (<div className="form-row border-light_blue p-4 rounded-10">
        //         <div className="col-12 col-sm-8">
        //             <span className="txt-color-dark_purple font-weight-bold h5">{`${tier.name} `}</span><br />
        //             <span className="txt-color-dark_purple font-weight-bold h5">{`($${tier.price})`}/ month</span>
        //         </div>
        //         <div className="col-12 col-sm-4">
        //             <button className="btn-outline-orange-sm rounded-20 px-2 py-1 float-right">Cancel Subscription</button>
        //         </div>
        //     </div>)
            
        // });

        

        

        console.log(this.state.practice);
        return (

            <div className="main-content">
                {
                    this.state.isLoading ? <LoadingOverlay /> :
                        <div className="container p-3 p-md-5">
                            {
                                this.state.redirectTo404 && <Redirect to='/404'/>
                            }
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-6 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Location</h2>
                                    <a href="/listings" className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                        className="fas fa-arrow-left mr-2"></i> Back to Location Listings</a>
                                </div>

                            </div>

                            <div className="row mt-5 mx-0">
                                <div className="col-12 col-lg-8  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                    <div className="d-table w-100 mb-4">
                                        <div className="form-row border-bottom mx-0 px-0 pb-4">
                                            <div className="col-10">
                                                <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">{this.state.practice.name}</h3>
                                                <div className="w-100">
                                                    <span className="form-label"> Record ID</span>
                                                    <span className="form-label"> #{this.state.practice.practice_id}</span>
                                                </div>
                                                {/*<div className="w-100">*/}
                                                {/*    <span className="form-label">Claimed by:</span>*/}
                                                {/*    <span className="form-label">Ralph Edwards</span>*/}
                                                {/*</div>*/}
                                            </div>

                                            <div className="col-2 justify-content-end">
                                                {/* <!-- <button className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right"><i className="fas fa-pen mr-1"></i>
                                            <span className="d-none d-sm-inline">Edit</span>
                                        </button> --> */}

                                                <Link to={`/listings/edit/${this.state.practice_id}`}
                                                   className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none">
                                                    <i className="fas fa-pen mr-1"></i>
                                                    <span className="d-none d-sm-inline">Edit</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--BASIC INFO--> */}
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Basic Info</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">NUCR
                                                    Practice Name</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.name}</label>
                                            </div>
                                        </div>

                                        {/*TODO: classification*/}
                                        {/*<div className="form-row mb-3 mb-md-2">*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor=""*/}
                                        {/*               className="form-label m-0 font-weight-bold">Classification</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0">Traditional UC</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">Email
                                                    Address</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0">{this.state.practice.email === "" ? "(Not available)" : this.state.practice.email}</label>
                                            </div>
                                        </div>

                                        {/*TODO: internal*/}
                                        {/*<div className="form-row mb-3 mb-md-2">*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0 font-weight-bold">Email*/}
                                        {/*            Address (Internal)</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor=""*/}
                                        {/*               className="form-label m-0">internal@amarillourgentcare.com</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">Phone</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.phone === "" ? "(Not available)" : this.state.practice.phone}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">Website</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0">{this.state.practice.website === "" ? "(Not available)" : this.state.practice.website}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Location Address--> */}
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Location Address</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">Street
                                                    Address</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.address === "" ? "(Not available)" : this.state.practice.address}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">City</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.city === "" ? "(Not available)" : this.state.practice.city}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">State</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.state === "" ? "(Not available)" : this.state.practice.state}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">ZIP
                                                    Code</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.zip === "" ? "(Not available)" : this.state.practice.zip}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">Latitude</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.latitude}</label>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor=""
                                                       className="form-label m-0 font-weight-bold">Longitude</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.longitude}</label>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <!--Business Info--> */}
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Business Info</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-3">
                                            {
                                                services.length > 0 &&
                                                <div className="col-12 col-sm-6">
                                                    <h4 className="form-label mb-3">Services</h4>
                                                    <ul className="basic-list">
                                                        {
                                                            services.map((s) => {
                                                                return (
                                                                    <li className="form-label">{s}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                            {
                                                insurance.length > 0 &&
                                                <div className="col-12 col-sm-6">
                                                    <h4 className="form-label mb-3">Insurance</h4>
                                                    <ul className="basic-list">
                                                        {
                                                            insurance.map((i) => {
                                                                return (
                                                                    <li className="form-label">{i}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }



                                            {
                                                location.about !== "" &&
                                                <div className="col-12 mt-4">
                                                    <h4 className="form-label mb-3">About</h4>
                                                    <p className="txt-color-secondary">
                                                        {this.state.practice.about}
                                                    </p>
                                                </div>
                                            }

                                            <div className="col-12 mt-4">
                                                    <h4 className="form-label mb-3">Partners</h4>
                                                    <ul className="basic-list">
                                                        {
                                                            this.state.practice.partners.map((p) => {
                                                                return <li className="form-label"><a href={p.partner.site}>{p.partner.name}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                            </div>

                                        </div>

                                        {
                                            this.state.practice.covid19TestServices.length > 0 &&
                                            <div className="form-row mb-3 mb-md-2">
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0 font-weight-bold">
                                                        COVID-19 Tests</label>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0">{this.state.practice.covid19TestServices.map((t) => {
                                                        return t.test.name;
                                                    }).join(', ')}</label>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.practice.antibodyTestServices.length > 0 &&
                                            <div className="form-row mb-3 mb-md-2">
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0 font-weight-bold">
                                                        Antibody Tests</label>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0">{this.state.practice.antibodyTestServices.map((t) => {
                                                        return t.test.name;
                                                    }).join(', ')}</label>
                                                </div>
                                            </div>
                                        }

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">Offers
                                                    X-ray?</label>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0">{this.state.practice.xray ? "Yes" : "No"}</label>
                                            </div>
                                        </div>

                                        {/*<div className="form-row mb-3 mb-md-2">*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0 font-weight-bold">Operates*/}
                                        {/*            24 hours?</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0">No</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<div className="form-row mb-3 mb-md-2">*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0 font-weight-bold">Operates*/}
                                        {/*            overnight?</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-12 col-sm-6">*/}
                                        {/*        <label htmlFor="" className="form-label m-0">No</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                    </div>
                                    {/* <!--Organization--> */}
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Organization</h4>
                                            </div>
                                            {/* <div className="col-12 col-sm-6">
                                                <label for="" className="form-label m-0 font-weight-bold">Geo Location Plan</label>
                                            </div> */}
                                            <div className="col-12 col-sm-6">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold">{this.state.organization}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!--Days of Operation--> */}
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Days of Operation</h4>
                                            </div>
                                        </div>

                                        {/*{*/}
                                        {/*    this.state.practice.schedules.map((s) => {*/}
                                        {/*        return (*/}
                                        {/*            <div className="form-row mb-3 mb-md-2">*/}
                                        {/*                <div className="col-12 col-sm-6">*/}
                                        {/*                    <label htmlFor=""*/}
                                        {/*                           className="form-label m-0 font-weight-bold">{s.occurrence.name}</label>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="col-12 col-sm-6">*/}
                                        {/*                    <label htmlFor="" className="form-label m-0">{*/}
                                        {/*                        `${convert24HourFormatStringTo12HourFormatString(s.opening)} - ${convert24HourFormatStringTo12HourFormatString(s.closing)}`*/}
                                        {/*                    }</label>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        )*/}
                                        {/*    })*/}
                                        {/*}*/}

                                        {
                                            DAYS_OF_THE_WEEK.map((day) => {
                                                const practice_schedule = this.state.practice.schedules.find((s) => s.occurrence.name === day);

                                                if(!practice_schedule) {
                                                    return (
                                                        <div className="form-row mb-3 mb-md-2">
                                                            <div className="col-12 col-sm-6">
                                                                <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <label htmlFor="" className="form-label m-0">(Closed)</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                console.log(practice_schedule);
                                                return (
                                                    <div className="form-row mb-3 mb-md-2">
                                                        <div className="col-12 col-sm-6">
                                                            <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <label htmlFor="" className="form-label m-0">
                                                                {`${convert24HourFormatStringTo12HourFormatString(practice_schedule.opening)} - ${convert24HourFormatStringTo12HourFormatString(practice_schedule.closing)}`}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/*TODO: subscription*/}
                                    {/*/!* <!--Subscription--> *!/*/}
                                    {/*<div className="w-100">*/}
                                    {/*    <div className="form-row mb-2">*/}
                                    {/*        <div className="col-12">*/}
                                    {/*            <h4 className="form-head-label txt-color-orange">Subscription</h4>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}

                                    {/*    <div className="form-row border-light_blue p-4 rounded-10">*/}
                                    {/*        <div className="col-12 col-sm-8">*/}
                                    {/*            <span*/}
                                    {/*                className="txt-color-dark_purple font-weight-bold h5">Tier 1</span><br></br>*/}
                                    {/*            <span className="txt-color-dark_purple font-weight-bold h5">$50.00 / month</span>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-12 col-sm-4">*/}
                                    {/*            <button*/}
                                    {/*                className="btn-outline-orange-sm rounded-20 px-2 py-1 float-right">Cancel*/}
                                    {/*                Subscription*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12">
                                                <label htmlFor="" className="form-label m-0 font-weight-bold txt-color-orange">Stripe Billing URL</label><br />
                                                {this.state.stripe_url ?
                                                    <div>
                                        <a href={this.state.stripe_url} className="bg-light border d-table p-2 rounded-5 w-100 mt-2">{this.state.stripe_url}</a></div>
                                        :
                                        <div className="bg-light border d-table p-2 rounded-5 w-100 mt-2 col-12">
                                        <span className="txt-color-dark_purple font-weight-bold h5"> No stripe url yet </span>
                                        </div>

                                        }
                                    </div>
                                </div>
                            </div>


                                    <div className="w-100">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Subscription</h4>
                                    </div>
                                </div>

                                <div>
                                    { this.state.subscription.name ? 
                                    <div className="form-row border-light_blue p-4 rounded-10">
                                    <div className="col-12 col-sm-8">
                                                <span className="txt-color-dark_purple font-weight-bold h5">{this.state.subscription.name}</span><br/>
                                                <span className="txt-color-dark_purple font-weight-bold h5">${this.state.subscription.price}/ month</span> 
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <button className="btn-outline-orange-sm rounded-20 px-2 py-1 float-right" onClick={this.handleCancelClick}>Cancel Subscription</button>
                                    </div>
                                    </div>:
                                    <div className="form-row border-light_blue p-4 rounded-10">
                                                <div className="col-12 col-sm-8">
                                                <span className="txt-color-dark_purple font-weight-bold h5"> No subscription yet </span>
                                                </div>
                                                </div>
                                        }
                                </div>

                                        {/* {subscription} */}
                            </div>

                                </div>

                            </div>
                        </div>

                }
            </div>


        )
    }
}
