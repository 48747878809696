import React from 'react';
import { Link } from "react-router-dom";
import { getProducts } from "../../../helpers/productsAPIServices";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import FooterSection from '../../Common/FooterSection/FooterSection';
import HeaderSection from '../../Common/HeaderSection/HeaderSection';
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import { getAllRequestsForSuperadmin } from '../../../helpers/accountAPIServices';
class ProductsPage extends React.Component {
    state = {
        isLoading: false,
        products: [],
    }

    componentDidMount = async () => {
        this.setLoading(true);

        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });

        const getProductsResults = await getProducts(getAccessTokenFromSession());

        if(getProductsResults.error) {
            console.log("There is an error fetching the products.");

            this.setState({
                products: []
            });

            return;
        }

        this.setState({
            products: getProductsResults
        });
        this.setLoading(false);
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }


    render() {
        return (

            <>
                <HeaderSection></HeaderSection>
                {
                    this.state.isLoading ? <LoadingOverlay /> :
                        <main>
                            <div className="main-content">
                                <div className="container container-lg p-3 py-md-5 px-md-0">
                                    <div className="row mb-4 p-0 mx-0">
                                        <div className="col-6n px-0 mx-0">
                                            <h2 className="h1 txt-color-dark_purple font-weight-bold">Products</h2>
                                        </div>
                                        <div
                                            className="col-6 px-0 mx-0 d-flex justify-content-start align-items-center">
                                            {/* <a href="tiers_add_new.html" className="btn btn-solid btn-solid-orange px-3">
                                <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                <span className="d-none d-sm-block">
                                    New Tier
                                </span>
                            </a> */}

                                            <Link to='/products/add'
                                                  className="btn btn-outline btn-outline-orange px-3 ml-2">
                                                <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                                <span className="d-none d-sm-block">
                                    Add Product
                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-12 col-lg-7 mb-4">
                                            <div className="d-table w-100 border-light_blue rounded-4 p-3 p-xl-5">
                                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Products</h3>

                                                {
                                                    this.state.products.map((product) => {
                                                        return <div className="w-100 border-light_blue rounded-4 p-3 row p-0 m-0 mb-3">
                                                            <div className="col-10 col-md-9 px-0">
                                                                <div className="w-100 mb-2">
                                                            <span
                                                                className="d-table  font-weight-bold txt-color-dark_purple">{product.description}</span>
                                                                </div>
                                                                <div className="w-100 mb-0 d-flex mt-3">
                                                                    <a href={product.redirect_url}
                                                                       className="btn btn-outline btn-outline-purple px-4 mr-2">{product.name}</a>
                                                                </div>
                                                            </div>

                                                            <div className="col-2 col-md-3 px-0">
                                                                {/* <!-- <button className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right"><i className="fas fa-pen mr-1"></i>
                                            <span className="d-none d-sm-inline">Edit</span>
                                        </button> --> */}

                                                                <Link to={`/products/edit/${product.code}`}
                                                                      className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none">
                                                                    <i className="fas fa-pen mr-1"></i>
                                                                    <span className="d-none d-sm-inline">Edit</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    })
                                                }


                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </main>
                }
                <FooterSection></FooterSection>
            </>
        );
    }
}

export default ProductsPage;