import React from 'react';
import { updatePassword, updateProfile } from "../../../helpers/accountAPIServices";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import PleaseWaitButton from "../../Buttons/PleaseWaitButton/PleaseWaitButton";

class SuperadminSettingsPage extends React.Component {
    state = {
        user: this.props.user,
        currentUserProfile: {
            first_name: this.props.user.user.first_name,
            last_name: this.props.user.user.last_name,
        },
        userPasswordDetails: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        errors: [],
        message: "",
        isLoading: false,
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleUserProfileChange = (event) => {
        const field = event.target.name;
        const newValue = event.target.value;

        const newUserProfile = {
            ...this.state.currentUserProfile
        };

        newUserProfile[field] = newValue;

        this.setState({
            currentUserProfile: newUserProfile,
        }, () => console.log(this.state.currentUserProfile));
    }

    handlePasswordFieldChange = (event) => {
        const field = event.target.name;
        const newValue = event.target.value;

        const newPasswordDetails = {
            ...this.state.userPasswordDetails
        };

        newPasswordDetails[field] = newValue;

        this.setState({
            userPasswordDetails: newPasswordDetails,
        });

        this.setLoading(false);
    }

    handleSaveClick = async () => {
        this.setLoading(true);
        if (this.needsUpdate()) {
            if (!this.isValidProfileDetails()) {
                this.setErrors(['First name or Last name should not be blank.']);
                this.setLoading(false);
                return;
            }

            const updateProfileResults = await updateProfile(this.state.currentUserProfile, getAccessTokenFromSession());
            if (updateProfileResults.error) {
                console.log(updateProfileResults.error);
                this.setErrors(["There is a problem with setting your new profile details. Please, try again."]);
                return;
            }

            console.log(updateProfileResults);
            this.setState({
                user: updateProfileResults,
            });
            this.props.onChangeUser(updateProfileResults);
        } else {
            console.log('dont need update');
        }

        if (this.needsPasswordUpdate()) {
            if (this.isValidPasswordChangeDetails()) {
                // change password here

                const changePasswordResults = await updatePassword(this.state.userPasswordDetails, getAccessTokenFromSession());
                if (changePasswordResults.error) {
                    this.setErrors(["Incorrect password."]);
                    return;
                }
            } else {
                this.setLoading(false);
                return;
            }
        }
        this.setLoading(false);
        this.setMessage('Account details successfully updated.');
    }

    needsPasswordUpdate = () => {
        return this.state.userPasswordDetails.new_password !== "" ||
            this.state.userPasswordDetails.old_password !== "" ||
            this.state.userPasswordDetails.confirm_password !== "";
    }

    isValidPasswordChangeDetails = () => {
        if (this.state.userPasswordDetails.old_password === "" || this.state.userPasswordDetails.new_password === "" || this.state.userPasswordDetails.confirm_password === "") {
            this.setErrors(["The passwords cannot be blank."]);
            return false;
        }

        if (this.state.userPasswordDetails.new_password !== this.state.userPasswordDetails.confirm_password) {
            this.setErrors(["New password and confirm password mismatch."]);
            return false;
        }

        if (this.state.userPasswordDetails.new_password.length < 10) {
            this.setErrors(["New password must be 10 or more characters long."]);
            return false;
        }

        return true;
    }

    isValidProfileDetails = () => {
        return this.state.currentUserProfile.first_name !== "" && this.state.currentUserProfile.last_name !== "";
    }

    setErrors = (errorList) => {
        this.setState({
            message: "",
            errors: errorList,
        });
    }

    setMessage = (message) => {
        this.setState({
            message: message,
            errors: [],
        })
    }

    needsUpdate = () => {
        console.log(this.state.user.user);
        console.log(this.state.currentUserProfile);
        return (this.state.user.user.first_name !== this.state.currentUserProfile.first_name) || (this.state.user.user.last_name !== this.state.currentUserProfile.last_name);
    }

    render() {
        const userInformation = this.state.currentUserProfile;
        return (
            <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Settings</h2>
                        </div>

                    </div>

                    <div className="row mt-5 mx-0">

                        <div className="col-12 col-lg-6  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                            {
                                this.state.message !== "" &&
                                <p className="message" style={{
                                    color: "green",
                                }}>Profile successfully updated.</p>
                            }
                            {
                                this.state.errors.length > 0 &&
                                <p className="errors">
                                    {this.state.errors.map(e => {
                                        return <p className="error" style={{
                                            color: 'red',
                                        }}>{e}</p>
                                    })}
                                </p>
                            }
                            <div className="d-table w-100 ">
                                <h3 className="h6 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Profile
                                </h3>

                            </div>

                            <div className="w-100 mb-4 pb-0">

                                <div className="form-row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="" className="form-label">First Name</label>
                                        <input type="text" className="form-control" placeholder="" value={userInformation.first_name} name='first_name' onChange={this.handleUserProfileChange} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" placeholder="" value={userInformation.last_name} name='last_name' onChange={this.handleUserProfileChange} />
                                    </div>
                                </div>

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Email</label>
                                        <input type="email" className="form-control" placeholder=""
                                            value={this.state.user.user.email} name='email' disabled />
                                    </div>
                                </div>

                            </div>

                            <div className="d-table w-100  mt-5">
                                <h3 className="h6 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Change
                                    Password</h3>
                            </div>

                            <div className="w-100 mb-4 pb-0">

                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Current Password</label>
                                        <input type="password" className="form-control" placeholder="" value={this.state.userPasswordDetails.old_password} name='old_password' onChange={this.handlePasswordFieldChange} />
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">New Password</label>
                                        <input type="password" className="form-control" placeholder="" value={this.state.userPasswordDetails.new_password} name='new_password' onChange={this.handlePasswordFieldChange} />
                                    </div>
                                </div>
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="" value={this.state.userPasswordDetails.confirm_password} name='confirm_password' onChange={this.handlePasswordFieldChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 mt-5">
                                <div className="form-row mb-2">
                                    <button className="btn btn-solid btn-solid-orange w-100" onClick={this.handleSaveClick}>
                                        {
                                            this.state.isLoading ? <PleaseWaitButton loadingMessage="" /> : "Save Changes"
                                        }
                                    </button>
                                </div>

                                <a href='/settings'>
                                    <div className="form-row mb-2">
                                        <button className="btn btn-outline btn-outline-orange w-100">Cancel</button>
                                    </div>
                                </a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default SuperadminSettingsPage;