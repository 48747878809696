import React from 'react';
import { Redirect } from "react-router";
import { getNotifications } from "../../../helpers/notificationsAPIServices";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import FooterSection from '../../Common/FooterSection/FooterSection';
import HeaderSection from '../../Common/HeaderSection/HeaderSection';
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import NotificationsCard from "./NotificationsCard/NotificationsCard";
import { getAllRequestsForSuperadmin } from '../../../helpers/accountAPIServices';

export default class NotificationsPage extends React.Component {
    state = {
        notifications: [],
        isLoading: false,
        redirectTo404: false,
        search: '',
        filter: '',
        isFilter: false,
    }

    async componentDidMount() {
        this.setLoading(true);

        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });

        const getNotificationsResults = await getNotifications(getAccessTokenFromSession());

        // console.log(getNotificationsResults);

        if (getNotificationsResults.error) {
            console.log("There is a problem loading the notifications");
            this.setState({
                redirectTo404: true,
            });

            return;
        }

        this.setState({
            notifications: getNotificationsResults,
        });
        this.setLoading(false);
    };

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    };

    updateLocationClick = () => {
        this.setState({
            filter: 'edit_location_external',
            isFilter: true,
            search: '',
        })
    };

    newLocationClick = () => {
        this.setState({
            filter: 'new_location_external',
            isFilter: true,
            search: '',
        })
        console.log('Filter New Location');
    };

    claimLocationClick = () => {
        this.setState({
            filter: 'claim_location',
            isFilter: true,
            search: '',
        })
        console.log('Filter Claim Location');
    };

    adminClick = () => {
        this.setState({
            filter: 'superadministrator',
            isFilter: true,
            search: '',
        })
        console.log('Filter Admin');
    };

    cancelRequestClick = () => {
        this.setState({
            filter: 'location_admin_subscription_cancel',
            isCancelFilter: true,
            search: '',
        })
        console.log('Filter Cancel Request');
    };

    changeRequestClick = () => {
        this.setState({
            filter: 'location_admin_subscription_change',
            isChangeFilter: true,
            isFilter: false,
            isCancelFilter: false,
            search: '',
        })
        console.log('Filter Cancel Request');
    };

    clearFilterClick = () => {
        this.setState({
            filter: '',
            isFilter: false,
            isCancelFilter: false,
            isChangeFilter: false,
            search: '',
        })
    }

    handleSearchChange = (e) => {
        this.setState({
            search: e.target.value,
        });
        console.log(e.target.value);
    };


  render() {
    return (
      <>
          {
              this.state.redirectTo404 && <Redirect to='/404' />
              
          }
        <HeaderSection>

        </HeaderSection>
        

        <div className="main-content">
            {
                this.state.isLoading ? <LoadingOverlay /> :
                    <div className="container p-3 p-md-5">
                        <div className="row mb-4 p-0 mx-0">
                            <div className="col-12 px-0 mx-0">
                                <h2 className="h1 txt-color-dark_purple font-weight-bold">Notifications</h2>
                            </div>
                        </div>

                        <div className="row ">

                        <div className="col-12 search-input-notif">
                                    <div className="mb-3"><label> <input type="search" className="form-control form-control-sm" placeholder="Search" onChange={this.handleSearchChange} value={this.state.search}/></label></div>
						</div>
                                <div className="col-12 col-lg-7" style={this.state.notifications.length >= 5 ? { overflowY: "scroll", maxHeight: "650px" } : {}}>
                                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5">

                                    <div className="row pb-4">

                                    <div className="col-12 d-flex justify-content-end">
                                        <div className="dropdown">
                                            <button type="button" className="btn btn-outline-orange border-0 rounded-2 " data-toggle="dropdown">
                                                <i className="fas fa-bars"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button" onClick={this.updateLocationClick}>Update Locations</button>
                                                <button className="dropdown-item" type="button" onClick={this.newLocationClick}>New Locations</button>
                                                <button className="dropdown-item" type="button" onClick={this.claimLocationClick}>Claim Locations</button>
                                                <button className="dropdown-item" type="button" onClick={this.adminClick}>Administrator</button>
                                                        <button className="dropdown-item" type="button"
                                                            onClick={this.cancelRequestClick}
                                                >Cancel Requests</button>
                                                        <button className="dropdown-item" type="button"
                                                            onClick={this.changeRequestClick}
                                                        >Change Requests</button>
                                                <button className="dropdown-item" type="button" onClick={this.clearFilterClick}>All</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    {/*<div className="w-100 font-weight-bold pb-4  mx-0">*/}
                                    {/*    <button className="btn-solid-orange-sm rounded-20 px-2 py-1">*/}
                                    {/*        <span>(5)</span> New Request*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}

                                    { this.state.filter &&
                                            this.state.notifications.filter((notification) => {
                                                return notification.type === this.state.filter
                                        }).map((notification) => {
                                            return <NotificationsCard notification={notification} filter={this.state.isFilter} search={this.state.search}/>
                                            // return <NotificationsCard key={`NotificationsCard-${notification?.id}`} notification={notification} filter={this.state.isFilter}/>
                                        })
                                        }

                                    { this.state.search && !this.state.filter &&
                                        this.state.notifications.filter((notification) => {
                                        if(this.state.search === ''){
                                            return notification
                                        }
                                        else if (notification.information.toLowerCase().includes(this.state.search.toLowerCase())){
                                            return notification
                                            } else {return notification}
                                        }).map((notification) => {
                                            return <NotificationsCard notification={notification} filter={this.state.isFilter} search={this.state.search}/>
                                        })
                                    }

                                    {/* { this.state.search && this.state.filter &&
                                        this.state.notifications.filter((notification) => {
                                        if(this.state.search && this.state.filter === ''){
                                            return notification
                                            }
                                        else if (notification.information.toLowerCase().includes(this.state.search.toLowerCase()) && (notification.type === this.state.filter)){
                                            return notification
                                            }
                                        else if (notification.information.toLowerCase().includes(this.state.search.toLowerCase()) && (notification.type === this.state.filter)) {
                                            
                                        }}).map((notification) => {
                                            return <NotificationsCard notification={notification} filter={this.state.isFilter} search={this.state.search}/>
                                        })
                                    } */}
                                    
                                    { !this.state.filter && !this.state.search &&
                                            this.state.notifications.map((notification) => {

                                            return <NotificationsCard notification={notification} filter={this.state.isFilter} search={this.state.search}/>

                                                // return <NotificationsCard key={`NotificationsCard-${notification?.id}`} notification={notification} filter={this.state.isFilter}/>

                                        })
                                    }
                                    


                                    {/*<nav aria-label="Page navigation example">*/}
                                    {/*    <ul className="pagination justify-content-end">*/}
                                    {/*        <li className="page-item disabled">*/}
                                    {/*            <a className="page-link" href="#" tabIndex="-1"><i*/}
                                    {/*                className="fas fa-angle-double-left"></i></a>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="page-item"><a className="page-link txt-color-purple"*/}
                                    {/*                                     href="#">1</a></li>*/}
                                    {/*        <li className="page-item"><a className="page-link text-secondary"*/}
                                    {/*                                     href="#">2</a></li>*/}
                                    {/*        <li className="page-item"><a className="page-link text-secondary"*/}
                                    {/*                                     href="#">3</a></li>*/}
                                    {/*        <li className="page-item">*/}
                                    {/*            <a className="page-link txt-color-purple" href="#"><i*/}
                                    {/*                className="fas fa-angle-double-right"></i></a>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</nav>*/}

                                </div>
                            </div>
                        </div>

                    </div>
            }
        </div>
        


        <FooterSection></FooterSection>

      </>
    );
  }
}

NotificationsPage.propTypes = {

};
