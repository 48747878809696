import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import ChangePasswordPage from "./components/ChangePasswordPage/ChangePasswordPage";
import ChangePasswordSuccessPage from "./components/ChangePasswordSuccessPage/ChangePasswordSuccessPage";
import ClaimPage from "./components/ClaimPage/ClaimPage";
import FooterSection from "./components/Common/FooterSection/FooterSection";
import HeaderSection from "./components/Common/HeaderSection/HeaderSection";
import ForgotPasswordPage from "./components/ForgotPasswordPage/ForgotPasswordPage";
import ForgotPasswordSentEmailPage from "./components/ForgotPasswordSentEmailPage/ForgotPasswordSentEmailPage";
import ClaimSuccessPage from "./components/LocationsAdminPages/ClaimSuccessPage/ClaimSuccessPage";
import ContactUsPage from "./components/LocationsAdminPages/ContactUsPage/ContactUsPage";
import MembershipPlansPage from './components/LocationsAdminPages/MembershipPlansPage/MembershipPlansPage';
import LocationClaimPage from "./components/LocationsAdminPages/MyLocationsPages/LocationClaimPage/LocationClaimPage";
import MyLocationsPage from "./components/LocationsAdminPages/MyLocationsPages/MyLocationsPage";
import NavigationBar from "./components/LocationsAdminPages/NavigationBar/NavigationBar";
import OrderProductsPage from "./components/LocationsAdminPages/OrderProductsPages/OrderProductsPage/OrderProductsPage";
import SettingsPage from "./components/LocationsAdminPages/SettingsPages/SettingsPage";
import SubscriptionAndBillingsPage from "./components/LocationsAdminPages/SubscriptionAndBillingsPages/SubscriptionAndBillingsPage/SubscriptionAndBillingsPage";
import VerifiedEmailSentPage from "./components/LocationsAdminPages/UnverifiedUserPages/VerifiedEmailSentPage/VerifiedEmailSentPage";
import LoginPage from "./components/LoginPage/LoginPage";
import NotFound404Page from "./components/MiscellaneousPages/NotFound404Page/NotFound404Page";
import RegistrationFormPage from "./components/RegistrationFormPage/RegistrationFormPage";
import Dashboard from "./components/SuperadminPages/Dashboard/Dashboard";
import CSVReportsList from './components/SuperadminPages/Dashboard/reports/CSVReportsList';
import CSVUpLoadReportsNew from './components/SuperadminPages/Dashboard/reports/CSVUpLoadReportsNew';
import EditListingsReportViewPage from './components/SuperadminPages/LocationsListings/LocationListingsView/EditListingsReportViewPage';
import EditListingsViewPage from "./components/SuperadminPages/LocationsListings/LocationListingsView/EditListingsViewPage";
import ListingsViewPage from "./components/SuperadminPages/LocationsListings/LocationListingsView/ListingsViewPage";
import LocationViewPage from './components/SuperadminPages/LocationsListings/LocationListingsView/LocationViewPage';
import NewListingsViewPage from "./components/SuperadminPages/LocationsListings/LocationListingsView/NewListingsViewPage";
import LocationUpdatesRequests from "./components/SuperadminPages/LocationUpdatesRequests/LocationUpdatesRequests";
import LocationUpdatesView from "./components/SuperadminPages/LocationUpdatesRequests/LocationUpdatesView/LocationUpdatesView";
import AddCampaignPage from './components/SuperadminPages/MarketingCampaignsPage/AddCampaignPage/AddCampaignPage';
import CampaignLocationsPage from './components/SuperadminPages/MarketingCampaignsPage/CampaignLocationspage/CampaignLocationsPage';
import MarketingCampaignsPage from "./components/SuperadminPages/MarketingCampaignsPage/MarketingCampaignsPage";
import NewGeolocationPlanPage from "./components/SuperadminPages/MarketingCampaignsPage/NewGeolocationPlanPages/NewGeolocationPlanPage";
import NewIndividualPlanPage from "./components/SuperadminPages/MarketingCampaignsPage/NewIndividualPlanPages/NewIndividualPlanPage";
import NewOrganizationPlanPage from "./components/SuperadminPages/MarketingCampaignsPage/NewOrganizatonPlanPages/NewOrganizationPlanPage";
import MembershipUpdatesPageNew from './components/SuperadminPages/MembershipUpdatesPage/MembershipUpdatesPageNew';
import NotificationsPage from "./components/SuperadminPages/NotificationsPage/NotificationsPage";
import AddProductPage from './components/SuperadminPages/ProductsPage/AddProductPage';
import ProductsPage from './components/SuperadminPages/ProductsPage/ProductsPage';
import SendInvitePage from "./components/SuperadminPages/SendInvitePage/SendInvitePage/SendInvitePage";
import CreateOrganizationPage from "./components/SuperadminPages/SubscriberPages/ManageOrganizationsPages/CreateOrganizationPage/CreateOrganizationPage";
import EditOrganizationPage from "./components/SuperadminPages/SubscriberPages/ManageOrganizationsPages/EditOrganizationPage/EditOrganizationPage";
import OrganizationsPage from "./components/SuperadminPages/SubscriberPages/ManageOrganizationsPages/OrganizationsPage/OrganizationsPages";
import TierPage from "./components/SuperadminPages/SubscriberPages/TierPage/TierPage";
import SuperadminNavigationBar from "./components/SuperadminPages/SuperadminNavigationBar/SuperadminNavigationBar";
import SuperadminSettingsPage from "./components/SuperadminPages/SuperadminSettingsPage/SuperadminSettingsPage";
import AddEditTier from './components/SuperadminPages/TiersAdmin/AddEditTier';
import TierDetails from './components/SuperadminPages/TiersAdmin/TierDetails';
import TiersMain from './components/SuperadminPages/TiersAdmin/TiersMain';
import {
    getAccessTokenFromSession, getRefreshTokenFromSession,
    getUserProfileFromSession,
    setAccessTokenToSession,
    setRefreshTokenToSession,
    setUserProfileToSession
} from "./helpers/sessionHelpers";



class App extends React.Component {
    state = {
        user: null,
        token: null,
    }

    componentDidMount() {
        if(getUserProfileFromSession()) {
            this.setState({
                user: getUserProfileFromSession(),
            });
        }
        if(getAccessTokenFromSession()) {
            this.setState({
                token: {
                    access: getAccessTokenFromSession(),
                    refresh: getRefreshTokenFromSession(),
                },
                user: getUserProfileFromSession(),
            });
        }
    }

    handleUserChange = (user) => {
        this.setState({
            user: user,
        });
        
        setUserProfileToSession(user);
    }

    handleTokenChange = (token) => {
        this.setState({
            token: token
        });

        setAccessTokenToSession(token.access);
        setRefreshTokenToSession(token.refresh);
    }

    // componentDidMount() {
    //     if(getAccessTokenFromSession()) {
    //         this.setState({
    //             token: {
    //                 access: getAccessTokenFromSession(),
    //                 refresh: getRefreshTokenFromSession(),
    //             },
    //             user: getUserProfileFromSession(),
    //         });
    //     }
    // }

    handleChangeUser = (user) => {
        setUserProfileToSession(user);
        this.setState({
            user: user,
        });
    }

    handleLogout = () => {
        this.setState({
            user: null,
            token: null
        });

        // sessionStorage.clear();
        localStorage.clear();
    }

    render() {
        if(!this.state.user) {
            return (
                <>
                    <Switch>
                        <Route exact path='/' component={() => <LoginPage onUserChange={this.handleUserChange}
                                                                          onTokenChange={this.handleTokenChange}/>} />
                        <Route exact path='/claim/:practice_id' component={ClaimPage} />
                        <Route exact path='/404' component={NotFound404Page} />
                        <Route exact path='/password/forgot' component={ForgotPasswordPage} />
                        <Route exact path='/password/forgot/success' component={ForgotPasswordSentEmailPage} />
                        <Route exact path='/password/change/success' component={ChangePasswordSuccessPage} />
                        <Route path='/password/change/' component={ChangePasswordPage} />
                        <Route path='/register' component={RegistrationFormPage} />

                        <Route component={() => <LoginPage onUserChange={this.handleUserChange}
                                                        onTokenChange={this.handleTokenChange}/>} />
                    </Switch>
                </>
            );
        } else {
            if(this.state.user.role === "external") {
                // if the user's email is not yet verified
                if(this.state.user && this.state.user.verifications.find((v) => v.verification_type.type === 'email')) {
                    return (
                        <>
                            <HeaderSection />
                            <NavigationBar user={this.state.user} onLogout={this.handleLogout}/>
                            <Switch>
                                <Route exact path='/' component={MyLocationsPage} />
                                <Route exact path='/subscriptions' render={() => <SubscriptionAndBillingsPage user={this.state.user} />} />
                                <Route exact path='/settings' render={() => <SettingsPage user={this.state.user} onChangeUser={this.handleChangeUser} onLogout={this.handleLogout} />} />
                                <Route exact path='/claim-success/:practice_id' component={ClaimSuccessPage} />
                                <Redirect exact from={'/claim/:practice_id'} to={'/claim-success/:practice_id'} />
                                <Redirect exact from={'/listings'} to={'/'} />
                                <Route exact path='/listings/new' render={() => <NewListingsViewPage user={this.state.user} />} />
                                <Route exact path='/listings/edit/:practice_id' render={props => <EditListingsViewPage {...props} user={this.state.user} />} />
                                <Route exact path='/listings/claim/' component={LocationClaimPage} />
                                <Route exact path='/products/' render={() => <OrderProductsPage user={this.state.user} />} />
                                <Route exact path='/contact' component={ContactUsPage} />
                                <Route exact path='/membership-plans' component={MembershipPlansPage}/>


                                <Route exact path='/404' component={NotFound404Page} />
                                <Route component={NotFound404Page} />
                            </Switch>
                            <FooterSection />
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <Switch>
                                <Route render={() => <VerifiedEmailSentPage onClear={this.handleLogout} email={this.state.user.user.email} />} />
                                <Route exact path='/404' component={NotFound404Page} />
                            </Switch>
                        </>
                    )
                }

            } else if (this.state.user.role === "superadministrator") {
                return (
                    <>
                        <HeaderSection />
                        <SuperadminNavigationBar user={this.state.user} onLogout={this.handleLogout}/>
                        <Switch>
                            {/*Add footer on each page!!!*/}
                            <Route exact path='/' component={Dashboard} />

                            <Route exact path='/reports-list' component={CSVReportsList}/>
                            <Route exact path='/reports-view/:upload_report_id' component={CSVUpLoadReportsNew} />

                            <Route exact path='/requests' component={LocationUpdatesRequests}/>
                            <Route exact path='/requests/:id' component={LocationUpdatesView}/>
                            <Route exact path='/settings' render={() => <SuperadminSettingsPage user={this.state.user} onChangeUser={this.handleChangeUser} />} />
                            <Route exact path='/invite' component={SendInvitePage} />
                            <Route exact path='/listings' component={ListingsViewPage} />
                            <Route exact path='/listings-test' component={EditListingsReportViewPage} />
                            <Route exact path='/listings/new' render={() => <NewListingsViewPage user={this.state.user} />} />
                            <Route exact path='/listings/:practice_id' component={LocationViewPage}/>
                            <Route exact path='/listings/edit/:practice_id' render={props => <EditListingsViewPage {...props} user={this.state.user} />} />
                            <Route exact path='/notifications' component={NotificationsPage}/>
                            <Route exact path='/products' component={ProductsPage}/>
                            <Route exact path='/products/edit/:code' render={() => <AddProductPage edit={true} />} />
                            <Route exact path='/products/add' component={AddProductPage}/>
                            <Route exact path='/campaigns/' component={MarketingCampaignsPage}/>
                            <Route exact path='/campaigns/add' component={AddCampaignPage}/>
                            <Route exact path='/campaigns/add/individual' component={NewIndividualPlanPage}/>
                            <Route exact path='/campaigns/edit/individual/:code' render={() => <NewIndividualPlanPage edit={true} />} />
                            <Route exact path='/campaigns/add/organization' component={NewOrganizationPlanPage}/>
                            <Route exact path='/campaigns/edit/organization/:code' render={() => <NewOrganizationPlanPage edit={true} />} />
                            <Route exact path='/campaigns/add/geolocation' component={NewGeolocationPlanPage} />
                            <Route exact path='/campaigns/edit/geolocation/:code' render={() => <NewGeolocationPlanPage edit={true} />} />
                            <Route exact path='/campaigns/locations' component={CampaignLocationsPage}/>
                            <Route exact path='/subscribers' component={TierPage}/>
                            <Route exact path='/subscribers/organizations' component={OrganizationsPage}/>
                            <Route exact path='/subscribers/organizations/create' component={CreateOrganizationPage}/>
                            <Route exact path='/subscribers/organizations/edit/:code' component={EditOrganizationPage} />
                            <Route exact path='/membership/updates' component={MembershipUpdatesPageNew}/>


                            {/* TIERS ADMIN */}
                            <Route exact path='/tiers' component={TiersMain} />
                            <Route exact path='/tiers/add' component={AddEditTier} />
                            <Route exact path='/tiers/:tier_code/edit' component={AddEditTier} />
                            <Route exact path='/tiers/:tier_code' component={TierDetails} />
                            

                            
                            


                            <Route exact path='/404' component={NotFound404Page} />
                            <Route component={NotFound404Page} />
                        </Switch>
                    </>
                )
            } else {
                return <Redirect to='/404' />
            }
        }
    }
}

export default App;