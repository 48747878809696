import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {parseResponse} from "./passwordManagementServices";
import {catchGenericError, parseJSON} from "./responseHandlers";

const getTiers = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


/* TIERS */
export const getTiersList = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/get_tiers/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const getTiersListWithFeatures = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/get_tiers_with_features/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const getTierDetails = (tier_code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/${tier_code}/details/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const getSuggestedTierCode = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/suggested_tier_code/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const addTier = (payload = {}, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/add_tier/`;
    
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(payload)
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const editTier = (tier_code, payload = {}, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/${tier_code}/edit/`;
    
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(payload),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const deleteTier = (tier_code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/${tier_code}/delete/`;
    const options = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


/* TIERS FEATURES */
export const getTierFeatures = (tier_code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/${tier_code}/get_tier_features/`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const bulkUpdateOrCreateTierFeatures = (tier_code, payload = {}, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/${tier_code}/bulk_update_or_create_tier_features/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(payload),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export const deleteTierFeature = (payload = {}, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/tier_features/delete_tier_feature/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(payload),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


const getSubscriptions = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const getTierSubscription = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription/check/` + practice_id;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const subscribe = (practice_id, tier_code, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription/subscribe`;
    const fields = {
        practice_id,
        tier_code,
    };
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields)
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}

const stripeURL = (practice_id, tier_code, stripe_billing_url, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription/stripe`;
    const fields = {
        practice_id,
        tier_code,
        stripe_billing_url,
    };
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields)
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


const editStripeUrl = (practice_id, tier_code, stripe_billing_url, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription/stripe/edit/` + tier_code + `/` + practice_id;
    const fields = {
        stripe_billing_url,
    };
    const options = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(fields)
    };


    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}




const deleteSubscription = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/admin/subscription/delete/` + practice_id;
    const options = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch(catchGenericError);
}


export {
    getTiers,
    getSubscriptions,
    getTierSubscription,
    subscribe,
    stripeURL,
    editStripeUrl,
    deleteSubscription,
}