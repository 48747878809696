import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FooterSection from '../../../Common/FooterSection/FooterSection';
import HeaderSection from '../../../Common/HeaderSection/HeaderSection';


export class CampaignLocationsPage extends Component {

  state = {
    search: '',
    zip: 0,
    city: '',
    locState: '',
  };

  handleSearchChange = (e) => {
    this.setState({search: e.target.value})
  };

  handleZipChange = (e) => {
    this.setState({zip: e.target.value})
  };

  handleCityChange = (e) => {
    this.setState({city: e.target.value})
  };

  handleLocationStateChange = (e) => {
    this.setState({locState: e.target.value})
  };

  render() {
    return (
      <>
      <HeaderSection/>
      <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">New Campaign</h2>
                            <Link to='/campaigns' className="mt-4 d-table txt-color-purple text-decoration-none "><i className="fas fa-arrow-left mr-2"></i> Back to Marketing Campaigns</Link>
                        </div>
                      
                    </div>

                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                            <div className="d-table w-100 ">
                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Campaign Info</h3> 
                            </div>

                              

                <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-control-input-search-label"></label>
                            <input type="text" className="form-control form-control-input-search" placeholder="Search..." value={this.state.search} onChange={this.handleSearchChange}/>
                        </div>
                    </div>

                    <div className="row py-4 d-flex align-items-center">
                        <div className="col-4">
                            <label htmlFor="" className="form-control-label">
                                <span className="font-weight-bold">Radius:</span>
                                <span className="font-weight-normal" id="rad_value">50</span>
                                <span className="font-weight-normal">mi</span>
                            </label>
                        </div>
                        <div className="col-6">
                            <input type="range" min="1"   max="100" value="50" data-orientation="horizontal"/>
                        </div>
                    </div>

                <div className="row mb-4">
                            <div className="col-12 col-sm-4 mb-3">
                                <label htmlFor="" className="form-label">Zip Code</label>&nbsp;
                                <input type="text" className="form-control" value={this.state.zip}
                                onChange={this.handleZipChange}/>
                            </div>
                            <div className="col-12 col-sm-4 mb-3">
                                <label htmlFor="" className="form-label">City</label>&nbsp;
                                <input type="text" className="form-control" value={this.state.city}
                                onChange={this.handleCityChange}/>
                            </div>
                            <div className="col-12 col-sm-4 mb-3">
                                <label htmlFor="" className="form-label">State</label>&nbsp;
                                <input type="text" className="form-control" value={this.state.locState} onChange={this.handleLocationStateChange}/>
                            </div>
                        </div>

                            

                            <div className="row">
                                <div className="col-lg-6">

<ul className="list-unstyled1">
      
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Alaska</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Alabama</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Arkansas</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Arizona</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">California</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Colorado</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Connecticut</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">District of Columbia</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Delaware</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Florida</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Georgia</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Hawaii</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Iowa</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Idaho</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Illinois</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Indiana</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Kansas</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Kentucky</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Louisiana</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Massachusetts</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Maryland</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Maine</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Michigan</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Minnesota</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Missouri</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Mississippi</a></li>
            
</ul>

                                </div>

                                <div className="col-lg-6">
                                    
<ul className="list-unstyled1">
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Montana</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">North Carolina</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">North Dakota</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Nebraska</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">New Hampshire</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">New Jersey</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">New Mexico</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Nevada</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">New York</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Ohio</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Oklahoma</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Oregon</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Pennsylvania</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Puerto Rico</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Rhode Island</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">South Carolina</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">South Dakota</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Tennessee</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Texas</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Utah</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Virginia</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Vermont</a></li>       
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Washington</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">Wisconsin</a></li>
            <li><a href="void(0);" onClick={(e)=> e.preventDefault()} data-toggle="modal" data-target="#select_location">West Virginia</a></li>

</ul>
                                </div>
                            </div>
                            
                            

                            

                            <div className="col-12 mx-0 px-0 pt-4 ">
                                <div className="form-row mx-0 px-0">
                                    <div className="col-12 mx-0 px-0">
                                        <button className="btn btn-solid btn-solid-orange w-100 ">
                                            Create Campaign
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                      
                    </div>
                </div>
            </div>
        <FooterSection/>
      </>
    )
  }
}

export default CampaignLocationsPage
