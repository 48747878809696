import React from 'react';
import CreateOrganizationPage from "../CreateOrganizationPage/CreateOrganizationPage";
import {useParams} from "react-router";

const EditOrganizationPage = (props) => {
    const {code} = useParams();
    console.log(code);
    return (
        <CreateOrganizationPage edit={true} code={code}/>
    );
}

export default EditOrganizationPage;