export function range(size = 0, startAt = 0) {
    return [...Array(Number(size)).keys()].map(i => i + Number(startAt));
}

export function pageRange(size = 0) {
    return range(Number(size), 1)
}

export function adjustRangeOffset(numArray = range(), offsetBy = 1, size = 0) {
    /* 
    Parameters:
        numArray = the original array of numbers.
        offsetBy = (Optional) the number to add to the starting point of the array.
        size = (Optional) the new size of the array. Default is the length of 'numArray'.
    
    Returns a tuple. (range, min, max)
    */
    let min = Math.min(...numArray);
    let max = Math.max(...numArray);
    let _startAt = min + Number(offsetBy);
    let _size = (size) ? size : numArray.length;
    let newRange = range(_size, _startAt);

    min = Math.min(...newRange);
    max = Math.max(...newRange);

    return (_startAt < 1) ? { newRange: numArray, min, max } : { newRange, min, max };
}
