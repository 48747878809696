import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { parseResponse } from "./passwordManagementServices";
import { parseJSON } from "./responseHandlers";


const cancelRequest = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'tiers/cancel/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

const changeRequest = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'tiers/change/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

const getTierSubscription = (practice_id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `tiers/subscription/check/` + practice_id;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

const getSubscriptionUpdates = (page, limit, search, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `subscription-updates/?page=${page}&limit=${limit}&search=${search}`;
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}


const changeSubscription = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'subscription-updates/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}


const setSubscriptionUpdateStatus = (details, id, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + `subscription-updates/${id}/set_completed/`;
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}


export {
    cancelRequest,
    changeRequest,
    getTierSubscription,
    getSubscriptionUpdates,
    changeSubscription,
    setSubscriptionUpdateStatus,
};
