import React, { useEffect, useState } from 'react';
import { getUSAStatesForOptionsComponent } from "../../../../helpers/addressHelpers";
import { searchClinicsForInvite } from "../../../../helpers/sendInviteAPIServices";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";

const SelectLocationWidget = (props) => {
    const [searchDetails, setSearchDetails] = useState({
        query: "",
        zip: "",
        city: "",
        state: "default",
    });

    const [isLoading, setIsLoading] = useState(false);

    const [filters, setFilters] = useState({
        covid_testing: false,
        antibody_testing: false,
        xray: false,
    });


    const [fetchedLocations, setFetchedLocations] = useState([]);

    const [filteredFetchedLocations, setFilteredFetchedLocations] = useState([]);

    const [selectedLocations, setSelectedLocations] = useState([]);

    const isValidFields = () => {
        const fields = searchDetails;
        return !([
            fields.query === "",
            fields.zip === "",
            fields.city === "",
            fields.state === "default" || fields.state === "",
        ].every((test) => test));
    }

    const handleFilterChanges = (event) => {
        const fieldName = event.target.name;
        const checked = event.target.checked;

        const newFilters = {
            ...filters,
        };

        newFilters[fieldName] = checked;
        setFilters(newFilters);
    }

    const handleClickPracticeToCampaign = (practice) => {
        // check if it's already selected
        if (selectedLocations.find((selectedLocation) => selectedLocation.practice_id === practice.practice_id)) {
            const newSelectedLocations = selectedLocations.filter((location) => location.practice_id !== practice.practice_id);
            setSelectedLocations(newSelectedLocations);
        } else {
            const newSelectedLocations = [...selectedLocations, practice];
            setSelectedLocations(newSelectedLocations);
        }
    }

    const handleSearchDetailsChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        const newSearchDetails = {
            ...searchDetails,
        };

        newSearchDetails[fieldName] = fieldValue;
        setSearchDetails(newSearchDetails);
    }

    const prepareSearchDetails = (fields) => {
        return {
            ...fields,
            state: fields.state === "default" ? "" : fields.state
        }
    }

    const handleSearchLocationClick = async () => {
        const preparedSearchDetails = prepareSearchDetails(searchDetails);
        setIsLoading(true);
        const searchResults = await searchClinicsForInvite(preparedSearchDetails);

        if (searchResults.error) {
            console.log('There is an error searching for clinics.');
            setIsLoading(false);

            return;
        }

        console.log(searchResults);
        setFetchedLocations(searchResults);
        setIsLoading(false);
    }

    const filterClinics = (clinics, filter) => {
        // COVID-19 tests
        let filtered_clinics = [...clinics];
        if (filter.covid_testing) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.covid19TestServices.length > 0);
        }

        if (filter.antibody_testing) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.antibodyTestServices.length > 0);
        }

        if (filter.xray) {
            filtered_clinics = filtered_clinics.filter((clinic) => clinic.xray);
        }

        return filtered_clinics;
    }

    useEffect(() => {
        setFilteredFetchedLocations(filterClinics(fetchedLocations, filters));
    }, [fetchedLocations, filters])

    const handleAddLocationsClick = () => {
        props.onChangeSelectedLocations(selectedLocations);
        setFetchedLocations([]);
        setSelectedLocations([]);
    };

    const handleEnterSubmit = (e) => {
        if (e.key === "Enter") {
            handleSearchLocationClick()
        }
    }

    return (
        <div className="modal fade p-5" id="select_location" tabIndex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">Select Locations</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fas fa-times-circle fa-xs"></i>
                        </button>
                    </div>
                    {
                        isLoading && <LoadingOverlay />
                    }
                    <div className="modal-body p-4" >
                        <div onKeyDown={handleEnterSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="form-control-input-search-label"></label>
                                    <input type="text" className="form-control form-control-input-search"
                                        value={searchDetails.query} name='query'
                                        onKeyDown={handleEnterSubmit}
                                        onChange={handleSearchDetailsChange} />
                                </div>
                            </div>
                            <div className="row py-3 mx-0">
                                <div className="col-12 mx-0 px-0">
                                    <label className="form-label font-weight-bold" htmlFor="">Filter:</label>
                                    <div className="form-row mx-0 px-4">
                                        <div
                                            className="col-6 mx-0 py-2 custom-checkbox">
                                            <input type="checkbox" id="covid_testing" name="covid_testing"
                                                className="" onChange={handleFilterChanges}
                                                checked={filters.covid_testing} />
                                            <label className="" htmlFor="covid_testing" style={{
                                                margin: '0px 10px',
                                            }}>COVID-19 Testing</label>
                                        </div>
                                        <div
                                            className="col-6 mx-0 py-2 custom-checkbox">
                                            <input type="checkbox" id="antibody_testing" name="antibody_testing"
                                                className="" onChange={handleFilterChanges}
                                                checked={filters.antibody_testing} />
                                            <label className="" htmlFor="antibody_testing" style={{
                                                margin: '0px 10px',
                                            }}>Antibody Testing</label>
                                        </div>
                                        <div
                                            className="col-6 mx-0 py-2 custom-checkbox">
                                            <input type="checkbox" id="xray" name="xray"
                                                className="" onChange={handleFilterChanges} checked={filters.xray} />
                                            <label className="" htmlFor="xray" style={{
                                                margin: '0px 10px',
                                            }}>X-Ray</label>
                                        </div>
                                        {/*<div*/}
                                        {/*    className="col-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                        {/*    <input type="checkbox" id="24_hrs" name="customRadioInline1"*/}
                                        {/*           className="form-control-input-checkbox" />*/}
                                        {/*        <label className="form-control-input-checkbox-label form-label"*/}
                                        {/*               htmlFor="24_hrs">24 Hours</label>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row py-4 d-flex align-items-center">*/}
                            {/*    <div className="col-4">*/}
                            {/*        <label htmlFor="" className="form-control-label">*/}
                            {/*            <span className="font-weight-bold">Radius:</span>*/}
                            {/*            <span className="font-weight-normal" id="rad_value">50</span>*/}
                            {/*            <span className="font-weight-normal">mi</span>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-6">*/}
                            {/*        <input type="range" min="1" max="100" value="50" data-orientation="horizontal" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="row mb-4">
                                <div className="col-4">
                                    <label htmlFor="" className="form-label">ZIP Code</label>
                                    <input type="text" className="form-control" name='zip' value={searchDetails.zip}
                                        onChange={handleSearchDetailsChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="" className="form-label">City</label>
                                    <input type="text" className="form-control" name='city' value={searchDetails.city}
                                        onChange={handleSearchDetailsChange} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="" className="form-label">State</label>
                                    <select className="form-control" name='state'
                                        value={searchDetails.state} onChange={handleSearchDetailsChange}>
                                        <option value='default' key='default'></option>
                                        {
                                            getUSAStatesForOptionsComponent().map((state) => {
                                                return <option value={state.value} key={state.key}>{state.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            isValidFields() ?
                                <div className="modal-footer">
                                    <button className="btn btn-solid btn-solid-orange w-100 "
                                        onClick={handleSearchLocationClick}>
                                        Search
                                    </button>
                                </div> :
                                <div className="modal-footer">
                                    <button className="btn btn-solid btn-solid-orange w-100 "
                                        style={{ backgroundColor: 'gray' }}>
                                        Search
                                    </button>
                                </div>
                        }

                        <div className="row rounded-10 border-light_blue  p-3 mx-0 overflow-auto campaign-location">
                            {
                                filteredFetchedLocations.map(location => {
                                    const selected = selectedLocations.find((selectedLocation) => location.practice_id === selectedLocation.practice_id);
                                    return (
                                        <div className={`row border-light_blue p-3 mx-0 mb-4 rounded-10 campaign-location-btn ` + (selected ? 'active' : '')} onClick={() => handleClickPracticeToCampaign(location)}>
                                            <div className="col-10 col-md-8">
                                                <div className="w-100 mb-3 d-flex">
                                                    <span className="d-table  font-weight-bold">{location.name}</span>
                                                </div>
                                                <div className="w-100 mb-3 d-flex">
                                                    <span className="d-table txt-color-purple mr-3"><i
                                                        className="fas fa-map-marker-alt"></i></span>
                                                    <span className="d-table font-weight-normal">
                                                        {`${location.address}, ${location.city}, ${location.state} ${location.zip}`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-2 col-md-4 justify-content-end">
                                                <button
                                                    className={`btn-select-changes float-right ` + (selected ? 'selected' : '')} onClick={() => handleClickPracticeToCampaign(location)}>Add</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {
                            selectedLocations.length > 0 ?
                                <div className="modal-footer">
                                    <button className="btn btn-solid btn-solid-orange w-100 "
                                        onClick={handleAddLocationsClick}>
                                        Add Locations
                                    </button>
                                </div> :
                                <div className="modal-footer">
                                    {/*<button className="btn btn-solid btn-solid-orange w-100 "*/}
                                    {/*        style={{backgroundColor: 'gray'}}>*/}
                                    {/*    Add Locations*/}
                                    {/*</button>*/}
                                </div>
                        }
                    </div>
                    {/*<div className="modal-footer">*/}
                    {/*    <p className="text-center d-block txt-color-purple mx-auto pb-3"> 5 Selected </p>*/}
                    {/*    <button className="btn btn-solid btn-solid-orange w-100 ">*/}
                    {/*        Add Locations*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default SelectLocationWidget;