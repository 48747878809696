import React from 'react';
import {Link, Redirect} from "react-router-dom";
import LocationUpdatesViewFooter from "./LocationUpdatesViewFooter/LocationUpdatesViewFooter";
import {approveRequest, rejectRequest} from "../../../../helpers/superadminAPIServices";
import {getAccessTokenFromSession} from "../../../../helpers/sessionHelpers";
import {convert24HourFormatStringTo12HourFormatString, DAYS_OF_THE_WEEK} from "../../../../helpers/scheduleUtilties";
import {subscribe} from "../../../../helpers/tierAPIServices";

class LocationUpdatesView extends React.Component {
    state = {
        practice: this.props.location.practice ? this.props.location.practice.practice : {
            id: "",
            name: "",
            practice_id: "",
            address: "",
            city: "",
            zip: "",
            services: "",
            claimed_by: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            about: "",
            insurance: "",
            partners: [],
            covid19TestServices: [],
            antibodyTestServices: [],
            schedules: [],
        },
        redirect: false,
        approved: false,
        rejected: false,
        tier: 'BASIC',
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    async componentDidMount() {
        // get practice here
        if(!this.props.location.practice) {
            // redirect to requests
            this.setState({
                redirect: true
            })
        }
    }

    handleApproveClick = async (event) => {
        // approve request
        console.log('approving...');
        const approveRequestResults = await approveRequest(this.state.practice.id, getAccessTokenFromSession());
        if(approveRequestResults.error) {
            console.log("error submitting approval");
            console.log(approveRequestResults.error);
            return;
        }

        const updateTier = await subscribe(this.state.practice.practice_id, 'BASIC', getAccessTokenFromSession());
        console.log(updateTier);

        console.log(approveRequestResults);
        this.setState({
            approved: true,
        })
    }

    handleDeclineClick = async (event) => {
        // reject request
        console.log('rejecting...');
        const rejectRequestResults = await rejectRequest(this.state.practice.id, getAccessTokenFromSession());
        if(rejectRequestResults.error) {
            console.log("error submitting rejection");
            console.log(rejectRequestResults.error);
            return;
        }

        console.log(rejectRequestResults);
        this.setState({
            approved: false,
            rejected: true,
        })
    }

    render() {
        console.log(this.state.practice);
        const claimedBy = this.state.practice.claimed_by;
        const location = this.state.practice;
        let website = "";
        if(this.state.practice.website !== "") {
            const website_pattern = /^https?:\/\//;
            /* eslint-disable-next-line */
            website = website_pattern.test(this.state.practice.website) ? this.state.practice.website :
                "https://" + this.state.practice.website;
        }
        console.log("Location >> ", location);
        let location_service = location.services ?? '' ;
        let location_insurance = location.insurance ?? '';
        let services = location_service !== "" ? location_service.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];
        let insurance = location_insurance !== "" ? location_insurance.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];

        const covid19TestsString = this.state.practice.covid19TestServices.map(test => test.test.name).join(', ');
        const antibodyTestsString = this.state.practice.antibodyTestServices.map(test => test.test.name).join(', ');

        return (
            <>
                {
                    this.state.redirect && <Redirect to='/requests/' />
                }
                <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Location Request</h2>
                            <Link to="/requests"
                               className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                className="fas fa-arrow-left mr-2"></i> Back to Location Updates/Request List</Link>
                        </div>

                    </div>

                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-lg-8  border-light_blue rounded-4 p-3 p-md-5 mx-0 mb-4">
                            <div className="form-row mx-0 px-0">
                                <div className="col-10 mb-3">
                                    <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0" >Approve
                                        location request?</h3>
                                </div>

                                <div className="col-6 col-sm-4">
                                    <button className="w-100 btn btn-solid btn-solid-orange mr-2" data-toggle="modal"
                                            data-target="#approved_update" onClick={this.handleApproveClick}>Approve
                                    </button>
                                </div>

                                <div className="col-6 col-sm-4">
                                    <button className="w-100 btn btn-outline btn-outline-orange" data-toggle="modal"
                                            data-target="#declined_update" onClick={this.handleDeclineClick}>Decline
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                            <div className="d-table w-100 mb-4 border-bottom ">
                                <div className="form-row">
                                    <div className="col-10 col-md-8">
                                        <h3 className="h4 txt-color-dark_purple font-weight-bold pb-4 mb-4 mx-0">{`${claimedBy.first_name} ${claimedBy.last_name}`}</h3>
                                    </div>
                                    <div className="col-2 col-md-4">
                                        <a href="void(0);" onClick={(e) => e.preventDefault()}
                                           className="float-right d-none d-md-table btn-location-changes">Location
                                            Changes</a>
                                        <a href="void(0);" onClick={(e) => e.preventDefault()}
                                           className="float-right d-flex d-md-none btn-location-changes-icon"><i
                                            className="far fa-compass"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">Email
                                            Address</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{claimedBy.email}</label>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">Phone</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{this.state.practice.phone !== "" ? this.state.practice.phone : "(Not available)"}</label>
                                    </div>
                                </div>


                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">NUCR Practice name</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{this.state.practice.name}</label>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">Website</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{this.state.practice.website !== "" ? this.state.practice.website : "(Not available)"}</label>
                                    </div>
                                </div>

                            </div>

                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Hours of Operation</h4>
                                    </div>
                                </div>

                                {
                                    DAYS_OF_THE_WEEK.map((day) => {
                                        const practice_schedule = this.state.practice.schedules.find((s) => s.occurrence.name === day);

                                        if(!practice_schedule) {
                                            return (
                                                <div className="form-row mb-3 mb-md-2">
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label htmlFor="" className="form-label m-0">(Closed)</label>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        console.log(practice_schedule);
                                        return (
                                            <div className="form-row mb-3 mb-md-2">
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <label htmlFor="" className="form-label m-0">
                                                        {`${convert24HourFormatStringTo12HourFormatString(practice_schedule.opening)} - ${convert24HourFormatStringTo12HourFormatString(practice_schedule.closing)}`}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            <div className="w-100 mb-4 pb-4 ">
                                <div className="form-row mb-3">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Business Info</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-3">
                                    {
                                        services.length > 0 &&
                                        <div className="col-12 col-sm-6">

                                            <h4 className="form-label mb-3">Services</h4>
                                            <ul className="basic-list">
                                                {
                                                    services.map((s) => {
                                                        return <li className="form-label">{s}</li>

                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }

                                    {
                                        insurance.length > 0 &&
                                        <div className="col-12 col-sm-6">
                                            <h4 className="form-label mb-3">Insurance</h4>
                                            <ul className="basic-list">
                                                {
                                                    insurance.map((s) => {
                                                        return <li className="form-label">{s}</li>

                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }

                                    {
                                        this.state.practice.about !== "" &&
                                        <div className="col-12 mt-4">
                                            <h4 className="form-label mb-3">About</h4>
                                            <p className="txt-color-secondary">
                                                {this.state.practice.about}
                                            </p>
                                        </div>
                                    }

                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">COVID-19 Tests</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{covid19TestsString !== "" ? covid19TestsString : "(Not available)"}</label>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0 font-weight-bold">Antibody Tests</label>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label htmlFor="" className="form-label m-0">{antibodyTestsString !== "" ? antibodyTestsString : "(Not available)"}</label>
                                    </div>
                                </div>

                                {/*TODO: Photos*/}
                                {/*<div className="form-row mb-3 mb-md-2">*/}
                                {/*    <div className="col-12 mt-4">*/}
                                {/*        <h4 className="form-label mb-3">Photos</h4>*/}
                                {/*        <div className="w-100 d-flex">*/}
                                {/*            <div*/}
                                {/*                className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                {/*            </div>*/}

                                {/*            <div*/}
                                {/*                className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                {/*            </div>*/}

                                {/*            <div*/}
                                {/*                className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                <LocationUpdatesViewFooter approved={this.state.approved} rejected={this.state.rejected}/>
            </>
        )
    }
}

export default LocationUpdatesView;