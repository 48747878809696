import parse from 'html-react-parser';
import React from 'react';
import { getMyLocations } from "../../../../helpers/accountAPIServices";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import {
    cancelRequest,
    changeRequest, changeSubscription, getTierSubscription
} from '../../../../helpers/subscriptionBillingServices';
import { getTiersListWithFeatures } from '../../../../helpers/tierAPIServices';
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";




class SubscriptionAndBillingsPage extends React.Component {

    state = {
        myLocations: {
            approved: [],
            rejected: [],
            pending_edit: [],
            pending_new: []
        },
        accessToken: getAccessTokenFromSession(),
        isLoading: false,
        cancel: false,
        change: false,
        errorMessage: '',
        errors: [],
        tiersData: [],
        currentTier: null,
        currentTierID: null,
        selectedTier: null,
        stripe_url: '',
        tier_code: null,
    }

    componentDidMount() {
        const { accessToken } = this.state;

        this.setLoading(true);

        /* Get Locations */
        getMyLocations(accessToken)
            .then((result1) => {
                const getLocationsResults = result1;

                this.setState({
                    myLocations: {
                        ...getLocationsResults,
                    },
                });

                /* Get IDs of Locations */
                const allID = [...getLocationsResults?.approved].map((location) => location?.practice_id);
                
                
                /* Get current Tier subscription */
                getTierSubscription(allID[0], accessToken)
                    .then((result2) => {
                        const getSubscriptionResult = result2;
                        const currentTier = getSubscriptionResult?.tier;
                        const tierCode = currentTier?.code;
                        const subscriptionID = getSubscriptionResult?.id;

                        /* UPDATED */
                        this.setState({
                            stripe_url: getSubscriptionResult?.stripe_billing_url,
                            selectedTier: currentTier,
                            currentTier,
                            currentTierID: subscriptionID,
                            tier_code: tierCode
                        });
                        
                    })
                    .catch((error) => {
                        if (error === "No subscription found.") {
                            this.setErrors([
                                'There is a problem getting your Subscription. Please, send a message to contact@urgentcare.com',
                            ]);
                            return;
                        }
                        else {
                            this.setErrors([
                                'There is a problem getting your Subscription. Please, send a message to contact@urgentcare.com',
                            ]);
                            this.setState({
                                errorMessage: 'There is a problem getting your Subscription. Please, send a message to contact@urgentcare.com',
                            });
                            return;
                        };
                    })
            })
            .catch((error) => {
                this.setErrors([
                    'There is a problem getting your locations. Please, send a message to contact@urgentcare.com',
                ]);
                this.setState({
                    errorMessage: 'There is a problem getting your locations. Please, send a message to contact@urgentcare.com',
                });
                return;
            })

        /* New Tier API integration */
        getTiersListWithFeatures(accessToken)
            .then((response) => {
                const tiersData = response?.result ?? [];
                this.setState({ tiersData });
                this.setLoading(false);
            })
            .catch((error) => {
                console.log("error", error)
                this.setLoading(false);
            })
    }

    setLoading = (status) => {
        this.setState({ isLoading: status });
    }

    setErrors = (errorList) => {
        this.setState({
            message: "",
            errors: errorList,
        });
    }

    didChangeTierOption = (selectedData = null) => {
        const selectedTier = selectedData;
        if (selectedTier) {
            this.setState({ selectedTier });
        }
    }

    handleCancelSubmit = () => {
        this.setLoading(true);

        const {
            currentTier,
            accessToken,
            currentTierID,
        } = this.state;

        /* Show error if no subscription yet */
        if (currentTier?.code === "BASIC") {
            this.setErrors([
                'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
            ]);

            this.setState({
                errorMessage: `You are not subscribed to any Plan, yet.`
            });

            this.setLoading(false);

            /* Dismiss alert message after N seconds */
            setTimeout(() => {
                this.setState({ errorMessage: '' })
            }, 5000);

            return;
        }
        else {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const userInfo = this.props.user;

            const myLocations = this.state.myLocations;
            const approvedLocations = myLocations?.approved ?? {};
            const pendingLocations = myLocations?.pending_edit ?? {};

            const allPractice = [
                ...approvedLocations,
                ...pendingLocations,
            ].map((location) => location?.practice_id).join(',');

            const allID = [...approvedLocations].map((location) => location?.practice_id);

            let message = [
                `A Location Admin, with listings as follows (${allPractice})`,
                `is requesting to cancel their membership.`,
            ].join(" ");


            let subscriptionChange = {
                current_subscription_id: currentTierID,
                requested_tier_id: 1,
            }

            changeSubscription(subscriptionChange, accessToken)
            .then((response) => {
                    this.setState({
                        errorMessage: '',
                        isLoading: false,
                        cancel: false,
                        change: true,
                    });
                    console.log(response)

                /* Dismiss alert message after N seconds */
                setTimeout(() => {
                    this.setState({
                        cancel: false,
                        change: false,
                    });
                }, 5000);
            })
            .catch((error) => {
                console.log("error", error)
                this.setErrors([
                    'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
                ]);
                this.setState({
                    errorMessage: 'There is a problem sending your request. Please, send a message to contact@urgentcare.com',
                });
                this.setLoading(false);
                return;
            })


            let payload = {
                first_name: userInfo?.user?.first_name,
                last_name: userInfo?.user?.last_name,
                email: userInfo?.user?.email,
                message: message,
                link: `${BASE_URL}/listings/${allID[0]}`,
            }

            /* API request to cancel subscription */
            cancelRequest(payload, accessToken)
                .then((response) => {
                    this.setState({
                        errorMessage: '',
                        isLoading: false,
                        cancel: true,
                        change: false,
                    });
                    
                    /* Dismiss alert message after N seconds */
                    setTimeout(() => {
                        this.setState({
                            cancel: false,
                            change: false,
                        });
                    }, 5000);
                })
                .catch((error) => {
                    console.log("error", error)
                    this.setErrors([
                        'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
                    ]);
                    this.setState({
                        errorMessage: 'There is a problem sending your request. Please, send a message to contact@urgentcare.com',
                    });
                    this.setLoading(false);
                    return;
                })
        }
    }
    
    handleChangePlanSubmit = () => {
        this.setLoading(true);

        const {
            currentTier,
            selectedTier,
            accessToken,
            currentTierID,
        } = this.state;
        console.log(selectedTier.id);
        if (selectedTier?.code === currentTier?.code) {
            this.setErrors([
                'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
            ]);

            this.setState({
                errorMessage: `Your are currently subscribed to '${currentTier?.name}' Plan. Please select a different plan to change to.`
            });

            this.setLoading(false);

            /* Dismiss alert message after N seconds */
            setTimeout(() => {
                this.setState({ errorMessage: '' })
            }, 5000);

            return;
        }
        else {
            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const userInfo = this.props.user;

            const myLocations = this.state.myLocations;
            const approvedLocations = myLocations?.approved ?? {};
            const pendingLocations = myLocations?.pending_edit ?? {};

            const allPractice = [
                ...approvedLocations,
                ...pendingLocations,
            ].map((location) => location?.practice_id).join(',');

            const allID = [...approvedLocations].map((location) => location?.practice_id);

            let message = [
                `A Location Admin, with listings as follows (${allPractice})`,
                `is requesting to change their membership from '${currentTier?.name}' to '${selectedTier?.name}'.`,
            ].join(" ");

            let subscriptionChange = {
                current_subscription_id: currentTierID,
                requested_tier_id: selectedTier?.id
            }

            /* API change plan subscription Update */
            changeSubscription(subscriptionChange, accessToken)
            .then((response) => {
                    this.setState({
                        errorMessage: '',
                        isLoading: false,
                        cancel: false,
                        change: true,
                    });
                    console.log(response)

                /* Dismiss alert message after N seconds */
                setTimeout(() => {
                    this.setState({
                        cancel: false,
                        change: false,
                    });
                }, 5000);
            })
            .catch((error) => {
                console.log("error", error)
                this.setErrors([
                    'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
                ]);
                this.setState({
                    errorMessage: 'There is a problem sending your request. Please, send a message to contact@urgentcare.com',
                });
                this.setLoading(false);
                return;
            })

            let payload = {
                first_name: userInfo?.user?.first_name,
                last_name: userInfo?.user?.last_name,
                email: userInfo?.user?.email,
                message: message,
                link: `${BASE_URL}/listings/${allID[0]}`,
            }
            
            /* API request for change subscription */
            changeRequest(payload, accessToken)
                .then((response) => {
                    if (selectedTier?.code === "BASIC") {
                        this.setState({
                            errorMessage: '',
                            isLoading: false,
                            cancel: true,
                            change: false,
                        });
                    }
                    else {
                        this.setState({
                            errorMessage: '',
                            isLoading: false,
                            cancel: false,
                            change: true,
                        });
                    }

                    /* Dismiss alert message after N seconds */
                    setTimeout(() => {
                        this.setState({
                            cancel: false,
                            change: false,
                        });
                    }, 5000);
                })
                .catch((error) => {
                    console.log("error", error)
                    this.setErrors([
                        'There is a problem sending your request. Please, send a message to contact@urgentcare.com'
                    ]);
                    this.setState({
                        errorMessage: 'There is a problem sending your request. Please, send a message to contact@urgentcare.com',
                    });
                    this.setLoading(false);
                    return;
                })

        }
    }
    
    render() {

        
        if (this.state.isLoading) return <LoadingOverlay />
        
        const myLocations = this.state.myLocations;
        const approvedLocations = myLocations?.approved ?? {};
        const pendingLocations = myLocations?.pending_edit ?? {};

        const allLocations = [
            ...approvedLocations,
            ...pendingLocations,
        ].map((location) => location?.practice_id).join(',');
        
        return (
            <div className="main-content">
                <div className="container p-3 p-md-4">
                    {
                        (this.state.errorMessage) &&
                        <div className="alert alert-success error-message mb-5" role="alert">
                            <i className="fa fa-exclamation-circle mr-2" />
                            {this.state.errorMessage}
                        </div>
                    }
                    {
                        (this.state.change) &&
                        <div className="alert alert-success rounded-40 mb-md-5 py-3 px-4 align-middle " role="alert">
                            <i className="fa fa-check-circle mr-2" />
                            Success! Your request to change plan has been submitted.
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                onClick={() => this.setState({ change: false })}>
                                <span >&times;</span>
                            </button>
                            <i className="fa fa-check-circle mr-2 invisible" />
                        </div>
                    }
                    {
                        (this.state.cancel) &&
                        <div className="alert alert-success rounded-40 mb-md-5 py-3 px-4 align-middle " role="alert">
                            <div className="cancel-alert">
                                <div>
                                    <i className="fa fa-check-circle mr-2" />
                                </div>
                                <div>
                                    Your request to cancel your paid subscription with UrgentCare.com was forwarded to your manager and you will receive an email notification when the cancellation is finalized.
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        onClick={() => this.setState({ cancel: false })}>
                                        <span >&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-lg-4 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">Membership Plans</h2>
                        </div>
                        {
                            (this.state.currentTier?.code !== "BASIC") &&
                            <div className="col-lg-8 px-0 mx-0 d-flex flex-column flex-lg-row justify-content-end">
                                <button
                                    className={`btn btn-outline btn-outline-orange px-3 mr-0 mr-lg-2 mb-2 mb-lg-0`}
                                    data-toggle="modal"
                                    data-target="#change_plan">
                                    <span className="d-sm-block">
                                        Change Plan
                                    </span>
                                </button>

                                <button
                                    className={`btn btn-outline btn-outline-orange px-3 mr-0 mr-lg-2 mb-2 mb-lg-0`}
                                    data-toggle="modal"
                                    data-target="#cancel_membership">
                                    {/* <!-- <i className="fas fa-plus mr-0 mr-sm-2"></i>  --> */}
                                    <span className=" d-sm-block">
                                        Cancel Membership
                                    </span>
                                </button>

                                <a
                                    href={this.state.stripe_url}
                                    className={`btn btn-outline btn-outline-orange px-3 mr-0 mr-lg-2 mb-2 mb-lg-0`}
                                    rel="noreferrer"
                                    target='_blank'>
                                    {/* <!-- <i className="fas fa-plus mr-0 mr-sm-2"></i>  --> */}
                                    <i className="far fa-eye mr-2 mr-sm-2" />
                                    <span className="d-sm-block">
                                        View Billing Info
                                    </span>
                                </a>
                            </div>
                        }
                    </div>

                    {/* <!--MAIN CONTENT END--> */}

                    <div className="d-flex flex-column flex-lg-row mt-5 overflow-auto overflow-x-auto py-3 px-2 border-light_blue rounded-4">
                        <TiersList
                            data={this.state.tiersData}
                            currentValue={this.state.currentTier}
                            currentUser={this.props.user}
                            locations={allLocations}
                        />
                    </div>
                    
                    {/* <!-- Change Plan Modal --> */}
                    <div className="modal fade" id="change_plan" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content overflow-hidden border-0">
                                <div className="modal-header">
                                    <div className="w-100">
                                        <div className="row">
                                            <div className="col-10">
                                                <h3 className="modal-title" id="exampleModalLabel">I want to change my plan to:</h3>
                                            </div>
                                            <div className="col-2">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <i className="fas fa-times-circle fa-xs" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-body p-0">
                                    <div className="w-100 p-5">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Tiers</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 mx-0 px-0">
                                                <div className="row mx-0 px-0">
                                                    <TierOptions
                                                        data={this.state.tiersData}
                                                        currentValue={this.state.currentTier}
                                                        selectedValue={this.state.selectedTier}
                                                        primaryAction={(selectedData) => this.didChangeTierOption(selectedData)}
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <button
                                                className="btn btn-solid btn-solid-orange w-100 mt-4"
                                                data-dismiss="modal"
                                                onClick={this.handleChangePlanSubmit}>
                                                Change Plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Cancel Membership modal */}
                    <div className="modal fade" id="cancel_membership" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content overflow-hidden border-0">
                                <div className="modal-body p-0">
                                    <div className="w-100 p-0">
                                        <img src="img/orange_bg.svg" className="w-100" alt="" />
                                        <div className="p-5">
                                            <h2 className="txt-color-dark_purple mb-4 text-center">Confirm</h2>
                                            <p className="txt-color-dark_purple mb-4 text-center">
                                                Are you sure you want to cancel your current paid subscription with Urgentcare.com?
                                            </p>
                                            
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-solid btn-solid-orange w-100 mt-4"
                                                    data-dismiss="modal"
                                                    onClick={this.handleCancelSubmit}
                                                    value='confirm'>
                                                    Confirm
                                                </button>
                                            </div>

                                            <div className="col-12">
                                                <button className="btn btn-outline btn-outline-purple w-100 mt-2" data-dismiss="modal">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


function TiersList(props) {
    const data = props?.data ?? [];
    const currentUser = props.currentUser;
    const locations = props.locations;
    const currentValue = props?.currentValue ?? null;
    const selectedValue = props?.selectedValue ?? null;
    // eslint-disable-next-line no-unused-vars
    const primaryAction = props?.primaryAction ?? function () { };

    /* Convert Tier Codes to uppercase */
    let currentTier = `${currentValue?.code}`.toUpperCase();
    let selectedTier = `${selectedValue?.code}`.toUpperCase();

    console.log(selectedTier);
    
    const getPaymentMode = (item) => {
        let paymentMode = null;
        
        if (item?.code === "BASIC") {
            return "Free";
        }
        else if (item?.code === "CUSTOM") {
            return "Custom Pricing";
        }
        else {
            switch (item?.payment_mode) {
                case "monthly":
                    paymentMode = "month";
                    break;
                case "annually":
                    paymentMode = "year";
                    break;
                case "daily":
                    paymentMode = "day";
                    break;
                default:
            }
        }
        
        return `$${item?.price} / ${paymentMode}`;
    }

    if (!data) return null;

    var layout = [];
    
    for (var i in data) {
        let tier = data[i];
        let tierName = tier?.name;
        let tierCode = `${tier?.code}`.toUpperCase();
        let tierHeaderColor = tier?.header_color ?? "";

        
        /* Create Tier Features layout */
        let tierFeatures = tier?.features;
        let featuresList = [];
        for (var j in tierFeatures) {
            let feature = tierFeatures[j];
            featuresList.push(
                <li className="txt-color-secondary mb-3">
                    {parse(feature?.description)}
                </li>
            );
        }

        /* Modify external subscription link */
        let tierExternalLink = tier?.external_link;
        if (tierExternalLink) {
            var _tierExternalLink_ = tierExternalLink;
            if (currentUser?.id) _tierExternalLink_ += `/?account_id=${currentUser?.id}`;
            if (locations?.length > 0) _tierExternalLink_ += `&locations=${locations}`;
            tierExternalLink = _tierExternalLink_;
        }

        /* Stringify Tier Price */
        let displayedTierPrice = getPaymentMode(tier);

        let isCurrent = (currentTier === tierCode);

        layout.push(
            <div className="col-lg-5 d-flex flex-column mr-0 mb-5 mb-lg-0 w-100">
                <div className="border-light_blue rounded-4 h-100 d-flex flex-column">
                    {
                        isCurrent &&
                        <h2
                            className={"h5 font-weight-bold position-absolute text-white mt-5 top-50 start-50"}
                            style={{ "left": "50%", "transform": "translate(-50%, -50%)" }}>
                            Current Plan
                        </h2>
                    }
                    
                    <TierHeaderColor hexColor={tierHeaderColor} />

                    <div className="w-100 p-4">
                        <center>
                            <h3 className="h6 font-weight-normal text-center txt-color-dark_purple m-0">{tierName}</h3>
                            <span className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">
                                {displayedTierPrice}
                            </span>
                        </center>

                        <div className="w-100 py-4">
                            <ul className="basic-list">
                                {featuresList}
                            </ul>
                        </div>
                    </div>

                    {
                        /* Show only for non-BASIC Tiers */
                        /* Show only for unsubscribed Users */
                        (tierCode !== "BASIC" && currentTier === "BASIC") &&
                        <div className="mx-3 mt-auto mb-3">
                            <a href={tierExternalLink}>
                                <button
                                    className={"btn btn-outline btn-outline-purple w-100"}
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    data-target="#terms">
                                    Subscribe
                                </button>
                            </a>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return layout
}


function TierHeaderColor({ hexColor = "" }) {
    /* 
    On SVG files, change these:
        sketch:type
        xmlns:xlink
        xlink:href
    Into these:
        sketchType
        xmlnsXlink
        xlinkHref
    */
    let fillColor = (hexColor.length >= 3 && !hexColor.includes("#")) ? `#${hexColor}` : hexColor;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="570px"
            height="232px"
            viewBox="0 0 570 232"
            version="1.1"
            className="img-fluid w-100 mb-3">
            <title>tier_color</title>
            <defs>
                <path d="M0,0 C101.370443,0 198.493924,0 291.370443,0 C384.246962,0 477.123481,0 570,0 L570,150.563151 C496.260579,144.742762 407.880853,157.340278 304.860821,188.355698 C201.840789,219.371118 100.220515,233.855922 0,231.810109 L0,0 Z" id="path-1"></path>
            </defs>
            <g id="Location-Admin" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="green_bg">
                    <g
                        id="Mask"
                        fill={fillColor}
                        fill-rule="nonzero">
                        <path d="M0,0 C101.370443,0 198.493924,0 291.370443,0 C384.246962,0 477.123481,0 570,0 L570,150.563151 C496.260579,144.742762 407.880853,157.340278 304.860821,188.355698 C201.840789,219.371118 100.220515,233.855922 0,231.810109 L0,0 Z" id="path-1"></path>
                    </g>
                    <g id="Group-3-Clipped">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="path-1"></g>
                        <g id="Group-3" mask="url(#mask-2)" fill-opacity="0.238964161">
                            <g transform="translate(-31.000000, 125.000000)" id="Fill-1">
                                <path d="M0,118 L302,118 C294.238662,64.8215937 243.176529,50.035572 196.097975,69.1484335 C173.128438,78.4739682 155.742465,76.805036 143.422634,55.3265037 C103.823136,-13.7125454 39.6046724,-9.80814678 0,21.8824682 L0,118 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}


function TierOptions(props) {
    const data = props?.data ?? [];
    const currentValue = props?.currentValue ?? null;
    const selectedValue = props?.selectedValue ?? null;
    const primaryAction = props?.primaryAction ?? function () { };

    let currentTier = `${currentValue?.code}`.toUpperCase();
    let selectedTier = `${selectedValue?.code}`.toUpperCase();
    
    if (!data) return null;

    var layout = [];

    for (var i in data) {

        let tier = data[i];
        let tierName = tier?.name;
        let tierCode = `${tier?.code}`.toUpperCase();
        let tierPrice = tier?.price;
        

        let isCurrent = (currentTier === tierCode);
        let isSelected = (selectedTier === tierCode);

        if (tierCode === "BASIC") {
            tierPrice = "(Free)";
        }
        else if (tierCode === "CUSTOM") {
            tierPrice = "";
        }
        else {
            tierPrice = `($${tierPrice})`;
        }
        
        layout.push(
            <div className="col-12 mr-4 mb-3 pl-1">
                <input
                    onClick={() => primaryAction(tier)}
                    value={tierCode}
                    className="form-check-input form-control-input-radio"
                    type="radio"
                    name="tiersOption"
                    id={`tiers-option-${i}`}
                    checked={isSelected}
                />
                <label
                    for={`tiers-option-${i}`}
                    className="form-check-label form-control-input-radio-label form-label">
                    {`${tierName} ${tierPrice}`}
                    <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" data-placement="right">
                        <i className="fas fa-info-circle txt-color-purple mr-2" />
                        {isCurrent && "Current"}
                    </span>
                </label>
            </div>
        );
    }

    return layout;
}


export default SubscriptionAndBillingsPage;