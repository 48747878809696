import dateFormat from 'dateformat';
import React, { useCallback, useEffect, useState } from 'react';
import {
	getAccessTokenFromSession
} from "../../../helpers/sessionHelpers";
import {
	getSubscriptionUpdates,
	setSubscriptionUpdateStatus
} from "../../../helpers/subscriptionBillingServices";
import {
	adjustRangeOffset,
	range
} from "../../../utils/Utils.js";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import { getAllRequestsForSuperadmin } from '../../../helpers/accountAPIServices';

const MembershipUpdatesPageNew = () => {

  const [search, setSearch] = useState('');
	const [submitSearch, setSubmitSearch] = useState('');
  const [updates, setUpdates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(7);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
        async function getRequest() {
            const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
            if (getRequestResults?.error) {
                console.log("API ERROR: " + getRequestResults?.error.response.statusText);

                if (getRequestResults?.error.response.status === 401) {
                    localStorage.clear();
                    window.location.reload(true);
                }
                this.setState({
                    error: "There is an error fetching all the requests.",
                });
                this.setLoading(false);
                return;
            }
        }

        getRequest();
  });

  useEffect(() => {

    async function loadSubscriptionUpdates() {
      setLoading(true);

    const getUpdates = await getSubscriptionUpdates(page, limit, submitSearch, getAccessTokenFromSession());
    console.log(getUpdates.pagination);

    if (getUpdates.error) {
      console.log("Error Getting Updates");
      setLoading(false)
      return;
    }
    setUpdates(getUpdates.result.data);
    setPages(getUpdates.pagination.num_pages);
    setLoading(false);

    }
    
    loadSubscriptionUpdates();

  }, [limit, page, submitSearch]);


  const setUpdateStatus = (updateStatus, id) => {

		console.log(updateStatus);
		console.log(id);
    async function loadUpdateStatus() {
      const setUpdate = await setSubscriptionUpdateStatus(updateStatus, id, getAccessTokenFromSession())
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log("error", error);
        })
      console.log(setUpdate);
    }
    loadUpdateStatus();
			// window.location.reload(false);
	}

	const onChangePage = (e) => {
		setPage(e);
		console.log(e);
	}


	const handleLimitChange = (e) => {
		setLoading(true);
		setLimit(e.target.value);
		setLoading(false);
		console.log(e.target.value);
	}

	const handleSearchChange = (e) => {
		// if (e.target.value === '') {
		// 	setSearch(e.target.value)
		// 	setSubmitSearch(search)
		// }
		// else {
		// 	setSearch(e.target.value);
		// }
		setSearch(e.target.value);
    console.log(e.target.value);
  };

	const handleSearchSubmit = e => {
		if (e.key === "Enter") {
			setSubmitSearch(search)
			console.log(search)
		}
	}


  if (isLoading) { return <LoadingOverlay> </LoadingOverlay> }
  else {
    return (
      <>
        {/* <!--MAIN CONTENT START--> */}
        <div className="main-content">
          <div className="container p-3 p-md-5">
            <div className="row mb-4 p-0 mx-0">
              <div className="col-6 px-0 mx-0">
                <h2 className="h1 txt-color-dark_purple font-weight-bold">Membership Updates</h2>
              </div>
              {/* <!--<div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
						<a href="location_listings_add_new_location.html" className="btn btn-solid btn-solid-orange px-3">
							<i className="fas fa-plus mr-0 mr-sm-2"></i>
							<span className="d-none d-sm-block">
								New Location
							</span>
						</a>
					</div>--> */}
            </div>

            <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0">
              <div className="col-12">
                <div className="d-table w-100 ">
                  <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Membership Updates</h3>
                </div>
              </div>

              <div className="col-12 mt-2">

                <div className="w-100">
                  <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="top">
                      <div className="row mb-4">
                        {/* <!-- SHOW NUMBER OF ENTRIES IN LIST --> */}
                        <div className="col-12 col-md-6 show-entries">
                          <div className="dataTables_length" id="DataTables_Table_0_length"><label>Show
                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" value={limit} onChange={handleLimitChange}>
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select> entries</label></div>
                        </div>

                        {/* <!-- SEARCH FROM LIST --> */}
                        <div className="col-12 col-md-6 search-input">
                          <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>Search:<input type="search" className="form-control form-control-sm" placeholder="Search"
                            // aria-controls="DataTables_Table_0"
														onKeyDown={handleSearchSubmit}
														value={search}
														onChange={handleSearchChange}
													/></label></div>
                        </div>

                      </div>
                    </div>
                    <div className="bottom"></div>
                    <div className="clear"></div>
                  </div>

                  <table className="table table-custom display dt-responsive nowrap w-100">
                    <thead className="thead-light rounded-10">
                      <tr>
                        <th>Practice ID</th>
                        <th>Name</th>
                        <th>Current Membership</th>
                        <th>Request</th>
                        <th>Date</th>
                        <th>Completed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <UpdatesList
                        data={updates}
                        setUpdateStatus={(updateStatus, id) => setUpdateStatus(updateStatus, id)}
                        dateFormat={(date) => dateFormat(date, "d mmm, yyyy")}
                      />
                    </tbody>
                  </table>
                </div>

                <Paginator
                  currentPage={Number(page)}
                  pageMax={Number(pages)}
                  paginateToPageAction={(val) => onChangePage(val)}
                />


              </div>
            </div>
          </div>
        </div>
        {/* <!--MAIN CONTENT END--> */}
      </>
    )
  }
}

export default MembershipUpdatesPageNew

function UpdatesList(props) {
	const data = props?.data ?? [];
	console.log("Data >> " + data.length);
	// const setUpdateStatus = props?.setUpdateStatus ?? function () { };
	const dateFormat = props?.dateFormat ?? function () { };
	var layout = [];

	if (data.length === 0){
		layout.push(
			<tr>
				<td colSpan={6} style={{textAlign: "center"}}>No Available Data</td>
			</tr>
		);
	}

	for (var i in data) {
		let update = data[i];
		// let updateID = update?.id;
		let practiceID = update?.current_subscription.practice.practice_id;
		let practiceName = update.current_subscription.practice.name;
		let currentTier = update.current_subscription.tier.name;
		let requestedTier = update.requested_tier.name;
		let link = `/listings/${practiceID}`;
		// let checked = update.is_completed;
		let date = update.created_at;
		// let unChecked = !checked;
		let formatDate = dateFormat(date);


		// let updateStatus = {
    //   is_completed: unChecked,
		// };

		// console.log(updateID);
		// console.log(update.id);
		// console.log(formatDate);

		layout.push(
			<tr>
			<td>{practiceID}</td>
				<td><a href={link}>{practiceName}</a></td>
			<td>{currentTier}</td>
			<td>{requestedTier}</td>
				<td>{formatDate}</td>
			<td>
					{/* <input type="checkbox" id={`completed_${updateID}`} name="customRadioInline1" className="form-control-input-checkbox"
					checked={checked}
					onClick={() => setUpdateStatus( updateStatus, updateID )}
					/>
				<label className="form-control-input-checkbox-label form-label m-0" for={`completed_${updateID}`}></label> */}
			</td>
		</tr>
		);
	}

	return layout;
}



function Paginator({
	currentPage = 1,
	pageMax = 0,
	limit = 7,
	paginateToPageAction = function () { },
	containerLayout = function () { return null },
	itemsLayout = function () { return null },
}) {


	const initPageArray = useCallback(() => {

			let r;
			if (Number(pageMax) < Number(limit)) {

					r = range(pageMax, 1);
			}
			else {

					r = range(limit, 1);
			}
			return r;
	}, [limit, pageMax]);

	const [currentPageArray, setCurrentPageArray] = useState(initPageArray());
	const [, setPrevMin] = useState(1);
	const [prevMax, setPrevMax] = useState(limit);


	// const [numOfPages, setNumOfPages] = useState(pageMax);
	// const [limitPerSet, setLimitPerSet] = useState(limit);


	useEffect(() => {
			setCurrentPageArray(initPageArray());
			setPrevMin(1);
	}, [pageMax, initPageArray])

	const paginateLeftAction = () => {
			let { newRange, min, max } = adjustRangeOffset(currentPageArray, -(Number(limit)));
			setCurrentPageArray(newRange);
			setPrevMin(min);
			setPrevMax(max);
	}

	const paginateRightAction = () => {
			let { newRange, min, max } = adjustRangeOffset(currentPageArray, limit);

			if (Number(pageMax) < Number(max)) {
					if (Number(prevMax) >= Number(pageMax)) return;

					let finalRange = [...newRange];
					let finalMax = Number(finalRange.pop())
					let finalMin = Number(prevMax) + 1;

					setCurrentPageArray(newRange);
					setPrevMin(finalMin);
					setPrevMax(finalMax);
			}
			else {
					setCurrentPageArray(newRange);
					setPrevMin(min);
					setPrevMax(max);
			}
	}

	const PaginatorItems = () => {
			let items = [];
			for (let i in currentPageArray) {
					let num = Number(currentPageArray[i]);
					let showPage = (Number(pageMax) >= num);
					let _currentPage_ = Number(currentPage);
					let isActive = (_currentPage_ === num);

					items.push(
							<li key={`PaginatorItems-${i}`} className={`mx-0 page-item text-center ${(isActive) ? 'active' : ''}`} style={{ width: "50px" }}>
									{
								showPage &&
								// eslint-disable-next-line
											<a href='javascript:void(0);'
													className="page-link txt-color-purple m-0"
													onClick={() => (!isActive) ? paginateToPageAction(num) : false}>
													{num}
											</a>
									}
							</li>
					)
			}
			return items;
	}

	const PaginatorContainer = () => {
			return (
					<nav aria-label="Page navigation example">
							<ul className="pagination justify-content-end">
									<li className="page-item text-center">
											<button className="page-link txt-color-purple" onClick={() => paginateLeftAction()}><i className="fa fa-angle-double-left" /></button>
									</li>
									<PaginatorItems />
									<li className="page-item text-center">
											<button className="page-link txt-color-purple" onClick={() => paginateRightAction()}><i className="fa fa-angle-double-right" /></button>
									</li>
							</ul>
					</nav>
			)
	}

	return <PaginatorContainer />
}

