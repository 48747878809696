/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';

const MembershipPlansPage = () => {

	const [isBasic, ] = useState(false);
	const [isTier1, ] = useState(true);
	const [isTier2, ] = useState(false);
	const [isCustom,] = useState(false);




  return (
    <>
      <div className="main-content">
			<div className="container p-3">
				<div className="row mb-4 p-0 mx-0">
                    <div className="col-lg-4 px-0 mx-0">
                        <h2 className="h1 txt-color-dark_purple font-weight-bold">Membership Plans</h2>
                    </div>
                    <div className="col-lg-8 px-0 mx-0 d-flex flex-column flex-lg-row justify-content-end">
                        <a href="void(0);" onClick={(e)=>{e.preventDefault()}} className="btn btn-outline btn-outline-orange px-3 mr-0 mr-lg-2 mb-2 mb-lg-0"  data-toggle="modal" data-target="#change_plan">
                            {/* <!-- <i className="fas fa-plus mr-0 mr-sm-2"></i>  --> */}
                            <span className="d-sm-block">
                                Change Plan
                            </span>
                        </a>
                        <a href="void(0);" onClick={(e)=>{e.preventDefault()}} className="btn btn-outline btn-outline-orange px-3 mr-0 mr-lg-2 mb-2 mb-lg-0">
                            {/* <!-- <i className="fas fa-plus mr-0 mr-sm-2"></i>  --> */}
                            <span className=" d-sm-block">
                                Cancel Membership
                            </span>
                        </a>
                        <a href="subscription_view_billing_info.html" className="btn btn-outline btn-outline-orange px-3 mb-2 mb-lg-0">
                            {/* <!-- <i className="fas fa-plus mr-0 mr-sm-2"></i>  --> */}
                            <i className="far fa-eye mr-2 mr-sm-2"></i>
                            <span className=" d-sm-block">
                                View Billing Info
                            </span>
                        </a>
                        
                    </div>
                </div>

				<div className="d-flex flex-column mt-5 flex-lg-row ">

                        {/* <!-- Basic --> */}
                        <div className="d-flex flex-column mr-0 mr-lg-3 mb-5 mb-lg-0 w-100 ">
                            <div className="mx-auto text-center mb-n4 position-relative ">
								<div className={`badge badge-dark p-3 rounded-100 bg-color-dark_purple ${!isBasic && `invisible`}`}>Current Plan</div>
                            </div>
                            <div className="border-light_blue rounded-4 h-100 d-flex flex-column">
                                <img src="img/orange_bg.svg" alt="" className="img-fluid w-100 mb-3"/>
                                <div className="w-100 p-4 ">
                                    <center>
                                        <h2 className="h6 font-weight-normal text-center txt-color-dark_purple m-0">Basic</h2>
                                        <span className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">Free</span>
                                    </center>

                                    <div className="w-100 py-4">
                                        <ul className="basic-list">
                                            <li className="txt-color-secondary mb-3"> Featured Listing on UrgentCare.com htmlFor consumers within 10 miles radius of your location</li>
                                            <li className="txt-color-secondary mb-3"> Featured tweets on our Twitter page: <a href="Twitter.com/UrgentCare" target="_blank" className="txt-color-orange">Twitter.com/UrgentCare</a></li>
                                            <li className="txt-color-secondary mb-3"> Dedicated PPC campaigns htmlFor individual centers that we Manage</li>
                                            <li className="txt-color-secondary mb-3"> Inclusion in our quarterly Hello Newsletter to the primary care physicians within a 10 mile radius of your center</li>
                                            <li className="txt-color-secondary mb-3"> Supply chain liaison htmlFor antibody and COVID testing</li>
                                            <li className="txt-color-secondary mb-3"> Inclusion on Antibodytesting.com as a Covid and Antibody testing site</li>
                                            <li className="txt-color-secondary mb-3"> Featured tweets on our Twitter page:<br/><a href="Twitter.com/AntibodyTesting" target="_blank" className="txt-color-orange">Twitter.com/AntibodyTesting</a></li>
                                        </ul>

                                    </div>

                                </div>
                                <div className="mx-3 mt-auto mb-3">
                                    <button className=" btn btn-outline btn-outline-purple w-100 invisible" data-dismiss="modal" data-toggle="modal" data-target="#terms">Select Plan</button>
                                </div>
                            </div>
                            
                        </div>
                    
                        {/* <!-- Tier 1 --> */}
                        <div className="d-flex flex-column mr-0 mr-lg-3 mb-5 mb-lg-0  w-100">
                            <div className="mx-auto text-center mb-n4 position-relative ">
                                <div className={`badge badge-dark p-3 rounded-100 bg-color-dark_purple ${!isTier1 && `invisible`}`}>Current Plan</div>
                            </div>
                            <div className="border-light_blue rounded-4 h-100 d-flex flex-column">
                                <img src="img/green_bg.svg" alt="" className="img-fluid w-100 mb-3"/>
                                <div className="w-100 p-4 ">
                                    <center>
                                        <h3 className="h6 font-weight-normal text-center txt-color-dark_purple m-0">Tier One</h3>
                                        <span className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">$1000.00
                                            / month</span>
                                    </center>

                                    <div className="w-100 py-4">
                                        <ul className="basic-list">
                                            <li className="txt-color-secondary mb-3"> Featured Listing on UrgentCare.com htmlFor
                                                consumers within 10 miles radius of your location</li>
                                            <li className="txt-color-secondary mb-3"> Featured tweets on our Twitter page: <a href="Twitter.com/UrgentCare" target="_blank" className="txt-color-orange">Twitter.com/UrgentCare</a></li>
                                            <li className="txt-color-secondary mb-3"> Dedicated PPC campaigns htmlFor individual centers that we Manage</li>
                                            <li className="txt-color-secondary mb-3"> Inclusion in our quarterly Hello
                                                Newsletter to the primary care physicians within a 10 mile radius of your
                                                center</li>
                                            <li className="txt-color-secondary mb-3"> Supply chain liaison htmlFor antibody and
                                                COVID testing</li>
                                            <li className="txt-color-secondary mb-3"> Inclusion on Antibodytesting.com as a
                                                Covid and Antibody testing site</li>
                                            <li className="txt-color-secondary mb-3"> Featured tweets on our Twitter page:<br/><a href="Twitter.com/AntibodyTesting" target="_blank" className="txt-color-orange">Twitter.com/AntibodyTesting</a></li>
                                        </ul>

                                    </div>

                                </div>
                                <div className="mx-3 mt-auto mb-3">
                                    <button className=" btn btn-outline btn-outline-purple w-100" data-dismiss="modal" data-toggle="modal" data-target="#terms">Select Plan</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* <!-- Tier 2 --> */}
                        <div className="d-flex flex-column mr-0 mr-lg-3 mb-5 mb-lg-0  w-100">
                            <div className="mx-auto text-center mb-n4 position-relative ">
                                <div className={`badge badge-dark p-3 rounded-100 bg-color-dark_purple ${!isTier2 && `invisible`}`}>Current Plan</div>
                            </div>
                            <div className="border-light_blue rounded-4 h-100 d-flex flex-column">
                                <img src="img/purple_bg.svg" alt="" className="img-fluid w-100 mb-3"/>
                                <div className="w-100 p-4 ">
                                    <center>
                                        <h3 className="h6 font-weight-normal text-center txt-color-dark_purple m-0">Tier Two</h3>
                                        <span className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">$500.00
										/ month</span>
                                    </center>

                                    <div className="w-100 py-4">
                                        <ul className="basic-list">
										<li className="txt-color-secondary mb-3"> Featured Listing on UrgentCare.com htmlFor
											consumers within 10 miles radius of your location</li>
										<li className="txt-color-secondary mb-3"> Dedicated PPC campaigns htmlFor individual
											centers that we will manage</li>
										<li className="txt-color-secondary mb-3"> Supply chain liaison htmlFor antibody and
											COVID testing</li>
										<li className="txt-color-secondary mb-3"> Inclusion on Antibodytesting.com as a
											Covid and Antibody testing site</li>
                                            
									</ul>

                                    </div>

                                </div>
                                <div className="mx-3 mt-auto mb-3">
                                    <button className=" btn btn-outline btn-outline-purple w-100" data-dismiss="modal" data-toggle="modal" data-target="#terms">Select Plan</button>
                                </div>
                            </div>
                            
                        </div>
                        {/* <!-- Custom --> */}
                        <div className="d-flex flex-column mr-0 mb-5 mb-lg-0  w-100">
                            <div className="mx-auto text-center mb-n4 position-relative ">
                                <div className={`badge badge-dark p-3 rounded-100 bg-color-dark_purple ${!isCustom && `invisible`}`}>Current Plan</div>
                            </div>
                            <div className="border-light_blue rounded-4 h-100 d-flex flex-column">
                                <img src="img/pink_bg.svg" alt="" className="img-fluid w-100 mb-3"/>
                                <div className="w-100 p-4 ">
                                    <center>
									   <h2 className="h6 font-weight-normal text-center txt-color-dark_purple m-0">Custom Organizational Plan</h2>
									   <span className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">Custom Pricing</span>
								    </center>

                                    <div className="w-100 py-4">
                                        <ul className="basic-list">
                                            <li className="txt-color-secondary mb-3"> If you're looking htmlFor a marketing partnership that is unique and doesn't exactly fit into one of the other tiers, please select this option. </li>
                                            <li className="txt-color-secondary mb-3"> Marketing may include multiple urgent care locations </li>
                                            <li className="txt-color-secondary mb-3"> Marketing may be supported by custom development and designs</li>
                                            <li className="txt-color-secondary mb-3"> Custom pricing may include discounts htmlFor multiple locations</li>
                                            <li className="txt-color-secondary mb-3"> Marketing may include unique opportunities and channels outside of traditional PPC and social campaigns</li>
                                            
                                        </ul>

                                    </div>

                                </div>
                                <div className="mx-3 mt-auto mb-3">
                                    <button className=" btn btn-outline btn-outline-purple w-100" data-dismiss="modal" data-toggle="modal" data-target="#terms">Select Plan</button>
                                </div>
                            </div>

                        </div>
				    </div>


			</div>
		</div>
		{/* <!--MAIN CONTENT END--> */}

		{/* <!-- Change Plan Modal --> */}
		<div className="modal fade" id="change_plan" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content overflow-hidden border-0">
					<div className="modal-header">
						<div className="w-100">
							<div className="row">
								<div className="col-10">
									<h3 className="modal-title" id="exampleModalLabel">I want to change my plan to:</h3>
	
								</div>
								<div className="col-2">
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<i className="fas fa-times-circle fa-xs"></i>
									</button>
								</div>
							</div>
						</div>
	
					</div>
					<div className="modal-body p-0">
						<div className="w-100 p-5">
							<div className="form-row mb-2">
								<div className="col-12">
									<h4 className="form-head-label txt-color-orange">Tiers</h4>
								</div>
							</div>
							<div className="form-row mb-3 mb-md-2">
								<div className="col-12 mx-0 px-0">
								   <div className="row mx-0 px-0">
										<div className="col-12 mr-4 mb-3 pl-1">
											<input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-1" checked/>
											<label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-1">
												Basic ($Free) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple mr-2" ></i></span> Current
											</label>

										</div>
										<div className="col-12 mr-4 mb-3 pl-1">
											<input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-2"/>
											<label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-2">
												Tier 1 ($1,000.00) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple" ></i></span>
											</label>
										</div>
									   <div className="col-12 mr-4 mb-3 pl-1">
											<input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-3"/>
											<label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-3">
												Tier 2  ($500.00) <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple" ></i></span>
											</label>
										</div>
									   <div className="col-12 mr-4 mb-3 pl-1">
											<input className="form-check-input form-control-input-radio" type="radio" name="tiersOption" id="tiers-option-4"/>
											<label className="form-check-label form-control-input-radio-label form-label" htmlFor="tiers-option-4">
												Custom <span className="d-inline-block ml-2" tabIndex="0" data-toggle="tooltip" title="Lorem Ipsum" data-placement="right"><i className="fas fa-info-circle txt-color-purple" ></i></span>
											</label>
                                          </div>
                                      </div>
								</div>
							</div>

							{/* <!-- <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table" data-dismiss="modal">Got It</button> --> */}
							<div className="col-12">
								<button className="btn btn-solid btn-solid-orange w-100 mt-4" data-dismiss="modal">Change Plan</button>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
    </>
  );
};

export default MembershipPlansPage;
