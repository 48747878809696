import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getAllCampaigns } from "../../../helpers/campaignAPIServices";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import { getAllRequestsForSuperadmin } from '../../../helpers/accountAPIServices';

const MarketingCampaignsPage = (props) => {
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    // const [searchResults, setSearchResults] = useState([]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        async function getRequest() {
            const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
            if (getRequestResults?.error) {
                console.log("API ERROR: " + getRequestResults?.error.response.statusText);

                if (getRequestResults?.error.response.status === 401) {
                    localStorage.clear();
                    window.location.reload(true);
                }
                this.setState({
                    error: "There is an error fetching all the requests.",
                });
                this.setLoading(false);
                return;
            }
        }

        getRequest();
  });

    useEffect(() => {
        // fetch all campaigns here
        async function loadCampaigns() {
            setIsLoading(true);
            const getCampaignsResults = await getAllCampaigns(getAccessTokenFromSession());
            if (getCampaignsResults.error) {
                console.log('Error in fetching results: ' + getCampaignsResults.error);
                return;
            }
            setCampaigns(getCampaignsResults);
            setIsLoading(false);
        }

        loadCampaigns();

        // setSearchResults([])
        // campaigns.filter(val=>{
        //     if(val.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())){
        //         setSearchResults(searchResults=>[...searchResults,val])
        //     }
        // })

    }, []);

    return (
        isLoading ? <LoadingOverlay/> :
            <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">Marketing Campaigns</h2>
                        </div>
                        <div className="col-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                            <div className="dropdown">
                                <a className="btn dropdown-toggle btn btn-solid btn-solid-orange px-3" href="void(0);" onClick={(e) => e.preventDefault()} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-plus mr-0 mr-sm-2"></i>
                                    <span className="d-none d-sm-block">
                                    New Campaign
                                </span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <span className="p-2 p-md-3"><small>Select Campaign Type</small></span>
                                    <Link className="dropdown-item" to="/campaigns/add/individual" data-toggle="modal"
                                          data-target="#select-location">Individual Plan </Link>
                                    <Link to='/campaigns/add/organization' className="dropdown-item" href="#">Organization
                                        Plan</Link>
                                    <Link to='/campaigns/add/geolocation' className="dropdown-item" style={{}} href="#">Geolocation Plan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 border-light_blue rounded-4 p-3 p-md-5 mx-0 flex">
                        <div className="col-12">
                            <div className="d-table w-100 ">
                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Current
                                    Marketing Campaigns</h3>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <div className="w-100">

                                <div id="DataTables_Table_0_wrapper"
                                     className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="top">
                                        <div className="row mb-4">
                                            {/* <div className="col-12 col-md-6 show-entries"> */}
                                                {/*<div className="dataTables_length" id="DataTables_Table_0_length">*/}
                                                {/*    <label>Show <select name="DataTables_Table_0_length"*/}
                                                {/*                        aria-controls="DataTables_Table_0"*/}
                                                {/*                        className="custom-select custom-select-sm form-control form-control-sm">*/}
                                                {/*        <option value="10">10</option>*/}
                                                {/*        <option value="25">25</option>*/}
                                                {/*        <option value="50">50</option>*/}
                                                {/*        <option value="50">59</option>*/}
                                                {/*        <option value="100">100</option>*/}
                                                {/*    </select> entries</label></div>*/}
                                            {/* </div> */}

                                            <div className="col-12 col-md-6 search-input">
                                                <div id="DataTables_Table_0_filter" className="dataTables_filter  search-box-left" >
                                                    <label>
                                                        Search:
                                                        <input
                                                            type="search"
                                                            className="form-control form-control-sm"
                                                            placeholder="Search"
                                                            aria-controls="DataTables_Table_0"
                                                            onChange={handleChange}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bottom"></div>
                                    <div className="clear"></div>
                                </div>

                                <div className="table-responsive">
                                <table className="table table-custom display nowrap overflow-auto w-100">
                                    <thead className="thead-light rounded-10">
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>Campaign Name</th>
                                        <th>Campaign Type</th>
                                        <th>URL</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        campaigns.filter((campaign) => {
                                            const url = `https://www.urgentcare.com/campaigns/${campaign.campaign_url}`;
                                            const ids = campaign.locations.map(location => {
                                                return location.practice_id;
                                            }).join(', ');
                                            const locations = campaign.locations.map(location => {
                                                return location.city;
                                            }).join(', ');
                                            if (searchTerm === "") {
                                                return campaign
                                            } else if (campaign.campaign_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return campaign
                                            } else if (url.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return campaign
                                            } else if (ids.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return campaign
                                            } else if (locations.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                return campaign
                                            }
                                            else { return campaign }

                                        }).map((campaign) => {
                                            const url = `https://www.urgentcare.com/campaigns/${campaign.campaign_url}`;
                                            const ids = campaign.locations.map(location => {
                                                return location.practice_id;
                                            }).join(', ');
                                            const locations = campaign.locations.map(location => {
                                                return location.city;
                                            }).join(', ');
                                            console.log("Locations >> " + locations);
                                            let campaignType = '';
                                            switch(campaign.campaign.code) {
                                                case 'INDIVIDUAL_PLAN':
                                                    campaignType = "Individual";
                                                    break;
                                                case 'GEOLOCATION_PLAN':
                                                    campaignType = "Geolocation";
                                                    break;
                                                case 'ORGANIZATION_PLAN':
                                                    campaignType = "Organization";
                                                    break;
                                                default: 
                                            }
                                            return (
                                                <tr>
                                                    <td></td>
                                                    <td>{ids}</td>
                                                    <td>{campaign.campaign_name}</td>
                                                    <td>{campaignType}</td>
                                                    <td><a href={url} target='_blank' rel='noreferrer' className="text-decoration-none d-table">Go to this campaign</a>
                                                    </td>

                                                    {
                                                        campaign.campaign.code === "GEOLOCATION_PLAN" &&
                                                        <td><Link to={`/campaigns/edit/geolocation/${campaign.code}`}
                                                                  className="text-decoration-none">Edit</Link></td>
                                                    }

                                                    {
                                                        campaign.campaign.code === "INDIVIDUAL_PLAN" &&
                                                        <td><Link to={`/campaigns/edit/individual/${campaign.code}`}
                                                                  className="text-decoration-none">Edit</Link></td>
                                                    }
                                                    {
                                                        campaign.campaign.code === "ORGANIZATION_PLAN" &&
                                                        <td><Link to={`/campaigns/edit/organization/${campaign.code}`}
                                                                  className="text-decoration-none">Edit</Link></td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                    </table>
                                    </div>
                            </div>
                            {/*<nav aria-label="Page navigation example">*/}
                            {/*    <ul className="pagination justify-content-end">*/}
                            {/*        <li className="page-item disabled">*/}
                            {/*            <a className="page-link" href="#" tabIndex="-1"><i*/}
                            {/*                className="fas fa-angle-double-left"></i></a>*/}
                            {/*        </li>*/}
                            {/*        /!*<li className="page-item"><a className="page-link txt-color-purple" href="#">1</a></li>*!/*/}
                            {/*        /!*<li className="page-item"><a className="page-link text-secondary" href="#">2</a></li>*!/*/}
                            {/*        /!*<li className="page-item"><a className="page-link text-secondary" href="#">3</a></li>*!/*/}
                            {/*        /!*<li className="page-item">*!/*/}
                            {/*        /!*    <a className="page-link txt-color-purple" href="#"><i*!/*/}
                            {/*        /!*        className="fas fa-angle-double-right"></i></a>*!/*/}
                            {/*        /!*</li>*!/*/}
                            {/*    </ul>*/}
                            {/*</nav>*/}
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default MarketingCampaignsPage;