import React from 'react';

class HeaderSection extends React.Component {
    render() {
        return (
            <header className="main-header border-bottom-light_blue container-fluid p-0 fixed-top">
                <div className="row m-0 py-0 px-3 d-flex align-items-center h-100">
                    <div className="col-6 m-0 p-0 d-flex align-items-center ">
                        <a href="void(0);" onClick={(e)=>{e.preventDefault()}} className="txt-color-purple mr-3 d-lg-none d-table" id="btn-menu"><i
                            className="fas fa-bars"></i></a>
                        <img src="/img/logo.svg" alt="" className="nav-brand" />
                    </div>
                    <div className="col-6 m-0 p-0 d-flex justify-content-end align-items-center">

                        {/*<div className="main-profile-container">*/}
                        {/*    <img src="img/profile_img.png" alt="" className="d-none d-sm-block" />*/}
                        {/*</div>*/}
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderSection;