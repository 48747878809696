import React from 'react';
import {getAllRequestsForSuperadmin} from "../../../helpers/accountAPIServices";
import {getAccessTokenFromSession} from "../../../helpers/sessionHelpers";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";
import {Link} from "react-router-dom";
import FooterSection from "../../Common/FooterSection/FooterSection";

class LocationUpdatesRequests extends React.Component {
    state = {
        requests: {
            pending_edit: [],
            pending_new: [],
        },
        isLoading: false,
        error: "",
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    async componentDidMount() {
        // load the requests first
        this.setLoading(true);
        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });
        this.setLoading(false);
    }

    render() {
        const allLocations = [...this.state.requests.pending_edit,
            ...this.state.requests.pending_new];

        const cards = allLocations.map((location) => {
            const claimedBy = location.claimed_by;
            return (
                <div className="w-100 border-light_blue rounded-4 p-4 row p-0 m-0 mb-3">
                    <div className="col-10 col-md-8">
                        <div className="w-100 mb-3 d-flex">
                                            <span className="d-table  txt-color-purple mr-3"><i
                                                className="fas fa-user"></i></span>
                            <span className="d-table  font-weight-bold">{`${claimedBy.first_name} ${claimedBy.last_name}`}</span>
                        </div>
                        <div className="w-100 mb-3 d-flex">
                                            <span className="d-table  txt-color-purple mr-3"><i
                                                className="fa fa-heartbeat" aria-hidden="true"></i></span>
                            <span className="d-table  font-weight-bold">{location.name}</span>
                        </div>
                        <div className="w-100 mb-3 d-flex">
                                            <span className="d-table txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                            <span className="d-table font-weight-normal">
                                {`${location.address}, ${location.city}. ${location.state} ${location.zip}`}
                                            </span>
                        </div>
                        <div className="w-100 mb-3 d-flex mt-3">
                            <Link to={{
                                pathname: `/requests/${location.id}`,
                                practice: {
                                    practice: location
                                }
                            }}>
                            <button className="btn btn-outline btn-outline-purple px-4">View Details <i
                                className="fas fa-arrow-right ml-3"></i></button></Link>
                        </div>
                    </div>

                    <div className="col-2 col-md-4">
                        <p
                            className="d-none d-md-table btn-location-changes" disabled>{location.approval_status === "pending_edit" ? "Claim/Edit" : "New"}</p>
                        <p href=""
                           className="d-flex d-md-none btn-location-changes-icon"><i
                            className="far fa-compass"></i></p>
                    </div>
                </div>
            )
        });
        return(
            <>
                <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-12 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">Location Updates / Request</h2>
                        </div>
                    </div>

                    {
                        this.state.isLoading ? <LoadingOverlay /> :
                            <div className="row ">
                                    <div className="col-12 col-lg-7" style={cards.length >= 2 ? { overflowY: "scroll", maxHeight: "700px" } : {}}>
                                    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5">
                                        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Pending
                                            Location Changes and Claims</h3>
                                        <div className="w-100 font-weight-bold pb-4  mx-0">
                                            <button className="btn-solid-orange-sm rounded-20 px-2 py-1">
                                                <span>({cards.length})</span> New Request{cards.length > 1 ? "s" : ""}
                                            </button>
                                        </div>
                                        {cards}
                                    </div>
                                </div>
                                {/*TODO: upload CSV*/}
                                {/*<div className="col-12 col-lg-5 mt-5 mt-lg-0">*/}
                                {/*    <div className="d-table w-100 border-light_blue rounded-4 p-3 p-md-5">*/}
                                {/*        <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Upload*/}
                                {/*            CSV File</h3>*/}

                                {/*        <p>*/}
                                {/*            Duis nisl dictum pretium lorem fames tortor mauris non ac. Ipsum senectus.*/}
                                {/*        </p>*/}

                                {/*        <div className="w-100 mb-3 d-flex mt-3">*/}
                                {/*            <button className="btn btn-outline btn-outline-purple px-4"><i*/}
                                {/*                className="fas fa-file-upload mr-3"></i>Upload CSV*/}
                                {/*            </button>*/}
                                {/*        </div>*/}

                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                    }


                </div>
            </div>
                <FooterSection />
            </>
        )
    }
}

export default LocationUpdatesRequests;