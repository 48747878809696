import React from 'react';
import NotFoundImage from './404.svg';


const NotFound404Page = () => {
    return (
        <div className="jumbotron w-100 m-0">
            <div className="container mx-auto"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-12  ">
                            <img src={NotFoundImage} alt="not found" style={{
                                width: '200px',
                            }} />
                            <h1 className="pt-md-5 pb-md-3">Page not found</h1>
                            <p className="pb-md-5">The page you requested could not be found.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default NotFound404Page;