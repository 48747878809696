import { SUPERADMIN_API_ROOT_URL } from "./authServices";
import { parseResponse } from "./passwordManagementServices";
import { parseJSON } from "./responseHandlers";

const getNotifications = (token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'notifications/';
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,
        }
    };

    return fetch(URL, options)
        .then(parseResponse)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

export {
    getNotifications,
};
