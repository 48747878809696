import React from 'react';
import LoginHeaderSection from "../LoginPage/LoginHeaderSection/LoginHeaderSection";
import queryString from 'query-string';
import {Redirect} from "react-router";
import {change_password_from_request} from "../../helpers/passwordManagementServices";
import LoadingOverlay from "../Common/LoadingOverlay/LoadingOverlay";

class ChangePasswordPage extends React.Component {
    state = {
        details: {
            new_password: "",
            confirm_password: "",
            email: queryString.parse(this.props.location.search).email,
        },
        code: queryString.parse(this.props.location.search).code,
        redirectToLogin: false,
        error: "",
        isLoading: false,
        redirectToSuccess: false,
    }

    componentDidMount() {
        if(!this.state.details.email || !this.state.code) {
            this.setState({
                redirectToLogin: true,
            })
        }
    }

    handlePasswordChange = (event) => {
        const newDetails = {
            ...this.state.details,
        };

        newDetails[event.target.name] = event.target.value;

        this.setState({
            ...this.state,
            details: newDetails,
        }, () => console.log(this.state));
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleSubmitClick = async () => {
        // check if passwords are matched
        if(this.state.details.confirm_password !== this.state.details.new_password) {
            this.setState({
                error: "Passwords are not matching."
            });

            return;
        }

        if(this.state.details.confirm_password === "" || this.state.details.new_password === "") {
            this.setState({
                error: "Passwords cannot be blanks."
            });

            return;
        }


        if(this.state.details.confirm_password.length < 10) {
            this.setState({
                error: "Passwords must be 10 or more characters long.",
            });

            return;
        }

        this.setLoading(true);
        const changePasswordResults = await change_password_from_request(this.state.details, this.state.code);

        if(changePasswordResults.error) {
            this.setState(
                {error: changePasswordResults.error,}
            )
            this.setLoading(false);
            return;
        }

        this.setState({
            redirectToSuccess: true,
        });
    }

    render() {
        return(
            <>
                <LoginHeaderSection />
                {
                    this.state.redirectToLogin && <Redirect to='/' />
                }

                {
                    this.state.redirectToSuccess && <Redirect to={{
                        pathname: '/password/change/success',
                        redirectedFromSuccess: true,
                    }} />
                }
                {
                    this.state.isLoading ? <LoadingOverlay /> :
                        <main>
                            <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                                <div className="form-login-container p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                                    <h4 className="border-bottom pb-4 txt-color-dark_purple">New Password</h4>

                                    <div className="form-control-wrap mt-5">

                                        <div className="form-row mb-4">
                                            {
                                                this.state.error !== "" &&
                                                <div className="col-12">
                                                    <p style={{
                                                        color: 'red',
                                                    }}>{this.state.error}</p>
                                                </div>
                                            }
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Please enter your new password.</label>
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Password</label>
                                                <input type="password" className="form-control" name="new_password" value={this.state.details.new_password} onChange={this.handlePasswordChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-4">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Confirm Password</label>
                                                <input type="password" className="form-control" name='confirm_password' value={this.state.details.confirm_password} onChange={this.handlePasswordChange}/>
                                            </div>
                                        </div>


                                        <div className="form-row mb-4">
                                            <div className="col-12 mt-3">
                                                <button href="#"
                                                        className="btn btn-solid btn-solid-orange w-100" onClick={this.handleSubmitClick}>Submit</button>
                                                <a href="login.html"
                                                   className="btn btn-outline btn-outline-purple w-100 mt-2"><i
                                                    className="fas fa-arrow-left mr-2"></i> Back to Login</a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                }
            </>
        )
    }
}

export default ChangePasswordPage;