import React from 'react';

const RegistrationHeaderSection = (props) => {
    return (
        <header
            className="p-4 border-bottom-light_blue d-flex align-items-center justify-content-center position-relative position-xs-relative">
            <img src="/img/logo.svg" alt="" />
        </header>
    );
};

export default RegistrationHeaderSection;