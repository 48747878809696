const SESSION_KEY_USER = 'user';
const SESSION_KEY_ACCESS_TOKEN = 'access_token';
const SESSION_KEY_REFRESH_TOKEN = 'refresh_token';

const ANTIBODY_TESTS = [
    {
        name: "Nasal Swab",
        value: "nasal swab",
    },
    {
        name: "Blood Draw",
        value: "blood",
    },
    {
        name: "Finger Prick",
        value: "finger",
    },
    {
        name: "Rapid Test",
        value: "rapid test",
    }

]

const COVID19_TESTS = [
    {
        name: "Nasal Swab",
        value: "nasal swab",
    },
    {
        name: "Blood Draw",
        value: "blood",
    },
    {
        name: "Finger Prick",
        value: "finger",
    },
    {
        name: "Spit",
        value: "spit",
    },
    {
        name: "Rapid Test",
        value: "rapid test",
    }
];

const PARTNERS = [
    {
        website: "www.urgentcare.com",
        code: "URGENTCARE",
    },
    {
        website: "www.antibodytesting.com",
        code: "ABTESTING"
    }
];

export {
    SESSION_KEY_ACCESS_TOKEN,
    SESSION_KEY_REFRESH_TOKEN,
    SESSION_KEY_USER,
    COVID19_TESTS,
    PARTNERS,
    ANTIBODY_TESTS,
}