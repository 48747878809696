import React from 'react';
import LocationCard from "../LocationCard/LocationCard";
import {getUSAStatesForOptionsComponent} from "../../../../helpers/addressHelpers";
import {
    REGEX_EMAIL_DELIMITER_PATTERN,
    searchClinicsForInvite,
    sendInvite
} from "../../../../helpers/sendInviteAPIServices";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import AddedLocationCard from "../AddedLocationCard/AddedLocationCard";
import EmailCard from "../EmailCard/EmailCard";
import {EMAIL_REGEX_PATTERN} from "../../../../helpers/accountAPIServices";
import {getAccessTokenFromSession} from "../../../../helpers/sessionHelpers";
import { getAllRequestsForSuperadmin } from '../../../../helpers/accountAPIServices';
class SendInvitePage extends React.Component {
    state = {
        fields: {
            query: "",
            zip: "",
            city: "",
            state: "default",
        },
        error: "",
        loading: false,
        isLoading: false,
        queryPractices: [],
        addedPractices: [],
        emailField: "",
        emails: [],
        inviteSent: false,
    }

    async componentDidMount() {

        const getRequestResults = await getAllRequestsForSuperadmin(getAccessTokenFromSession());
        if (getRequestResults?.error) {
            console.log("API ERROR: " + getRequestResults?.error.response.statusText);

            if (getRequestResults?.error.response.status === 401) {
                localStorage.clear();
                window.location.reload(true);
            }
            this.setState({
                error: "There is an error fetching all the requests.",
            });
            this.setLoading(false);
            return;
        }

        this.setState({
            requests: getRequestResults,
        });
    }

    handleSendInviteClicked = async () => {
        const emails = this.state.emails;
        const practices = this.state.addedPractices.map((p) => p.practice_id);

        if(emails.length < 1 || practices.length < 1) {
            this.setState({
                error: "Must have at least one email and practice to send an invite."
            });
            return;
        }

        this.setLoadingForInvite(true);
        const sendInviteResults = await sendInvite({
            emails,
            practices,
        }, getAccessTokenFromSession());

        if(sendInviteResults.error) {
            this.setState({
                error: sendInviteResults.error
            });
            this.setLoadingForInvite(false);
            return;
        }

        this.setLoadingForInvite(false);

        this.setState({
            inviteSent: true,
            error: "",
        });
    }

    handleRemoveEmailAddress = (email) => {
        const newEmails = this.state.emails.filter((e) => e !== email);
        this.setState({
            emails: newEmails
        }, ()=>console.log(this.state.emails));
    }

    handleAddEmailClicked = () => {
        const raw_email_value = this.state.emailField.replaceAll(REGEX_EMAIL_DELIMITER_PATTERN, ',');
        const emails = raw_email_value.split(',');
        const newEmails = [];

        emails.forEach((email) => {
            const emailIndicator = email === "" ? "." : `, ${email}.`;
            if(!this.isValidEmail(email)) {
                this.setState({
                    error: `Invalid email address${emailIndicator}`,
                });
                return;
            }

            // add to emails
            if(!this.state.emails.find((e) => e === email) && !newEmails.find((e) => e === email)) {
                newEmails.push(email);
            }
        });

        if(newEmails.length > 0) {
            this.setState({
                emails: [
                    ...this.state.emails,
                    ...newEmails,
                ],
                emailField: "",
            }, ()=>console.log(this.state.emails));
        }

        this.setState({
            emailField: "",
        });
    }


    isValidEmail = (email) => {
        return EMAIL_REGEX_PATTERN.test(email) && !/\s/.test(email) && !/,/.test(email);
    }

    handleEmailChange = (event) => {
        this.setState({
            emailField: event.target.value.toLowerCase(),
            error: "",
        });
    }

    setLoading = (status) => {
        this.setState({
            loading: status,
        });
    }

    setLoadingForInvite = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleSearchEnterKeyPressed = (event) => {
        if(event.key === "Enter") {
            console.log('Key');
            this.handleSearchClick();
        }
    }

    handleSearchClick = async () => {
    //    Check if all fields are valid
        await this.prepareFields();
        this.setLoading(true);
        const searchClinicsResults = await searchClinicsForInvite(this.state.fields);

        if(searchClinicsResults.error) {
            this.setState({
                error: searchClinicsResults.error,
            });
            this.setLoading(false);
            return;
        }

        console.log(searchClinicsResults);

        this.setState({
            queryPractices: [...searchClinicsResults],
        });

        this.setLoading(false);
    }

    prepareFields = async () => {
        await this.setState({
            fields: {
                ...this.state.fields,
                state: this.state.fields.state === "default" ? "" : this.state.fields.state
            }
        }, ()=> console.log(this.state.fields));
    }


    isValidFields = () => {
        const fields = this.state.fields;
        return !([
            fields.query === "",
            fields.zip === "",
            fields.city === "",
            fields.state === "default" || fields.state === "",
        ].every((test) => test));
    }

    handleMouseEvent = (event) => {
        console.log("H");
        if (event.type === "mouseup" || event.type === "touchend") {
            this.setState({
                fields: {
                    ...this.state.fields,
                    radius: event.target.value,
                }
            }, ()=> console.log(this.state.fields));
        }
    }

    handleRadiusChange = (event) => {
        console.log('A');
        this.setState({
            fields: {
                ...this.state.fields,
                radius: event.target.value,
            }
        }, ()=> console.log(this.state.fields));
    }

    handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const newValue = event.target.value;

        const newFieldsState = {
            ...this.state.fields,
        };

        newFieldsState[fieldName] = newValue;

        this.setState({
            fields: newFieldsState,
        }, ()=>console.log(this.state));
    }

    handleSelectClicked = (practice) => {
        // console.log(practice);
        this.toggleAddPractice(practice);
    }

    toggleAddPractice = (practice) => {
        // check if practice is already added
        if(this.state.addedPractices.find((p) => p.practice_id === practice.practice_id)) {
            // Remove if existing
            const newAddedPractices = this.state.addedPractices.filter((p) => p.practice_id !== practice.practice_id);
            this.setState({
                addedPractices: newAddedPractices
            }, ()=>console.log(this.state.addedPractices));
        } else {
            this.setState({
                addedPractices: [
                    ...this.state.addedPractices,
                    practice
                ]
            }, ()=>console.log(this.state.addedPractices));
        }
    }

    handleEnterPressed = (event) => {
        if(event.key === "Enter") {
            console.log('Enter key is pressed.');
            this.handleAddEmailClicked();
        }
    }

    render() {
        const queryPracticesCards = this.state.queryPractices.map((practice) => {
            return (
                <LocationCard practice={practice} onSelectClicked={this.handleSelectClicked} key={practice.practice_id}/>
            )
        });

        const addedPracticesCards = this.state.addedPractices.map((practice) => {
            return (
                <AddedLocationCard practice={practice} onRemovePractice={this.handleSelectClicked} key={practice.practice_id}/>
            )
        });
        return (
            <>
            <div className="main-content">
                <div className="container p-3 p-md-5">

                    {
                        this.state.inviteSent &&
                        <div className="alert alert-success mb-5" role="alert">
                            <i className="fa fa-check-circle mr-2"></i> Invite Sent
                        </div>
                    }
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Send Invite</h2>
                        </div>

                    </div>
                    {
                        this.state.error !== "" &&
                        <div className="errors">
                            <p style={{
                                color: "red",
                            }}>{this.state.error}</p>
                        </div>
                    }

                    {
                        this.state.isLoading ? <LoadingOverlay /> :
                            <div className="row mt-5 mx-0 d-flex align-items-center justify-content-center">

                                <div className="col-12 col-md-10  col-lg-6 rounded-4 p-3 p-md-5 mx-0">
                                    <div className="w-100 mb-4 pb-0">

                                        <div className="form-row mb-3">


                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Please enter an e-mail address to
                                                    invite</label>
                                                {/*<input type="email" className="form-control tags" placeholder="E-mail" value=""*/}
                                                {/*       data-role="tagsinput" size="500" />*/}
                                                {/*    <small className="mt-2 d-table">*/}
                                                {/*        <i className="fas fa-info-circle txt-color-purple mr-2"></i>*/}
                                                {/*        <span className="font-style-italic text-secondary">*/}
                                                {/*       you can separate emails by comma(' , ')</span>*/}
                                                {/*    </small>*/}

                                                <div className="input-group border-light_blue rounded-5 overflow-hidden">
                                                    <input type="text" className="form-control shadow-none border-0" name='query'
                                                           placeholder="Email Address" onChange={this.handleEmailChange} value={this.state.emailField} onKeyDown={this.handleEnterPressed}/>
                                                    <div className="input-group-append">
                                                        <button className="btn btn-solid-light_blue rounded-0 custom-search-button" onClick={this.handleAddEmailClicked}
                                                        >Add
                                                        </button>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.emails.map((email) => {
                                                        return <EmailCard email={email} onRemove={this.handleRemoveEmailAddress}/>
                                                    })
                                                }
                                            </div>
                                        </div>

                                        <div className="form-row px-2 px-lg-4 mt-5">

                                            <span className="h3 font-weight-bold txt-color-secondary">Search for a Clinic</span>
                                            <div className="input-group border-light_blue rounded-5 overflow-hidden">
                                                <input type="text" className="form-control shadow-none border-0" name='query'
                                                       placeholder="Client ID or Client Name" value={this.state.fields.query} onChange={this.handleFieldChange}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-solid-light_blue rounded-0 custom-search-button"
                                                            data-toggle="modal"
                                                            data-target="#search_location">Search
                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                addedPracticesCards
                                            }
                                        </div>
                                    </div>


                                    <div className="w-100 mt-5">
                                        <div className="form-row mb-2">
                                            <button className="btn btn-solid btn-solid-orange w-100" onClick={this.handleSendInviteClicked}>Send Invite</button>
                                        </div>

                                    </div>

                                </div>


                            </div>
                    }

                </div>
            </div>

                <div className="modal fade p-1 p-sm-5" id="search_location" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLabel">Search Locations</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fas fa-times-circle fa-xs"></i>
                                </button>
                            </div>
                            <div className="modal-body p-4">
                                {
                                    this.state.loading ? <LoadingOverlay />:
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-input-search-label"></label>
                                                <input type="text" className="form-control form-control-input-search" value={this.state.fields.query} name='query' onChange={this.handleFieldChange}
                                                       placeholder="Search..." />
                                            </div>
                                        </div>
                                }

                                {
                                    this.isValidFields() ? <button className="btn btn-solid btn-solid-orange w-100 search-button-send-invite" id="set_loc_invite" onClick={this.handleSearchClick} onKeyDown={this.handleSearchEnterKeyPressed}>
                                            Search
                                        </button> :
                                        <button className="btn btn-solid btn-solid-orange w-100 search-button-send-invite disabled-search-send-invite" id="set_loc_invite">
                                            Search
                                        </button>
                                }

                                {/*TODO: add test filters*/}
                                {/*<div className="row py-3 mx-0">*/}
                                {/*    <div className="col-12 mx-0 px-0">*/}
                                {/*        <label className="form-label font-weight-bold" htmlFor="">Filter:</label>*/}
                                {/*        <div className="form-row mx-0 px-4">*/}
                                {/*            <div*/}
                                {/*                className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                {/*                <input type="checkbox" id="covid_testing" name="customRadioInline1"*/}
                                {/*                       className="form-control-input-checkbox" />*/}
                                {/*                    <label className="form-control-input-checkbox-label form-label"*/}
                                {/*                           htmlFor="covid_testing">Covid Testing</label>*/}
                                {/*            </div>*/}
                                {/*            <div*/}
                                {/*                className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                {/*                <input type="checkbox" id="x_ray" name="customRadioInline1"*/}
                                {/*                       className="form-control-input-checkbox" />*/}
                                {/*                    <label className="form-control-input-checkbox-label form-label"*/}
                                {/*                           htmlFor="x_ray">X-Ray</label>*/}
                                {/*            </div>*/}
                                {/*            <div*/}
                                {/*                className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                {/*                <input type="checkbox" id="antibody_testing" name="customRadioInline1"*/}
                                {/*                       className="form-control-input-checkbox" />*/}
                                {/*                    <label className="form-control-input-checkbox-label form-label"*/}
                                {/*                           htmlFor="antibody_testing">Antibody Testing</label>*/}
                                {/*            </div>*/}
                                {/*            <div*/}
                                {/*                className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">*/}
                                {/*                <input type="checkbox" id="24_hrs" name="customRadioInline1"*/}
                                {/*                       className="form-control-input-checkbox" />*/}
                                {/*                    <label className="form-control-input-checkbox-label form-label"*/}
                                {/*                           htmlFor="24_hrs">24*/}
                                {/*                        Hours</label>*/}

                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*TODO: add radius filter*/}
                                {/*<div className="row py-4 d-flex align-items-center" >*/}
                                {/*    <div className="col-12 col-sm-4">*/}
                                {/*        <label htmlFor="" className="form-control-label">*/}
                                {/*            <span className="font-weight-bold">Radius:  </span>*/}
                                {/*            <span className="font-weight-normal">{this.state.fields.radius}</span>*/}
                                {/*            <span className="font-weight-normal">mi</span>*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-12 col-sm-6" >*/}
                                {/*        <input type="range" min="1" max="100" value={this.state.fields.radius}*/}
                                {/*               data-orientation="horizontal"*/}
                                {/*               onChange={(e) => this.handleRadiusChange(e)}*/}
                                {/*               onMouseDown={this.handleMouseEvent}*/}
                                {/*               onMouseUp={this.handleMouseEvent}*/}
                                {/*               onTouchStart={this.handleMouseEvent}*/}
                                {/*               onTouchEnd={this.handleMouseEvent}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="row mb-4">
                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="" className="form-label">Zip Code</label>&nbsp;
                                        <input type="text" className="form-control" name='zip' value={this.state.fields.zip} onChange={this.handleFieldChange}/>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="" className="form-label">City</label>&nbsp;
                                        <input type="text" className="form-control" name='city' value={this.state.fields.city} onChange={this.handleFieldChange}/>
                                    </div>
                                    <div className="col-12 col-sm-4 mb-3">
                                        <label htmlFor="" className="form-label">State</label>&nbsp;
                                        {/*<input type="text" className="form-control" />*/}
                                        <select className="form-control" name='state' value={this.state.fields.state} onChange={this.handleFieldChange}>
                                            <option value='default' key='default'></option>
                                            {
                                                getUSAStatesForOptionsComponent().map((state) => {
                                                    return <option value={state.value} key={state.key}>{state.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className="row rounded-10 border-light_blue  p-3 mx-0 overflow-auto campaign-location">

                                    {/*TODO: put location cards here.*/}

                                    {/*{*/}
                                    {/*    this.state.queryPractices.length < 1 ? <p>No results found.</p> :*/}
                                    {/*        {queryPracticesCards}*/}
                                    {/*}*/}

                                    {queryPracticesCards}

                                    {/*<LocationCard practice={{*/}
                                    {/*    name: "Catli General Hospital",*/}
                                    {/*    address: "591 San Juan",*/}
                                    {/*    city: "San Luis, Pampanga",*/}
                                    {/*    zip: "2014"*/}
                                    {/*}} />*/}

                                </div>
                            </div>
                            <div className="modal-footer">
                                {
                                    !this.state.loading && <button className="btn btn-solid btn-solid-orange w-100 " id="set_loc_invite" data-dismiss="modal">
                                        Add to Invitation
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SendInvitePage;