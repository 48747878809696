import { SUPERADMIN_API_ROOT_URL } from "../authServices";

export function handleCSVUpload(payload, user, token) {
  const { userID, username, email } = user;
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/handle_csv_upload/?user_id=${userID}&username=${username}&email=${email}`;
  // const url = SUPERADMIN_API_ROOT_URL + `csv_manager/handle_csv_upload/?user_id=${userID}&username=${username}&email=${email}&csv_filters=PRACTICE_ID,NAME`;
  const options = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
    },
    body: payload,
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function getAllUploadReports(search = "", page = 1, limit = 10, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/get_all_upload_report/?page=${page}&limit=${limit}&search=${search}`;
  const options = {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function getUploadReport(id, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/get_upload_report/${id}/`;
  const options = {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function getLatestUploadReport(token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/get_latest_upload_report/`;
  const options = {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function getUploadReportPractices(search = "", page = 1, limit = 10, payload, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/get_upload_report_practices/?page=${page}&limit=${limit}&search=${search}`;
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}

export function approvePracticeUpdate(practice_id, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/approve/${practice_id}/`;
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}

export function batchApprovePracticeUpdate(payload, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/batch-approve/`;
  console.log(payload)
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function denyPracticeUpdate(practice_id, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/deny/${practice_id}/`;
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}


export function batchDenyPracticeUpdate(payload, token) {
  const url = SUPERADMIN_API_ROOT_URL + `csv_manager/batch-deny/`;
  console.log(payload)
  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error)
}
