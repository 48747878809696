import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import HeaderSection from '../../../Common/HeaderSection/HeaderSection';
import FooterSection from '../../../Common/FooterSection/FooterSection';

export class AddCampaignPage extends Component {

state = {
    name: '',
    url: '',
};

handleNameChange = (e) => {
    this.setState({name: e.target.value})
};

handleUrlChange = (e) => {
    this.setState({url: e.target.value})
};


render() {
    return (
    <>
    <HeaderSection/>
        <div className="main-content">
                <div className="container p-3 p-md-5">
                    <div className="row mb-4 p-0 mx-0">
                        <div className="col-6 px-0 mx-0">
                            <h2 className="h1 txt-color-dark_purple font-weight-bold">New Campaign</h2>
                            <Link to='/campaigns' className="mt-4 d-table txt-color-purple text-decoration-none "><i className="fas fa-arrow-left mr-2"></i> Back to Marketing Campaigns</Link>
                        </div>
                      
                    </div>

                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">
                            <div className="d-table w-100 ">
                                <h3 className="h4 txt-color-dark_purple border-bottom font-weight-bold pb-4 mb-4 mx-0">Campaign Info</h3> 
                            </div>

                            <div className="col-12 mx-0 px-0">
                                <div className="form-row mb-4 mx-0 px-0">
                                    <div className="col-12 mx-0 px-0 d-flex my-3">
                                        <div className="form-check-inline">
                                            <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label className="form-check-label form-control-input-radio-label form-label" htmlFor="flexRadioDefault1">
                                                Results Page
                                            </label>
                                        </div>

                                        <div className="form-check-inline">
                                            <input className="form-check-input form-control-input-radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
                                            <label className="form-check-label form-control-input-radio-label form-label" htmlFor="flexRadioDefault2">
                                                Detailed Listing Page
                                            </label>
                                        </div>
                                        {/* <!-- <input type="radio" id="test2" name="radio-group">
                                        <label htmlFor="test2">Peach</label> --> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mx-0 px-0">
                                <div className="form-row mb-4 mx-0 px-0">
                                    <div className="col-12 mx-0 px-0">
                                        <label htmlFor="" className="form-label">Campaign Name</label> 
                                        <input type="text" className="form-control" value={this.state.name} onChange={this.handleNameChange}/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12 mx-0 px-0">
                                <div className="form-row mb-4 mx-0 px-0">
                                    <div className="col-12 mx-0 px-0">
                                        <label htmlFor="" className="form-label">URL</label> 
                                        <input type="text" className="form-control" placeholder="urgentcare.com/" value={this.state.url} onChange={this.handleUrlChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mx-0 px-0 mb-4">
                                <div className="form-row mb-4 mx-0 px-0">
                                    <div className="col-12 mx-0 px-0">
                                        <label htmlFor="" className="form-label">Location</label> 
                                        <Link to='/campaigns/locations' className="btn btn-outline btn-outline-purple w-50  " >
                                            <i className="fas fa-plus mr-2"></i> 
                                            New Location
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mx-0 px-0 pt-4 ">
                                <div className="form-row mx-0 px-0">
                                    <div className="col-12 mx-0 px-0">
                                        <button className="btn btn-solid btn-solid-orange w-100 ">
                                            Create Campaign
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
            <FooterSection/>
    </>
    )
}
}

export default AddCampaignPage
