import {SUPERADMIN_API_ROOT_URL} from "./authServices";
import {checkStatus, parseJSON} from "./responseHandlers";

const REGEX_EMAIL_DELIMITER_PATTERN = /[\s,;]+/g;

const searchClinicsForInvite = (fields) => {
    const URL = SUPERADMIN_API_ROOT_URL + "invite/";
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fields),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e
            }
        });
}

const sendInvite = (details, token) => {
    const URL = SUPERADMIN_API_ROOT_URL + 'invite/send/';
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token,

        },
        body: JSON.stringify(details),
    };

    return fetch(URL, options)
        .then(checkStatus)
        .then(parseJSON)
        .catch((e) => {
            return {
                error: e,
            }
        });
}

export {
    searchClinicsForInvite,
    sendInvite,
    REGEX_EMAIL_DELIMITER_PATTERN,
}