import dateFormat from 'dateformat';
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
    approvePracticeUpdate, batchApprovePracticeUpdate,
    denyPracticeUpdate, batchDenyPracticeUpdate,
    getUploadReport, getUploadReportPractices
} from "../../../../helpers/csv_utils/csv_utils";
import { compareSchedules, convert24HourFormatStringTo12HourFormatString } from "../../../../helpers/scheduleUtilties";
import { getAccessTokenFromSession } from "../../../../helpers/sessionHelpers";
import {
    adjustRangeOffset,
    range
} from "../../../../utils/Utils";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
import { Bars } from 'react-loader-spinner';



const CREATED = 'created';
const UPDATED = 'updated';
const NO_ACTION = 'no_action';
const APPROVED = 'approved';
const ERRORED = 'error';

const TABLE_HEADERS = [
    "ID",
    "NUCR Practice Name",
    "Address",
    "City",
    "State",
    "Zip",
    "Latitude",
    "Longitude",
    "Phone",
    "Website",
    "Antibody Test",
    "Covid19 Test",
    "Xray",
    "Hrs of Operation",
    "Approval",
    "Review"

];

const ITEM_PARAMS = [
    "practice_id",
    "name",
    "address",
    "city",
    "state",
    "zip",
    "latitude",
    "longitude",
    "phone",
    "website",
    "antibodyTest",
    "covid19Test",
    "xray",
    "hr_of_operation",
    "approval_status",
    "review"
];

var itemCheckedList = [];
var checkedFilters = [];
var checkAllItem = false;
var tableDataAvailable = true;
var allUpdatedRecords = [];

const BASE_URL = process.env.REACT_APP_BASE_URL;

// eslint-disable-next-line
function CSVUploadReports(props) {
    const { upload_report_id } = useParams();

    var csvFilename = useRef(null);
    var uploadedAt = useRef(null);
    var searchDelay = null;

    /* === Hooks === */
    const [isLoading, setIsLoading] = useState(false);

    const [uploadReport, setUploadReport] = useState({
        id: null,
        csvFilename: null,
        uploadedAt: null,
    });

    const [updatedRecords, setUpdatedRecords] = useState([]);
    const [createdRecords, setCreatedRecords] = useState([]);
    const [noActionRecords, setNoActionRecords] = useState([]);
    const [approvedRecords, setApprovedRecords] = useState([]);
    const [erroredRecords, setErroredRecords] = useState([]);

    const [updatedRecordsCount, setUpdatedRecordsCount] = useState(0);
    const [createdRecordsCount, setCreatedRecordsCount] = useState(0);
    const [noActionRecordsCount, setNoActionRecordsCount] = useState(0);
    const [approvedRecordsCount, setApprovedRecordsCount] = useState(0);
    const [erroredRecordsCount, setErroredRecordsCount] = useState(0);

    const [updatedOriginalCount, setUpdatedOriginalCount] = useState(0);
    const [createdOriginalCount, setCreatedOriginalCount] = useState(0);
    const [noActionOriginalCount, setNoActionOriginalCount] = useState(0);
    const [approvedOriginalCount, setApprovedOriginalCount] = useState(0);
    const [erroredOriginalCount, setErroredOriginalCount] = useState(0);

    const [pageUpdated, setPageUpdated] = useState(1);
    const [pageCreated, setPageCreated] = useState(1);
    const [pageNoAction, setPageNoAction] = useState(1);
    const [pageApproved, setPageApproved] = useState(1);
    const [pageErrored, setPageErrored] = useState(1);

    const [pageNumbersUpdated, setPageNumbersUpdated] = useState(0);
    const [pageNumbersCreated, setPageNumbersCreated] = useState(0);
    const [pageNumbersNoAction, setPageNumbersNoAction] = useState(0);
    const [pageNumbersApproved, setPageNumbersApproved] = useState(0);
    const [pageNumbersErrored, setPageNumbersErrored] = useState(0);

    const [limitUpdated, setLimitUpdated] = useState(250);
    const [limitCreated, setLimitCreated] = useState(10);
    const [limitNoAction, setLimitNoAction] = useState(10);
    const [limitApproved, setLimitApproved] = useState(10);
    const [limitErrored, setLimitErrored] = useState(10);

    const [limitUpdatedOver, setLimitUpdatedOver] = useState(true);

    const [searchUpdated, setSearchUpdated] = useState("");
    const [searchCreated, setSearchCreated] = useState("");
    const [searchNoAction, setSearchNoAction] = useState("");
    const [searchApproved, setSearchApproved] = useState("");
    const [searchErrored, setSearchErrored] = useState("");

    const [reviewIndex, setReviewIndex] = useState(null);
    const [approveId, setApproveId] = useState(null);
    const [denyId, setDenyId] = useState(null);

    const [itemIdList, setCheckListId] = useState([]);
    const [isFilterChecked, setFilterChecked] = useState([]);
    const [filters, setFilters] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);

    const [records, setRecords] = useState([]);

    // for select all item.practice.id in table
    const [isCheckAllItem, setIsCheckAllItem] = useState(false);

    // items that are selected
    const [isAllItemCheck, setAllItemCheck] = useState([]);

    const [isTableLoading, setIsTableLoading] = useState(false);

    const [numberOfRecords, setNumberOfRecords] = useState(0);

    // if data !== []
    // const [isDataAvailable, setIsDataAvailable] = useState(false);


    /**
     * Load initial Report details.
     */

    const loadUploadReportDetails = useCallback(() => {
        if (!upload_report_id) return;

        getUploadReport(upload_report_id, getAccessTokenFromSession())
            .then((response) => {
                const result = response?.result ?? {};

                // eslint-disable-next-line react-hooks/exhaustive-deps
                csvFilename = result?.csv_filename ?? null;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                uploadedAt = (result?.["uploaded_at"])
                    ? dateFormat(result?.["uploaded_at"], "mmmm dd, yyyy - h:MM TT")
                    : null;

                setUploadReport({
                    id: Number(result?.id),
                    csvFilename,
                    uploadedAt,
                });

                setIsLoading(false);
            })
            .catch((error) => {
                console.log(`getUploadReport(): ${error}`);
                setIsLoading(false);
            });
    }, [upload_report_id]);


    useEffect(() => {
        setIsLoading(true);
        loadUploadReportDetails();
    }, [setIsLoading, setIsTableLoading, loadUploadReportDetails]);

    const loadUpdatedRecords = useCallback((page, limit, status = "") => {
        setIsTableLoading(true);
        if (!uploadReport?.id) return;

        let uploadReportId = uploadReport?.id;
        let report_id = localStorage.getItem("report_id") ?? "";

        let page_num = localStorage.getItem("page_num") ?? 1;
        let page_limit = localStorage.getItem("page_limit") ?? 250;

        let storedFilter = JSON.parse(localStorage.getItem("storage_filter")) ?? [];

        // If report_id exist, check if equal to previous report_id clicked. Else, remove
        // all saved item/cookies
        if (report_id) {
            // If not equal, remove all saved item/cookies
            if (report_id === upload_report_id) {
                uploadReportId = report_id;
            } else {
                localStorage.removeItem("report_id");
                localStorage.removeItem("storage_filter");
                localStorage.setItem("page_num", 1);
                localStorage.setItem("page_limit", 250);
                localStorage.removeItem("current_page_array");
                localStorage.setItem("report_id", upload_report_id);
            }
            // console.log("Not empty", uploadReportId);
        } else {
            localStorage.removeItem("report_id");
            localStorage.removeItem("storage_filter");
            localStorage.setItem("page_num", 1);
            localStorage.setItem("page_limit", 250);
            localStorage.removeItem("current_page_array");
            localStorage.setItem("report_id", upload_report_id);
        }

        let payload = {
            upload_report_id: uploadReportId,
            practice_status: status,
        };

        if (page_num) {
            page = page_num;
        }

        if (page_limit) {
            limit = page_limit;
            if (limit > 10) {
                setLimitUpdatedOver(true);
            } else {
                setLimitUpdatedOver(false);
            }
        }

        /* Update page number and page limit */
        setPageUpdated(Number(page));
        setLimitUpdated(Number(limit));

        getUploadReportPractices(searchUpdated, page, limit, payload, getAccessTokenFromSession())
            .then((response) => {
                setIsTableLoading(false);
                const { result, pagination } = response;
                const { data, original_count, count } = result;

                setUpdatedRecords(data ?? []);
                allUpdatedRecords = data ?? [];
                setRecords(data ?? []);

                setPageNumbersUpdated(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchUpdated?.trim() === "") {
                    setUpdatedRecordsCount(Number(count));
                    setUpdatedOriginalCount(Number(original_count));
                }

                // Check if cookie is empty for storage_filter
                // If not empty, set it as default filters
                if (storedFilter) {
                    checkedFilters = storedFilter;
                    setFilters(checkedFilters);
                    setFilterChecked(checkedFilters);
                    if (checkedFilters.includes("select_all")) {
                        setIsCheckAll(true);
                    }
                } else {
                    console.log("Empty storage");
                }

                let newItem = [];
                var filter = checkedFilters;

                if (filter.length !== 0) {
                    newItem = allUpdatedRecords.filter((newVal) => {
                        let by_name;
                        let by_address;
                        let by_xray;
                        let by_phone;
                        let by_website;
                        let orig_schedule = newVal.original_practice.schedules;
                        let schedule = newVal.practice.schedules;
                        let by_time;

                        if (filter.includes("name")) {
                            by_name = newVal.original_practice.name !== newVal.practice.name;
                        }

                        if (filter.includes("address")) {
                            by_address = newVal.original_practice.address !== newVal.practice.address ||
                                newVal.original_practice.zip !== newVal.practice.zip ||
                                newVal.original_practice.city !== newVal.practice.city ||
                                newVal.original_practice.state !== newVal.practice.state ||
                                newVal.original_practice.latitude !== newVal.practice.latitude ||
                                newVal.original_practice.longitude !== newVal.practice.longitude;
                        }

                        if (filter.includes("xray")) {
                            by_xray = newVal.original_practice.xray !== newVal.practice.xray;
                        }

                        if (filter.includes("phone")) {
                            by_phone = newVal.original_practice.phone !== newVal.practice.phone
                        }

                        if (filter.includes("website")) {
                            by_website = newVal.original_practice.website !== newVal.practice.website
                        }

                        if (filter.includes("time")) {
                            by_time = !compareSchedules(orig_schedule, schedule);
                        }

                        return (by_name || by_address || by_xray || by_phone || by_website || by_time) && newVal.practice.approval_status !== "approved";
                    });
                } else {
                    newItem = allUpdatedRecords;
                }
                setRecords(newItem);
                setNumberOfRecords(newItem.length);

                if (newItem.length !== 0) {
                    tableDataAvailable = true;
                } else {
                    tableDataAvailable = false;
                }

                if (checkAllItem) {
                    var itemPending = newItem.filter((item) => {
                        return item.practice.approval_status === "csv_pending";
                    })
                    itemCheckedList = itemPending.map(item => '' + item.practice.id);
                }
                setCheckListId(itemCheckedList);
                setAllItemCheck(itemCheckedList);

            })
            .catch((error) => {
                setIsTableLoading(false);
                console.log(`getUploadReportPractices(): ${error}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchUpdated, uploadReport?.id]);

    const loadErroredRecords = useCallback((page, limit, status = "") => {
        if (!uploadReport?.id) return;

        let payload = {
            upload_report_id: uploadReport?.id,
            practice_status: status,
        };

        /* Update page number and page limit */
        setPageErrored(Number(page));
        setLimitErrored(Number(limit));

        getUploadReportPractices(searchErrored, page, limit, payload, getAccessTokenFromSession())
            .then((response) => {

                const { result, pagination } = response;

                const { count, data, original_count } = result;

                console.log(data);

                setErroredRecords(data ?? []);
                setPageNumbersErrored(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchErrored?.trim() === "") {
                    setErroredRecordsCount(Number(count));
                    setErroredOriginalCount(Number(original_count));
                }
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchErrored, uploadReport?.id]);

    const loadApprovedRecords = useCallback((page, limit, status = "") => {
        if (!uploadReport?.id) return;

        let payload = {
            upload_report_id: uploadReport?.id,
            practice_status: status,
        };

        /* Update page number and page limit */
        setPageApproved(Number(page));
        setLimitApproved(Number(limit));

        getUploadReportPractices(searchApproved, page, limit, payload, getAccessTokenFromSession())
            .then((response) => {

                const { result, pagination } = response;

                const { count, data, original_count } = result;

                console.log(data);

                setApprovedRecords(data ?? []);
                setPageNumbersApproved(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchApproved?.trim() === "") {
                    setApprovedRecordsCount(Number(count));
                    setApprovedOriginalCount(Number(original_count));
                }
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchApproved, uploadReport?.id]);


    const loadCreatedRecords = useCallback((page, limit, status = "") => {
        if (!uploadReport?.id) return;

        let payload = {
            upload_report_id: uploadReport?.id,
            practice_status: status,
        };

        /* Update page number and page limit */
        setPageCreated(Number(page));
        setLimitCreated(Number(limit));

        getUploadReportPractices(searchCreated, page, limit, payload, getAccessTokenFromSession())
            .then((response) => {
                // eslint-disable-next-line
                const { result, pagination, status_code } = response;
                // eslint-disable-next-line
                const { count, data, original_count } = result;

                setCreatedRecords(data ?? []);
                setPageNumbersCreated(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchCreated?.trim() === "") {
                    setCreatedRecordsCount(Number(count));
                    setCreatedOriginalCount(Number(original_count));
                }
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }, [searchCreated, uploadReport?.id]);

    const loadNoActionRecords = useCallback((page, limit, status = "") => {
        if (!uploadReport?.id) return;

        let payload = {
            upload_report_id: uploadReport?.id,
            practice_status: status,
        };

        /* Update page number and page limit */
        setPageNoAction(Number(page));
        setLimitNoAction(Number(limit));

        getUploadReportPractices(searchNoAction, page, limit, payload, getAccessTokenFromSession())
            .then((response) => {
                // eslint-disable-next-line
                const { result, pagination, status_code } = response;
                // eslint-disable-next-line
                const { count, data, original_count } = result;

                setNoActionRecords(data ?? []);
                setPageNumbersNoAction(Number(pagination?.num_pages));

                // If not searching, set original total count.
                if (searchNoAction?.trim() === "") {
                    setNoActionRecordsCount(Number(count));
                    setNoActionOriginalCount(Number(original_count));
                }
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }, [searchNoAction, uploadReport?.id]);



    // useEffect(() => {
    //     setIsTableLoading(true);
    //     loadUpdatedRecords(pageUpdated, limitUpdated, UPDATED);
    //     loadErroredRecords(pageErrored, limitErrored, ERRORED);
    //     loadApprovedRecords(pageApproved, limitApproved, APPROVED);
    //     loadNoActionRecords(pageNoAction, limitNoAction, NO_ACTION);
    //     loadCreatedRecords(pageCreated, limitCreated, CREATED);
    // }, [setIsTableLoading, uploadReport, limitUpdated, loadUpdatedRecords, pageUpdated, limitErrored, loadErroredRecords, pageErrored, loadApprovedRecords, pageApproved, limitApproved, loadCreatedRecords, pageCreated, limitCreated, loadNoActionRecords, pageNoAction, limitNoAction]);

    /**
     * Load 'updated' records of Report.
     */
    useEffect(() => {
        setIsTableLoading(true);
        loadUpdatedRecords(pageUpdated, limitUpdated, UPDATED);
    }, [setIsTableLoading, pageUpdated, limitUpdated, loadUpdatedRecords]);

    useEffect(() => {
        loadUpdatedRecords(1, limitUpdated, UPDATED);
    }, [limitUpdated, searchUpdated, loadUpdatedRecords]);

    /**
     * Load 'errored' records of Report.
     */
    useEffect(() => {
        loadErroredRecords(pageErrored, limitErrored, ERRORED);
    }, [setIsTableLoading, pageErrored, limitErrored, loadErroredRecords]);

    useEffect(() => {
        loadErroredRecords(1, limitErrored, ERRORED);
    }, [limitErrored, searchErrored, loadErroredRecords]);

    /**
     * Load 'approved' records of Report.
     */
    useEffect(() => {
        loadApprovedRecords(pageApproved, limitApproved, APPROVED);
    }, [pageApproved, limitApproved, loadApprovedRecords]);

    useEffect(() => {
        loadApprovedRecords(1, limitApproved, APPROVED);
    }, [limitApproved, searchApproved, loadApprovedRecords]);


    /**
     * Load 'created' records of Report.
     */
    useEffect(() => {
        loadCreatedRecords(pageCreated, limitCreated, CREATED);
    }, [pageCreated, limitCreated, loadCreatedRecords]);

    useEffect(() => {
        loadCreatedRecords(1, limitCreated, CREATED);
    }, [limitCreated, searchCreated, loadCreatedRecords]);

    /**
     * Load 'no action' records of Report.
     */
    useEffect(() => {
        loadNoActionRecords(pageNoAction, limitNoAction, NO_ACTION);
    }, [pageNoAction, limitNoAction, loadNoActionRecords]);

    useEffect(() => {
        loadNoActionRecords(1, limitNoAction, NO_ACTION);
    }, [limitNoAction, searchNoAction, loadNoActionRecords]);

    /* === Approve/Deny  === */

    const onReviewClick = (e) => {
        setReviewIndex(e);
    };

    const onApproveClick = (e) => {
        setApproveId(e);
    };

    const onDenyClick = (e) => {
        setDenyId(e);
    };

    /**
     *
        List all checked listings
     */

    const onCheckChange = (e) => {
        var updatedCheckListId = [...itemIdList];

        if (!updatedCheckListId.includes(e.target.value) && e.target.checked === true) {
            updatedCheckListId = [...itemIdList, e.target.value];
        } else {
            updatedCheckListId.splice(itemIdList.indexOf(e.target.value), 1);
        }

        var itemPending = records.filter((item) => {
            return item.practice.approval_status === "csv_pending";
        })

        if (itemPending.length === updatedCheckListId.length) {
            checkAllItem = true;
        } else {
            checkAllItem = false;
        }

        setCheckListId(updatedCheckListId);
        setAllItemCheck(updatedCheckListId);
        setIsCheckAllItem(checkAllItem);
        itemCheckedList = updatedCheckListId;
    }

    const selectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        let newItem = [];
        var filterList = ['name', 'address', 'xray', 'phone', 'website', 'time', 'select_all'];
        var filterChecked = filterList.map(fl => fl);
        if (isCheckAll) {
            filterChecked = [];
        }
        setFilterChecked(filterChecked);
        checkedFilters = filterChecked;
        localStorage.setItem("storage_filter", JSON.stringify(checkedFilters));


        if (filterChecked.length !== 0) {
            newItem = updatedRecords.filter((newVal) => {
                let by_name;
                let by_address;
                let by_xray;
                let by_phone;
                let by_website;
                let orig_schedule = newVal.original_practice.schedules;
                let schedule = newVal.practice.schedules;
                let by_time;

                if (filterChecked.includes("name")) {
                    by_name = newVal.original_practice.name !== newVal.practice.name;
                }

                if (filterChecked.includes("address")) {
                    by_address = newVal.original_practice.address !== newVal.practice.address ||
                        newVal.original_practice.zip !== newVal.practice.zip ||
                        newVal.original_practice.city !== newVal.practice.city ||
                        newVal.original_practice.state !== newVal.practice.state ||
                        newVal.original_practice.latitude !== newVal.practice.latitude ||
                        newVal.original_practice.longitude !== newVal.practice.longitude;
                }

                if (filterChecked.includes("xray")) {
                    by_xray = newVal.original_practice.xray !== newVal.practice.xray;
                }

                if (filterChecked.includes("phone")) {
                    by_phone = newVal.original_practice.phone !== newVal.practice.phone
                }

                if (filterChecked.includes("website")) {
                    by_website = newVal.original_practice.website !== newVal.practice.website
                }

                if (filterChecked.includes("time")) {
                    by_time = !compareSchedules(orig_schedule, schedule);
                }

                return (by_name || by_address || by_xray || by_phone || by_website || by_time) && newVal.practice.approval_status !== "approved";
            });
        } else {
            newItem = updatedRecords;
        }

        setRecords(newItem);
        setFilters([]);
        setNumberOfRecords(newItem.length);
        if (checkAllItem) {
            var itemPending = newItem.filter((item) => {
                return item.practice.approval_status === "csv_pending";
            })
            itemCheckedList = itemPending.map(item => '' + item.practice.id);
        } else {
            itemCheckedList = [];
        }

        setCheckListId(itemCheckedList);
        setAllItemCheck(itemCheckedList);

        if (newItem.length !== 0) {
            tableDataAvailable = true;
        } else {
            tableDataAvailable = false;
        }
    }

    const selectFilters = (e) => {
        setFilterChecked([...isFilterChecked, e.target.value])
        if (!e.target.checked) {
            setFilterChecked(isFilterChecked.filter(item => item !== e.target.value));
        }
    }

    const filterField = (e) => {

        let newItem = [];
        var filter = [...filters];
        var selectAllFilters = [...isFilterChecked];
        var stored_filter = JSON.parse(localStorage.getItem("storage_filter")) ?? [];
        if (stored_filter) {
            filter = stored_filter;

            console.log(stored_filter + " <<<<<< this is the stored filters")
        }

        if (selectAllFilters.includes("select_all")) {
            filter = selectAllFilters;
            filter.splice(selectAllFilters.indexOf('select_all'), 1);

            if (!filter.includes(e.target.value)) {
                filter = [...filters, e.target.value];

                if (filter.length >= 6) {
                    setIsCheckAll(true);
                }
            } else {
                filter.splice(filter.indexOf(e.target.value), 1);
                setIsCheckAll(false);
            }
        } else {
            if (!filter.includes(e.target.value)) {
                filter = [...filters, e.target.value];
                if (filter.length >= 6) {
                    setIsCheckAll(true);
                    filter.push("select_all");
                }
            } else {
                filter.splice(filter.indexOf(e.target.value), 1);
                setIsCheckAll(false);
            }
        }

        setFilters(filter);
        localStorage.setItem("storage_filter", JSON.stringify(filter));
        console.log(filter)
        checkedFilters = filter;

        if (filter.length !== 0) {
            newItem = updatedRecords.filter((newVal) => {
                let by_name;
                let by_address;
                let by_xray;
                let by_phone;
                let by_website;

                let orig_schedule = newVal.original_practice.schedules;
                let schedule = newVal.practice.schedules;

                let by_time;

                if (filter.includes("name")) {
                    by_name = newVal.original_practice.name !== newVal.practice.name;
                }

                if (filter.includes("address")) {
                    by_address = newVal.original_practice.address !== newVal.practice.address ||
                        newVal.original_practice.zip !== newVal.practice.zip ||
                        newVal.original_practice.city !== newVal.practice.city ||
                        newVal.original_practice.state !== newVal.practice.state ||
                        newVal.original_practice.latitude !== newVal.practice.latitude ||
                        newVal.original_practice.longitude !== newVal.practice.longitude;
                }

                if (filter.includes("xray")) {
                    by_xray = newVal.original_practice.xray !== newVal.practice.xray;
                }

                if (filter.includes("phone")) {
                    by_phone = newVal.original_practice.phone !== newVal.practice.phone
                }

                if (filter.includes("website")) {
                    by_website = newVal.original_practice.website !== newVal.practice.website
                }

                if (filter.includes("time")) {
                    by_time = !compareSchedules(orig_schedule, schedule);
                }

                return (by_name || by_address || by_xray || by_phone || by_website || by_time) && newVal.practice.approval_status !== "approved";
            });
        } else {
            newItem = updatedRecords;
        }

        setRecords(newItem);
        setNumberOfRecords(newItem.length);
        if (checkAllItem) {
            var itemPending = newItem.filter((item) => {
                return item.practice.approval_status === "csv_pending";
            })
            itemCheckedList = itemPending.map(item => '' + item.practice.id);
        }

        setCheckListId(itemCheckedList);
        setAllItemCheck(itemCheckedList);

        if (newItem.length !== 0) {
            tableDataAvailable = true;
        } else {
            tableDataAvailable = false;
        }
    };

    const onCheckAllItem = (e) => {
        setIsCheckAllItem(!isCheckAllItem);

        if (e.target.checked) {
            setIsCheckAllItem(true);
            checkAllItem = true;
            var itemPending = records.filter((item) => {
                return item.practice.approval_status === "csv_pending";
            })
            itemCheckedList = itemPending.map(item => '' + item.practice.id);
            if (!e.target.checked) {
                setIsCheckAllItem(false);
                checkAllItem = false;
                setAllItemCheck(itemCheckedList.filter(id => id !== e.target.value));
                setCheckListId(itemCheckedList.filter(id => id !== e.target.value));
            }
        } else {
            setIsCheckAllItem(false);
            checkAllItem = false;
            itemCheckedList = [];
        }
        setCheckListId(itemCheckedList);
        setAllItemCheck(itemCheckedList);
    }

    const onApproveConfirm = () => {
        setIsLoading(true)
        approvePracticeUpdate(approveId, getAccessTokenFromSession())
            .then((response) => {
                console.log(response)
                window.location.reload();
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }

    const onBatchApproveConfirm = () => {
        setIsLoading(true)
        let payload = {
            "practice_ids": itemCheckedList
        }

        batchApprovePracticeUpdate(payload, getAccessTokenFromSession())
            .then((response) => {
                console.log(response)
                window.location.reload();
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }

    const onDenyConfirm = (e) => {
        setIsLoading(true)
        denyPracticeUpdate(denyId, getAccessTokenFromSession())
            .then((response) => {
                console.log(response)
                window.location.reload();
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }

    const onBatchDenyConfirm = () => {
        setIsLoading(true)
        let payload = {
            "practice_ids": itemCheckedList
        }

        batchDenyPracticeUpdate(payload, getAccessTokenFromSession())
            .then((response) => {
                console.log(response)
                window.location.reload();
            })
            .catch((error) => {
                console.log(`getUploadReportPractices(): ${error}`);
            });
    }

    /* === /Hooks === */

    const paginateToPage = (page = 1, status = "") => {
        /**
         * Parameters:
         * number: pageNumber
         * string: status (CREATED, UPDATED)
         */

        switch (status) {
            case UPDATED:
                setPageUpdated(Number(page));
                localStorage.setItem("page_num", Number(page));
                break;
            case ERRORED:
                setPageErrored(Number(page));
                break;
            case APPROVED:
                setPageApproved(Number(page));
                break;
            case CREATED:
                setPageCreated(Number(page));
                break;
            case NO_ACTION:
                setPageNoAction(Number(page));
                break;
            default:
        }
    };

    const onChangeSearch = (val, status = "") => {
        clearTimeout(searchDelay);
        searchDelay = setTimeout(() => {
            switch (status) {
                case UPDATED:
                    setSearchUpdated(val);
                    break;
                case ERRORED:
                    setSearchErrored(val);
                    break;
                case APPROVED:
                    setSearchApproved(val);
                    break;
                case CREATED:
                    setSearchCreated(val);
                    break;
                case NO_ACTION:
                    setSearchNoAction(val);
                    break;
                default:
            }
        }, 200);
    };

    const onChangeNumEntries = (val, status = "") => {
        switch (status) {
            case UPDATED:
                localStorage.setItem("page_limit", val);
                setLimitUpdated(val);
                localStorage.setItem("page_num", 1);
                localStorage.setItem("current_page_array", JSON.stringify([1,2,3,4,5,6,7]));
                if (val > 10) {
                    setLimitUpdatedOver(true);
                } else {
                    setLimitUpdatedOver(false);
                }
                break;
            case ERRORED:
                setLimitErrored(val);
                break;
            case APPROVED:
                setLimitApproved(val);
                break;
            case CREATED:
                setLimitCreated(val);
                break;
            case NO_ACTION:
                setLimitNoAction(val);
                break;
            default:
        }
    }

    if (isLoading) return <LoadingOverlay />;
    return (
        <div className="main-content">
            <div className="container p-3 p-md-5">
                <div className="row mb-4 p-0 mx-0">
                    <div className="col-6 px-0 mx-0">
                        <Link to="/reports-list" className="mb-4 d-table txt-color-purple text-decoration-none">
                            <i className="fa fa-arrow-left mr-2" /> Back to Reports list
                        </Link>
                        <h2 className="h1 txt-color-dark_purple font-weight-bold">Reports</h2>
                    </div>
                    <div className="col-md-6 px-0 mx-0 d-flex justify-content-end align-items-center">
                        <div className="row row-cols-3 ">
                            <div className="col-12">
                                <p>Filter:</p>
                            </div>
                            <div className="col-12">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterAllCheckbox" value="select_all" id="select_all" checked={isCheckAll} onChange={selectAll} />
                                <label htmlFor="select_all" className="form-control-input-checkbox-label small txt-color-secondary">Select All</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="name" id="check_name" checked={isFilterChecked.includes("name")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_name" className="form-control-input-checkbox-label small txt-color-secondary">Name</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="xray" id="check_xray" checked={isFilterChecked.includes("xray")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_xray" className="form-control-input-checkbox-label small txt-color-secondary">Xray</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="website" id="check_website" checked={isFilterChecked.includes("website")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_website" className="form-control-input-checkbox-label small txt-color-secondary">Website</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="address" id="check_address" checked={isFilterChecked.includes("address")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_address" className="form-control-input-checkbox-label small txt-color-secondary">Address</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="phone" id="check_phone" checked={isFilterChecked.includes("phone")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_phone" className="form-control-input-checkbox-label small txt-color-secondary">Phone</label>
                            </div>
                            <div className="col">
                                <input type="checkbox" className="form-control-input-checkbox" name="filterCheckbox" value="time" id="check_time" checked={isFilterChecked.includes("time")} onClick={selectFilters} onChange={filterField} />
                                <label htmlFor="check_time" className="form-control-input-checkbox-label small txt-color-secondary">Time</label>
                            </div>

                        </div>
                    </div>
                </div>



                {/* === Updated Records === */}
                <h5 className="mt-4">{`Title: ${uploadReport?.csvFilename}`}</h5>
                <p><strong>{`Uploaded: ${uploadReport?.uploadedAt}`}</strong></p>


                <p><strong>{updatedOriginalCount}</strong> Records for Review </p>
                <p>Showing {numberOfRecords} - {limitUpdated} of {updatedOriginalCount}</p>
                <CustomDataTable
                    key={`CustomDataTable-[${UPDATED}]`}
                    function_name={`CustomDataTable-[${UPDATED}]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    itemChecked={itemCheckedList}
                    checkFilters={checkedFilters}
                    data={records}
                    dataAvailable={tableDataAvailable}
                    dataTotalCount={Number(updatedRecordsCount)}
                    currentPage={Number(pageUpdated)}
                    numOfPages={Number(pageNumbersUpdated)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val, UPDATED)}
                    onChangePage={(val) => paginateToPage(val, UPDATED)}
                    onChangeSearch={(val) => onChangeSearch(val, UPDATED)}
                    onCheckAllItems={onCheckAllItem}
                    isCheckAllItem={isCheckAllItem}
                    isAllItemChecked={isAllItemCheck}
                    onReviewClick={onReviewClick}
                    onApproveClick={onApproveClick}
                    onDenyClick={onDenyClick}
                    onCheckChange={onCheckChange}
                    reviewIndex={reviewIndex}
                    onApproveConfirm={onApproveConfirm}
                    onBatchApproveConfirm={onBatchApproveConfirm}
                    onDenyConfirm={onDenyConfirm}
                    onBatchDenyConfirm={onBatchDenyConfirm}
                    convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                    compareSchedules={compareSchedules}
                    BASE_URL={BASE_URL}
                    isTableLoading={isTableLoading}
                    limitUpdated={limitUpdated}
                    limitUpdatedOver={limitUpdatedOver}
                    numberOfRecords={numberOfRecords}
                />

                {/* === Errored Records === */}
                {/* eslint-disable-next-line */}
                <h5 className="mt-4"></h5>

                <p><strong>{erroredOriginalCount}</strong> Records has error </p>
                <CustomDataTable
                    key={`CustomDataTable-[${ERRORED}]`}
                    function_name={`CustomDataTable-[${ERRORED}]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    dataAvailable={tableDataAvailable}
                    data={erroredRecords}
                    dataTotalCount={Number(erroredRecordsCount)}
                    currentPage={Number(pageErrored)}
                    numOfPages={Number(pageNumbersErrored)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val, ERRORED)}
                    onChangePage={(val) => paginateToPage(val, ERRORED)}
                    onChangeSearch={(val) => onChangeSearch(val, ERRORED)}
                    convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                    BASE_URL={BASE_URL}
                />

                {/* === Approved Records === */}
                {/* eslint-disable-next-line */}
                <h5 className="mt-4"></h5>

                <p><strong>{approvedOriginalCount}</strong> Records has been approved/denied </p>
                <CustomDataTable
                    key={`CustomDataTable-[${APPROVED}]`}
                    function_name={`CustomDataTable-[${APPROVED}]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    dataAvailable={tableDataAvailable}
                    data={approvedRecords}
                    dataTotalCount={Number(approvedRecordsCount)}
                    currentPage={Number(pageApproved)}
                    numOfPages={Number(pageNumbersApproved)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val, APPROVED)}
                    onChangePage={(val) => paginateToPage(val, APPROVED)}
                    onChangeSearch={(val) => onChangeSearch(val, APPROVED)}
                    convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                    BASE_URL={BASE_URL}
                />


                {/* === New Records === */}
                {/* eslint-disable-next-line */}
                <h5 className="mt-4"></h5>

                <p><strong>{createdOriginalCount}</strong> Records has been added</p>
                <CustomDataTable
                    key={`CustomDataTable-[${CREATED}]`}
                    function_name={`CustomDataTable-[${CREATED}]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    dataAvailable={tableDataAvailable}
                    data={createdRecords}
                    dataTotalCount={Number(createdRecordsCount)}
                    currentPage={Number(pageCreated)}
                    numOfPages={Number(pageNumbersCreated)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val, CREATED)}
                    onChangePage={(val) => paginateToPage(val, CREATED)}
                    onChangeSearch={(val) => onChangeSearch(val, CREATED)}
                    convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                    BASE_URL={BASE_URL}
                />

                {/* === No Action Records === */}
                {/* eslint-disable-next-line */}
                <h5 className="mt-4"></h5>

                <p><strong>{noActionOriginalCount}</strong> Records has no changes </p>
                <CustomDataTable
                    key={`CustomDataTable-[${NO_ACTION}]`}
                    function_name={`CustomDataTable-[${NO_ACTION}]`}
                    tableHeaders={TABLE_HEADERS}
                    itemParams={ITEM_PARAMS}
                    dataAvailable={tableDataAvailable}
                    data={noActionRecords}
                    dataTotalCount={Number(noActionRecordsCount)}
                    currentPage={Number(pageNoAction)}
                    numOfPages={Number(pageNumbersNoAction)}
                    onChangeNumEntries={(val) => onChangeNumEntries(val, NO_ACTION)}
                    onChangePage={(val) => paginateToPage(val, NO_ACTION)}
                    onChangeSearch={(val) => onChangeSearch(val, NO_ACTION)}
                    convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                    BASE_URL={BASE_URL}
                />


            </div>
        </div>
    );
}

function CustomDataTable({
    tableHeaders = [],
    itemParams = [],
    itemChecked = [],
    checkFilters = [],
    checked = false,
    data,
    dataAvailable = true,
    key,
    function_name,
    dataTotalCount = 0,
    currentPage = 1,
    numOfPages = 7,
    reviewIndex = null,
    isCheckAllItem = false,
    isAllItemChecked = [],
    onCheckAllItems = (e) => null,
    onChangeNumEntries = () => null,
    onChangePage = () => null,
    onChangeSearch = () => null,
    onReviewClick = (e) => null,
    onApproveClick = (e) => null,
    onDenyClick = (e) => null,
    onCheckChange = (e) => null,
    onApproveConfirm = (e) => null,
    onBatchApproveConfirm = (e) => null,
    onDenyConfirm = (e) => null,
    onBatchDenyConfirm = (e) => null,
    convert24HourFormatStringTo12HourFormatString = (e) => null,
    compareSchedules = (e) => null,
    BASE_URL = "",
    viewItemLink = "#",
    isTableLoading,
    limitUpdated,
    limitUpdatedOver,
    numberOfRecords,
}) {
    /* eslint-disable */
    return (
        <div className={"row mt-3 border-light_blue rounded-4 p-3 p-md-5 mx-0 " + function_name} name={function_name}>
            <div className="col-12 mt-2">
                <div className="w-100">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="top">
                            <div className="row mb-4">
                                <div className="col-12 col-md-6 show-entries">
                                    {/* <!-- SHOW NUMBER OF ENTry IN LIST --> */}
                                    {
                                        (function_name === "CustomDataTable-[updated]") &&
                                        <div
                                            className="dataTables_length"
                                            id="DataTables_Table_0_length">
                                            <label>
                                                Show <select
                                                    name="DataTables_Table_0_length"
                                                    aria-controls="DataTables_Table_0"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    value={localStorage.getItem("page_limit") ? localStorage.getItem("page_limit") : "250"}
                                                    onChange={(e) => onChangeNumEntries(e.target.value)}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="250">250</option>
                                                </select> entries
                                            </label>
                                        </div>
                                    }
                                    {
                                        (function_name !== "CustomDataTable-[updated]") &&
                                        <div
                                            className="dataTables_length"
                                            id="DataTables_Table_0_length">
                                            <label>
                                                Show <select
                                                    name="DataTables_Table_0_length"
                                                    aria-controls="DataTables_Table_0"
                                                    className="custom-select custom-select-sm form-control form-control-sm"
                                                    onChange={(e) => onChangeNumEntries(e.target.value)}>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="250">250</option>
                                                </select> entries
                                            </label>
                                        </div>
                                    }
                                </div>

                                {/* <!-- SEARCH FROM LIST --> */}
                                <div className="col-12 col-md-6 search-input">
                                    <div
                                        id="DataTables_Table_0_filter"
                                        className="dataTables_filter">
                                        <label>Search:
                                            <input
                                                type="search"
                                                className="form-control form-control-sm"
                                                placeholder="Search"
                                                aria-controls="DataTables_Table_0"
                                                onChange={(e) => onChangeSearch(e?.target?.value)}
                                            />
                                        </label>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {
                            (function_name === 'CustomDataTable-[updated]') &&
                            <BatchApproveDeny
                                isCheckAllItem={isCheckAllItem}
                                onCheckAllItems={onCheckAllItems}
                                function_name={function_name}
                                numRecords={numberOfRecords}
                            />
                        }
                        <div className="bottom">
                        </div>
                        <div className="clear" />
                    </div>

                    {/* Table data starts here! */}
                    {
                        (isTableLoading) &&
                        <>
                        <br/>
                            <div className="col-12 my-3">
                                <div className="row">
                                    <div className="col-md-9 d-flex justify-content-end">
                                        <Bars
                                            height="30"
                                            width="30"
                                            color="#6610f2"
                                            ariaLabel="bars-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        &nbsp;  Please wait for a moment while we load the data on the table . . .
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        (dataTotalCount > 0 && function_name === 'CustomDataTable-[updated]') &&
                        <div className="row no-gutters mb-5">
                            <div className="col-12">
                                <div className={"table-responsive tr-" + function_name} style={{ ...limitUpdatedOver ? {overflowY:"scroll", height:"500px"} : {}}}>
                                        <table className="table table-custom display dt-responsive text-prewrap w-100 table-approval" id={"table-"+function_name}>
                                        <thead className="thead-light rounded-10 text-nowrap">
                                            {<tr>
                                                {tableHeaders.map((e) => <th>{`${e}`}</th>)}
                                            </tr>}
                                        </thead>
                                        <tbody>
                                            <TableData
                                                data={data}
                                                dataAvailable={dataAvailable}
                                                function_name={function_name}
                                                itemParams={itemParams}
                                                viewItemLink={viewItemLink}
                                                onApproveClick={onApproveClick}
                                                onReviewClick={onReviewClick}
                                                onDenyClick={onDenyClick}
                                                onCheckChange={onCheckChange}
                                                isAllItemChecked={isAllItemChecked}
                                                itemChecked={itemChecked}
                                                checkFilters={checkFilters}
                                                checked={checked}
                                                convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                                                compareSchedules={compareSchedules}
                                                BASE_URL={BASE_URL}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (dataTotalCount > 0 && function_name !== 'CustomDataTable-[updated]') &&
                        <div className="row no-gutters mb-5">
                            <div className="col-12">
                                <div className={"table-responsive tr-" + function_name}>
                                        <table className="table table-custom display dt-responsive text-prewrap w-100 table-approval">
                                        <thead className="thead-light rounded-10 text-nowrap">
                                            {<tr>
                                                {tableHeaders.map((e) => <th>{`${e}`}</th>)}
                                            </tr>}
                                        </thead>
                                        <tbody>
                                            <TableData
                                                data={data}
                                                dataAvailable={dataAvailable}
                                                function_name={function_name}
                                                itemParams={itemParams}
                                                viewItemLink={viewItemLink}
                                                onApproveClick={onApproveClick}
                                                onReviewClick={onReviewClick}
                                                onDenyClick={onDenyClick}
                                                onCheckChange={onCheckChange}
                                                isAllItemChecked={isAllItemChecked}
                                                itemChecked={itemChecked}
                                                checkFilters={checkFilters}
                                                checked={checked}
                                                convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                                                compareSchedules={compareSchedules}
                                                BASE_URL={BASE_URL}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {/* <!-- Pagination control --> */}
                <Paginator
                    currentPage={Number(currentPage)}
                    pageMax={Number(numOfPages)}
                    paginateToPageAction={(val) => onChangePage(val)}
                    function_name={function_name}
                />

            </div>



            <div className="modal fade p-5" id="for-approval" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <ApproveModal
                                key="approval_modal"
                                data={data}
                                itemParams={itemParams}
                                viewItemLink={viewItemLink}
                                reviewIndex={reviewIndex}
                                onApproveClick={onApproveClick}
                                onDenyClick={onDenyClick}
                                convert24HourFormatStringTo12HourFormatString={convert24HourFormatStringTo12HourFormatString}
                                compareSchedules={compareSchedules}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade p-5" id="approve-approve" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">Are you sure you want to <span className="txt-color-purple font-weight-bold">Approve</span> update?</h3>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-solid btn-solid-orange mb-2 mb-md-0" onClick={() => onApproveConfirm()} data-dismiss="modal">Yes</button>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-outline btn-outline-orange" data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade p-5" id="approve-deny" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">Are you sure you want to <span className="txt-color-orange font-weight-bold">Deny</span> update?</h3>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-solid btn-solid-orange mb-2 mb-md-0" onClick={() => onDenyConfirm()} data-dismiss="modal">Yes</button>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-outline btn-outline-orange" data-dismiss="modal">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade p-5" id="approve-all" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h4 txt-color-dark_purple font-weight-bold pb-2 mx-0 mb-0">Approve All?</h3>
                                    <p>Are you sure you want to Approve all the suggested changes? </p>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-solid btn-solid-orange mb-2 mb-md-0" onClick={() => onBatchApproveConfirm()}>Yes</button>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-outline btn-outline-orange">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade p-5" id="deny-all" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Approval</h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <i className="fas fa-times-circle fa-xs"></i>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="row">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h4 txt-color-orange font-weight-bold pb-2 mx-0 mb-0">Deny All?</h3>
                                    <p>Are you sure you want to Deny all the suggested changes? </p>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-solid btn-solid-orange mb-2 mb-md-0" onClick={() => onBatchDenyConfirm()}>Yes</button>
                                        </div>
                                        <div className="col-md-4">
                                            <button className="w-100 d-inline-block btn btn-outline btn-outline-orange">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BatchApproveDeny({
    isCheckAllItem = false,
    onCheckAllItems = (e) => null,
    function_name = ``,
    numRecords = 0,
}) {
    if (function_name = 'CustomDataTable-[updated]') {
        return (
            <div className="col-12 my-3">
                <div className="row d-flex justify-content-end">
                    <div className="col-md-6 d-flex justify-content-end">
                        <a href="#" className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none mx-1" data-toggle="modal" data-target="#approve-all">
                            <span className="d-sm-inline">Approve All</span>
                        </a>
                        <a href="#" className="btn-outline-orange-sm rounded-20 px-2 py-1 float-right text-decoration-none  mx-1" data-toggle="modal" data-target="#deny-all">
                            <span className="d-sm-inline">Deny All</span>
                        </a>
                        <input type="checkbox" className="form-control-input-checkbox" name="batch_check" id="batch_check_all" value="check_all_item" checked={isCheckAllItem} onChange={onCheckAllItems} />
                        <label htmlFor="batch_check_all" className="form-control-input-checkbox-label small txt-color-secondary m-0 mx-2"> </label>
                    </div>
                </div>
            </div>
        )
    } else {
        return
    }
}


function ApproveModal({
    data = [],
    itemParams = [],
    reviewIndex = null,
    onApproveClick = () => null,
    onDenyClick = () => null,
    convert24HourFormatStringTo12HourFormatString = () => null,
    compareSchedules = () => null,
}) {

    var current_layout = [];
    let i = reviewIndex;


    let item = data[i]?.practice;
    let original_item = data[i]?.original_practice;
    let schedules = item?.schedules;
    let original_schedules = original_item?.schedules;



    let currentData = [];
    for (var k in itemParams) {
        let _key = itemParams[k];
        if (_key == "approval_status") {
            currentData.push(
                <div className="col-12 mb-3">
                </div>
            )
        }
        else if (_key == "hr_of_operation" && !compareSchedules(original_schedules, schedules)) {
            let schedule_data = [];


            for (let i in original_schedules) {
                let day = original_schedules[i]?.occurrence?.name;
                let opening = convert24HourFormatStringTo12HourFormatString(original_schedules[i]?.opening);
                let closing = convert24HourFormatStringTo12HourFormatString(original_schedules[i]?.closing);
                schedule_data.push(
                    <input type="text" className="form-control" value={`${day} : ${opening}-${closing}`} disabled />
                )
            }

            currentData.push(
                <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">Hours of Operation</label>
                    {schedule_data}
                </div>
            )

        }

        else if (_key == "xray" && original_item?.[_key] != item?.[_key]) {

            switch (original_item?.[_key]) {
                case true:
                    currentData.push(
                        <div className="col-12 mb-3">
                            <label htmlFor="" className="form-label">{_key}</label>
                            <input type="text" className="form-control" value="Yes" disabled />
                        </div>
                    )
                    break;
                case false:
                    currentData.push(
                        <div className="col-12 mb-3">
                            <label htmlFor="" className="form-label">{_key}</label>
                            <input type="text" className="form-control" value="No" disabled />
                        </div>
                    )
                    break;
                default:
                    break;
            }
        }
        else if (original_item?.[_key] != item?.[_key]) {
            currentData.push(
                <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">{_key}</label>
                    <input type="text" className="form-control" value={original_item?.[_key]} disabled />
                </div>
            )
        };
    }

    current_layout.push(
        <div className="form-row">
            {currentData}
        </div>
    )


    var suggested_layout = [];


    let suggestedData = [];
    for (var k in itemParams) {
        let _key = itemParams[k];
        if (_key == "approval_status") {
            suggestedData.push(
                <div className="col-12 mb-3">
                </div>
            )
        }
        else if (_key == "hr_of_operation" && !compareSchedules(original_schedules, schedules)) {
            let schedule_data = [];

            for (let i in schedules) {
                let day = schedules[i]?.occurrence?.name;
                let opening = convert24HourFormatStringTo12HourFormatString(schedules[i]?.opening);
                let closing = convert24HourFormatStringTo12HourFormatString(schedules[i]?.closing);
                schedule_data.push(
                    <input type="text" className="form-control" value={`${day} : ${opening}-${closing}`} disabled />
                )
            }
            suggestedData.push(
                <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">Hours of Operation</label>
                    {schedule_data}
                </div>
            )

        }

        else if (_key == "xray" && original_item?.[_key] != item?.[_key]) {

            switch (item?.[_key]) {
                case true:
                    suggestedData.push(
                        <div className="col-12 mb-3">
                            <label htmlFor="" className="form-label">{_key}</label>
                            <input type="text" className="form-control" value="Yes" disabled />
                        </div>
                    )
                    break;
                case false:
                    suggestedData.push(
                        <div className="col-12 mb-3">
                            <label htmlFor="" className="form-label">{_key}</label>
                            <input type="text" className="form-control" value="No" disabled />
                        </div>
                    )
                    break;
                default:
                    break;
            }
        }
        else if (original_item?.[_key] != item?.[_key]) {
            suggestedData.push(
                <div className="col-12 mb-3">
                    <label htmlFor="" className="form-label">{_key}</label>
                    <input type="text" className="form-control" value={item?.[_key]} disabled />
                </div>
            )
        };
    }

    suggested_layout.push(
        <div className="form-row">
            {suggestedData}
        </div>
    )



    return (
        <>
            <div className="row">
                <div className="col-md-6 mb-4">
                    <h6 className="txt-color mb-4">
                        Current
                    </h6>
                    {current_layout}

                </div>
                <div className="col-md-6 mb-4">
                    <h6 className="txt-color mb-4">
                        Suggested Edit
                    </h6>
                    {suggested_layout}
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-3 px-1 mb-3">
                    <button className="btn btn-solid-orange px-5 w-100" data-toggle="modal" data-target="#approve-approve" data-dismiss="modal" onClick={() => onApproveClick(item?.id)}>Approve</button>
                </div>
                <div className="col-md-3 px-1 mb-3">
                    <button className="btn btn-outline-purple px-5 w-100" data-toggle="modal" data-target="#approve-deny" data-dismiss="modal" onClick={() => onDenyClick(item?.id)}>Deny</button>
                </div>

            </div>
        </>
    )
}

function TableLoading () {
    return (
        <div className='m-1 text-center align-self-center'>
            <i className="fa fa-refresh loading-icon" aria-hidden="true"></i>
        </div>
    )
}


function TableData({
    data = [],
    dataAvailable = true,
    function_name = ``,
    itemParams = [],
    itemChecked = [],
    isAllItemChecked = [],
    checkFilters = [],
    viewItemLink = "#",
    checked = false,
    onReviewClick = (e) => null,
    onDenyClick = (e) => null,
    onCheckChange = (e) => null,
    convert24HourFormatStringTo12HourFormatString = () => null,
    compareSchedules = () => null,
    BASE_URL = "",
}) {
    /**
     * Each item in data should have an 'id' attribute.
     */
    var layout = [];

    if (function_name === 'CustomDataTable-[updated]' || function_name === "CustomDataTable-[approved]") {
        if (dataAvailable === true || (function_name === "CustomDataTable-[approved]" && dataAvailable === false)) {
            for (let i in data) {
                let item = data[i]?.practice;
                let original_item = data[i]?.original_practice;

                let tableData = [];


                if (data[i]?.status === "updated") {
                    for (var k in itemParams) {

                        let _key = itemParams[k];
                        if (_key == "_action_view") {
                            tableData.push(
                                <td key={`(${k})-TableData-${_key}`}>
                                    <a
                                        href={`${viewItemLink}/${item?.id}`}
                                        // target="_blank"
                                        className="text-decoration-none d-flex txt-color-orange align-items-center"
                                    >
                                        <i className="fas fa-eye mr-0 mr-sm-2"></i>
                                        <span className="d-none d-sm-block small">
                                            View
                                        </span>
                                    </a>
                                </td>
                            );
                        }
                        else if (_key == "xray") {
                            switch (item?.[_key]) {
                                case true:
                                    if (original_item?.[_key] != item?.[_key]) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">Yes</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}>Yes</td>)
                                    };
                                    break;
                                case false:
                                    if (original_item?.[_key] != item?.[_key]) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">No</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}>No</td>)
                                    };
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (_key == "approval_status" || _key == "review") {
                            switch (item?.[_key]) {
                                case "csv_pending":

                                    tableData.push(<td className="text=center" key={`(${k})-TableData-${_key}`}>
                                        <NavLink to="#" className="text-decoration-none txt-color-purple" data-toggle="modal" data-target="#for-approval" onClick={() => onReviewClick(i)}>Review</NavLink>|
                                        <NavLink to="#" className="text-decoration-none txt-color-orange " data-toggle="modal" data-target="#approve-deny" onClick={() => onDenyClick(item.id)}>Deny</NavLink>
                                        {/* <a href="javascript:void(0)" className="text-decoration-none txt-color-purple" data-toggle="modal" data-target="#for-approval" onClick={() => onReviewClick(i)}>Review </a>|
                                        <a href="javascript:void(0)" className="text-decoration-none txt-color-orange " data-toggle="modal" data-target="#approve-deny" onClick={() => onDenyClick(item.id)}> Deny</a> */}
                                    </td>)
                                    var ids = '' + item.id;

                                    tableData.push(<td className="text=center" key={`(${k})-TableData-review`}>
                                        <input type="checkbox" className="form-control-input-checkbox" id={`check_${item.id}`} value={item.id} checked={isAllItemChecked.includes(ids)} onChange={onCheckChange} />
                                        <label htmlFor={`check_${item.id}`} className="form-control-input-checkbox-label small txt-color-secondary m-0 mx-2"> </label>
                                    </td>)
                                    break;
                                case "approved":
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="font-italic text-muted font-weight-lighter">Approved</span></td>);
                                    tableData.push(<td key={`(${k})-TableData-blank-approved`}></td>);
                                    break;
                                case "approved_history":
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="font-italic text-muted font-weight-lighter">Approved</span></td>);
                                    tableData.push(<td key={`(${k})-TableData-blank-approved`}></td>);
                                    break;
                                case "csv_denied":
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="font-italic text-muted font-weight-lighter">Denied</span></td>);
                                    tableData.push(<td key={`(${k})-TableData-blank-csv-denied`}></td>);
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (_key == "review") {
                            switch (item?.[_key]) {
                                case "csv_pending":
                                    // if (!itemChecked.includes((item.id).toString())) {
                                    tableData.push(<td className="text=center" key={`(${k})-TableData-${_key}`}>
                                        <input type="checkbox" className="form-control-input-checkbox" id={`check_${item.id}`} value={item.id} onChange={onCheckChange} />
                                        <label htmlFor={`check_${item.id}`} className="form-control-input-checkbox-label small txt-color-secondary m-0 mx-2"> </label>
                                    </td>)
                                    break;
                                case "approved":
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}></td>);
                                    break;
                                case "csv_denied":
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}></td>);
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (_key == "website") {
                            if (original_item?.[_key] != item?.[_key]) {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger text-wrap">{item?.[_key]}</span></td>)
                            }
                            else {
                                tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">{item?.[_key]}</td>)
                            };
                        }
                        else if (_key == "antibodyTest") {
                            switch (item?.antibodyTestServices.length !== 0) {
                                case true:
                                    if (original_item?.antibodyTestServices.length != item?.antibodyTestServices.length) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger text-wrap">Yes</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">Yes</td>)
                                    };
                                    break;
                                case false:
                                    if (original_item?.antibodyTestServices.length != item?.antibodyTestServices.length) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger text-wrap">No</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">No</td>)
                                    };
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (_key == "covid19Test") {
                            switch (item?.covid19TestServices.length !== 0) {
                                case true:
                                    if (original_item?.covid19TestServices.length != item?.covid19TestServices.length) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger text-wrap">Yes</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">Yes</td>)
                                    };
                                    break;
                                case false:
                                    if (original_item?.covid19TestServices.length != item?.covid19TestServices.length) {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger text-wrap">No</span></td>)
                                    }
                                    else {
                                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">No</td>)
                                    };
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (_key == "hr_of_operation") {
                            let schedules = item?.schedules;
                            let schedulesLength = schedules?.length;
                            let dayStart = schedules[0]?.occurrence.name.slice(0, 3);
                            let dayEnd = schedules[schedulesLength - 1]?.occurrence.name.slice(0, 3);
                            let timeStart = schedules[0]?.opening;
                            let timeEnd = schedules[0]?.closing;

                            let original_schedules = original_item?.schedules ?? [];
                            let originalSchedulesLength = original_schedules?.length;
                            let originalDayStart = original_schedules[0]?.occurrence?.name.slice(0, 3);
                            let originalDayEnd = original_schedules[originalSchedulesLength - 1]?.occurrence.name.slice(0, 3);
                            let originalTimeStart = original_schedules[0]?.opening;
                            let originalTimeEnd = original_item?.schedules[0]?.closing;


                            const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                            original_schedules.sort(function (a, b) {
                                return days.indexOf(a.occurrence.name) > days.indexOf(b.occurrence.name);
                            });
                            schedules.sort(function (a, b) {
                                return days.indexOf(a.occurrence.name) > days.indexOf(b.occurrence.name);
                            });


                            if (timeStart !== undefined && originalTimeStart !== undefined) {

                                if (!compareSchedules(original_schedules, schedules)) {
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</span></td>)
                                }
                                else {
                                    tableData.push(<td key={`(${k})-TableData-${_key}`}>{originalDayStart}-{originalDayEnd} {convert24HourFormatStringTo12HourFormatString(originalTimeStart)}-{convert24HourFormatStringTo12HourFormatString(originalTimeEnd)}</td>)
                                };
                            }

                            else if (timeStart !== undefined && originalTimeStart == undefined) {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</span></td>)
                            }
                            else if (timeStart == undefined && originalTimeStart !== undefined) {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">{originalDayStart}-{originalDayEnd} {convert24HourFormatStringTo12HourFormatString(originalTimeStart)}-{convert24HourFormatStringTo12HourFormatString(originalTimeEnd)}</span></td>)
                            }
                            else {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>No Schedule</td>)
                            }



                        }
                        else if (_key == "practice_id") {
                            tableData.push(<td key={`(${k})-TableData-${_key}`}><a href={`${BASE_URL}/listings/${item?.[_key]}`} target="_blank" rel="noreferrer">{item?.[_key]}</a></td>)
                        }
                        else {
                            if (original_item?.[_key] != item?.[_key]) {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger" category="updated">{item?.[_key]}</span></td>)
                            }
                            else {
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>{item?.[_key]}</td>)
                            };
                        }
                    }
                }

                layout.push(
                    <tr key={`TableRow-${i}`}>
                        {tableData}
                    </tr>
                );
            }
        } else {
            layout.push(
                <tr key={`no_data`}>
                    <td colSpan={12}>No Data Available</td>
                </tr>
            );
        }
    } else {
        for (let i in data) {
            let item = data[i]?.practice;
            let original_item = data[i]?.original_practice;

            let tableData = [];
            if (data[i]?.status !== "updated" && data[i]?.status !== "error") {
                for (var k in itemParams) {
                    let _key = itemParams[k];
                    if (_key == "_action_view") {
                        tableData.push(
                            <td key={`(${k})-TableData-${_key}`}>
                                <a
                                    href={`${viewItemLink}/${item?.id}`}
                                    // target="_blank"
                                    className="text-decoration-none d-flex txt-color-orange align-items-center"
                                >
                                    <i className="fas fa-eye mr-0 mr-sm-2"></i>
                                    <span className="d-none d-sm-block small">
                                        View
                                    </span>
                                </a>
                            </td>
                        );
                    }
                    else if (_key == "xray") {
                        switch (item?.[_key]) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>Yes</td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>No</td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "website") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap">{item?.[_key]}</td>)
                    }
                    else if (_key == "antibodyTest") {
                        switch (item?.antibodyTestServices.length !== 0) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>Yes</td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>No</td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "covid19Test") {
                        switch (item?.covid19TestServices.length !== 0) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>Yes</td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}>No</td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "approval_status") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}>Approved</td>);
                    }
                    else if (_key == "hr_of_operation") {
                        let schedules = item?.schedules;
                        let schedulesLength = schedules?.length;

                        // if (timeStart !== undefined) {
                        //     tableData.push(<td key={`(${k})-TableData-${_key}`}>{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</td>)
                        // }
                        // else {

                        if (schedulesLength == 0 || schedulesLength == undefined) {
                            tableData.push(<td key={`(${k})-TableData-${_key}`}>No Schedule</td>)
                        }
                        else {
                            let dayStart = schedules[0]?.occurrence.name.slice(0, 3);
                            let dayEnd = schedules[schedulesLength - 1]?.occurrence.name.slice(0, 3);
                            let timeStart = schedules[0]?.opening;
                            let timeEnd = schedules[0]?.closing;

                            tableData.push(<td key={`(${k})-TableData-${_key}`}>{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</td>)
                        }
                        // }
                    }
                    else if (_key == "practice_id") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}><a href={`${BASE_URL}/listings/${item?.[_key]}`} target="_blank" rel="noreferrer">{item?.[_key]}</a></td>)
                    }
                    else {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}>{item?.[_key]}</td>);
                    }
                }
            } else if (data[i]?.status === "error") {
                for (var k in itemParams) {
                    let _key = itemParams[k];
                    if (_key == "_action_view") {
                        tableData.push(
                            <td key={`(${k})-TableData-${_key}`}>
                                <a
                                    href={`${viewItemLink}/${item?.id}`}
                                    // target="_blank"
                                    className="text-decoration-none d-flex txt-color-orange align-items-center"
                                >
                                    <i className="fas fa-eye mr-0 mr-sm-2"></i>
                                    <span className="d-none d-sm-block small" > 
                                        View
                                    </span>
                                </a>
                            </td>
                        );
                    }
                    else if (_key == "xray") {
                        switch (item?.[_key]) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>Yes</span></td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>No</span></td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "website") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`} className="text-wrap"><span className='text-danger'>{item?.[_key]}</span></td>)
                    }
                    else if (_key == "antibodyTest") {
                        switch (item?.antibodyTestServices.length !== 0) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>Yes</span></td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>No</span></td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "covid19Test") {
                        switch (item?.covid19TestServices.length !== 0) {
                            case true:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>Yes</span></td>)
                                break;
                            case false:
                                tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>No</span></td>)
                                break;
                            default:
                                break;
                        }
                    }
                    else if (_key == "approval_status") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">Error</span></td>);
                    }
                    else if (_key == "hr_of_operation") {
                        let schedules = item?.schedules;
                        let schedulesLength = schedules?.length;

                        // if (timeStart !== undefined) {
                        //     tableData.push(<td key={`(${k})-TableData-${_key}`}>{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</td>)
                        // }
                        // else {

                        if (schedulesLength == 0 || schedulesLength == undefined) {
                            tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>No Schedule</span></td>)
                        }
                        else {
                            let dayStart = schedules[0]?.occurrence.name.slice(0, 3);
                            let dayEnd = schedules[schedulesLength - 1]?.occurrence.name.slice(0, 3);
                            let timeStart = schedules[0]?.opening;
                            let timeEnd = schedules[0]?.closing;

                            tableData.push(<td key={`(${k})-TableData-${_key}`}><span className='text-danger'>{dayStart}-{dayEnd} {convert24HourFormatStringTo12HourFormatString(timeStart)}-{convert24HourFormatStringTo12HourFormatString(timeEnd)}</span></td>)
                        }
                        // }
                    }
                    else if (_key == "practice_id") {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}><a href={`${BASE_URL}/listings/${item?.[_key]}`} target="_blank" rel="noreferrer"><span className="text-danger">{item?.[_key]}</span></a></td>)
                    }
                    else {
                        tableData.push(<td key={`(${k})-TableData-${_key}`}><span className="text-danger">{item?.[_key]}</span></td>);
                    }
                }
            }
            layout.push(
                <tr key={`TableRow-${i}`}>
                    {tableData}
                </tr>
            );
        }
    }

    return layout;
}

function Paginator({
    currentPage = 1,
    pageMax = 0,
    limit = 7,
    checkedFilters = [],
    paginateToPageAction = function () { },
    function_name,
}) {
    /**
     * Parameters:
     * number: pageMax = the total / max number of pages. (Default: 0)
     * number: limit = the number of pages per set. (Default: 7)
     * function: paginateToPageAction = function to move to another page.
     */
    let page_num = localStorage.getItem("page_num") ?? 1;
    if (function_name === 'CustomDataTable-[updated]') {
        if (page_num) {
            currentPage = page_num;
        }
    }

    // console.log("This is the number of limit", pageMax);

    const initPageArray = () => {
        // Returns an array of numbers.
        let r;
        if (Number(pageMax) < Number(limit)) {
            // If (N = total number of pages) is less than (L = limit per set),
            // create range and use (N) as the last page for the first set.
            r = range(pageMax, 1);
        }
        else {
            // Else, create range and use L as the last page for the first set.
            r = range(limit, 1);
        }
        return r;
    };

    let [currentPageArray, setCurrentPageArray] = useState(initPageArray());
    const [prevMin, setPrevMin] = useState(1);
    const [prevMax, setPrevMax] = useState(limit);

    /* TODO: Figure out where to use these */
    const [numOfPages, setNumOfPages] = useState(pageMax);
    const [limitPerSet, setLimitPerSet] = useState(limit);

    /**
     * If last known minimum page changes,
     * retain the last page array even if no items available for render.
     */
    // useEffect(() => {
    //     if (prevMin > 1) {
    //         let r = range(limit, prevMin);
    //         setCurrentPageArray(r);
    //     }
    //     else {
    //         setCurrentPageArray(initPageArray());
    //         setPrevMin(1);
    //     }
    // }, [pageMax])

    /**
     * Resets the page array every time the total number of pages changes.
     */
    useEffect(() => {
        setCurrentPageArray(initPageArray());
        setPrevMin(1);
    }, [pageMax]);

    const paginateLeftAction = () => {
        let { newRange, min, max } = adjustRangeOffset(currentPageArray, -(Number(limit)));

        if (function_name === 'CustomDataTable-[updated]') {
            localStorage.setItem("current_page_array", JSON.stringify(newRange));
        }

        setCurrentPageArray(newRange);
        setPrevMin(min);
        setPrevMax(max);
    };

    const paginateRightAction = () => {
        let { newRange, min, max } = adjustRangeOffset(currentPageArray, limit);

        if (function_name === 'CustomDataTable-[updated]') {
            localStorage.setItem("current_page_array", JSON.stringify(newRange));
        }

        if (Number(pageMax) < Number(max)) {
            if (Number(prevMax) >= Number(pageMax)) return;

            let finalRange = [...newRange];
            let finalMax = Number(finalRange.pop());
            let finalMin = Number(prevMax) + 1;

            setCurrentPageArray(newRange);
            setPrevMin(finalMin);
            setPrevMax(finalMax);
        }
        else {
            setCurrentPageArray(newRange);
            setPrevMin(min);
            setPrevMax(max);
        }
    };

    const PaginatorItems = () => {
        let items = [];

        // if (function_name === 'CustomDataTable-[updated]') {
        //     console.log("Original array", currentPageArray);
        //     console.log("Current stored in local", localStorage.getItem("current_page_array"));
        //     let storeCurrentPage = localStorage.getItem("current_page_array") ? JSON.parse(localStorage.getItem("current_page_array")) : [];
        //     console.log("123213", storeCurrentPage);
        //     storeCurrentPage = storeCurrentPage.length != currentPageArray.length ? currentPageArray : storeCurrentPage;
        //     console.log("dsadas", storeCurrentPage);
        //     currentPageArray = storeCurrentPage;
        //     console.log("Current page array", currentPageArray)
        //     localStorage.setItem("current_page_array", JSON.stringify(currentPageArray));
        // }

        let storeCurrentPageArray = JSON.parse(localStorage.getItem("current_page_array")) ?? [];

        if (function_name === 'CustomDataTable-[updated]') {
            if (storeCurrentPageArray.length > 0) {
                currentPageArray = JSON.parse(localStorage.getItem("current_page_array"));
            } else {
                localStorage.setItem("current_page_array", JSON.stringify(currentPageArray));
            }
        }


        for (let i in currentPageArray) {
            let num = Number(currentPageArray[i]);
            let showPage = (Number(pageMax) >= num);
            let _currentPage_ = Number(currentPage);
            let isActive = (_currentPage_ === num);

            // add filter function
            items.push(
                <li key={`PaginatorItems-${i}`} className={`page-item text-center ${(isActive) ? 'active' : ''}`} style={{ width: "50px" }}>
                    {
                        showPage &&
                        <a
                            className="page-link txt-color-purple"
                            onClick={() => (!isActive) ? paginateToPageAction(num) : false}>
                            {num}
                        </a>
                    }
                </li>
            );
        }

        return items;
    };

    const PaginatorContainer = () => {
        return (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className="page-item text-center">
                        <button className="page-link txt-color-purple" onClick={() => paginateLeftAction()}><i className="fa fa-angle-double-left" /></button>
                    </li>
                    <PaginatorItems />
                    <li className="page-item text-center">
                        <button className="page-link txt-color-purple" onClick={() => paginateRightAction()}><i className="fa fa-angle-double-right" /></button>
                    </li>
                </ul>
            </nav>
        );
    };

    return <PaginatorContainer />;

}


export default CSVUploadReports;
