const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
    "October", "November", "December"];

const parseTimeString = (time) => {
    const time_split = time.split(":");
    return {
        hours: time_split[0],
        minutes: time_split[1],
        seconds: time_split[2],
    }
};

const getTimeDecimalEquivalent = (time) => {
    const time_split = parseTimeString(time);
    return (parseInt(time_split.hours) + parseInt(time_split.minutes) / 60);
};

const isStillOpenV2 = (opening, closing) => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    const currentTime = Number(currentHour) + (Number(currentMinutes) / 60);

    if (currentTime < opening && currentTime < closing) {
        const openingIn = Math.ceil(opening - currentTime);
        return {
            status: "Open today",
            message: `Opens in ${openingIn} hour${openingIn > 1 ? "s" : ""}`
        };
    } else if (currentTime >= opening && currentTime < closing) {
        const closingIn = Math.ceil(closing - currentTime)
        return {
            status: "Open now",
            message: `Closes in ${closingIn} hour${closingIn > 1 ? "s" : ""}`
        };
    } else {
        return {
            status: "Closed",
            message: ""
        }
    }
}

export {
    isStillOpenV2,
    getTimeDecimalEquivalent,
    DAYS,
    MONTHS,
}