import React from 'react';
import { Link, useLocation } from "react-router-dom";

const NavigationBar = (props) => {
    const currentPathName = useLocation().pathname;
    const displayName = (props.user.user.first_name && props.user.user.last_name) ?  `${props.user.user.first_name} ${props.user.user.last_name}` : props.user.user.email;
    const listingsLinkTest = /\/listings.*/;
    
    return (
        <div className="sidebar-container">
            <div className="sidebar-profile-container">
                <div className="sidebar-profile-content">
                    {/*TODO: Profile Picture*/}
                    {/*<div className="sidebar-profile-content-img">*/}
                    {/*    <img src="img/logo.svg" alt="" />*/}
                    {/*</div>*/}
                    <div className="sidebar-profile-content-details">
                        <p className="sidebar-profile-content-details-name">
                            {displayName}
                        </p>
                        <Link to='/settings' className="sidebar-profile-content-details-view">View Profile</Link>
                    </div>
                </div>
            </div>

            <div className="sidebar-nav-container">


                <Link to='/' className={`sidebar-nav-item ${listingsLinkTest.test(currentPathName) ? "active" : ""}`}>My Locations</Link>
                {/* <Link to='/membership-plans' className={`sidebar-nav-item ${currentPathName == '/membership-plans' ? "active" : ""}`}>Membership Plans</Link> */}
                <Link to='/subscriptions' className={`sidebar-nav-item ${currentPathName === '/subscriptions' ? "active" : ""}`}>Subscription & Billing</Link>
                <Link to='/products' className={`sidebar-nav-item ${currentPathName === '/products' ? "active" : ""}`}>Order Products</Link>
                <Link to='/settings' className={`sidebar-nav-item ${currentPathName === '/settings' ? "active" : ""}`}>Settings</Link>
                <Link to='/contact' className={`sidebar-nav-item ${currentPathName === '/contact' ? "active" : ""}`}>Contact Us</Link>

            </div>

            <div className="sidebar-logout-container">
                <button className="btn btn-solid btn-solid-dark_purple w-100 text-center rounded-100" onClick={props.onLogout}><i
                    className="fas fa-power-off mr-2" ></i> Logout
                </button>
            </div>

        </div>
    );
};

export default NavigationBar;