// eslint-disable-next-line
import React, {useEffect, useState} from 'react';
import {EMAIL_REGEX_PATTERN} from "../../../helpers/accountAPIServices";
import {Link} from "react-router-dom";
import {getAccessTokenFromSession} from "../../../helpers/sessionHelpers";
import {sendInquiry} from "../../../helpers/contactUsAPIServices";
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";

const ContactUsPage = (props) => {
    const [fields, setFields] = useState({
        first_name: '',
        last_name: '',
        email: '',
        message: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(null);
    const [isEmptyField, setIsEmptyField] = useState(false);

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        email_invalid: false,
        message: false,
    });

    const handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        const newFields = {
            ...fields,
        };

        newFields[fieldName] = fieldValue;

        setFields(newFields);
        setIsEmptyField(false);
    };

    // useEffect(() => {
    //     const newErrors = {
    //         ...errors,
    //     };

    //     newErrors.first_name = fields.first_name === "";
    //     newErrors.last_name = fields.last_name === "";
    //     newErrors.email = fields.email === "";
    //     newErrors.email_invalid = !EMAIL_REGEX_PATTERN.test(fields.email);
    //     newErrors.message = fields.message === "";


    //     console.log(newErrors)

    //     setErrors(newErrors);
    // }, [errors, fields]);

    const handleSubmitClick = async () => {
        setIsSuccess(false);
        setIsLoading(true);

        const newErrors = {
            ...errors,
        };

        newErrors.first_name = fields.first_name === "";
        newErrors.last_name = fields.last_name === "";
        newErrors.email = fields.email === "";
        newErrors.email_invalid = !EMAIL_REGEX_PATTERN.test(fields.email);
        newErrors.message = fields.message === "";

        setErrors(newErrors);

        if (newErrors.first_name || newErrors.last_name || newErrors.email_invalid || newErrors.email || newErrors.message) {
            console.log(newErrors);
            setIsEmptyField(true);
        } else {
            const sendInquiryResults = await sendInquiry(fields, getAccessTokenFromSession());

            if (sendInquiryResults.error) {
                setIsError(sendInquiryResults.error);
                setIsLoading(false);
                return;
            }

            setIsLoading(false);
            setIsSuccess(true);
        }
    };

    return (
        <>
            {
                isLoading ? <LoadingOverlay /> :
                    <div className="main-content">
                        <div className="container p-3 p-md-5">
                            {
                                isSuccess &&
                                <div className="alert alert-success mb-5" role="alert">
                                    <i className="fa fa-check-circle mr-2"></i> Your inquiry has been successfully sent. Please, give us time to respond to the inquiry. Thank you!
                                </div>
                            }
                            {
                                isError && <div className="alert alert-success error-message mb-5" role="alert">
                                    <i className="fa fa-exclamation-circle mr-2"></i>{isError}
                                </div>
                            }
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-6 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Contact Us</h2>
                                </div>
                            </div>

                            <div className="row mt-5 mx-0">

                                <div className="col-12 col-lg-6  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                    <div className="d-table w-100 ">
                                    </div>

                                    <div className="w-100 mb-4 pb-0">

                                        <div className="form-row mb-3">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">First Name</label>
                                                <input type="text" className="form-control" placeholder="" value={fields.first_name} name='first_name' onChange={handleFieldChange}/>
                                                {
                                                    errors.first_name &&
                                                    <small className="py-2 d-table txt-color-secondary">
                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                    </small>
                                                }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">Last Name</label>
                                                <input type="text" className="form-control" placeholder="" value={fields.last_name} name='last_name' onChange={handleFieldChange}/>
                                                {
                                                    errors.last_name && <small className="py-2 d-table txt-color-secondary">
                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                    </small>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Email</label>
                                                <input type="email" className="form-control" placeholder="" value={fields.email} name='email' onChange={handleFieldChange}/>
                                                {
                                                    errors.email && <small className="py-2 d-table txt-color-secondary">
                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                    </small>
                                                }
                                                {
                                                    errors.email_invalid && <small className="py-2 d-table txt-color-secondary">
                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Invalid email address
                                                    </small>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <label htmlFor="">Message</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"
                                                      placeholder="Type message here..." value={fields.message} name='message' onChange={handleFieldChange}></textarea>
                                            {
                                                errors.message && <small className="py-2 d-table txt-color-secondary">
                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                </small>
                                            }
                                        </div>


                                    </div>

                                    <div className="w-100 mt-5">
                                        {
                                            !(isEmptyField) ?
                                                <div className="form-row mb-2">
                                                    <button className="btn btn-solid btn-solid-orange w-100" onClick={handleSubmitClick}>Submit
                                                    </button>
                                                </div> :
                                                <div className="form-row mb-2">
                                                    <button className="btn btn-solid btn-solid-orange w-100" style={{
                                                        backgroundColor: 'gray',
                                                    }}>Submit
                                                    </button>
                                                </div>
                                        }

                                        <div className="form-row mb-2">
                                            <Link to='/' className="btn btn-outline btn-outline-orange w-100">Cancel</Link>
                                        </div>
                                    </div>


                                    <div className="mt-3">
                                        <p>If there are problems submitting the form, you may contact <a href="mailto:erika@urgentcare.com">erika@urgentcare.com</a> with any
                                            questions or concerns. <br/><br/>

                                            We are always here to help. Emails will be returned in the order they were received.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

export default ContactUsPage;