import React from 'react';

const LoadingOverlay = () => {
    return (
        <div className="jumbotron w-100 d-flex justify-content-center loading-landing">
            <div className='m-1 text-center align-self-center '>
                <i className="fa fa-spinner fa-2x loading-icon" aria-hidden="true"></i>
                <p className="mx-auto pt-3 mb-0">Please wait for a moment...</p>
            </div>
        </div>
    );
};

export default LoadingOverlay;