import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { send_forgot_password_request } from "../../helpers/passwordManagementServices";
import LoadingOverlay from "../Common/LoadingOverlay/LoadingOverlay";
import LoginHeaderSection from "../LoginPage/LoginHeaderSection/LoginHeaderSection";

class ForgotPasswordPage extends React.Component {
    state = {
        email: "",
        isLoading: false,
        redirectToSuccess: false,
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value,
        });
    }

    handleResetPasswordClick = async (e) => {
        e.preventDefault();
        if(this.state.email === "") {
            this.setState({
                error: "E-mail address cannot be blank.",
            });

            return;
        }

        this.setLoading(true);
        const sendForgotPasswordRequestResults = await send_forgot_password_request(this.state.email);

        if(sendForgotPasswordRequestResults.error) {
            this.setState({
                error: sendForgotPasswordRequestResults.error,
            });
            this.setLoading(false);

            return;
        }

        this.setState({
            redirectToSuccess: true,
        });
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    render() {
        return(
            <>
                <LoginHeaderSection/>

                {
                    this.state.redirectToSuccess && <Redirect to={{
                        pathname: "/password/forgot/success",
                        redirectedFromSuccess: true,
                    }} />
                }

                {
                    this.state.isLoading ? <LoadingOverlay /> :
                        <main>
                            <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">

                                <div className="form-login-container p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                                    <h4 className="border-bottom pb-4 txt-color-dark_purple">Forgot Password</h4>

                                    <div className="form-control-wrap mt-5">

                                        <div className="form-row mb-4">
                                            {
                                                this.state.error !== "" &&
                                                <div className="col-12">
                                                    <p style={{
                                                        color: 'red',
                                                    }}>{this.state.error}</p>
                                                </div>

                                            }
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Enter your registered e-mail address.</label>
                                            </div>
                                        </div>
                                        <div className="form-row mb-4">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Email Address</label>
                                                <input type="text" className="form-control" value={this.state.email} onChange={this.handleEmailChange}/>
                                            </div>
                                        </div>


                                        <div className="form-row mb-4">
                                            <div className="col-12 mt-3">
                                                <a href="void(0);"
                                                   className="btn btn-solid btn-solid-orange w-100" onClick={this.handleResetPasswordClick}>Reset Password</a>
                                                <Link to="/"
                                                      className="btn btn-outline btn-outline-purple w-100 mt-2"><i
                                                    className="fas fa-arrow-left mr-2"></i> Back to Login</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </main>
                }
            </>
        )
    }
};

export default ForgotPasswordPage;