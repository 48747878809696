import React from 'react';
import LoginHeaderSection from "../LoginPage/LoginHeaderSection/LoginHeaderSection";
import {Link, Redirect} from "react-router-dom";

const ForgotPasswordSentEmailPage = (props) => {
    return (
        <>
            <LoginHeaderSection />
            {
                !props.location.redirectedFromSuccess && <Redirect to='/' />
            }
            <main>
                <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                    <div className="form-login-container p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                        <h4 className="border-bottom pb-4 txt-color-dark_purple">Forgot Password</h4>

                        <div className="form-control-wrap mt-5">

                            <div className="form-row mb-4">
                                <div className="col-12">
                                    <label htmlFor="" className="form-label">Please check your email. We’ve sent you a
                                        link to reset your password.</label>
                                </div>
                            </div>

                            <div className="form-row mb-4">
                                <div className="col-12">
                                    <Link to='/' className="btn btn-solid btn-solid-orange w-100">Back to Login</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ForgotPasswordSentEmailPage;