/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { deleteLocation } from '../../../helpers/rejectedLocationHelpers';
import { convert24HourFormatStringTo12HourFormatString, DAYS_OF_THE_WEEK } from "../../../helpers/scheduleUtilties";

const MyLocationsPageFooter = (props) => {

    const token = props.token;

    const [practiceLocation, setPracticeLocation] = useState('');

    const handleCancelClick = (e) => {
        setPracticeLocation(e.target.value);
        console.log(e.target.value);
    };

    const handleConfirmCancel = async () => {
        const locationDelete = await deleteLocation(practiceLocation, token);

        if (locationDelete.error) {
            console.log('ERRROR');
            return;
        }

        console.log(locationDelete);
        window.location.reload(false);
    };

    return (
        <footer>
            {
                props.myLocations.map((location) => {
                    let website = "";
                    if(location.website !== "") {
                        const website_pattern = /^https?:\/\//;
                        website = website_pattern.test(location.website) ? location.website :
                            "https://" + location.website;
                    }

                    let services = location.services !== "" ? location.services.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];
                    let insurance = location.insurance !== "" ? location.insurance.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim()) : [];
                    console.log(services);
                    return (
                        <div className="modal fade p-1 p-sm-5" id={`modal-location-${location.practice_id}-${location.approval_status}`} tabIndex="-1" role="dialog"
                             aria-labelledby="exampleModalLabel" aria-modal="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className="w-100">
                                            <div className="row">
                                                <div className="col-10">
                                                </div>
                                                <div className="col-2">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <i className="fas fa-times-circle fa-xs"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-body">
                                        <div className="w-100 border-bottom-light_blue pb-3 mb-3">
                                            <div className="row mb-2">
                                                <div className="col-12 col-md-2">
                                                    <div
                                                        className="d-table mx-auto icon-50x50-circle border-light_blue bg-color-light_blue mb-4">
                                                        <img src="" alt="" />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-7">
                                                    <div className="w-100">
                                                        <h4 className="txt-color-dark_purple">{location.name}</h4>
                                                    </div>
                                                    <div className="w-100">
                                                        {/*TODO: Review*/}
                                                        {/*<div className="w-100 d-flex">*/}
                                                        {/*    <span className="txt-color-purple mr-3"><i className="fas fa-star"></i></span>*/}
                                                        {/*    <p className="txt-color-secondary">*/}
                                                        {/*        5.0 (241 Reviews) • <a href="#"*/}
                                                        {/*                               className="txt-color-orange text-decoration-underline"> Write*/}
                                                        {/*        a*/}
                                                        {/*        review</a>*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}
                                                        <div className="w-100 d-flex">
                                            <span className="txt-color-purple mr-3"><i
                                                className="fas fa-map-marker-alt"></i></span>
                                                            <p className="txt-color-secondary">
                                                                {`${location.address}, ${location.city}, ${location.state} ${location.zip}`} •
                                                                {/*TODO: send map*/}
                                                                {/*<a href="#"*/}
                                                                {/*                                           className="txt-color-orange text-decoration-underline"> Send*/}
                                                                {/*Map to*/}
                                                                {/*SMS</a>*/}
                                                            </p>
                                                        </div>
                                                        <div className="w-100 d-flex">
                                                <span className="txt-color-purple mr-3"><i
                                                    className="fas fa-phone-alt"></i></span>
                                                            <p className="txt-color-secondary">
                                                                {location.phone}
                                                            </p>
                                                        </div>
                                                        <div className="w-100 d-flex">
                                                            <span className="txt-color-purple mr-3"><i className="fas fa-globe"></i></span>
                                                            <p className="txt-color-secondary">
                                                                <a href="void(0);" onClick={(e)=>{e.preventDefault()}} className="txt-color-orange text-decoration-underline">
                                                                    { website === "" ? "Not available" : website }</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (location.approval_status === 'approved' || location.approval_status === "rejected") &&
                                                    <div className="col-12 col-md-3">
                                                        {/* <a href={`/listings/edit/${location.practice_id}`}
                                                           className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none">
                                                            <i className="fas fa-pen mr-1"></i>
                                                            <span className="d-none d-sm-inline">Edit</span>
                                                        </a> */}

                                                        <button className="btn-outline-purple-sm rounded-20 px-2 py-1 float-right text-decoration-none"  data-toggle="modal" data-target="#cancel_request" value={`${location.practice_id}`} data-dismiss="modal" onClick={handleCancelClick}>Cancel Request
                                                        </button>
                                                    </div>
                                                }
                                            </div>

                                        </div>



                                        <div className="w-100 border-light_blue rounded-5 p-3 mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="txt-color-orange h6 font-weight-bold">COVID-19 Viral Testing</h4>
                                                    <p className="txt-color-secondary">
                                                        This test looks for an active infection of
                                                        SARS-CoV-2, or whether you are currently sick with Coronavirus. The method of testing varies by
                                                        Urgent Care location.
                                                    </p>

                                                    <div className="w-100">
                                                        <p className="txt-color-secondary">We Offer:</p>
                                                        {/*TODO: covidtests*/}
                                                        {/*<div className="d-block d-sm-flex">*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">PCR</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Blood Draw</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Finger Prick</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Spit Test</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {
                                                            location.covid19TestServices.length < 1 ? <p className="information">Please, call for more information.</p> :
                                                                <>{
                                                                    location.covid19TestServices.map((test) => {
                                                                        return (
                                                                            <li className="test">{test.test.name}</li>
                                                                        );
                                                                    })
                                                                }</>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="w-100 border-light_blue rounded-5 p-3 mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4 className="txt-color-orange h6 font-weight-bold">COVID-19 Antibody Testing</h4>
                                                    <p className="txt-color-secondary">
                                                        This test looks for a past infection with SARS-CoV-2, which indicates that you have been sick but
                                                        recovered from Coronavirus. A past exposure with positive antibodies can possibly protect against
                                                        future infections, although this information is not fully validated.
                                                    </p>

                                                    <div className="w-100">
                                                        <p className="txt-color-secondary">We Offer:</p>
                                                        {/*<div className="d-block d-sm-flex">*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Roche<br />*/}
                                                        {/*Venipuncture</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Finger Prick</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="d-block px-3">*/}
                                                        {/*        <center>*/}
                                                        {/*            <div className="icon-50x50-circle"></div>*/}
                                                        {/*            <span className="txt-color-secondary">Blood Test</span>*/}
                                                        {/*        </center>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {
                                                            location.antibodyTestServices.length < 1 ? <p className="information">Please, call for more information.</p> :
                                                                <>{
                                                                    location.antibodyTestServices.map((test) => {
                                                                        return (
                                                                            <li className="test">{test.test.name}</li>
                                                                        );
                                                                    })
                                                                }</>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100 border-light_blue rounded-5 p-3 mb-3">
                                            <div className="w-100 border-bottom-light_blue pb-3 mb-3">
                                            <h4 className="txt-color-orange h6 font-weight-bold">Hours of Operation</h4>
                                            {
                                                DAYS_OF_THE_WEEK.map((day) => {
                                                    const practice_schedule = location.schedules.find((s) => s.occurrence.name === day);

                                                    if(!practice_schedule) {
                                                        return (
                                                            <div className="open_sched row mb-2">
                                                                <div className="col-12 col-sm-4">
                                                                    <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                                </div>
                                                                <div className="col-12 col-sm-8">
                                                                    <label htmlFor="" className="form-label m-0">(Closed)</label>
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div className="open_sched row mb-2">
                                                            <div className="col-12 col-sm-4">
                                                                <label htmlFor="" className="form-label m-0 font-weight-bold">{day}</label>
                                                            </div>
                                                            <div className="col-12 col-sm-8">
                                                                <label htmlFor="" className="form-label m-0">
                                                                    {`${convert24HourFormatStringTo12HourFormatString(practice_schedule.opening)} - ${convert24HourFormatStringTo12HourFormatString(practice_schedule.closing)}`}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        </div>

                                        <div className="w-100 border-light_blue rounded-5 p-3 mb-3">

                                            <div className="row">
                                                {
                                                    services.length > 0 &&
                                                    <div className="col-12 col-sm-6">
                                                        <h4 className="form-label mb-3 txt-color-orange">Services</h4>
                                                        <ul className="basic-list">
                                                            {
                                                                services.map((service) => {
                                                                    return (
                                                                        <li className="form-label">{service}</li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }

                                                {
                                                    insurance.length > 0 &&
                                                    <div className="col-12 col-sm-6">
                                                        <h4 className="form-label mb-3 txt-color-orange">Insurance</h4>
                                                        <ul className="basic-list">
                                                            {
                                                                insurance.map((i) => {
                                                                    return (
                                                                        <li className="form-label">{i}</li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }




                                            </div>
                                        </div>

                                        <div className="w-100 border-light_blue rounded-5 p-3 mb-3">
                                            {
                                                location.about !== "" &&
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="txt-color-orange h6 font-weight-bold">About Us</h4>
                                                        <p className="txt-color-secondary">
                                                            {location.about}
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                        {/*TODO: photos*/}
                                        {/*<div className="w-100 border-top-light_blue pt-3">*/}
                                        {/*    <div className="row">*/}
                                        {/*        <div className="col-12">*/}
                                        {/*            <h4 className="form-label mb-3 d-table float-left">Photos</h4>*/}

                                        {/*            <a href="javascript:void(0)"*/}
                                        {/*               className="btn-outline-purple-sm px-2 py-1 float-right rounded-20">View*/}
                                        {/*                All</a>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="col-12 mt-4">*/}
                                        {/*            <div className="w-100 d-flex mb-2">*/}
                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}

                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}

                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="w-100 d-flex mb-2">*/}
                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}

                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}

                                        {/*                <div*/}
                                        {/*                    className="feature-partner-img overflow-hidden mr-2 rounded-10 border-light_blue">*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }


<div className="modal fade" id="cancel_request" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content overflow-hidden border-0">
					<div className="modal-body p-0">

						<div className="w-100 p-0">
							<img src="img/orange_bg.svg" className="w-100" alt=""/>
							<div className="p-5">
								<h2 className="txt-color-dark_purple mb-4 text-center">Confirm</h2>
								<p className="txt-color-dark_purple mb-4 text-center">Are you sure you want to cancel your request?</p>


								{/* <!-- <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table" data-dismiss="modal">Got It</button> --> */}
								<div className="col-12">
                                        <button className="btn btn-solid btn-solid-orange w-100 mt-4" data-toggle="modal" data-dismiss="modal" onClick={handleConfirmCancel}>Confirm</button>
								</div>
								<div className="col-12">
									<button className="btn btn-outline btn-outline-purple w-100 mt-2" data-dismiss="modal">Cancel</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>





            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TimelineLite.min.js"
                    integrity="sha512-UznfQR3LKkKPL0k7HISMqOsmUCjVhjWK8HlR0Pxo5JpjN8eZ8XRwKBRX6OTOQIO0FYOl8Oue+4dG4uHtx1SgKQ=="
                    crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                    integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                    crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                    integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                    crossOrigin="anonymous"></script>
            <script src="animation.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                    integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                    crossOrigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                    integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                    crossOrigin="anonymous"></script>
        </footer>
    );
};

export default MyLocationsPageFooter;