import React from 'react';

class AddedLocationCard extends React.Component {
    handleRemoveClicked = (e) => {
        e.preventDefault();
        this.props.onRemovePractice(this.props.practice);
    }

    render() {
        return (
            <div className="w-100 pt-3" id="address_container">
                <div className="row p-1 p-sm-3 mx-0 mb-2 border-bottom-light_blue">
                    <div className="col-12 col-md-8">
                        <div className="w-100 mb-3 d-flex">
                            <span className="d-table txt-color-purple mr-3"><i
                                className="fas fa-map-marker-alt"></i></span>
                            <div className="w-100">
                                <span className="d-table  font-weight-bold ">{this.props.practice.name}</span>
                                <span className="d-table font-weight-normal">
                                    {`${this.props.practice.address}, ${this.props.practice.city}, ${this.props.practice.state} ${this.props.practice.zip}`}
                                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="col-2 d-none d-sm-inline col-md-4 justify-content-end">
                        <a href="void(0);" className="float-right txt-color-secondary" id="remove_row" onClick={this.handleRemoveClicked}>
                            <i className="fas fa-times"></i>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddedLocationCard;