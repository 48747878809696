import React from 'react';
import { Link } from "react-router-dom";
import { getUSAStatesForOptionsComponent } from "../../../../helpers/addressHelpers";
import { searchClinicsForInvite } from "../../../../helpers/sendInviteAPIServices";

class LocationClaimPage extends React.Component {
    state = {
        fetchedPractices: [],
        fields: {
            query: "",
            zip: "",
            city: "",
            state: "default",
        },
        loading: false,
    }

    handleSearchClick = async () => {

        this.setLoading(true);
        await this.prepareFields();
        const getPracticesResults = await searchClinicsForInvite(this.state.fields);

        if(getPracticesResults.error) {
            console.log('error:');
            console.log(getPracticesResults.error)
            this.setLoading(false);
            return;
        }

        console.log(getPracticesResults);
    }

    prepareFields = async () => {
        await this.setState({
            fields: {
                ...this.state.fields,
                state: this.state.fields.state === "default" ? "" : this.state.fields.state
            }
        }, ()=> console.log(this.state.fields));
    }

    handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const newValue = event.target.value;

        const newFieldsState = {
            ...this.state.fields,
        };

        newFieldsState[fieldName] = newValue;

        this.setState({
            fields: newFieldsState,
        }, ()=>console.log(this.state));
    }

    setLoading = (status) => {
        this.setState({
            loading: status,
        });
    }

    render() {
        return (
            <>
                <div className="main-content">
                    <div className="container p-3 p-md-5">
                        <div className="row mb-4 p-0 mx-0">
                            <div className="col-6 px-0 mx-0">
                                <h2 className="h1 txt-color-dark_purple font-weight-bold">Claim a Location</h2>
                                <Link to="/"
                                   className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                    className="fas fa-arrow-left mr-2"></i> Back to My Locations</Link>
                            </div>
                        </div>

                        <div className="row mt-5 mx-0">
                            <div className="col-12 col-md-10 col-lg-7 border-light_blue rounded-4 p-3 p-xl-5 mx-0">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-6 px-0 mx-0">
                                            <h2 className="h3 txt-color-dark_purple font-weight-bold">Search
                                                Location</h2>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-control-input-search-label"></label>
                                            <input type="text" className="form-control form-control-input-search"
                                                   placeholder="Search..." value={this.state.fields.query} name='query' onChange={this.handleFieldChange}/>
                                        </div>
                                    </div>

                                    <div className="row py-3 mx-0">
                                        <div className="col-12 mx-0 px-0">
                                            <label className="form-label font-weight-bold" htmlFor="">Filter:</label>
                                            <div className="form-row mx-0 px-4">
                                                <div
                                                    className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" id="covid_testing" name="customRadioInline1"
                                                           className="form-control-input-checkbox" checked />
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="covid_testing">Covid Testing</label>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" id="x_ray" name="customRadioInline1"
                                                           className="form-control-input-checkbox" checked />
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="x_ray">X-Ray</label>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" id="antibody_testing"
                                                           name="customRadioInline1"
                                                           className="form-control-input-checkbox" checked />
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="antibody_testing">Antibody Testing</label>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-6 mx-0 py-2 custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" id="24_hrs" name="customRadioInline1"
                                                           className="form-control-input-checkbox" checked />
                                                        <label className="form-control-input-checkbox-label form-label"
                                                               htmlFor="24_hrs">24
                                                            Hours</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-sm-4 mb-3">
                                            <label htmlFor="" className="form-label">Zip Code</label>&nbsp;
                                            <input type="text" className="form-control" value={this.state.fields.zip} onChange={this.handleFieldChange}/>
                                        </div>
                                        <div className="col-12 col-sm-4 mb-3">
                                            <label htmlFor="" className="form-label">City</label>&nbsp;
                                            <input type="text" className="form-control" value={this.state.fields.city} onChange={this.handleFieldChange} />
                                        </div>
                                        <div className="col-12 col-sm-4 mb-3">
                                            <label htmlFor="" className="form-label">State</label>&nbsp;
                                            {/*<input type="text" className="form-control" />*/}
                                            <select name='state' className="form-control" value={this.state.fields.state} onChange={this.handleFieldChange}>
                                                <option value='default' key='default'></option>
                                                {
                                                    getUSAStatesForOptionsComponent().map((state) => {
                                                        return <option value={state.value} key={state.key}>{state.key} - {state.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row py-4 d-flex align-items-center">
                                        <div className="col-12 col-sm-4">
                                            <label htmlFor="" className="form-control-label">
                                                <span className="font-weight-bold">Radius:</span>
                                                <span className="font-weight-normal" id="rad_value">50</span>
                                                <span className="font-weight-normal">mi</span>
                                            </label>
                                        </div>
                                        <div className="col-12 col-sm-6" >
                                            <input type="range" min="1" max="100" value="50"
                                                   data-orientation="horizontal"
                                                   style={{
                                                       position: "absolute",
                                                       width: "1px",
                                                       height: "1px",
                                                       overflow: "hidden",
                                                       opacity: "0",
                                                   }} />
                                        </div>
                                    </div>

                                    <div
                                        className="row rounded-10 border-light_blue  p-3 mx-0 overflow-auto campaign-location">

                                        <div className="row border-light_blue p-1 p-sm-3 mx-0 mb-4 rounded-10">
                                            <div className="col-12 col-md-8">
                                                <div className="w-100 mb-3 d-flex">
                                                    <span
                                                        className="d-table  font-weight-bold">Amarillo Urgent Care</span>
                                                </div>
                                                <div className="w-100 mb-3 d-flex">
                                                    <span className="d-table txt-color-purple mr-3"><i
                                                        className="fas fa-map-marker-alt"></i></span>
                                                    <span className="d-table font-weight-normal">
                                                    1915 S. Coulter St. Amarillo TX 79106
                                                </span>
                                                </div>
                                            </div>

                                            <div className="col-2 d-none d-sm-inline col-md-4 justify-content-end">
                                                <a href="void(0)" onClick={(e)=>{e.preventDefault()}} className="btn-select-changes float-right"
                                                   data-toggle="modal" data-target="#claim_location">
                                                <span className="d-none d-sm-block">
                                                    Claim Location
                                                </span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12 col-md-6">
                                            <button className="btn btn-solid btn-solid-orange px-3" onClick={this.handleSearchClick}>Search Location
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LocationClaimPage;