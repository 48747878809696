//*****************************************************************************************************************
//                                UTILITY FUNCTIONS FOR API RESPONSES                                            //
//*****************************************************************************************************************

const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        throw new APIException(response);
    }
}

// const checkAPIResponse = (response) => {
//     if (response.status >= 200 && response.status < 300) {
//         return response;
//     } else {
//         response.text().then((text) => throw new Error(text));
//     }
// };

const parseJSON = (response) => {
    return response.json();
}

const catchGenericError = (error) => {
    return {
        error: error
    }
}

const parseResponse = (response) => {
    const response_json = parseJSON(response);
    if(response_json.error) {
        return response_json;
    } else {
        return {
            success: true,
            results: response_json
        }
    }
}

function APIException(response) {
    this.response = response;
}

const checkStatusWithErrorCode = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } 
    else {
        throw new APIException(response);
    }
}

const catchSpecificError = (response) => {
    return response.data.json();
}

export {
    checkStatus,
    parseJSON,
    catchGenericError,
    parseResponse,
    checkStatusWithErrorCode,
    catchSpecificError,
    // checkAPIResponse,
}