import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { getUSAStatesForOptionsComponent } from "../../../../helpers/addressHelpers";
import { editClinic } from "../../../../helpers/clinicModificationAPIServices";
import { ANTIBODY_TESTS, COVID19_TESTS, PARTNERS } from "../../../../helpers/constants";
import { getListingOrganization, getOrganizations } from "../../../../helpers/organizationAPIServices";
import { deleteLocationListing, getPracticeInformation } from "../../../../helpers/practiceHelpers";
import {
    convertTimeStringToDecimalValue,
    DAYS_OF_THE_WEEK,
    generateTimeObjects
} from "../../../../helpers/scheduleUtilties";
import { getAccessTokenFromSession, getRefreshTokenFromSession } from "../../../../helpers/sessionHelpers";
import { editStripeUrl, getTiers, getTierSubscription, subscribe } from "../../../../helpers/tierAPIServices";
import LoadingOverlay from "../../../Common/LoadingOverlay/LoadingOverlay";
// import AddOrganizationOption from "./AddOrganizationsOption/AddOrganizationOption";

class EditListingsViewPage extends React.Component {
    state = {
        practice: {
            practice_id: "",
            name: "",
            longitude: 121,
            latitude: 15,
            address: "",
            state: "",
            city: "",
            zip: "",
            xray: false,
            about: "",
            services: "",
            website: "",
            email: "",
            internal_email: "",
        },
        user: this.props.user,
        partners: [],
        covid19TestServices: [],
        antibodyTestServices: [],
        schedules: [
            // {
            //     occurrence: "Monday",
            //     closing: "23:59:00",
            //     opening: "13:00:00"
            // }
        ],
        status: {
            offersCOVID19Test: false,
            offersAntibodyTest: false,
            subscribedForPaidService: false,
            operates24Hours: false,
            operatesOvernight: false,
            dailyOperation: {
                Monday: true,
                Tuesday: true,
                Wednesday: true,
                Thursday: true,
                Friday: true,
                Saturday: true,
                Sunday: true,
            },
            token: null,
            errors: [],
            isLoading: false,
            messages: [],
            loadingPractice: false,
            redirectTo404: false,
        },
        tiers: [],
        tier: 'BASIC',
        oldTier: 'BASIC',
        stripe_url: 'https://',
        oldUrl: 'https://',
        subscription_id: 0,
        isDeleted: null,
        organization: '',
        newOrganization: '',
        organizations: [],
    }

    

    async componentDidMount () {
        // console.log(getToken());

        this.setLoadingPractice(true);
        const getPracticeResults = await getPracticeInformation(this.props.match.params.practice_id);
        if(getPracticeResults.error) {
            this.setState({
                ...this.state,
                status: {
                    ...this.state.status,
                    redirectTo404: true,
                }
            });

            return;
        }

        this.populateForm(getPracticeResults);

        // populate tier options
        let tierOptions = await getTiers(getAccessTokenFromSession());
        if(tierOptions.error) {
            console.log('Error fetching tiers');
            tierOptions = [];
        }
        console.log(tierOptions);

        const tierSubscription = await getTierSubscription(this.props.match.params.practice_id, getAccessTokenFromSession());

        console.log(tierSubscription);

        console.log(tierSubscription.id);

        if(tierSubscription.id === null ) {
            console.log("No Subscription Yet")
            this.setLoadingPractice(false);
        };

        console.log(tierSubscription.stripe_billing_url);

        if(tierSubscription.stripe_billing_url === null ) {
                console.log("Stripe URL is not set")
                this.setLoadingPractice(false);
        };
        this.setLoadingPractice(false);
        this.setState({
            status: {
                ...this.state.status,
                token: getRefreshTokenFromSession(),
            },
            tiers: tierOptions,
            tier: tierSubscription.tier ? tierSubscription.tier.code : this.state.tier,
            oldTier: tierSubscription.tier ? tierSubscription.tier.code : this.state.tier,
            subscription_id: tierSubscription.id,
            stripe_url: tierSubscription.stripe_billing_url,
            oldUrl: tierSubscription.stripe_billing_url,
        }, ()=>console.log(''));

       


        let getOrganizationsResults = await getOrganizations(getAccessTokenFromSession());
        if(getOrganizationsResults.error) {
            console.log('Error fetching organizations');
            getOrganizationsResults = [];
            return;
        }
        console.log(getOrganizationsResults);
        
        this.setState({
            organizations: getOrganizationsResults,
        })

        const listingOrganization = await getListingOrganization(getAccessTokenFromSession(), this.state.practice.practice_id);
        if(listingOrganization.error){
            console.log(listingOrganization.error);
            return;
        }

        this.setState({
            organization: listingOrganization,
            newOrganization: listingOrganization,
        })

        // let getOrganizationsResults = await AddOrganizationOption();

        // console.log(getOrganizationsResults);

        this.setLoadingPractice(false);

    }

    populateForm = (practice) => {
        // populate the form here
        console.log(practice);

        // schedules
        const schedules = practice.schedules.map((s) => {
            return {
                occurrence: s.occurrence.name,
                opening: s.opening,
                closing: s.closing,
            }
        });

        // partners
        const partners = practice.partners.map((p) => {
            return p.partner.code;
        });

        // offers antibody tests
        const offersAntibodyTest = practice.antibodyTestServices.length > 0;
        const antibodyTests = practice.antibodyTestServices.map((t) => t.test.type);

        // offers COVID-19 tests
        const offersCOVID19Test = practice.covid19TestServices.length > 0;
        const covid19Tests = practice.covid19TestServices.map((t) => t.test.type);


        console.log(partners);

        this.setState({
            ...this.state,
            practice: {
                ...practice
            },
            schedules: schedules,
            partners: partners,
            covid19TestServices: covid19Tests,
            antibodyTestServices: antibodyTests,
            status: {
                ...this.state.status,
                offersAntibodyTest: offersAntibodyTest,
                offersCOVID19Test: offersCOVID19Test,
            }
        }, () => console.log(this.state))
    }

    setLoadingPractice = (status) => {
        this.setState({
            ...this.state,
            status: {
                ...this.state.status,
                loadingPractice: status,
            }
        });
    }

    handleOrganizationChange = (e) => {
        this.setState({
            newOrganization: e.target.value,
        });
        console.log(e.target.value);
    }

    handleStripeUrlChange = (e) => {
        this.setState({
            stripe_url: e.target.value,
        })
        console.log(e.target.value);
    }

    handlePracticeInformationChange = (event) => {
        const newPractice = {
            ...this.state,
        }
        newPractice.practice[event.target.name] = event.target.value;
        this.setState(newPractice, ()=>console.log(this.state));
    };

    handlePracticeInformationChange = (event) => {
        const newPractice = {
            ...this.state,
        }
        newPractice.practice[event.target.name] = event.target.value;
        this.setState(newPractice, ()=>console.log(this.state));
    };

    handleScheduleChange = (event) => {
        const [day, part] = event.target.name.split('-');
        console.log(day, part);

        // look for existing record first
        if(!this.state.schedules.find((schedule) => {
            return day === schedule.occurrence;
        })) {
            const new_schedule = {
                occurrence: day,
            };
            new_schedule[part] = event.target.value === "default_schedule" ? null : event.target.value;

            this.setState({
                schedules: [
                    ...this.state.schedules,
                    new_schedule
                ]
            }, () => {
                console.log("NEW SCHEDULE");
                console.log(this.state.schedules);
            });
        } else {
            //    record has been found
            const new_schedule = this.state.schedules.map((schedule) => {
                if(schedule.occurrence === day) {
                    const updated_schedule = {
                        ...schedule,
                    }
                    updated_schedule[part] = event.target.value === "default_schedule" ? null : event.target.value;

                    return updated_schedule;
                }

                return schedule;
            });


            this.setState({
                schedules: new_schedule
            }, ()=>console.log(this.state.schedules));
        }
    }

    generateScheduleSelectGroup = (interval_minutes) => {
        const time_objects = generateTimeObjects(interval_minutes);
        const options = time_objects.map((time) => {
            return <option value={time.value}>{time.display}</option>
        });
        return options;
    }

    handleTestChange = (event) => {
        console.log(event.target.name);
        console.log(event.target.value);
        console.log(event.target.checked);

        if(event.target.name === 'covid19test') {
            if(event.target.checked) {
                if(!this.state.covid19TestServices.find((test) => test === event.target.name)) {
                    this.setState({
                        covid19TestServices: [
                            ...this.state.covid19TestServices,
                            event.target.value
                        ]
                    }, ()=>console.log(this.state.covid19TestServices));
                }
            } else {
                this.setState({
                    covid19TestServices: this.state.covid19TestServices.filter((t) => t !== event.target.value)
                },()=>console.log(this.state.covid19TestServices));
            }
        } else if(event.target.name === 'antibodytest') {
            if(event.target.checked) {
                if(!this.state.antibodyTestServices.find((test) => test === event.target.name)) {
                    this.setState({
                        antibodyTestServices: [
                            ...this.state.antibodyTestServices,
                            event.target.value
                        ]
                    }, ()=>console.log(this.state.antibodyTestServices));
                }
            } else {
                this.setState({
                    antibodyTestServices: this.state.antibodyTestServices.filter((t) => t !== event.target.value)
                },()=>console.log(this.state.antibodyTestServices));
            }
        }
    }

    handlePartnerChange = (event) => {
        const partnerCode = event.target.value;
        const isPartnerChecked = event.target.checked;

        if(isPartnerChecked) {
            if(!this.state.partners.find((partner) => partner === partnerCode)) {
                const newPartners = [...this.state.partners];
                newPartners.push(partnerCode);

                this.setState({
                    partners: newPartners
                }, () => console.log(this.state.partners));
            }
        } else {
            const newPartners = this.state.partners.filter((partner) => partner !== partnerCode);
            this.setState({
                partners: newPartners,
            }, () => console.log(this.state.partners));
        }
    }

    handleOperationChange = (event) => {
        const [status, operationMode] = event.target.value.split('-');
        const newStatus = {
            ...this.state.status,
        };
        newStatus[operationMode] = status === 'yes';

        if(operationMode === "operates24Hours") {
            const isChecked = event.target.checked;
            if(isChecked) {
                const newSchedule = DAYS_OF_THE_WEEK.map((day) => {
                    return {
                        occurrence: day,
                        opening: "00:00:00",
                        closing: "23:59:00",
                    }
                });

                const newStatus = {
                    ...this.state.status,
                    operates24Hours: true,
                }

                console.log("setting it here.");
                this.setState({
                    schedules: newSchedule,
                    status: newStatus,
                }, () => console.log(this.state));
                return;
            } else {
                this.setState({
                    schedules: [],
                    status: {
                        ...this.state.status,
                        operates24Hours: false,
                    }
                }, () => console.log(this.state));
                return;
            }
        }

        this.setState({
            status: newStatus
        }, () => console.log(this.state.status));
    }

    addMessage = (message) => {
        const newMessages = [
            ...this.state.status.messages,
            message,
        ];

        this.setState({
            status: {
                ...this.state.status,
                messages: newMessages
            }
        });
    }

    addErrorMessage = (message) => {
        const newErrors = [
            ...this.state.status.errors,
            message
        ];

        this.setState({
            status: {
                ...this.state.status,
                errors: newErrors
            }
        });
    }

    setLoading = (state) => {
        this.setState({
            status: {
                ...this.state.status,
                isLoading: state,
            }
        });
    }

    prepareState = () => {
        //    Prepare schedule
        const preparedSchedule = this.state.schedules.filter((schedule) => {
            return this.state.status.dailyOperation[schedule.occurrence];
        });

        return new Promise((resolve) => {
            this.setState({
                schedules: preparedSchedule
            }, resolve);
        });
    }

    validateState = () => {
        let errorMessages = [];

        // Practice state validation
        const practice = this.state.practice;
        const practiceValidations = [
            this.createValidation(practice.practice_id !== "", "Practice ID cannot be blank."),
            this.createValidation(practice.name !== "", "NUCR Practice Name cannot be blank."),
            this.createValidation(practice.address !== "", "Practice address cannot be blank."),
            this.createValidation(practice.city !== "", "Practice city information cannot be blank."),
            this.createValidation(practice.state !== "", "Practice city state information cannot be blank"),
            this.createValidation(practice.zip !== "", "Practice ZIP code information cannot be blank"),
            this.createValidation(!isNaN(practice.latitude), "Latitude must be a number."),
            this.createValidation(!isNaN(practice.longitude), "Longitude must be a number."),
            this.createValidation(!isNaN(practice.latitude) && parseFloat(practice.latitude) >= -90 && parseFloat(practice.latitude) <= 90,
                "Latitude must be between -90° and 90°."),
            this.createValidation(!isNaN(practice.longitude) && parseFloat(practice.longitude) >= -180 && parseFloat(practice.longitude) <= 180,
                "longitude must be between -180° and 180°."),
        ];

        let practiceErrors = practiceValidations.filter((test) => !test.validation);
        practiceErrors = practiceErrors.map((test) => test.error);


        // Schedule validation
        const schedules = this.state.schedules;
        const scheduleValidation = schedules.map((schedule) => {
            if(!(schedule.opening && schedule.closing)) {
                return this.createValidation(false, `${schedule.occurrence} should have opening and closing times.`)
            }

            return this.createValidation(convertTimeStringToDecimalValue(schedule.opening) < convertTimeStringToDecimalValue(schedule.closing),
                `${schedule.occurrence}'s opening time should be earlier than closing time.`)
        });

        let scheduleErrors = scheduleValidation.filter((test) => !test.validation);
        scheduleErrors = scheduleErrors.map((test) => test.error);

        errorMessages = [
            ...practiceErrors,
            ...scheduleErrors
        ]

        errorMessages.forEach((error) => console.log(error));

        this.setState({
            status: {
                ...this.state.status,
                errors: errorMessages
            }
        });

        return errorMessages.length < 1;
    }

    createValidation = (test, error) => {
        return {
            validation: test,
            error: error
        }
    }

    handleClosedSchedule = (event) => {
        const [day, name] = event.target.value.split('-');
        const isClosed = event.target.checked;
        console.log(name);

        const newScheduleStatus = {
            ...this.state.status.dailyOperation
        };

        newScheduleStatus[day] = !isClosed;

        const newStatus = {
            ...this.state.status,
        };

        newStatus["dailyOperation"] = newScheduleStatus;

        this.setState({
            status: newStatus
        }, ()=>console.log(this.state));

        // if(isClosed) {
        // //    remove from schedule
        //     const newSchedule = this.state.schedules.filter((schedule) => schedule.occurrence !== day);
        //     this.setState({
        //         schedules: newSchedule
        //     }, ()=>console.log(this.state.schedules));
        // }
    }

    handleRadioButtonChange = (event) => {
        const [status, id] = event.target.value.split('-');
        if(id === 'xray') {
            const newPractice = {
                ...this.state,
            }
            newPractice.practice['xray'] = status === 'yes';
            this.setState(newPractice, ()=>console.log(this.state));
        }

        if(id === 'covid19tests') {
            const newPractice = {
                ...this.state,
            }

            newPractice.status = {
                ...newPractice.status,
                offersCOVID19Test: status === 'yes',
            }
            this.setState(newPractice, ()=>console.log(this.state.status));

            if(status === "no") {
                console.log("no for covid19");
                this.setState({
                    covid19TestServices: [],
                }, () => console.log(this.state.covid19TestServices));
            }
        }

        if(id === 'antibodytests') {
            const newPractice = {
                ...this.state,
            }

            newPractice.status = {
                ...newPractice.status,
                offersAntibodyTest: status === 'yes',
            }
            this.setState(newPractice, ()=>console.log(this.state.status));

            if(status === "no") {
                this.setState({
                    antibodyTestServices: []
                });
            }
        }
    }


    handleSubmitDelete = async (e) => {

        this.setLoading(true);

        // const getLocationListing = await checkLocationListing(this.state.practice?.practice_id, getAccessTokenFromSession());

        // console.log(getLocationListing);

        const deletingLocationListing = await deleteLocationListing(this.state.practice?.practice_id, getAccessTokenFromSession());

        // while (getLocationListing.message != "Not found.") {
        //     const deletingLocationListing = await deleteLocationListing(this.state.practice?.practice_id, getAccessTokenFromSession());
        //     console.log(deletingLocationListing);
        //     const getLocationListing = await checkLocationListing(this.state.practice?.practice_id, getAccessTokenFromSession());
        //     console.log(getLocationListing)

        //     if (getLocationListing.message == "Not found.") break;
        // }

        console.log(deletingLocationListing);

        if (deletingLocationListing.message !== "Success: Matching Practice(s) deleted!"){
            this.setState({
                isDeleted: false,
            })
            this.addErrorMessage('This listing has a subscription. Cannot be deleted')
            this.setLoading(false)
            return;
        }

            this.setState({
                isDeleted: true,
            })
            this.setLoading(false)


        console.log(`Deleted `+ this.state.practice?.practice_id);

    };

    handleOnChangeTier = (event) => {
        const tierCode = event.target.value;
        this.setState({
            tier: tierCode,
        });
        console.log(event.target.value);
    }

    handleSubmit = async () => {
        const isStateValid = this.validateState();

        if (isStateValid) {
            await this.prepareState();
            const clinicBody = this.state;
            this.setLoading(true);

            const addClinicResult = await editClinic(clinicBody, getAccessTokenFromSession());


            if(addClinicResult.error) {
                this.addErrorMessage(addClinicResult.error);
                return;
            }


            this.setLoading(true);
            if (this.state.stripe_url !== this.state.oldUrl) {
                const updateUrl = await editStripeUrl(this.state.practice.practice_id, this.state.tier, this.state.stripe_url, getAccessTokenFromSession());
                console.log(updateUrl);
            };

            // console.log(addClinicResult);
            this.setLoading(true);
            if(this.state.tier !== this.state.oldTier) {
                const updateTier = await subscribe(this.state.practice.practice_id, this.state.tier, getAccessTokenFromSession());
                console.log(updateTier);

                const updateUrl = await editStripeUrl(this.state.practice.practice_id, this.state.tier, this.state.stripe_url, getAccessTokenFromSession());

                console.log(updateUrl);

            }

            this.setLoading(true);
            if (this.state.stripe_url !== this.state.oldUrl) {
                const updateUrl = await editStripeUrl(this.state.practice.practice_id, this.state.tier, this.state.stripe_url, getAccessTokenFromSession());
                console.log(updateUrl);
            };


            // if (this.state.newOrganization !== this.state.organization) {
            //     const organizationField = {
            //         practice_id: addClinicResult.practice_id,
            //         code: this.state.newOrganization,
            //     }
            //     const addOrganization = await addListingOrganization(organizationField, getAccessTokenFromSession())

            //     console.log(addOrganization);

            //     if (addOrganization.error) {
            //         this.addErrorMessage(addOrganization.error);
            //         return;
            //     }

            //     this.setLoading(false)
            // }


            this.setLoading(false);
            this.addMessage("Edit request has been successfully submitted.");

            console.log(this.state.stripe_url)

        }
    }

    render() {
        const practice = this.state.practice;
        // const options = this.generateScheduleSelectGroup(15);
        const covid19Tests = COVID19_TESTS.map((test) => {
            const isChecked = this.state.covid19TestServices.find((t) => test.value === t);

            return (
                <div className="w-100 mr-4 test-element">
                    <input className="form-check-input form-control-input-checkbox" type="checkbox"
                           name="covid19test" id={"covidtest-" + test.value.replace(' ', '-')} value={test.value} onChange={this.handleTestChange}
                    checked={isChecked}/>
                    <label
                        className="form-check-label form-control-input-checkbox-label form-label"
                        htmlFor={"covidtest-" + test.value.replace(' ', '-')}>
                        {test.name}
                    </label>
                </div>
            )
        });

        const antibodyTests = ANTIBODY_TESTS.map((test) => {
            const isChecked = this.state.antibodyTestServices.find((t) => test.value === t);
            return (
                <div className="w-100 mr-4 test-element">
                    <input className="form-check-input form-control-input-checkbox" type="checkbox"
                           name="antibodytest" id={"antibodytest-" + test.value.replace(' ', '-')} value={test.value} onChange={this.handleTestChange}
                    checked={isChecked}/>
                    <label
                        className="form-check-label form-control-input-checkbox-label form-label"
                        htmlFor={"antibodytest-" + test.value.replace(' ', '-')} value={test.value}>
                        {test.name}
                    </label>
                </div>
            )
        });

        let buttonContent;
        // <i className="fas fa-exclamation-triangle"></i>
        if(this.state.status.isLoading) {
            buttonContent = <div className="button-content-loading"><i className="fas fa-spinner fa-spin"></i> Submitting form...</div>
        } else if(this.state.status.errors.length > 0) {
            buttonContent =  <div className="button-content-errors"><i className="fas fa-exclamation-triangle"></i>  Failed to submit form</div>
        } else {
            buttonContent = <div>Submit</div>
        }

        if(this.state.status.redirectTo404) {
            return (
                <Redirect to='/404' />
            )
        }

        const tierOptions = this.state.tiers.map((tier) => {
            return (
                <div
                    className="col-8 mx-0 py-2">
                    <input type="radio" id={tier.code} name="tier_control"
                           className="" value={tier.code} onChange={this.handleOnChangeTier} checked={this.state.tier===tier.code}/>
                    <label htmlFor={tier.code} style={{
                        margin: '0px 10px',
                    }}>{`${tier.name} ($${tier.price})`}</label>
                </div>
            )
        });

        // console.log(tierOptions);

        return (

                <>
                {
                this.state.status.loadingPractice ? <LoadingOverlay/> :
                    <div className="main-content">
                        <div className="container p-3 p-md-5">
                            <div className="row mb-4 p-0 mx-0">
                                <div className="col-12 col-md-8 px-0 mx-0">
                                    <h2 className="h1 txt-color-dark_purple font-weight-bold mb-3">Edit Location</h2>
                                    <Link to="/listings" className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                        className="fas fa-arrow-left mr-2"></i> Back to Location Listings</Link>
                                </div>

                            </div>

                            <div className="row mt-5 mx-0">
                                <div className="col-12 col-md-8  border-light_blue rounded-4 p-3 p-md-5 mx-0">
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        {
                                            this.state.status.errors.length > 0 &&
                                            <div className="add-form-errors">
                                                <p className="form-errors" style={{
                                                    color: "red",
                                                }}>{`Form Error${this.state.status.errors.length > 1 ? "s" : ""}`}</p>
                                                {this.state.status.errors.map((error) => <p className="form-error"
                                                                                            style={{
                                                                                                color: 'red',
                                                                                            }}><i
                                                    className="fas fa-exclamation-triangle"></i> {error}</p>)}
                                            </div>
                                        }

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Basic Info</h4>
                                            </div>
                                        </div>


                                        <div className="form-row mb-3">
                                            <div className="col-12 ">
                                                <label htmlFor="" className="form-label">Practice ID</label>
                                                <input type="text" className="form-control" placeholder="#A00001"
                                                       value={practice.practice_id} name="practice_id"
                                                       onChange={this.handlePracticeInformationChange} disabled/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Practice Name</label>
                                                <input type="text" className="form-control" placeholder="" name="name"
                                                       onChange={this.handlePracticeInformationChange}
                                                       value={practice.name}/>
                                            </div>
                                        </div>

                                        {/*<div className="form-row mb-3">*/}
                                        {/*    <div className="col-12 col-md-8">*/}
                                        {/*        <label htmlFor="" className="form-label">Classification</label>*/}
                                        {/*        <input type="text" className="form-control" placeholder=""/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Email Address</label>
                                                <input type="email" className="form-control" placeholder="" name="email"
                                                       onChange={this.handlePracticeInformationChange}
                                                       value={practice.email}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Internal Email Address</label>
                                                <input type="email" className="form-control" placeholder=""
                                                       name="internal_email" value={practice.internal_email}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Phone</label>
                                                <input type="email" className="form-control" placeholder="" name="phone"
                                                       value={practice.phone}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Website</label>
                                                <input type="email" className="form-control" placeholder=""
                                                       name="website"
                                                       value={practice.website}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Location Address</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Street Address</label>
                                                <input type="email" className="form-control" placeholder=""
                                                       name="address"
                                                       value={practice.address}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">City</label>
                                                <input type="text" className="form-control" placeholder="" name='city'
                                                       value={practice.city}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">State</label>
                                                <select name="state" id="state" className="form-control"
                                                        value={practice.state}
                                                        onChange={this.handlePracticeInformationChange}>
                                                    {
                                                        [<option name='state' value=''>---</option>,
                                                            ...getUSAStatesForOptionsComponent().map((state) => {
                                                                return (
                                                                    <option name='state'
                                                                            value={state.value}>{state.text}</option>
                                                                )
                                                            })]
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <label htmlFor="" className="form-label">ZIP Code</label>
                                                <input type="email" className="form-control" placeholder="" name="zip"
                                                       value={practice.zip}
                                                       onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        {
                                            (this.state.user !== null && this.state.user.role === "superadministrator") &&
                                            <div className="form-row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="" className="form-label">Latitude</label>
                                                    <input type="text" className="form-control" placeholder=""
                                                           name="latitude"
                                                           value={practice.latitude}
                                                           onChange={this.handlePracticeInformationChange}/>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="" className="form-label">Longitude</label>
                                                    <input type="text" className="form-control" placeholder=""
                                                           name="longitude"
                                                           value={practice.longitude}
                                                           onChange={this.handlePracticeInformationChange}/>
                                                </div>
                                            </div>

                                        }

                                    </div>

                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Business Info</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Services</label>
                                                <textarea id="" cols="30" rows="5" className="form-control"
                                                          name="services"
                                                          value={practice.services}
                                                          onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">About</label>
                                                <textarea id="" cols="30" rows="5" className="form-control" name="about"
                                                          value={practice.about}
                                                          onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3">
                                            <div className="col-12">
                                                <label htmlFor="" className="form-label">Insurance</label>
                                                <textarea id="" cols="30" rows="5" className="form-control"
                                                          name="insurance"
                                                          value={practice.insurance}
                                                          onChange={this.handlePracticeInformationChange}/>
                                            </div>
                                        </div>

                                        {/* <div className="form-row mb-3"> */}
                                            {/* <div className="col-12 mt-3"> */}
                                                <label htmlFor="" className="form-label">Offers COVID-19 Tests?</label>
                                                <div className="w-100 input-group mb-3">
                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault" id="yes" value="yes-covid19tests"
                                                               onChange={this.handleRadioButtonChange}
                                                               checked={this.state.status.offersCOVID19Test}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="yes">
                                                            Yes
                                                        </label>
                                                    </div>

                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault" id="no" value="no-covid19tests"
                                                               onChange={this.handleRadioButtonChange}
                                                               checked={!this.state.status.offersCOVID19Test}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="no">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>

                                            {/* </div> */}

                                            {
                                                this.state.status.offersCOVID19Test &&
                                                <div className="test-group">
                                                    <p>COVID-19 Tests</p>
                                                    {covid19Tests}
                                                </div>
                                            }


                                            <div className="col-12 col-md-8 mt-5">
                                                <label htmlFor="" className="form-label">Offers Antibody Tests?</label>
                                                <div className="w-100 input-group mb-3">
                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault2" id="yes_2"
                                                               value='yes-antibodytests'
                                                               onChange={this.handleRadioButtonChange}
                                                               checked={this.state.status.offersAntibodyTest}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="yes_2">
                                                            Yes
                                                        </label>
                                                    </div>

                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault2" id="no_2"
                                                               value='no-antibodytests'
                                                               onChange={this.handleRadioButtonChange}
                                                               checked={!this.state.status.offersAntibodyTest}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="no_2">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                this.state.status.offersAntibodyTest &&
                                                <div className="test-group">
                                                    <p>Antibody Tests</p>
                                                    {antibodyTests}
                                                </div>
                                            }

                                            <div className="col-12 col-md-8 mt-5">
                                                <label htmlFor="" className="form-label">Offers X-ray?</label>
                                                <div className="w-100 input-group">
                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault3" value='yes-xray' id="yes_3"
                                                               onChange={this.handleRadioButtonChange}
                                                               checked={this.state.practice.xray}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="yes_3">
                                                            Yes
                                                        </label>
                                                    </div>

                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-radio"
                                                               type="radio"
                                                               name="flexRadioDefault3" id="no_3" value='no-xray'
                                                               onChange={this.handleRadioButtonChange}/>
                                                        <label
                                                            className="form-check-label form-control-input-radio-label form-label"
                                                            htmlFor="no_3">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>

                                            {/*TODO*/}
                                            {/*<div className="col-12 col-md-8 mt-5">*/}
                                            {/*    <label htmlFor="" className="form-label">Subscribed for Paid Service?</label>*/}
                                            {/*    <div className="w-100 input-group">*/}
                                            {/*        <div className="float-none float-sm-left mr-4">*/}
                                            {/*            <input className="form-check-input form-control-input-radio" type="radio"*/}
                                            {/*                   name="flexRadioDefault4" id="yes_4"/>*/}
                                            {/*                <label*/}
                                            {/*                    className="form-check-label form-control-input-radio-label form-label"*/}
                                            {/*                    htmlFor="yes_4">*/}
                                            {/*                    Yes*/}
                                            {/*                </label>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="float-none float-sm-left mr-4">*/}
                                            {/*            <input className="form-check-input form-control-input-radio" type="radio"*/}
                                            {/*                   name="flexRadioDefault4" id="no_4"/>*/}
                                            {/*                <label*/}
                                            {/*                    className="form-check-label form-control-input-radio-label form-label"*/}
                                            {/*                    htmlFor="no_4">*/}
                                            {/*                    No*/}
                                            {/*                </label>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}

                                            {/*</div>*/}
                                        {/* </div> */}

                                    </div>

                                {(this.state.user != null && this.state.user.role === 'superadministrator') &&
                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                <div className="form-row mb-2">
                                    <div className="col-12">
                                        <h4 className="form-head-label txt-color-orange">Organization</h4>
                                    </div>
                                </div>

                                <div className="form-row mb-3 mb-md-2">
                                    <div className="col-12 mx-0 ">
                                       Organization if any <a href="/subscribers/organizations/create" className="btn btn-outline btn-outline-purple py-1 float-right text-decoration-none mb-3" target="_blank">
                                            <i className="fas fa-plus mr-1"></i>
                                            <span className="d-none d-sm-inline">Add Organization</span>
                                        </a>
                                                        <select name="organization" 
                                                            // value={`${this.state.newOrganization && this.state.newOrganization}${this.state.organization && !this.state.newOrganization && this.state.organization}`}
                                                            value={this.state.newOrganization}
                                                            id="" onChange={this.handleOrganizationChange} className="form-control px-3 mt-3">
                                                            {this.state.organization &&
                                                                <option value={this.state.organization.code}>{this.state.organization.name} (current)</option>
                                                            }   
                                                                <option value='' default>(None)</option>
                                                            
                                            {this.state.organizations.map((org) => {
                                            const org_code = org.code
                                            return (
                                            <option value={org_code}>{org.name}</option>
                                            );
                                            })
                                            }
                                        </select>
                                            {/* <AddOrganizationOption
                                                handleOrganizationChange={this.handleOrganizationChange}
                                                pratice_id={this.state.practice.practice_id}
                                            /> */}

                                    </div>
                                </div>

                            </div>
                            }

                                    {
                                        (this.state.user != null && this.state.user.role === 'superadministrator') &&
                                        <div className="w-100 mb-4 pb-4 border-bottom-light_blue" disabled>
                                            <div className="form-row mb-2">
                                                <div className="col-12">
                                                    <h4 className="form-head-label txt-color-orange">Tiers</h4>
                                                </div>
                                            </div>

                                            <div className="form-row mb-3 mb-md-2">
                                                <div className="col-12 mx-0 px-0">
                                                    <div className="form-row mx-0 px-0">
                                                        {/*{*/}
                                                        {/*    this.state.tiers.length > 0 ?*/}
                                                        {/*        {tierOptions} :*/}
                                                        {/*        <p>Error loading tiers.</p>*/}
                                                        {/*}*/}

                                                        {/* <div
                                                            className="col-8 mx-0 py-2">
                                                            <input type="radio" id='basic' name="tier_control"
                                                                   className="" value='basic' onChange={this.handleOnChangeTier} checked={this.state.tier==='basic'}/>
                                                            <label htmlFor='basic' style={{
                                                                margin: '0px 10px',
                                                            }}>Basic Tier</label>
                                                        </div> */}

                                                        {tierOptions}


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }

                                        {
                                            (this.state.user != null && this.state.user.role === 'superadministrator') &&
                                            <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                                <div className="form-row mb-2">
                                                    <div className="col-12">
                                                        <h4 className="form-head-label txt-color-orange">Stripe Billing</h4>
                                                    </div>
                                                </div>

                                                <div className="form-row mb-3">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="form-label">Add Stripe Billing URL</label>
                                                        <input type="text" className="form-control" placeholder="" onChange={this.handleStripeUrlChange} value={this.state.stripe_url} />
                                                    </div>
                                                </div>

                                            </div>

                                        }



                                    <div className="w-100 mb-4 pb-4 border-bottom-light_blue">
                                        <div className="form-row mb-2">
                                            <div className="col-12">
                                                <h4 className="form-head-label txt-color-orange">Hours of Operation</h4>
                                            </div>
                                        </div>

                                        <div className="form-row mb-3 mb-md-2">
                                            <div className="col-12 col-md-8 mt-3">
                                                {/*<label htmlFor="" className="form-label"></label>*/}
                                                <div className="w-100 input-group">
                                                    <div className="float-none float-sm-left mr-4">
                                                        <input className="form-check-input form-control-input-checkbox"
                                                               type="checkbox"
                                                               name="flexRadioDefault4c" id="yes_4c"
                                                               value="yes-operates24Hours"
                                                               onChange={this.handleOperationChange}/>
                                                        <label
                                                            className="form-check-label form-control-input-checkbox-label form-label"
                                                            htmlFor="yes_4c">
                                                            Operates 24 hours?
                                                        </label>
                                                    </div>

                                                    {/*<div className="float-none float-sm-left mr-4">*/}
                                                    {/*    <input className="form-check-input form-control-input-radio" type="radio"*/}
                                                    {/*           name="flexRadioDefault4c" id="no_4c" value="no-operates24Hours" onChange={this.handleOperationChange}/>*/}
                                                    {/*        <label*/}
                                                    {/*            className="form-check-label form-control-input-radio-label form-label"*/}
                                                    {/*            htmlFor="no_4c">*/}
                                                    {/*            No*/}
                                                    {/*        </label>*/}
                                                    {/*</div>*/}
                                                </div>

                                            </div>
                                            {/*<div className="col-12 col-md-8 mt-3">*/}
                                            {/*    <label htmlFor="" className="form-label">Operates Overnight</label>*/}
                                            {/*    <div className="w-100 input-group">*/}
                                            {/*        <div className="float-none float-sm-left mr-4">*/}
                                            {/*            <input className="form-check-input form-control-input-radio" type="radio"*/}
                                            {/*                   name="flexRadioDefault4z" id="yes_4z" value="yes-operatesOvernight" onChange={this.handleOperationChange}/>*/}
                                            {/*                <label*/}
                                            {/*                    className="form-check-label form-control-input-radio-label form-label"*/}
                                            {/*                    htmlFor="yes_4z">*/}
                                            {/*                    Yes*/}
                                            {/*                </label>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="float-none float-sm-left mr-4">*/}
                                            {/*            <input className="form-check-input form-control-input-radio" type="radio"*/}
                                            {/*                   name="flexRadioDefault4z" id="no_4z" value="no-operatesOvernight" onChange={this.handleOperationChange}/>*/}
                                            {/*                <label*/}
                                            {/*                    className="form-check-label form-control-input-radio-label form-label"*/}
                                            {/*                    htmlFor="no_4z">*/}
                                            {/*                    No*/}
                                            {/*                </label>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}

                                            {/*</div>*/}
                                        </div>

                                        {/*<div className="form-row mb-3 mb-md-2 mt-4">*/}
                                        {/*    <div className="col-12 mb-2">*/}
                                        {/*        <label htmlFor="" className="form-label m-0">Insurance</label>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-12 col-sm-8">*/}
                                        {/*        <select name="" id="" className="form-control px-3">*/}
                                        {/*            <option value="insurance_1">Insurance 1</option>*/}
                                        {/*            <option value="insurance_2">Insurance 2</option>*/}
                                        {/*            <option value="insurance_3">Insurance 3</option>*/}
                                        {/*        </select>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {
                                            DAYS_OF_THE_WEEK.map((day) => {
                                                const dayDisabled = this.state.status.dailyOperation[day];
                                                const day_record = this.state.schedules.find((schedule) => schedule.occurrence === day);
                                                const time_objects = generateTimeObjects(15);

                                                const opening_options = time_objects.map((time) => {
                                                    return <option value={time.value}
                                                                   selected={day_record && day_record.occurrence === day && day_record.opening === time.value}>{time.display}</option>
                                                });

                                                const closing_options = time_objects.map((time) => {
                                                    return <option value={time.value}
                                                                   selected={day_record && day_record.occurrence === day && day_record.closing === time.value}>{time.display}</option>
                                                });

                                                const checkBoxHidden = this.state.status.operates24Hours;
                                                console.log(checkBoxHidden);

                                                return (
                                                    <div className="form-row mb-3 mb-md-2 mt-4">
                                                        <div className="col-12 mb-2">
                                                            <label htmlFor="" className="form-label m-0">{day}</label>
                                                        </div>
                                                        <div className="col-12 col-sm-4">
                                                            <select name={day + "-opening"} id=""
                                                                    className="form-control px-3"
                                                                    onChange={this.handleScheduleChange}
                                                                    disabled={!dayDisabled}>
                                                                {[
                                                                    <option value="default_schedule">---</option>,
                                                                    ...opening_options
                                                                ]}
                                                            </select>
                                                        </div>

                                                        <div className="col-12 col-sm-4">
                                                            <select name={day + "-closing"} id=""
                                                                    className="form-control px-3"
                                                                    onChange={this.handleScheduleChange}
                                                                    disabled={!dayDisabled}>
                                                                {[
                                                                    <option value="default_schedule">---</option>,
                                                                    ...closing_options
                                                                ]}
                                                            </select>
                                                        </div>

                                                        <div
                                                            className="col-12 col-sm-4 mx-0 py-2 custom-control custom-checkbox custom-control-inline align-items-center"
                                                        >
                                                            <input type="checkbox" id={day + "-closed"}
                                                                   name="customRadioInline1"
                                                                   className="form-control-input-checkbox newClassName"
                                                                   value={day + "-closed"}
                                                                   onChange={this.handleClosedSchedule}
                                                                   checked={!dayDisabled}/>
                                                            <label
                                                                className="form-control-input-checkbox-label form-label m-0"
                                                                htmlFor={day + "-closed"}>Closed</label>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }

                {
                    (this.state.user != null && this.state.user.role === 'superadministrator') &&
                    <div className="form-row mb-3 mb-md-2 mt-4">
                    <div className="col-12 col-md-8 mt-3">
                    <label htmlFor="" className="form-label">Visible In:</label>
                    <div className="w-100 input-group">
                        {
                            PARTNERS.map((partner) => {
                                const isChecked = this.state.partners.find((p) => p === partner.code) ? true : false;
                                return (
                                    <div className="w-100 mr-4 mb-2">
                                        <input
                                            className="form-check-input form-control-input-checkbox"
                                            type="checkbox"
                                            name="flexRadioDefault4c" id={partner.code}
                                            value={partner.code}
                                            onChange={this.handlePartnerChange}
                                            checked={isChecked}
                                        />
                                        <label
                                            className="form-check-label form-control-input-checkbox-label form-label"
                                            htmlFor={partner.code}>
                                            {partner.website}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>

                    </div>

                </div>}
                {
                    this.state.status.errors.length > 0 &&
                    <div className="add-form-errors" style={{
                        color: 'red',}}>
                        <p className="form-errors">{`Form Error${this.state.status.errors.length > 1 ? "s" : ""}`}</p>
                        {this.state.status.errors.map((error) => <p className="form-error"
                            style={{color: 'red',}}><i
                            className="fas fa-exclamation-triangle"></i> {error}</p>)}  
                            </div>
                        }

                                        {/*{*/}
                                        {/*    this.state.status.messages.length > 0 &&*/}
                                        {/*    <div className="add-form-messages">*/}
                                        {/*        <p className="form-messages">{`Message${this.state.status.messages.length > 1 ? "s" : ""}`}</p>*/}
                                        {/*        {this.state.status.messages.map((message) => <p className="form-message"*/}
                                        {/*                                                        style={{*/}
                                        {/*                                                            color: 'green',*/}
                                        {/*                                                        }}><i*/}
                                        {/*            className="fa fa-check-circle" aria-hidden="true"></i> {message}*/}
                                        {/*        </p>)}*/}
                                        {/*    </div>*/}
                                        {/*}*/}


                                    </div>
                        {
                            (this.state.user != null && this.state.user.role === 'external') &&
                            <div className="w-100">
                                <div className="form-row mb-2">
                                    <button className="btn btn-solid btn-solid-orange w-100"
                                        onClick={this.handleSubmit} data-snip_target="submitted_for_approval" data-toggle="modal" data-target="#submit_success">
                                        {buttonContent}
                                    </button>
                                </div>
                            </div>
                        }

                        {
                            (this.state.user != null && this.state.user.role === 'superadministrator') &&

                            <div className="w-100">
							<div className="form-row mb-2">
								<div className="col-md-6 mb-3">
									<button className="btn btn-solid btn-solid-orange w-100" id="btn-snip" data-snip_target="submitted_for_approval" data-toggle="modal" onClick={this.handleSubmit} data-target="#submit_success">
										Submit Changes
									</button>
								</div>
								<div className="col-md-6 mb-3">
									<button className="btn btn-solid btn-outline-purple w-100" id="btn-snip" data-snip_target="submitted_for_delete_approval" data-toggle="modal" data-target="#confirm-delete">
										Delete
									</button>
								</div>
							</div>
						</div>
                        }
                                        
                                </div>

                            </div>
                        </div>
                    </div>
            }

                    /img/declined_location.svg
                <footer>

                            <div className="modal fade" id="submit_success" tabIndex="-1" role="dialog"
                          aria-labelledby="exampleModalCenterTitle" aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content overflow-hidden border-0">
                                <div className="modal-body p-0">
                                    {
                                        this.state.status.isLoading ? <LoadingOverlay/> :
                                            <img src="/img/success_approve.svg" alt="" className="w-100 img-fluid"/>
                                    }

                                    <div className="w-100 p-5">
                                        {
                                            this.state.status.isLoading ? <h4>Submitting request...</h4> :
                                            (this.state.user != null && this.state.user.role === 'superadministrator') ?
                                                <h4 className="txt-color-dark_purple text-center">
                                                    Successfully Updated
                                                </h4>:
                                                <h4 className="txt-color-dark_purple text-center">
                                                Successfully submitted <br/>for approval
                                                </h4>
                                        }
                                        {/*<p className="txt-color-dark_purple text-center my-4">*/}
                                        {/*    <!-- Non tristique sit in semper. Tortor mi nunc. -->*/}
                                        {/*</p>*/}

                                        <button className="btn btn-solid btn-solid-orange w-100 mt-4 d-table"
                                                data-dismiss="modal">
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade p-5" id="confirm-delete" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header border-bottom-0">
							
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<i className="fas fa-times-circle fa-xs"></i>
							</button>
						</div>
						<div className="modal-body p-4">

							<i className="fas fa-exclamation-triangle txt-color-orange fa-5x mx-auto text-center mb-4 d-table"></i>
							<h3 className="h2 font-weight-bold text-center">
								Delete Listing
							</h3>

							<p className="mt-4 text-center">
								Do you want to delete this listing?
							</p>

							<div className="form-row mt-5 px-5">
                                <div className="col-md-6 mb-3">
									<button className="btn btn-outline-purple w-100" data-target="#delete-success" data-toggle="modal" data-dismiss="modal" onClick={this.handleSubmitDelete}>
										Yes
									</button>
								</div>
								<div className="col-md-6 mb-3">
									<button className="btn btn-solid-orange w-100" data-dismiss="modal">
										No
									</button>
								</div>
							</div>

						</div>
						{/* <!-- <div className="modal-footer">
							<a href="#" data-toggle="modal" data-target="#search-location2" className="btn btn-solid btn-solid-orange w-100 " data-dismiss="modal">
								Search
							</a>
						</div> --> */}
					</div>
				</div>
			</div>

			{/* DELETE SUCCESS  */}
			<div className="modal fade p-5" id="delete-success" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header border-bottom-0">
			
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="fas fa-times-circle fa-xs"></i>
							</button>
						</div>
                                <div className="modal-body p-4">

                                    {/* <!-- <i className="fas fa-exclamation-triangle "></i> --> */}
                                    {
                                        this.state.status.isLoading ? <LoadingOverlay/> : this.state.isDeleted ?
                                            <i className="far fa-check-circle txt-color-orange fa-5x mx-auto text-center mb-4 d-table"></i> : <i className="far fa-times-circle txt-color-orange fa-5x mx-auto text-center mb-4 d-table"></i>
                                    }

                                    {
                                        this.state.status.isLoading ? <h4>Submitting request...</h4> : !this.state.isDeleted && <h6 className="font-weight-bold text-center">This listing has a subscription. Cannot be deleted</h6> }

                                    { !this.state.status.isLoading && this.state.isDeleted &&
                                        <>
                                            <h3 className="h2 font-weight-bold text-center">
                                                Success!
                                            </h3>
			
                                            <p className="mt-4 text-center">
                                                You have successfully deleted this listing
                                            </p>
			
                                            <div className="form-row mt-5 px-5">
                                                <div className="col-md-12 mb-3">
                                                    <a href="/listings" className="btn btn-solid-orange w-100">
                                                        OK
                                                    </a>
                                                </div>
                                            </div>
                                        </>
                                    }
                            
						</div>
						{/* <!-- <div className="modal-footer">
										<a href="#" data-toggle="modal" data-target="#search-location2" className="btn btn-solid btn-solid-orange w-100 " data-dismiss="modal">
											Search
										</a>
									</div> --> */}
					</div>
				</div>
			</div>


                </footer>
                </>
        )
    }





}


export default EditListingsViewPage;



