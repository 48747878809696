import React from 'react';
import { Redirect } from "react-router";
import { EMAIL_REGEX_PATTERN } from "../../helpers/accountAPIServices";
import {
    MINIMUM_8_CHARACTERS_REGEX,
    ONE_LOWERCASE_REGEX, ONE_NUMBER_REGEX, ONE_SPECIAL_CHARACTER_REGEX,
    ONE_UPPERCASE_REGEX
} from "../../helpers/passwordManagementServices";
import { register } from "../../helpers/registrationServicesHelper";
import LoadingOverlay from "../Common/LoadingOverlay/LoadingOverlay";
import RegistrationFooterSection from "./RegistrationFooterSection/RegistrationFooterSection";
import RegistrationHeaderSection from "./RegistrationHeaderSection/RegistrationHeaderSection";

class RegistrationFormPage extends React.Component {
    state = {
        details: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            confirm_password: "",
        },

        isLoading: false,

        redirectToLogin: false,

        passwordVisibility: {
            password: false,
            confirm_password: false,
        },

        termsAgreed: false,

        validations: {
            first_name: true,
            last_name: true,
            email: true,
            phone: true,
            password: true,
            confirm_password: true,
            passwordsMatch: true,
        },

        errors: {
            emailExists: false,
        },

        passwordValidation: {
            minimum10Characters: false,
            oneUppercaseLetter: false,
            oneLowercaseLetter: false,
            oneNumber: false,
            oneSpecialCharacter: false,

        }
    }
    
    BASE_URL = process.env.REACT_APP_BASE_URL;

    setLoading = (state) => {
        this.setState({
            isLoading: state,
        });
    }

    handleTermsAndConditionsChange = (event) => {
        const agreed = event.target.checked;

        this.setState({
            termsAgreed: agreed,
        });
    }

    handleFieldChange = (event) => {
        const fieldName = event.target.name;
        let value = event.target.value;

        if(fieldName === "first_name" || fieldName === "last_name") {
            value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        }

        if(fieldName === "email") {
            value = value.toLowerCase();
        }

        const newDetails = {
            ...this.state.details,
        };

        newDetails[fieldName] = value;

        this.setState({
            details: newDetails
        }, () => {
            if(fieldName === "password" || fieldName === "confirm_password") {
                this.checkPasswords();
                return;
            }

            if(fieldName === "email") {

            }
        });


    }

    handlePasswordVisibilityChange = (fieldName) => {
        const newVisibilityStatus = {
            ...this.state.passwordVisibility,
        };

        newVisibilityStatus[fieldName] = !newVisibilityStatus[fieldName];

        this.setState({
            passwordVisibility: newVisibilityStatus,
        });
    }

    handleSubmit = async () => {
        this.setState({
            validations: {
                first_name: this.state.details.first_name !== "",
                last_name: this.state.details.last_name !== "",
                email: this.state.details.email !== "" && EMAIL_REGEX_PATTERN.test(this.state.details.email),
                phone: this.state.details.phone !== "",
                password: this.state.details.password !== "",
                confirm_password: this.state.details.confirm_password !== "",
                passwordsMatch: this.state.details.password === this.state.details.confirm_password,
            },
        }, async () => {
            const tests = [
                this.state.validations.first_name,
                this.state.validations.last_name,
                this.state.validations.email,
                this.state.validations.password,
                this.state.validations.confirm_password,
                this.state.validations.password,
                this.state.validations.passwordsMatch,
                this.state.passwordValidation.oneNumber,
                this.state.passwordValidation.oneSpecialCharacter,
                this.state.passwordValidation.oneUppercaseLetter,
                this.state.passwordValidation.oneLowercaseLetter,
                this.state.passwordValidation.minimum10Characters,
                this.state.termsAgreed,
            ];

            if(!tests.every((test) => test)) {
                return;
            }

            // everything is good
            this.setLoading(true);
            const registerResults = await register(this.state.details);
            if(registerResults.error) {
                if(registerResults.error_code === "REG_EMAIL_EXISTS") {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            emailExists: true,
                        }
                    });

                    this.setLoading(false);
                    return;
                }
            }

            this.setState({
                redirectToLogin: true,
            })
            this.setLoading(false);
        });
    }

    checkPasswords = () => {
        // regex checks
        this.setState({
            passwordValidation: {
                minimum10Characters: MINIMUM_8_CHARACTERS_REGEX.test(this.state.details.password),
                oneUppercaseLetter: ONE_UPPERCASE_REGEX.test(this.state.details.password),
                oneLowercaseLetter: ONE_LOWERCASE_REGEX.test(this.state.details.password),
                oneNumber: ONE_NUMBER_REGEX.test(this.state.details.password),
                oneSpecialCharacter: ONE_SPECIAL_CHARACTER_REGEX.test(this.state.details.password),
                passwordsMatch: this.state.details.password === this.state.details.confirm_password,
            }
        })
    }


    render() {
        return(
            <>
                <RegistrationHeaderSection />

                {
                    this.state.redirectToLogin && <Redirect to='/' />
                }

                <main className="register ">
                    <div className="container-fluid d-block align-items-center justify-content-center  ">


                        <div className="row  ">
                            <div className="order-2 order-md-1  col-md-6 col-12 mt-md-5 mt-3 pt-md-5 ">
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="d-table banner-container px-0 px-sm-5 mt-5">
                                        <img src="/img/logo.svg" alt="" className="mb-4" />
                                            <h2 className="h1 font-weight-bold  ">
                                                Reach more patients nearby by claiming your listing today!
                                            </h2>

                                            <div className="w-100 mt-5">
                                                <p>
                                                    We have thousands of people visting our site daily looking for a
                                                    local urgent care centers. Let us guide them your way. We also have
                                                    a number of programs to help increase your exposure in the medical
                                                    community and on social media.
                                                    <br />
                                                        <br />
                                                            By signing up, you’ll have real time access to easily update
                                                            important information such as:
                                                </p>

                                                <p>
                                                    <ul>
                                                        <li>Hours of Operation</li>
                                                        <li>Medical Care Offered</li>
                                                        <li>COVID-19 Testing and Care</li>
                                                        <li>Insurance Accepted</li>
                                                    </ul>
                                                </p>

                                                <p>
                                                    The UrgentCare.com team is made up of medical and business
                                                    healthcare leaders, with a proven track record in online marketing,
                                                    and we can’t wait to partner with you. Let us do what we do best, so
                                                    you can get back to caring for your community.
                                                </p>

                                                <h3 className="text-center mt-5">
                                                    Sign up now. It’s free, quick and easy.
                                                </h3>
                                            </div>
                                    </div>
                                </div>

                            </div>
                            <div className="order-1 order-md-2 col-md-6 col-12 mb-5">

                                <div className="form-login-container mx-auto">
                                    <div className="WhitespaceLogin">
                                        <div className="w-100 pb-2">
                                            <p className="txt-color-secondary text-right px-3">
                                                Already have an account? <a href={this.BASE_URL}
                                                    className="txt-color-orange">Login</a>
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        this.state.isLoading ? <LoadingOverlay /> :
                                            <div
                                                className="w-100 p-3 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-4 mb-md-5 bg-white">
                                                <h1 className="border-bottom pb-4 txt-color-dark_purple">Sign Up</h1>

                                                <div className=" mt-5  ">
                                                    {/*<div className="alert alert-danger" role="alert">*/}
                                                    {/*    <i className="fa fa-info-circle mr-2 "></i> It Looks like you entered a*/}
                                                    {/*    wrong email or password. Please enter your name.*/}
                                                    {/*</div>*/}

                                                    <div className="form-row mb-4">
                                                        <div className="col-11">
                                                            <div className="form-row">
                                                                <div className="col-12 col-md-6">
                                                                    <label htmlFor="" className="form-label">First Name</label>
                                                                    <input type="text" className="form-control error" placeholder='First name' name='first_name' value={this.state.details.first_name} onChange={this.handleFieldChange}/>

                                                                    {!this.state.validations.first_name && <small className="py-2 d-table txt-color-secondary">
                                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                                    </small>}

                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <label htmlFor="" className="form-label">Last Name</label>
                                                                    <input type="text" className="form-control error" placeholder='Last name' name='last_name' value={this.state.details.last_name} onChange={this.handleFieldChange}/>

                                                                    {!this.state.validations.last_name && <small className="py-2 d-table txt-color-secondary">
                                                                        <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                                    </small>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<div className="col-1 d-flex align-items-start justify-content-center">*/}
                                                        {/*    <i className="fas fa-exclamation-circle py-5 warning-icon active"></i>*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="col-11">
                                                            <label htmlFor="" className="form-label">Email Address</label>
                                                            <input type="email" className="form-control error" placeholder='Email address' name='email' value={this.state.details.email} onChange={this.handleFieldChange}/>

                                                            {!this.state.validations.email && <small className="py-2 d-table txt-color-secondary">
                                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i> Required
                                                            </small>}

                                                            {/*TODO: email validation*/}
                                                            {
                                                                this.state.errors.emailExists &&
                                                                <small className="py-2 d-table txt-color-secondary">
                                                                    <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>
                                                                    Email Already Exists. Want to <a href="https://admin.urgentcare.com"
                                                                                                     className="txt-color-orange text-decoration-underline">
                                                                    <i>login</i>
                                                                </a>, instead?
                                                                </small>}
                                                        </div>
                                                        {/*<div className="col-1 d-flex align-items-start justify-content-center">*/}
                                                        {/*    <i className="fas fa-exclamation-circle py-5 warning-icon active"></i>*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="col-11">
                                                            <label htmlFor="" className="form-label">Mobile Number</label>
                                                            <input className="form-control error" type="tel" id="phone" placeholder='Mobile number' name='phone' value={this.state.details.phone} onChange={this.handleFieldChange}/>

                                                            {!this.state.validations.phone && <small className="py-2 d-table txt-color-secondary">
                                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i> A
                                                                valid phone number is required for verification
                                                            </small>}

                                                        </div>
                                                        {/*<div className="col-1 d-flex align-items-star justify-content-center">*/}
                                                        {/*    <i className="fas fa-exclamation-circle py-5 warning-icon active"></i>*/}
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="col-12 col-lg-6 mb-3">
                                                            <label htmlFor="" className="form-label">Set Password</label>
                                                            {
                                                                this.state.passwordVisibility.password ?
                                                                    <input type="text" className="form-control success" placeholder='' value={this.state.details.password} name='password' onChange={this.handleFieldChange}/>
                                                                    :
                                                                    <input type="password" className="form-control success" placeholder='' value={this.state.details.password} name='password' onChange={this.handleFieldChange}/>

                                                            }
                                                        
                                                            <div className="show_pass-container">
                                                                <input type='password' id="show_pass"
                                                                       className="show_pass" onChange={this.handlePasswordVisibilityChange} name='password'/>
                                                                <label htmlFor="show_pass"
                                                                       className="show_pass-label"><i
                                                                    className={this.state.passwordVisibility.password ? `fas fa-eye txt-color-secondary` :  `fas fa-eye-slash txt-color-secondary`} onClick={() => {this.handlePasswordVisibilityChange('password')}} name='password'></i></label>
                                                            </div>

                                                            {!this.state.validations.password && <small className="py-2 d-table txt-color-secondary">
                                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i> Required
                                                            </small>}
                                                        </div>

                                                        <div className="col-12 col-lg-6 mb-3">
                                                            <label htmlFor="" className="form-label">Confirm Password</label>
                                                            {
                                                                this.state.passwordVisibility.confirm_password ?
                                                                    <input type="text" className="form-control error" placeholder='' value={this.state.details.confirm_password} name='confirm_password' onChange={this.handleFieldChange}/>

                                                                    :
                                                                    <input type="password" className="form-control error" placeholder='' value={this.state.details.confirm_password} name='confirm_password' onChange={this.handleFieldChange}/>

                                                            }
                                                            <div className="show_pass-container">
                                                                <input type="password" name="" id="show_pass_confirm"
                                                                       className="show_pass" />
                                                                <label htmlFor="show_pass_confirm"
                                                                       className="show_pass-label" ><i
                                                                    className={this.state.passwordVisibility.confirm_password ? `fas fa-eye txt-color-secondary` :  `fas fa-eye-slash txt-color-secondary`} onClick={() => {this.handlePasswordVisibilityChange('confirm_password')}} name='confirm_password'></i></label>
                                                            </div>

                                                            {/*TODO: password check*/}
                                                            {!this.state.validations.passwordsMatch && <small className="py-2 d-table txt-color-secondary">
                                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i> Password
                                                                doesn't match
                                                            </small>}

                                                            {!this.state.validations.confirm_password && <small className="py-2 d-table txt-color-secondary">
                                                                <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i> Required
                                                            </small>}
                                                        </div>
                                                    </div>

                                                    <div className="form-row ">
                                                        <small>
                                                            <ul className="list-unstyled ">
                                                                <li><i className={this.state.passwordValidation.minimum10Characters ?
                                                                    `fa fa-check-circle text-success` : `fa fa-times-circle text-danger`
                                                                }></i> Minimum
                                                                    10 characters
                                                                </li>
                                                                <li><i className={this.state.passwordValidation.oneUppercaseLetter ?
                                                                    `fa fa-check-circle text-success` : `fa fa-times-circle text-danger`
                                                                }></i> One
                                                                    uppercase
                                                                </li>
                                                                <li><i className={this.state.passwordValidation.oneLowercaseLetter ?
                                                                    `fa fa-check-circle text-success` : `fa fa-times-circle text-danger`
                                                                }></i> One
                                                                    lowercase
                                                                </li>
                                                                <li><i className={this.state.passwordValidation.oneNumber ?
                                                                    `fa fa-check-circle text-success` : `fa fa-times-circle text-danger`
                                                                }></i> One
                                                                    number
                                                                </li>
                                                                <li><i className={this.state.passwordValidation.oneSpecialCharacter ?
                                                                    `fa fa-check-circle text-success` : `fa fa-times-circle text-danger`
                                                                }></i> One
                                                                    Special Character
                                                                </li>
                                                            </ul>
                                                        </small>
                                                    </div>

                                                    <div className="form-row mb-4">
                                                        <div className="col-12 d-flex my-3">
                                                            <input type="checkbox" className="mr-2 form-control-input-checkbox"
                                                                   id="remember_me_ckbx" name='terms_agreement' onChange={this.handleTermsAndConditionsChange} checked={this.state.termsAgreed}/>
                                                            <label htmlFor="remember_me_ckbx"
                                                                   className="txt-color-secondary pt-2 align-items-start form-control-input-checkbox-label m-0">
                                                                <p>
                                                                    By creating an account, you are agreeing to UrgentCare.com <a
                                                                    href="https://legal.urgentcare.com" className="txt-color-orange">Terms of
                                                                    Service</a> and <a href="https://www.urgentcare.com/privacy-policy"
                                                                                       className="txt-color-orange">Privacy
                                                                    Policy</a>
                                                                </p>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {
                                                        this.state.termsAgreed ?
                                                            <div className="form-row mb-4">
                                                                <div className="col-12">
                                                                    <button className="btn btn-solid btn-solid-orange w-100"
                                                                            data-toggle="modal" onClick={this.handleSubmit}>Submit
                                                                    </button>
                                                                </div>
                                                            </div> :
                                                            <div className="form-row mb-4">
                                                                <div className="col-12">
                                                                    <button className="btn btn-solid btn-solid-orange w-100"
                                                                            data-toggle="modal"
                                                                            style={{
                                                                                backgroundColor: "gray",
                                                                            }}>Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

                <RegistrationFooterSection />
            </>
        )


    }
}

export default RegistrationFormPage;