import React from 'react';
import {Link, Redirect} from "react-router-dom";
import {claimPracticeUsingToken, getMyLocations} from "../../../helpers/accountAPIServices";
import {getAccessTokenFromSession} from "../../../helpers/sessionHelpers";

class ClaimSuccessPage extends React.Component {
    state = {
        redirect: false,
        message: "Please, wait while we process your claim.",
    }

    async componentDidMount() {
    //    Get user's locations to check if claim is already submitted.
        const practice_id = this.props.match.params.practice_id;
        console.log(practice_id);
        const getMyLocationResults = await getMyLocations(getAccessTokenFromSession());
        if(getMyLocationResults.error) {
            this.setState({
                redirect: true,
            })
        }

        const isApproved = getMyLocationResults.approved.find((location) => location.approval_status === "approved" && practice_id === location.practice_id);
        console.log(isApproved);
        if(isApproved) {
            console.log('approved claim');
            this.setState({
                message: "Your claim has been approved.",
            });
            return;
        }

        const isPending = getMyLocationResults.pending_edit.find((location) => location.approval_status === "pending_edit" && practice_id === location.practice_id);
        console.log(isPending);
        if(isPending) {
            console.log('pending claim')
            this.setState({
                message: "You have already submitted this claim.",
            });
            return;
        }

    //    All good? Claim the clinic for the email.

        console.log("CLAIIIMING");
        const claimPracticeResults = await claimPracticeUsingToken(practice_id, getAccessTokenFromSession());
        if(claimPracticeResults.error) {
            console.log('claim error');
            console.log(claimPracticeResults.error);
            this.setState({
                redirect: true,
            })
            return;
        }

        if(claimPracticeResults.success) {
            console.log('claim successful');
            this.setState({
                message: "Your claim has been successfully submitted.",
            });
            return;
        }
    }
    render() {
        return (
            <div className="main-content">
                {
                    this.state.redirect && <Redirect to='/' />
                }
                <div className="container p-3 p-md-5">
                    <div className="row mt-5 mx-0">
                        <div className="col-12 col-lg-6 mb-4">
                            <div className="w-100  rounded-4 overflow-hidden border-light_blue">
                                <img src="/img/green_bg.svg" alt="" className="img-fluid w-100 mb-3" />
                                    <div className="w-100 p-4">
                                        <center>
                                            <span
                                                className="d-block text-center h1 txt-color-dark_purple font-weight-bold border-bottom-light_blue pb-3 mb-3">{this.state.message}</span>
                                        </center>

                                        <div className="w-100 py-4">
                                            <ul className="basic-list">
                                                <li className="txt-color-secondary mb-3">You can check your claims <Link to={'/'}>here</Link>.
                                                </li>
                                            </ul>

                                        </div>

                                    </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClaimSuccessPage;