import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { addNewProduct, editProduct, getProduct, URL_REGEX } from "../../../helpers/productsAPIServices";
import { getAccessTokenFromSession } from "../../../helpers/sessionHelpers";
import HeaderSection from '../../Common/HeaderSection/HeaderSection';
import LoadingOverlay from "../../Common/LoadingOverlay/LoadingOverlay";


const AddProductPage = (props) => {
    const [product, setProduct] = useState({
        name: "",
        description: "",
        redirect_url: "",
        visible_on_locations_admin: true
    });

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [redirectTo404, setRedirectTo404] = useState(false);
    const {code} = useParams();

    useEffect(() => {
        async function loadProduct () {
            setIsLoading(true);
            const getProductResult = await getProduct(code);
            if(getProductResult.error) {
                setRedirectTo404(true);
                return;
            }
            setProduct(getProductResult);
            setIsLoading(false);
            return;
        }

        if(props.edit) {
            loadProduct();
        }
    }, [code, props.edit]);

    const handleFieldChange = (event) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        const newProduct = {
            ...product
        };

        newProduct[fieldName] = value;
        setProduct(newProduct);
    }

    const validFields = () => {
        return [
            product.name !== "",
            product.description !== "",
            product.redirect_url !== "",
            URL_REGEX.test(product.redirect_url),
        ].every((t => t));
    }

    const handleSaveClick = async () => {
        setIsLoading(true);
        const addProductResult = !props.edit ? await addNewProduct(product, getAccessTokenFromSession()) :
                                                await editProduct(code, product, getAccessTokenFromSession());
        if(addProductResult.error) {
            console.log(`Error in ${props.edit ? 'editing the product' : 'adding a new product'}.`);
            console.log(addProductResult.error);
            setError(addProductResult.error);
            return;
        }

        setIsLoading(false);
        setMessage(!props.edit ? 'The new product is successfully added.' :
                                        'The product has been successfully edited.');
    }

    return (
        <>
            {
                redirectTo404 && <Redirect to='/404' />
            }
            <HeaderSection/>

            {/* {!props.location.redirectedFromAdd && <Redirect to='/' />} */}

            <main>
                <div className="main-content">
                    {
                        isLoading ? <LoadingOverlay /> :
                            <div className="container p-3 p-md-5">
                                {
                                    message && <div className="alert alert-success mb-5" role="alert">
                                        <i className="fa fa-check-circle mr-2"></i>{message}
                                    </div>
                                }
                                {
                                    error && <div className="alert alert-success error-message mb-5" role="alert">
                                        <i className="fa fa-exclamation-circle mr-2"></i>{error}
                                    </div>
                                }
                                <div className="row mb-4 p-0 mx-0">

                                    <div className="col-6 px-0 mx-0">
                                        <h2 className="h1 txt-color-dark_purple font-weight-bold">{props.edit ? 'Edit Product' : 'Add Product'}</h2>
                                        <Link to='/products'
                                                                className="mt-4 d-table txt-color-purple text-decoration-none "><i
                                            className="fas fa-arrow-left mr-2"></i> Back to Product</Link>
                                    </div>

                                </div>

                                <div className="row mt-5 mx-0">
                                    <div
                                        className="col-12 col-md-8 col-lg-6 border-light_blue rounded-4 p-3 p-md-5 mx-0">

                                        <div className="col-12 mx-0 px-0">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    <label htmlFor="" className="form-label">Name</label>
                                                    <input type="text" className="form-control" value={product.name}
                                                           name='name' onChange={handleFieldChange}/>
                                                    {
                                                        product.name === "" &&
                                                        <small className="py-2 d-table txt-color-secondary">
                                                            <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                        </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mx-0 px-0">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    <label htmlFor="" className="form-label">Description</label>
                                                    <textarea type="text" className="form-control"
                                                           value={product.description} name='description'
                                                           onChange={handleFieldChange}/>
                                                    {
                                                        product.description === "" &&
                                                        <small className="py-2 d-table txt-color-secondary">
                                                            <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                        </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mx-0 px-0">
                                            <div className="form-row mb-4 mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    <label htmlFor="" className="form-label">Redirect URL</label>
                                                    <input type="text" className="form-control"
                                                           value={product.redirect_url} name='redirect_url'
                                                           onChange={handleFieldChange}/>
                                                    {
                                                        (!URL_REGEX.test(product.redirect_url) || product.redirect_url === "") &&
                                                        <small className="py-2 d-table txt-color-secondary">
                                                            <i className="fas fa-exclamation-circle mr-2 txt-color-orange"></i>Required
                                                        </small>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                        {/*<div class="col-12 mx-0 px-0">*/}
                                        {/*    <div class="form-row mb-4 mx-0 px-0">*/}
                                        {/*        <div class="col-12 mx-0 px-0">*/}
                                        {/*            <label for="" class="form-label">Internat Notes</label>*/}
                                        {/*            <textarea name="" id="" cols="30" rows="6" class="form-control"></textarea>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                        <div className="col-12 mx-0 px-0 pt-4 ">
                                            <div className="form-row mx-0 px-0">
                                                <div className="col-12 mx-0 px-0">
                                                    {
                                                        validFields() ?
                                                            <button
                                                                className="btn btn-solid btn-solid-orange w-100 mb-2"
                                                                onClick={handleSaveClick}>
                                                                Save
                                                            </button> :
                                                            <button
                                                                className="btn btn-solid btn-solid-orange w-100 mb-2"
                                                                style={{
                                                                    backgroundColor: 'gray',
                                                                }}>
                                                                Save
                                                            </button>
                                                    }
                                                    {
                                                        !props.edit && <Link to='/products'
                                                                             className="btn btn-outline btn-outline-orange w-100 ">
                                                            Delete
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                    }
                </div>
            </main>
        </>
    )
}

export default AddProductPage;