import {SESSION_KEY_ACCESS_TOKEN, SESSION_KEY_REFRESH_TOKEN, SESSION_KEY_USER} from "./constants";

const getAccessTokenFromSession = () => {
    return localStorage.getItem(SESSION_KEY_ACCESS_TOKEN);
}

const setAccessTokenToSession = (access_token) => {
    localStorage.setItem(SESSION_KEY_ACCESS_TOKEN, access_token);
}

const getRefreshTokenFromSession = () => {
    return localStorage.getItem(SESSION_KEY_REFRESH_TOKEN);
}

const setRefreshTokenToSession = (refresh_token) => {
    localStorage.setItem(SESSION_KEY_REFRESH_TOKEN, refresh_token);
}

const getUserProfileFromSession = () => {
    return JSON.parse(localStorage.getItem(SESSION_KEY_USER));
}

const setUserProfileToSession = (user) => {
    localStorage.setItem(SESSION_KEY_USER, JSON.stringify(user));
}

export {
    getAccessTokenFromSession,
    setAccessTokenToSession,
    getRefreshTokenFromSession,
    setRefreshTokenToSession,
    getUserProfileFromSession,
    setUserProfileToSession,
}