import React from 'react';
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { EMAIL_REGEX_PATTERN, registerAndClaim } from "../../helpers/accountAPIServices";
import { getPracticeInformation } from "../../helpers/practiceHelpers";
import PleaseWaitButton from "../Buttons/PleaseWaitButton/PleaseWaitButton";

class ClaimPage extends React.Component {
    state = {
        profile: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            password: "",
            confirm_password: "",
        },
        agreedTerms: false,
        errors: [],
        practiceToClaim: this.props.match.params.practice_id,
        practice: null,
        redirect: false,
        redirectToLogin: false,
        isLoading: false,
    }

    async componentDidMount () {
        // get practice first
        const getPracticeResults = await getPracticeInformation(this.state.practiceToClaim);
        if(getPracticeResults.error) {
            this.setState({
                redirect: true,
            });
            return;
        }

        this.setState({
            practice: getPracticeResults
        });
    }

    setLoading = (status) => {
        this.setState({
            isLoading: status,
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if(!this.isValidForm()) {
            return;
        }

    //    TODO: handle different API error responses
        const profile = {
            ...this.state.profile,
            email: this.state.profile.email.toLowerCase(),
        }
        const registerAndClaimResults = await registerAndClaim(profile, this.state.practiceToClaim);

        if(registerAndClaimResults.error) {
            console.log(registerAndClaimResults.error);
            this.setState({
                errors: ["Email address already exists."]
            });
            return;
        }

        this.setState({
            redirectToLogin: true
        });
        return;
    }

    isValidForm = () => {
        const errors = [];
        const profile = this.state.profile;

        if(profile.first_name === "" || profile.last_name === "") {
            errors.push('First name or last name cannot be blank.');
        }

        if(!EMAIL_REGEX_PATTERN.test(profile.email)) {
            errors.push('Invalid email.')
        }

        if(profile.password === "" || profile.confirm_password === "") {
            errors.push('Passwords must not be blanks.')
        }

        if(profile.password !== profile.confirm_password) {
            errors.push('Password mismatch.');
        }

        if(profile.password.length < 10) {
            errors.push('Password must be 10 or more characters long.');
        }

        this.setState({
            errors: errors,
        });

        return errors.length < 1;
    }

    handleAgreedTermsChange = (event) => {
        const agreed = event.target.checked;
        this.setState({
            agreedTerms: agreed,
        });
    }

    handleProfileFieldChange = (event) => {
        const field = event.target.name;
        const newValue = event.target.value;

        const newProfileDetails = {
            ...this.state.profile,
        }

        newProfileDetails[field] = newValue;

        this.setState({
            profile: newProfileDetails,
        }, () => console.log(this.state));
    }

    render() {
        const profile = this.state.profile;
        return (
           <>
               {
                   this.state.redirect && <Redirect to='/404' />
               }

               {
                   this.state.redirectToLogin && <Redirect to='/' />
               }
               <header
                   className="p-4 border-bottom-light_blue d-flex align-items-center justify-content-center position-relative position-xs-relative">
                   <img src="/img/logo.svg" alt="" />
               </header>
               <main>

                <div className="w-100 py-4">
                        <p className="txt-color-secondary text-center">
                                   Already have an account? <Link to={this.state.practice ? `/claim-success/${this.state.practice.practice_id}`: "/"} className="txt-color-orange">Login</Link> to claim your listing.
                        </p>
                </div>

                   <div className="container-fluid d-block d-sm-flex align-items-center justify-content-center vh-100">
                       <div className="login-container-new">


                           <div className="w-100 p-1 p-sm-5 border-light_blue border-xs-0 rounded-5 mt-5">
                               <h4 className="border-bottom pb-4 txt-color-dark_purple">Create New Account</h4>
                               <div>
                                   <p>You are claiming:</p>
                                   <p style={{
                                       color: 'violet',
                                   }}><i className="fa fa-medkit" aria-hidden="true"></i> {this.state.practice ? this.state.practice.name : ""}</p>
                                   <p><i className="fa fa-location-arrow" aria-hidden="true"></i>{this.state.practice ? `${this.state.practice.address}, ${this.state.practice.city}, ${this.state.practice.state} ${this.state.practice.zip}`: ""}</p>
                               </div>


                               <div className="form-control-wrap mt-5">

                                   <div className="form-row mb-4">
                                       <div className="col-12 col-md-6">
                                           <label htmlFor="" className="form-label">First Name</label>
                                           <input type="text" className="form-control" name='first_name' value={profile.first_name} onChange={this.handleProfileFieldChange}/>
                                       </div>
                                       <div className="col-12 col-md-6">
                                           <label htmlFor="" className="form-label">Last Name</label>
                                           <input type="text" className="form-control" name='last_name' value={profile.last_name}  onChange={this.handleProfileFieldChange}/>
                                       </div>
                                   </div>

                                   <div className="form-row mb-4">
                                       <div className="col-12">
                                           <label htmlFor="" className="form-label">Email Address</label>
                                           <input type="email" className="form-control" name='email' value={profile.email} onChange={this.handleProfileFieldChange}/>
                                       </div>
                                   </div>

                                   <div className="form-row mb-4">
                                       <div className="col-12">
                                           <label htmlFor="" className="form-label">Phone Number</label>
                                           <input type="email" className="form-control" name='phone' value={profile.phone} onChange={this.handleProfileFieldChange}/>
                                       </div>
                                   </div>

                                   <div className="form-row mb-4">
                                       <div className="col-12">
                                           <label htmlFor="" className="form-label">Set Password</label>
                                           <input type="password" className="form-control" name='password' value={profile.password} onChange={this.handleProfileFieldChange}/>
                                       </div>
                                   </div>

                                   <div className="form-row mb-4">
                                       <div className="col-12">
                                           <label htmlFor="" className="form-label">Confirm Password</label>
                                           <input type="password" className="form-control" name='confirm_password' value={profile.confirm_password} onChange={this.handleProfileFieldChange}/>
                                       </div>
                                   </div>

                                   <div className="form-row mb-4">
                                       <div className="col-12 d-flex my-3">
                                           <input type="checkbox" className="mr-2 form-control-input-checkbox"
                                                  id="remember_me_ckbx" checked={this.state.agreedTerms} onChange={this.handleAgreedTermsChange}/>
                                               <label htmlFor="remember_me_ckbx"
                                                      className="txt-color-secondary pt-2 align-items-start form-control-input-checkbox-label m-0">
                                                   <p>
                                                       By creating an account, you are agreeing to UrgentCare.com <a href="https://www.urgentcare.com/terms-and-conditions"
                                                                                                                className="txt-color-orange">Terms
                                                       of Service</a> and <a href="https://www.urgentcare.com/privacy-policy" className="txt-color-orange">Privacy
                                                       Policy</a>
                                                   </p>
                                               </label>
                                       </div>


                                   </div>

                                    {
                                        this.state.agreedTerms ?
                                            <div className="form-row mb-4">
                                                <div className="col-12">
                                                    <a href="void(0);"
                                                        className="btn btn-solid btn-solid-orange w-100" onClick={this.handleSubmit}> {
                                                            this.state.isLoading ? <PleaseWaitButton loadingMessage="" /> : "Submit"
                                                        }</a>

                                                </div>
                                            </div> :
                                            <div className="form-row mb-4">
                                                <div className="col-12">
                                                    <a href="void(0);" onClick={(e)=>{e.preventDefault()}}
                                                        className="btn btn-solid btn-solid-orange w-100" style={{
                                                            backgroundColor: 'gray',
                                                            cursor: "default",
                                                        }}>Submit</a>
                                                </div>
                                            </div>

                                   }
                                   {
                                       this.state.errors.length > 0 &&
                                       <div className="form-errors">
                                           {
                                               this.state.errors.map((error) => {
                                                   return <p className="error" style={{
                                                       color: 'red',
                                                   }}>{error}</p>
                                               })
                                           }
                                       </div>
                                   }

                               </div>
                           </div>

                           
                       </div>
                   </div>
               </main>
               <footer>

                   <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TimelineLite.min.js"
                           integrity="sha512-UznfQR3LKkKPL0k7HISMqOsmUCjVhjWK8HlR0Pxo5JpjN8eZ8XRwKBRX6OTOQIO0FYOl8Oue+4dG4uHtx1SgKQ=="
                           crossOrigin="anonymous"></script>
                   <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                           integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                           crossOrigin="anonymous"></script>
                   <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                           integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                           crossOrigin="anonymous"></script>
                   <script src="animation.js"></script>
                   <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.6.0/gsap.min.js"
                           integrity="sha512-1dalHDkG9EtcOmCnoCjiwQ/HEB5SDNqw8d4G2MKoNwjiwMNeBAkudsBCmSlMnXdsH8Bm0mOd3tl/6nL5y0bMaQ=="
                           crossOrigin="anonymous"></script>
                   <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/plugins/CSSPlugin.min.js"
                           integrity="sha512-Xc+rcY3w0DANwcFGWBcnBf2dw/p5a4IBAKqHdBJScNOnIH+qoQYE6xuWEdW3wiP7Q+XvL9MacrnKkzoP0Kexug=="
                           crossOrigin="anonymous"></script>
               </footer>
           </>
        )
    }
}

export default ClaimPage;